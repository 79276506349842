import React, {useState, createContext, useContext} from 'react';
import {UserContext} from './UserContext';

export const KiwiPayContext = createContext();

export const KiwiPayProvider = ({children}) => {
    const {
        state: userState,
        userTracking,
        deleteUserTracking,
    } = useContext(UserContext);

    const initialState = {};

    const [state, setState] = useState(() => {
        const {
            kiwi_pay: {tracking},
        } = userState.products;

        const state = tracking?.context;

        if (state === undefined) {
            return initialState;
        }

        return state;
    });

    const saveState = async state => {
        setState(state);
        const response = await userTracking({
            status: 'kiwi-pay',
            context: state,
        });

        return response;
    };

    const resetState = async () => {
        setState(initialState);

        const response = await deleteUserTracking({
            status: 'kiwi-pay',
        });

        return response;
    };

    const providerValues = {
        /** State */
        state,
        resetState,
        setState,
        saveState,
        initialState,
    };

    return (
        <KiwiPayContext.Provider value={providerValues}>
            {children}
        </KiwiPayContext.Provider>
    );
};
