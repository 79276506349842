import PropTypes from "prop-types";

const SendLine = ({ className, ...props }) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.991 3.00909L3.39886 7.5637L7.59362 9.9922L11.293 6.29283C11.6835 5.90231 12.3167 5.90231 12.7072 6.29283C13.0977 6.68336 13.0977 7.3165 12.7072 7.707L9.0078 11.4064L11.4364 15.6012L15.991 3.00909ZM16.3138 0.765523C17.5093 0.333103 18.667 1.49076 18.2345 2.68629L12.952 17.2908C12.5185 18.4895 10.882 18.6354 10.2433 17.5322L7.02565 11.9744L1.46786 8.7567C0.364689 8.1181 0.510499 6.4816 1.70921 6.04802L16.3138 0.765523Z"
      fill="currentColor"
    />
  </svg>
);

SendLine.propTypes = {
  className: PropTypes.string,
};

export default SendLine;
