import  { useEffect, useContext } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { RequestLoanContext } from 'context/RequestLoanContext'

export default function RequestLoan() {
    const location = useLocation()
    const navigate = useNavigate()
    const { state: requestState } = useContext(RequestLoanContext)

    useEffect(() => {
        const reviewingPath = '/request-loan/reviewing'

        if (location.pathname !== reviewingPath && requestState.reviewing?.completed) {
            navigate(reviewingPath)
        }
    }, [])

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <Outlet />
        </div>
    )
}
