import {PERSONAL_LOAN_STATUS} from 'constants';
import {UserContext} from 'context/UserContext';
import dayjs from 'dayjs';
import {useContext, useMemo} from 'react';

export function useIsFirstMoneyOnTheWay() {
    const {state: userState} = useContext(UserContext);
    const {
        products: {
            personal_loan: {status, loan},
        },
    } = userState;

    const isMoneyOnTheWay =
        status === PERSONAL_LOAN_STATUS.approved && loan?.type === '';

    return isMoneyOnTheWay;
}

export function useIsSecondMoneyOnTheWay() {
    const {state: userState} = useContext(UserContext);
    const {
        products: {
            personal_loan: {status, loan},
        },
    } = userState;

    const currentDate = dayjs();
    const fundedDate = dayjs(loan?.db?.funded_at);
    const fundedDiffDate = currentDate.diff(fundedDate, 'hour');

    return (
        status === PERSONAL_LOAN_STATUS.current &&
        loan?.db?.loan_quantity > 1 &&
        fundedDiffDate <= 6
    );
}

export function usePersonalLoanStatus() {
    const {state: userState} = useContext(UserContext);

    const isFirstMoneyOnTheWay = useIsFirstMoneyOnTheWay();
    const isSecondMoneyOnTheWay = useIsSecondMoneyOnTheWay();

    const {
        products: {
            personal_loan: {status, tracking, loan, application},
        },
    } = userState;

    const personalLoanContext = tracking?.context ?? {};

    return useMemo(() => {
        const isPersonalLoanApply =
            status === PERSONAL_LOAN_STATUS.apply &&
            !personalLoanContext.reviewing?.completed;

        const isPersonalLoanReviewing =
            status === PERSONAL_LOAN_STATUS.apply &&
            personalLoanContext.reviewing?.completed;

        const isPersonalLoanNeedVerificationCall = Boolean(
          application?.need_verification_call,
        );

        const isPersonalLoanApproved = status === PERSONAL_LOAN_STATUS.approved;

        const isPersonalLoanMoneyOnWay =
            isFirstMoneyOnTheWay || isSecondMoneyOnTheWay;

        const isPersonalLoanMesalve =
            status === PERSONAL_LOAN_STATUS.approved &&
            loan?.type === 'mesalve';

        const isPersonalLoanRejected = status === PERSONAL_LOAN_STATUS.rejected;

        const isPersonalLoanActive = status === PERSONAL_LOAN_STATUS.current;

        return {
            isPersonalLoanApply,
            isPersonalLoanReviewing,
            isPersonalLoanNeedVerificationCall,
            isPersonalLoanApproved,
            isPersonalLoanMoneyOnWay,
            isFirstMoneyOnTheWay,
            isSecondMoneyOnTheWay,
            isPersonalLoanMesalve,
            isPersonalLoanRejected,
            isPersonalLoanActive,
            personalLoanContext,
        };
    }, [status, tracking, loan, application]);
}
