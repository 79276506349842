import { useNavigate } from "react-router-dom";
import RowIcon from "assets/rewards/row";
import { RewardsPaths } from "../types";
import { cs } from "utils/cs";
import formatToUSD from "utils/formatToUSD";
import PropTypes from "prop-types";

export const CardMoney = ({ money }) => {
  const navigate = useNavigate();
  return (
    <div className="flex row border-2 rounded-xl p-4 justify-between">
      <div className=" flex flex-col ">
        <span className="text-regal-blue-950-kiwi text-xs font-semibold opacity-50">
          Monto total
        </span>
        <h3 className="text-regal-blue-950-kiwi text-3xl">
          {formatToUSD(money)}
        </h3>
      </div>
      <button
        onClick={() =>
          navigate(RewardsPaths.MAKE_PAYMENT, { state: { money } })
        }
        className={cs([
          "bg-regal-blue-950-kiwi rounded-full gap-1 button px-3 py-1.5 w-fit h-fit flex-row text-center items-center justify-center",
          {
            hidden: !money,
            flex: !!money,
          },
        ])}
      >
        <p className="text-white text-xs font-semibold text-center">Retirar</p>
        <RowIcon />
      </button>
    </div>
  );
};

CardMoney.propTypes = {
  money: PropTypes.number,
};

CardMoney.defaultProps = {
  money: 0,
};
