import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import backIcon from 'assets/icons/back.svg';
import SmallSuccessBadge from 'assets/small_success_badge.svg';
import Warning from 'components/Warning';
import moneyFormat from 'utils/moneyFormat';
import {UserContext} from 'context/UserContext';
import {CREDIT_LIMIT_STATUS} from 'constants';
import {CreditLimitContext} from 'context/CreditLimitContext';
import Button from 'components/Button';
import {ProductsAPI} from 'api';

export default function CreditLimitSuccess() {
    const navigate = useNavigate();
    const {updateSurvey} = useContext(CreditLimitContext);
    const {state: userState, setState: setUserState} = useContext(UserContext);
    const {
        products: {
            personal_loan: {credit_limit},
        },
    } = userState;

    const [newLimit] = useState(credit_limit.new_credit_limit);
    const [oldLimit] = useState(credit_limit.old_credit_limit);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (credit_limit?.status !== CREDIT_LIMIT_STATUS.approved) {
            return navigate('/home');
        }
    }, []);

    const onBackward = () => {
        navigate('/home');
    };

    const onContinue = async () => {
        if (loading === true) {
            return;
        }

        try {
            setLoading(true);

            const {
                data: {success},
            } = await updateSurvey(credit_limit.id, {
                closed: true,
            });

            if (success) {
                const {
                    data: {data: personal_loan},
                } = await ProductsAPI.getPersonalLoan();

                setUserState({
                    ...userState,
                    products: {
                        ...userState.products,
                        personal_loan,
                    },
                });

                navigate('/approved-loan/amount', {
                    state: {
                        newLimit,
                        oldLimit,
                    },
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <img
                className="ml-2 mb-12 cursor-pointer"
                width="8"
                src={backIcon}
                onClick={onBackward}
            />

            <div className="flex flex-col justify-center  mb-auto">
                <img className="w-28 mx-auto" src={SmallSuccessBadge} />
                <h1 className="text-dark-kiwi font-semibold mb-6 text-2xl text-center">
                    ¡Felicitaciones!
                </h1>

                <p className="p-4 bg-gray-50-kiwi text-sm mb-6 text-center">
                    Hemos <strong>aumentado tu límite</strong> de crédito
                </p>

                <div className="flex flex-col gap-4">
                    <div className="flex justify-between gap-2">
                        <div>Nuevo límite de crédito</div>
                        <b>${moneyFormat(newLimit)}</b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div>Límite de crédito anterior</div>
                        <b>${moneyFormat(oldLimit)}</b>
                    </div>
                </div>
            </div>

            <div>
                <Warning className="mt-10">
                    <strong className="block">Tengo en cuenta</strong>
                    Puedes acceder a tu nuevo límite de crédito de inmediato
                </Warning>
                <Button
                    className="bg-blue-kiwi text-white rounded-xl p-3 mt-10 w-full"
                    loading={loading}
                    onClick={onContinue}>
                    Continuar
                </Button>
            </div>
        </Fragment>
    );
}
