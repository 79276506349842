import PlaidTemplateV2 from "templates/PlaidV2";
import { useNavigate } from "react-router-dom";
const BankAccountsPlaid = () => {
  const navigate = useNavigate();

  const onBackward = () => {
    navigate("/bank-accounts");
  };

  return (
    <PlaidTemplateV2
      title="Enlazar cuenta de banco"
      description="Vincula tu cuenta de bancaria de forma automática, asegúrate de que:"
      redirectPath="/bank-accounts/success"
      onBackward={onBackward}
    />
  );
};

export default BankAccountsPlaid;
