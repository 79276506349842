import Barcode from 'react-barcode';
import  {useEffect, useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from 'context/UserContext';
import moneyFormat from 'utils/moneyFormat';

import StoresModal from 'components/Modals/Stores';
import BarcodeModal from 'components/Modals/Barcode';
import Advisement from 'components/Advisement';

import supportIcon from 'assets/icons/support.svg';
import {
    CurrencyDollarIcon,
    IDCardIcon,
    Location2Icon,
} from 'assets/icons_v3/fonts';
import BackwardButton from 'components/Button/Backward';

export default function MesalveCode() {
    const navigate = useNavigate();
    const {state: userState} = useContext(UserContext);
    const [code, setCode] = useState('...');
    const [storeVisible, setStoreVisible] = useState(false);
    const [barcodeVisible, setBarcodeVisible] = useState(false);
    const [store, setStore] = useState('');

    const {
        personal_loan: {loan},
    } = userState.products;

    useEffect(() => {
        if (loan.type !== 'mesalve') {
            navigate('/home');
        }

        setCode(loan?.short_id);
    }, []);

    /**
     * Stores modal
     */
    const onOpenStoreModal = () => {
        setStoreVisible(true);
    };

    const onCloseStoreModal = () => {
        setStoreVisible(false);
    };

    const onSelectStore = store => {
        setStore(store);
    };

    /**
     * Barcode modal
     */
    const onOpenBarcodeModal = () => {
        setBarcodeVisible(true);
    };

    const onCloseBarcodeModal = () => {
        setBarcodeVisible(false);
    };

    const onBackward = () => {
        navigate('/home');
    };

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <StoresModal
                isVisible={storeVisible}
                selectedStore={store}
                closeModal={onCloseStoreModal}
                selectStore={onSelectStore}
            />

            <BarcodeModal
                isVisible={barcodeVisible}
                code={code}
                closeModal={onCloseBarcodeModal}
            />

            <BackwardButton onBackward={onBackward} />

            <div className="flex flex-col mb-10">
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Muestra el código para obtener el efectivo
                </h1>

                <p className="mb-10">
                    Con el representante de la tienda puedes validar el código
                </p>

                <div className="p-6 bg-gray-100-kiwi rounded-lg">
                    <div className="font-semibold text-xl text-center mb-2">
                        {code}
                    </div>
                    <div className="p-2 bg-white flex justify-center rounded">
                        <Barcode
                            width={3}
                            value={code}
                            displayValue={false}></Barcode>
                    </div>
                </div>

                <small
                    className="mt-4 mx-auto text-blue-kiwi cursor-pointer"
                    onClick={onOpenBarcodeModal}>
                    Ampliar código
                </small>
            </div>

            <div className="mt-auto flex flex-col gap-4 text-sm">
                <div className="flex items-center mr-4">
                    <div className="h-10 w-10 bg-slate-100-kiwi mr-4 grow-0 shrink-0 rounded-full flex">
                        <Location2Icon className="m-auto w-6 text-regal-blue-kiwi" />
                    </div>
                    <div>
                        <b>Ubicaciones tienda Me Salvé:</b>{' '}
                        {store !== '' && (
                            <span>
                                {store.store} - {store.address} -{' '}
                            </span>
                        )}
                        <span
                            className="text-blue-kiwi cursor-pointer"
                            onClick={onOpenStoreModal}>
                            {store === ''
                                ? 'Ver todas las ubicaciones donde puedes recoger el efectivo'
                                : 'Ver todas las ubicaciones'}
                        </span>
                    </div>
                </div>
                <div className="flex items-center mr-4">
                    <div className="h-10 w-10 bg-slate-100-kiwi mr-4 grow-0 shrink-0 rounded-full flex">
                        <CurrencyDollarIcon className="m-auto w-6 text-regal-blue-kiwi" />
                    </div>
                    <div>
                        <b>Monto a recoger:</b> ${moneyFormat(loan?.amount)}
                    </div>
                </div>
                <div className="flex items-center mr-4">
                    <div className="h-10 w-10 bg-slate-100-kiwi mr-4 grow-0 shrink-0 rounded-full flex">
                        <IDCardIcon className="m-auto w-6 text-regal-blue-kiwi" />
                    </div>
                    <div>
                        <b>Requerimiento:</b> Debes traer un ID estatal,
                        licencia de conducir o pasaporte
                    </div>
                </div>

                <Advisement className="mt-10" icon="money_shop">
                    Los desembolsos están sujetos a disponibilidad de la tienda.
                </Advisement>
            </div>

            <a
                href="tel:+17874221861"
                className="flex justify-center items-center rounded-xl p-3 mt-10 bg-blue-kiwi text-white">
                <img className="mr-2" src={supportIcon} />
                ¿Necesitas ayuda?
            </a>
        </div>
    );
}
