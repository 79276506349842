import { UserContext } from "context/UserContext";
import React, { useContext, useState } from "react";
import { ArrowRightIcon } from "assets/icons_v3/fonts";
import {
  usePersonalLoanRedirect,
  usePersonalLoanStatus,
} from "hooks/personalLoan";

export default function HomeStatusProgress() {
  const { getLoanFundingDate } = useContext(UserContext);

  const personalLoanRedirect = usePersonalLoanRedirect();
  const {
    isPersonalLoanNeedVerificationCall,
    isPersonalLoanMoneyOnWay,
    isPersonalLoanMesalve,
    isPersonalLoanReviewing,
    isPersonalLoanApproved,
    isPersonalLoanActive,
  } = usePersonalLoanStatus();

  const [statusDescription] = useState(() => {
    if (isPersonalLoanReviewing)
      return {
        title: "Revisando tu solicitud",
        description: "Estamos analizando tu solicitud de préstamo. ¡Ya casi!",
      };

    if (isPersonalLoanMesalve)
      return {
        title: "¡Recoge tu efectivo!",
        description:
          "Te invitamos a recoger tu efectivo en los comercios participantes",
      };

    if (isPersonalLoanNeedVerificationCall)
      return {
        title: "Llamada de verificación",
        description:
          "¡Te llamaremos pronto! Queremos verificar tu información dentro de las próximas 12 horas",
      };

    if (isPersonalLoanMoneyOnWay)
      return {
        title: "Dinero en camino",
        description: `Recibirás tu dinero en tu cuenta, se espera que los fondos lleguen el día <strong>${getLoanFundingDate()}</strong>`,
      };

    if (isPersonalLoanApproved)
      return {
        title: "Préstamo aprobado",
        description:
          "¡Felicitaciones! puedes seguir con el proceso para obtener tu dinero",
      };

    if (isPersonalLoanActive)
      return {
        title: "Préstamo activo",
        description: "",
      };
  });

  const [progressBarWidth] = useState(() => {
    if (isPersonalLoanReviewing) return "w-1/4";
    if (isPersonalLoanNeedVerificationCall) return "w-3/4";
    if (isPersonalLoanMoneyOnWay || isPersonalLoanMesalve) return "w-3/4";
    if (isPersonalLoanApproved) return "w-1/2";
    if (isPersonalLoanActive) return "w-full";
  });

  const RenderCTAButton = () => {
    if (isPersonalLoanMesalve)
      return (
        <div
          onClick={personalLoanRedirect}
          className="cursor-pointer mt-6 rounded-xl px-4 py-1 bg-regal-blue-kiwi text-xs text-white font-medium flex justify-center items-center"
        >
          Obtener efectivo <ArrowRightIcon className="w-5 ml-1" />
        </div>
      );

    if (isPersonalLoanNeedVerificationCall)
      return (
        <div
          onClick={personalLoanRedirect}
          className="cursor-pointer mt-6 rounded-xl px-4 py-1 bg-regal-blue-kiwi text-xs text-white font-medium flex justify-center items-center"
        >
          Validar llamada <ArrowRightIcon className="w-5 ml-1" />
        </div>
      );

    if (isPersonalLoanApproved)
      return (
        <div
          onClick={personalLoanRedirect}
          className="cursor-pointer mt-6 rounded-xl px-4 py-1 bg-regal-blue-kiwi text-xs text-white font-medium flex justify-center items-center"
        >
          Continuar proceso <ArrowRightIcon className="w-5 ml-1" />
        </div>
      );
  };

  return (
    <div className="p-4 rounded-xl bg-slate-100-kiwi">
      <h3 className="font-semibold text-dark-kiwi mb-1">
        {statusDescription?.title}
      </h3>
      <p
        className="text-xs mb-4 text-slate-700-kiwi"
        dangerouslySetInnerHTML={{
          __html: statusDescription?.description,
        }}
      ></p>

      <div className="mb-2">
        <div className="h-1 bg-slate-200-kiwi rounded-xl flex justify-between relative">
          <div
            className={`-mt-0.5 w-2 h-2 rounded-full z-10 bg-blue-kiwi ${
              isPersonalLoanReviewing
                ? "[&~:not(:last-child)]:bg-slate-200-kiwi"
                : null
            }`}
          ></div>
          <div
            className={`-mt-0.5 w-2 h-2 rounded-full z-10 bg-blue-kiwi ${
              isPersonalLoanApproved ||
              isPersonalLoanMoneyOnWay ||
              isPersonalLoanMesalve
                ? "[&~:not(:last-child)]:bg-slate-200-kiwi"
                : null
            }`}
          ></div>
          <div
            className={`-mt-0.5 w-2 h-2 rounded-full z-10 bg-blue-kiwi ${
              isPersonalLoanActive
                ? "[&~:not(:last-child)]:bg-slate-200-kiwi"
                : null
            }`}
          ></div>
          <div
            className={`h-1 rounded-xl bg-blue-kiwi absolute ${progressBarWidth}`}
          ></div>
        </div>
      </div>

      <div className="text-xs flex justify-between text-slate-700-kiwi">
        <div
          className={`w-1/3 text-left ${
            isPersonalLoanReviewing ? "[&~div]:text-slate-400-kiwi" : null
          }`}
        >
          Revisión
        </div>
        <div
          className={`w-1/3 text-center ${
            isPersonalLoanApproved ||
            isPersonalLoanMoneyOnWay ||
            isPersonalLoanMesalve
              ? "[&~div]:text-slate-400-kiwi"
              : null
          }`}
        >
          Aprobación
        </div>
        <div
          className={`w-1/3 text-right ${
            isPersonalLoanActive ? "[&~div]:text-slate-400-kiwi" : null
          }`}
        >
          Desembolso
        </div>
      </div>

      <RenderCTAButton />
    </div>
  );
}
