import { useEffect, useState } from "react";
import Star from "assets/icons_v3/fonts/star";
import BaseModal from "../BaseModal";
import Button from "components/Button";
import { RatingAPI } from "api";

const rating_reason = [
  { id: "10803", value: "Estados de cuenta" },
  { id: "10804", value: "Validación de ID" },
  { id: "10805", value: "Perfil de pago" },
  { id: "10807", value: "Monto del préstamo" },
  { id: "11362", value: "Intereses del préstamo" },
  { id: "11363", value: "Términos del préstamo" },
];

const RATINGS_REQUIRING_FEEDBACK = new Set([1, 2, 3]);

const RatingModal = ({ isVisible, closeModal, ratingStatus }) => {
  const [score, setScore] = useState(0);
  const [description, setDescription] = useState(null);
  const [ratingReason, setRatingReason] = useState(null);
  const [hasReason, setHasReason] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const createRating = async () => {
    setLoading(true);
    try {
      await RatingAPI.createRating({
        score,
        description: description ?? "",
        rating_reason: RATINGS_REQUIRING_FEEDBACK.has(score)
          ? ratingReason
          : null,
      });
      ratingStatus(score);
    } finally {
      setLoading(false);
    }
  };

  const onContinue = () => {
    if (!score) return;

    if (!RATINGS_REQUIRING_FEEDBACK.has(score)) return createRating();

    if (hasReason) return createRating();

    if (RATINGS_REQUIRING_FEEDBACK.has(score) && ratingReason) {
      setHasReason(true);
      setIsDisabled(true);
      return;
    }
  };

  const statusRating = () => {
    switch (score) {
      case 1:
        return "No me gustó";
      case 2:
        return "Podría mejorar";
      case 3:
        return "Estuvo bien";
      case 4:
        return "Me gustó";
      case 5:
        return "¡Me encantó!";
      default:
        return null;
    }
  };

  const handleClose = () => {
    closeModal();
  };

  useEffect(() => {
    if (score) {
      setHasReason(false);
      setRatingReason(null);
      setDescription(null);
    }
  }, [score]);

  return (
    <BaseModal isVisible={isVisible} closeModal={handleClose} title=" ">
      <div className="flex flex-col items-center justify-center">
        <p className="text-regal-blue-950-kiwi text-xl text-center font-semibold pb-2">
          Califica tu movimiento
        </p>
        <p className="text-center text-gray-700-kiwi mb-8">
          Tu opinión nos ayuda a mejorar. ¡Déjanos tu calificación!
        </p>
        <div className="flex flex-col items-center w-full gap-2">
          <div className="flex items-center w-max">
            {Array.from({ length: 5 }).map((_, index) => (
              <button
                key={index}
                className="mx-2"
                disabled={loading}
                onClick={() => {
                  setScore(index + 1);
                  if (!RATINGS_REQUIRING_FEEDBACK.has(index + 1)) {
                    return setIsDisabled(false);
                  }
                  setIsDisabled(true);
                }}
              >
                <Star
                  className={`${
                    index + 1 <= score ? "text-[#F99307]" : "text-slate-200"
                  }  w-10 h-10`}
                />
              </button>
            ))}
          </div>
          {!!score && (
            <p className="text-xs text-slate-500`">{statusRating()}</p>
          )}
        </div>
        {RATINGS_REQUIRING_FEEDBACK.has(score) && (
          <div className="flex flex-col items-center justify-center gap-6 w-full mt-8">
            <div className="flex flex-col items-center justify-center">
              <p className="text-regal-blue-950-kiwi text-center">
                ¿Cómo podemos mejorar?
              </p>
              {!hasReason && (
                <p className="text-center text-slate-600 text-sm">
                  Escoge una opción
                </p>
              )}
            </div>
            {!hasReason && (
              <div className="flex flex-wrap items-center justify-center gap-1.5">
                {rating_reason.map((reason, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      setRatingReason(reason.id);
                      setIsDisabled(false);
                    }}
                    className={`text-xs font-light  px-3 py-2 rounded-full border  ${
                      ratingReason === reason.id
                        ? "border-blue-kiwi text-slate-700 bg-blue-kiwi/10"
                        : "border-slate-200 text-slate-500"
                    }`}
                  >
                    {reason.value}
                  </button>
                ))}
              </div>
            )}
            {hasReason && (
              <textarea
                rows="3"
                className="w-full border border-slate-200 bg-slate-50 rounded-lg text-sm placeholder:text-slate-400 focus:outline-none focus:ring-0 focus:border-slate-200"
                placeholder="Cuéntanos más sobre tu experiencia"
                disabled={loading}
                onChange={(e) => {
                  setDescription(e.target.value);
                  if (e.target.value?.[2]) return setIsDisabled(false);
                  setIsDisabled(true);
                }}
              />
            )}
          </div>
        )}
        {!!score && (
          <Button
            className="text-white rounded-xl p-3 mt-10 text-base w-full"
            type="button"
            loading={loading}
            disabled={isDisabled || loading}
            onClick={onContinue}
          >
            {(RATINGS_REQUIRING_FEEDBACK.has(score) && !hasReason) || !score
              ? "Continuar"
              : "Calificar"}
          </Button>
        )}
      </div>
    </BaseModal>
  );
};

export default RatingModal;
