import Skeleton from "react-loading-skeleton";

const style = {
  baseColor: "#f6f8fa",
  highlightColor: "#fefefe",
  borderRadius: 16,
};

const SkeletonBlock = ({ height, width = "100%" }) => (
  <Skeleton
    baseColor={style.baseColor}
    highlightColor={style.highlightColor}
    height={height}
    borderRadius={style.borderRadius}
    width={width}
  />
);

const SkeletonAccounts = () => {
  return (
    <>
      <div className="w-full flex flex-col gap-10">
        <div className="flex flex-col gap-4">
          <SkeletonBlock height={32} width="80%" />
          <div className="w-full flex flex-col">
            <SkeletonBlock height={16} />
            <SkeletonBlock height={16} />
          </div>
        </div>
        <SkeletonBlock height={72} />
      </div>
      <div className="flex flex-col gap-4 mt-auto">
        <SkeletonBlock height={48} />
      </div>
    </>
  );
};

export default SkeletonAccounts;
