import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "context/UserContext";
import PrimaryButton from "components/Button/Primary";
import { Header } from "../components/Header";
import { RewardsPaths } from "../types";
import { useWalletWithdraw } from "../hooks/useWalletWithdraw";
import LoadingModal from "components/Modals/Loading";

export const RewardConfirmPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { onWithdraw, loading } = useWalletWithdraw(
    () => navigate(`${RewardsPaths.STATUS}?type=success`),
    () => navigate(`${RewardsPaths.STATUS}?type=error`),
  );
  const { state: userState } = useContext(UserContext);
  const { money } = location.state || {};
  const {
    user: { phone_number },
  } = userState;
  return (
    <div className="w-full min-h-full flex flex-col px-6 pt-10 pb-[136px]">
      <Header backRoute={RewardsPaths.MAKE_PAYMENT} />
      <LoadingModal isVisible={loading} />
      <div className="flex flex-col h-full justify-center items-center gap-6">
        <div>
          <h4 className="font-semibold text-dark-kiwi text-2xl">
            Confirma tu cuenta ATH Móvil para recibir tu desembolso
          </h4>
          <p className="text-base text-gray-700 mt-4">
            Para recibir los fondos de manera segura, confirma que el número
            asociado a tu cuenta ATH Móvil es el correcto:{" "}
            <span className="font-semibold">{phone_number}</span>
          </p>
        </div>
      </div>
      <div className="p-4 bg-white fixed z-10 bottom-0 left-0 right-0 h-40 gap-6 flex flex-col  justify-around text-slate-300-kiwi max-w-[450px] mx-auto sm:bottom-6 sm:rounded-bl-2xl sm:rounded-br-2xl">
        <p className="text-gray-500-kiwi text-xs font-normal text-left">
          Si este no es tu número de ATH Móvil, puedes <br /> contactar a
          nuestro equipo de soporte enviando un
          <br /> correo a{" "}
          <span className="font-semibold">support@kiwicredito.com</span>
        </p>
        <PrimaryButton
          onClick={() => onWithdraw(money, phone_number)}
        >
          Continuar
        </PrimaryButton>
      </div>
    </div>
  );
};
