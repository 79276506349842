import BackwardButton from "components/Button/Backward";
import LoadingModal from "components/Modals/Loading";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmptyAccounts from "./EmptyAccounts";
import SkeletonAccounts from "./SkeletonAccounts";
import SectionAccount from "./SectionAccount";
import { PlaidAPI } from "api";

const Accounts = () => {
  const [loader, setLoader] = useState(true);
  const [account, setAccount] = useState(null);
  const navigate = useNavigate();

  const onBackward = () => {
    navigate("/settings");
  };

  const getAccounts = async () => {
    try {
      const { data, success } = await PlaidAPI.checkBankConnection();
      if (!success) throw data;
      setAccount(data);
    } catch (error) {
      setAccount(null);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <>
      <BackwardButton onBackward={onBackward} />
      {loader ? (
        <>
          <LoadingModal isVisible={loader} />
          <SkeletonAccounts />
        </>
      ) : account ? (
        <SectionAccount account={account} />
      ) : (
        <EmptyAccounts />
      )}
    </>
  );
};

export default Accounts;
