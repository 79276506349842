import  {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import WorldLocationSVG from 'assets/onboarding_v3/world_location.svg';

export default function IdentityVerification() {
    const navigate = useNavigate();
    const [location, setLocation] = useState();

    useEffect(() => {
        const location = localStorage.getItem('selectedLocation');

        if (!location) {
            return navigate('/location/select-state');
        }

        setLocation(location);
    }, []);

    const onContinue = () => {
        window.open('https://www.kiwicredito.com', '_blank');
    };

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <div className="flex flex-col justify-center text-center m-auto">
                <img className="mx-auto mb-8" src={WorldLocationSVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Pronto estaremos en {location}
                </h1>
                <p>
                    Aún no estamos en tu estado. Te invitamos a seguirnos en
                    redes sociales para ser el primero en enterarte cuando
                    lleguemos.
                </p>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onContinue}>
                Visitar Kiwi
            </button>
        </div>
    );
}
