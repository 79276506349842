import PropTypes from 'prop-types';

import BaseModal from '../BaseModal';

const CreditTermsModal = ({isVisible, closeModal}) => {
    return (
        <BaseModal
            title="Autorización de Perfil de Crédito"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6">
                <p>
                    Al hacer clic en el botón para proceder con su solicitud de
                    préstamo personal o tomar una acción afirmativa que indique
                    su acuerdo con esta autorización, usted autoriza a Kiwi
                    Financial, Inc (&quot;Kiwi&quot;, &quot;nosotros&quot;, &quot;nuestro&quot;) que opera una
                    plataforma en línea a través de la cual los consumidores
                    pueden solicitar préstamos personales, para obtener y
                    utilizar su perfil de crédito personal y/o otra información
                    de una o más agencias de informes del consumidor, como
                    TransUnion, Experian o Equifax, como se describe a
                    continuación. También da permiso para que Kiwi y/o sus
                    socios confirmen la exactitud de la información que ha
                    enviado. Usted consiente que Kiwi y sus asociados contacten
                    a terceros para autenticar dicha información.
                </p>
                <p>
                    Usted reconoce y acepta que podemos usar dicha información
                    para cualquier propósito permitido por la ley, incluyendo:
                </p>
                <ul className="list-disc pl-10">
                    <li>Autenticar su identidad;</li>
                    <li>Tomar decisiones de crédito;</li>
                    <li>Determinar su relación actual de deuda a ingresos;</li>
                    <li>Obtener su informe y puntaje de crédito; y</li>
                    <li>Para otros propósitos relacionados</li>
                </ul>
                <p>
                    Usted acepta que cuando verifique si califica para un
                    préstamo personal y revise su oferta, se realizará una
                    &quot;consulta suave&quot; en su informe de crédito, lo cual no
                    impactará su puntaje de crédito. Usted acepta que podemos
                    usar dicha información para verificar si califica para un
                    préstamo personal y para determinar su elegibilidad y
                    ofrecerle productos o servicios financieros adicionales
                    desde la plataforma de Kiwi hasta 60 días calendario después
                    de su solicitud, siempre que pueda optar por no recibir
                    nuevas ofertas por correo electrónico contactándonos en
                    support@kiwicredito.com o enviándonos una carta a 151 Calle
                    de San Francisco Ste 200 PMB 1123, San Juan, PR 00901-1607.
                    Cuando envíe una solicitud de préstamo después de revisar
                    su(s) oferta(s) disponible(s), usted otorga a Kiwi Financial
                    Inc. permiso para acceder a sus informes de crédito cada vez
                    que solicite un préstamo, a lo largo del proceso de cierre
                    del préstamo y en diferentes intervalos durante la duración
                    de su préstamo. Esto es para fines relacionados con la
                    emisión, el servicio, el monitoreo, la cobranza o la
                    ejecución del préstamo. Estas consultas, conocidas como
                    &quot;consultas suaves&quot;, no afectarán su puntaje de crédito.
                </p>
                <p>
                    Además, usted acepta que podemos recopilar información de
                    las agencias de crédito después de que su préstamo personal
                    sea aprobado, ahora y periódicamente mientras tenga un
                    préstamo activo en la plataforma de Kiwi, para considerar si
                    permite avances adicionales y determinar los términos de los
                    avances, para proporcionar datos a los inversores que puedan
                    estar interesados en comprar su préstamo, para evaluar los
                    riesgos asociados con su préstamo y monitorear los cambios
                    en su perfil de crédito, para mostrarle su información de
                    crédito y para analizar datos.
                </p>
                <p>
                    Usted nos autoriza a verificar la información en su informe
                    de crédito y acepta que podemos comunicarnos con terceros
                    sin previo aviso para verificar toda la información
                    relacionada con los asuntos anteriores o que haya
                    proporcionado en conexión con una solicitud de crédito o
                    para administrar o monitorear su cuenta si se aprueba un
                    préstamo personal.
                </p>
                <p>
                    En conexión con los propósitos anteriores, usted nos
                    autoriza a compartir toda o parte de sus informes de crédito
                    con nuestros socios bancarios, agentes, cualquier comprador
                    de su préstamo personal y cualquiera de nuestros respectivos
                    sucesores y cesionarios.
                </p>
            </div>
        </BaseModal>
    );
};

CreditTermsModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default CreditTermsModal;
