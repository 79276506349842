import  { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import MobileProductsSVG from 'assets/onboarding_v3/mobile_products.svg'
import { ChevronLeftIcon, ArrowRightIcon } from 'assets/icons_v3/fonts'
import MoneySVG from 'assets/icons_v3/money.svg'
import ChartLineSVG from 'assets/icons_v3/chart_line.svg'
import {
    PersonalLoanApplyRedirect,
    PersonalLoanApprovedRedirect
} from 'utils/redirectPersonalLoan'
import { PERSONAL_LOAN_STATUS, STATES_AVAILABLES } from 'constants'
import { UserContext } from 'context/UserContext'

export default function CreditMonitoringDisabled() {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)
    const {
        products: { personal_loan },
        user
    } = userState
    const {
        status: personalLoanStatus,
        tracking: personalLoanTracking,
        loan: personalLoanLoan
    } = personal_loan
    const personalLoanContext = personalLoanTracking?.context ?? {}

    const isPersonalLoanApply =
        personalLoanStatus === PERSONAL_LOAN_STATUS.apply &&
        !personalLoanContext.reviewing?.completed
    const isPersonalLoanReviewing =
        personalLoanStatus === PERSONAL_LOAN_STATUS.apply &&
        personalLoanContext.reviewing?.completed
    const isPersonalLoanApproved =
        personalLoanStatus === PERSONAL_LOAN_STATUS.approved
    const isPersonalLoanMoneyOnWay =
        personalLoanStatus === PERSONAL_LOAN_STATUS.approved &&
        personalLoanLoan &&
        personalLoanLoan.type === ''
    const isPersonalLoanMesalve =
        personalLoanStatus === PERSONAL_LOAN_STATUS.approved &&
        personalLoanLoan &&
        personalLoanLoan.type === 'mesalve'
    const isPersonalLoanRejected =
        personalLoanStatus === PERSONAL_LOAN_STATUS.rejected

    const onBackward = () => {
        navigate(-1)
    }

    const onPersonalLoan = async () => {
        if (isPersonalLoanMoneyOnWay) {
            return navigate('/moneys-on-the-way')
        }

        if (isPersonalLoanMesalve) {
            return navigate('/mesalve-code')
        }

        if (isPersonalLoanApply || isPersonalLoanReviewing) {
            const route = await PersonalLoanApplyRedirect(personalLoanContext)
            return navigate(route)
        }

        if (isPersonalLoanApproved) {
            const route = await PersonalLoanApprovedRedirect(
                personalLoanContext
            )
            return navigate(route)
        }

        if (isPersonalLoanRejected) {
            return navigate('/rejected-loan')
        }
    }

    const onCreditBuilder = () => {
        navigate('/credit-builder')
    }

    return (
        <div className="px-6 py-10">
            <ChevronLeftIcon
                className="cursor-pointer w-2 mb-12"
                onClick={onBackward}
            />

            <div className="flex flex-col justify-center text-center">
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    ¡Desbloquea y conoce tu reporte crediticio!
                </h1>
                <p>
                    Adquiere uno de nuestros productos y desbloquea tu reporte
                    hoy
                </p>
            </div>

            <img className="mx-auto my-8" src={MobileProductsSVG} />

            <div className="flex flex-col gap-4">
                <div className="font-semibold text-center">
                    Accede gratis a tu reporte de crédito TransUnion adquiriendo
                    uno de estos productos:
                </div>

                <div
                    className="p-4 border border-slate-200-kiwi rounded-xl flex items-center"
                    onClick={onPersonalLoan}>
                    <img src={MoneySVG} className="w-6 mr-4" />
                    <div className="font-semibold text-sm mr-auto">
                        Solicita un préstamo personal
                    </div>
                    <ArrowRightIcon className="w-5" />
                </div>

                {user.state !== STATES_AVAILABLES.florida && (
                    <div
                        className="p-4 border border-slate-200-kiwi rounded-xl flex items-center"
                        onClick={onCreditBuilder}>
                        <img src={ChartLineSVG} className="w-6 mr-4" />
                        <div className="font-semibold text-sm mr-auto">
                            Adquiere un Credit Builder
                        </div>
                        <ArrowRightIcon className="w-5" />
                    </div>
                )}
            </div>
        </div>
    )
}
