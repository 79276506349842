import PrimaryButton from "components/Button/Primary";
import PropTypes from "prop-types";

export const ButtonTop = ({ title, handleClick }) => {
  return (
    <div className="p-4 bg-white fixed z-10 bottom-0 left-0 right-0 h-24 flex items-center justify-around text-slate-300-kiwi max-w-[450px] mx-auto sm:bottom-6 sm:rounded-bl-2xl sm:rounded-br-2xl">
      <PrimaryButton onClick={handleClick}>{title}</PrimaryButton>
    </div>
  );
};

ButtonTop.propTypes = {
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};
