import Skeleton from 'react-loading-skeleton';

export default function SkeletonCardCashback() {
    return (
        <div className="border flex items-start rounded-xl bg-white border-slate-200-kiwi p-4 gap-4">
            <Skeleton borderRadius={1000} width={56} height={56} />
            <div className="flex flex-col gap-2 justify-start w-full">
                <div className="w-full">
                    <Skeleton height={24} borderRadius={12} />
                </div>
                <div className="w-full">
                    <Skeleton height={12} borderRadius={12} />
                    <Skeleton height={12} borderRadius={12} />
                </div>
            </div>
        </div>
    );
}
