import { Route } from "react-router-dom";
import { HistoryRewardsPage } from "./pages/HistoryRewards";
import { MakePaymentRewards } from "./pages/MakePaymentRewards";
import { RewardConfirmPage } from "./pages/RewardConfirm";
import { RewardStatus } from "./pages/RewardStatus";

const RewardsRouters = (
  <Route path="rewards">
    <Route index element={<HistoryRewardsPage />}></Route>
    <Route exact path="make-payment" element={<MakePaymentRewards />}></Route>
    <Route exact path="confirm-reward">
      <Route index element={<RewardConfirmPage />}></Route>
      <Route exact path="status" element={<RewardStatus />}></Route>
    </Route>
  </Route>
);

export default RewardsRouters;
