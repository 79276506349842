import * as React from "react";

const Wallet1 = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.125 13C17.125 13.8284 16.4534 14.5 15.625 14.5C14.7966 14.5 14.125 13.8284 14.125 13C14.125 12.1716 14.7966 11.5 15.625 11.5C16.4534 11.5 17.125 12.1716 17.125 13Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.9382 5.72763L15.9382 2.58477C17.5352 2.12847 19.125 3.32763 19.125 4.98858V5.99999C20.2296 5.99999 21.125 6.89542 21.125 7.99999V18C21.125 19.1046 20.2296 20 19.125 20H5.125C4.02043 20 3.125 19.1046 3.125 18V7.99999C3.125 6.95543 3.95983 6.00716 4.9382 5.72763ZM16.4876 4.50782C16.807 4.41656 17.125 4.65639 17.125 4.98858V5.99999H11.2651L16.4876 4.50782ZM5.125 7.99999H19.125V18H5.125C5.125 15.0151 5.125 7.99999 5.125 7.99999Z"
      fill="currentColor"
    />
  </svg>
);

export default Wallet1;
