import  { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { UserContext } from 'context/UserContext'
import { ProductsAPI, UsersAPI } from 'api'
import MagnifyingSVG from 'assets/onboarding_v3/magnifying.svg'

export default function VeritecTemplate({ onEligible }) {
    const { state: userState, setState: setUserState } = useContext(UserContext)

    useEffect(() => {
        checkEligibilityEvaluateVeritec()
    }, [])

    const checkEligibilityEvaluateVeritec = async () => {
        try {
            await UsersAPI.checkEligibilityEvaluateVeritec(userState.user.id)
            onEligible()
        } catch {
            const {
                data: { data: personal_loan }
            } = await ProductsAPI.getPersonalLoan()

            setTimeout(() => {
                setUserState({
                    ...userState,
                    products: {
                        ...userState.products,
                        personal_loan
                    }
                })
            }, 3000)
        }
    }

    return (
        <div className="flex flex-col justify-center min-h-full text-center m-auto">
            <img className="mx-auto mb-8" src={MagnifyingSVG} />
            <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                Revisando tus datos
            </h1>
            <p>
                Estamos analizando los datos que ingresaste, recibirás respuesta
                en unos segundos
            </p>
        </div>
    )
}

VeritecTemplate.propTypes = {
    onEligible: PropTypes.func.isRequired
}
