import mixpanel from 'utils/mixpanel';
import React, {useState, Fragment, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {usePlaidLink} from 'react-plaid-link';
import {exchangePlaidToken, createLinkToken} from 'utils/plaid';
import {StarIcon} from '@heroicons/react/solid';

import LoadingModal from 'components/Modals/Loading';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import {CreditLimitContext} from 'context/CreditLimitContext';
import moneyToNumber from 'utils/moneyToNumber';
import {UserContext} from 'context/UserContext';
import {ProductsAPI} from 'api';

export default function CreditLimitBankStatements() {
    const navigate = useNavigate();

    const {
        state: userState,
        setState: setUserState,
        deleteUserTracking,
    } = useContext(UserContext);

    const {
        state: creditLimitState,
        createSurvey,
        resetState: resetCreditState,
    } = useContext(CreditLimitContext);

    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [plaidToken, setPlaidToken] = useState('');
    const [validationType, setValidationType] = useState('');

    useEffect(() => {
        if (creditLimitState.survey.completed === false) {
            return navigate('/credit-limit/survey');
        }

        createPlaidToken();
    }, []);

    const createPlaidToken = async () => {
        try {
            setLoading(true);

            const {
                data: {
                    data: {link_token},
                },
            } = await createLinkToken();

            setPlaidToken(link_token);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const {open: onOpenPlaid} = usePlaidLink({
        token: plaidToken,
        onSuccess: async public_token => {
            try {
                setShowLoader(true);

                const {
                    data: {success},
                } = await exchangePlaidToken(public_token);

                if (success) {
                    onSuccessPlaidConnection();
                }
            } catch (error) {
                console.error(error);
            } finally {
                setShowLoader(false);
            }
        },
    });

    const deleteApprovedTracking = () => {
        deleteUserTracking({
            status: 'approved',
        });

        setUserState({
            ...userState,
            products: {
                ...userState.products,
                personal_loan: {
                    ...userState.products.personal_loan,
                    tracking: null,
                },
            },
        });
    };

    const onSuccessPlaidConnection = async () => {
        try {
            setLoading(true);
            setShowLoader(true);

            const {annual, additional, mortgage} = creditLimitState.survey;

            /**
             * Eliminar tracking para empezar desde la página de la calculadora...
             */
            deleteApprovedTracking();

            const {
                data: {success},
            } = await createSurvey({
                annual_income: moneyToNumber(annual),
                annual_additional_income: moneyToNumber(additional),
                monthly_payment: moneyToNumber(mortgage),
                plaid_connected: true,
            });

            if (success) {
                const {
                    data: {data: personal_loan},
                } = await ProductsAPI.getPersonalLoan();

                setUserState({
                    ...userState,
                    products: {
                        ...userState.products,
                        personal_loan,
                    },
                });

                mixpanel.track('Limit Increase Plaid Successful');

                resetCreditState();
                navigate('/credit-limit/reviewing');
            }
        } catch (error) {
            const {
                data: {success},
            } = error.response;

            if (success === false) {
                const {
                    data: {data: personal_loan},
                } = await ProductsAPI.getPersonalLoan();

                setUserState({
                    ...userState,
                    products: {
                        ...userState.products,
                        personal_loan,
                    },
                });

                navigate('/approved-loan/amount', {
                    state: {
                        showRejectedModal: true,
                    },
                });
            }
        } finally {
            setLoading(false);
            setShowLoader(false);
        }
    };

    const onContinue = () => {
        if (validationType === 'plaid') {
            onOpenPlaid();
        }
    };

    const onBackward = () => {
        if (loading === false) {
            navigate('/credit-limit/survey');
        }
    };

    const onSelectValidationType = type => {
        setValidationType(type);
    };

    return (
        <Fragment>
            <LoadingModal isVisible={showLoader} />

            <ProgressBar progress="2/2" onBackward={onBackward} />

            <div className="flex flex-col mb-10">
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Validación de estados financieros
                </h1>
                <p className="mb-10">
                    Validamos tus ingresos y gastos mensuales para determinar tu
                    límite
                </p>

                <div className="flex flex-col gap-6">
                    <div className="relative">
                        <input
                            className="peer hidden"
                            type="radio"
                            id="linkAccount"
                            name="bank"
                            onChange={() => onSelectValidationType('plaid')}
                            checked={validationType === 'plaid'}
                        />
                        <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                        <label
                            htmlFor="linkAccount"
                            className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4">
                            <div className="leading-5 mb-1">
                                Enlazar cuenta de banco
                            </div>
                            <p className="flex items-center text-xs text-gray-500-kiwi">
                                Automático
                                <span className="flex ml-2 bg-yellow-kiwi text-white px-4 py-0.5 rounded-lg">
                                    <StarIcon className="w-4 mr-1" />
                                    Recomendado
                                </span>
                            </p>
                        </label>
                    </div>
                </div>
            </div>

            <Button
                className={`rounded-xl p-3 mt-auto ${
                    validationType === ''
                        ? 'bg-gray-200-kiwi text-gray-500-kiwi'
                        : 'bg-blue-kiwi text-white '
                }`}
                loading={loading}
                onClick={onContinue}
                disabled={validationType === ''}>
                Conectar cuenta de banco
            </Button>
        </Fragment>
    );
}
