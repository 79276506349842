import BaseModal from '../BaseModal'
import PropTypes from 'prop-types'

const RouletteTermsModal = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="Términos y condiciones Ruleta de Premios Kiwi"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="text-justify flex flex-col gap-6">
                <div>
                    A continuación, se detallan los términos de servicio
                    aplicables a la participación en la Ruleta de Premios Kiwi,
                    ofrecida actualmente por Kiwi Financial, Inc (&quot;Kiwi&quot;) y sus
                    afiliados. Al participar en la Ruleta de Premios Kiwi
                    (&quot;Actividad&quot;), acepta los siguientes términos:
                </div>
                <div>
                    <div className="font-semibold">
                        Fecha de Inicio y Periodicidad
                    </div>
                    La actividad se llevará a cabo de manera semanal, todos los
                    jueves a las 8:30 pm hora de Puerto Rico. En caso de cambios
                    en la fecha o imposibilidad de realizarla en una semana
                    específica, la actividad se reprogramará para el siguiente
                    jueves o en una fecha alternativa.
                </div>
                <div>
                    <div className="font-semibold">Duración y Cancelación</div>
                    Nos reservamos el derecho de cancelar la dinámica semanal en
                    cualquier momento y sin previo aviso. En caso de
                    cancelación, aseguramos que los participantes recibirán los
                    premios correspondientes a cualquier actividad que haya
                    tenido lugar antes de la cancelación. Nos comprometemos a
                    liquidar cualquier premio pendiente con los ganadores para
                    garantizar la transparencia y la integridad de la actividad.
                </div>
            </div>
        </BaseModal>
    )
}

RouletteTermsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default RouletteTermsModal