import { getOpeningClosingTimes } from "../../../../utils/formatOpeningClosingTimes";
import PropTypes from "prop-types";

const OfferDetails = ({ offer }) => {
  const { openingTime, closingTime, isOpen } = getOpeningClosingTimes(offer);

  return (
    <div className="flex flex-col items-start gap-2 w-full overflow-hidden text-ellipsis">
      <div className="flex flex-col items-start w-full">
        <div className="flex items-start justify-between w-full gap-2">
          <div className="w-full">
            <p className="text-sm text-slate-900-kiwi font-semibold  overflow-hidden text-ellipsis whitespace-nowrap">
              {offer?.store?.merchant?.merchant_name}
            </p>
            <h1 className="text-xs text-regal-blue-950-kiwi overflow-hidden text-ellipsis whitespace-nowrap">
              {offer?.store?.merchant?.discount_percentage}% de cashback
            </h1>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start">
        <p className="text-xs text-slate-700-kiwi line-clamp-1">
          {offer?.store.address}
        </p>
        <p className="text-xs text-slate-700-kiwi">
          <span className={`text-${isOpen ? "green" : "red"}-kiwi`}>
            {isOpen ? "Abierto" : "Cerrado"}
          </span>
          {isOpen
            ? ` ⋅ Cierra a las ${closingTime}`
            : ` ⋅ Abre a las ${openingTime}`}
        </p>
      </div>
    </div>
  );
};

OfferDetails.propTypes = {
  offer: PropTypes.object.isRequired,
};

export default OfferDetails;
