import  {useEffect, useContext} from 'react';
import ReactPixel from 'react-facebook-pixel';
import {UserContext} from 'context/UserContext';
import {Outlet} from 'react-router-dom';

export const PixelMiddleware = () => {
    const {state: userState} = useContext(UserContext);
    const userEmail = userState?.user?.email ?? '';
    const userPhone = userState?.user?.phone_number ?? '';
    useEffect(() => {
        if (userState.user.email) {
            const advancedMatching = {em: userEmail, ph: userPhone};
            ReactPixel.init(
                process.env.REACT_APP_META_PIXEL_ID,
                advancedMatching,
            );
        } else {
            ReactPixel.init(process.env.REACT_APP_META_PIXEL_ID);
        }
        ReactPixel.pageView(); // Track initial page view
    }, [userState.user.email]);

    return <Outlet></Outlet>;
};
