import  { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CreditBuilderContext } from 'context/CreditBuilderContext'
import playIcon from 'assets/icons/play.svg'
import BackwardButton from 'components/Button/Backward'

export default function CreditBuilderActiveCourse() {
    const navigate = useNavigate()
    const { courseId } = useParams()

    const { getCourse } = useContext(CreditBuilderContext)

    const [course, setCourse] = useState({})

    useEffect(() => {
        courseDetails()
    }, [])

    const courseDetails = async () => {
        const {
            data: [course]
        } = await getCourse(courseId)

        setCourse(course)
    }

    const onBackward = () => {
        navigate('/credit-builder')
    }

    const onLessonDetails = (lessonId) => {
        navigate(`/credit-builder/courses/${courseId}/${lessonId}`)
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />

            <div className="h-40 bg-gray-200-kiwi rounded-lg mb-6">
                <div
                    className="h-40 w-full rounded-lg bg-no-repeat bg-center bg-cover"
                    style={{
                        backgroundImage: `url(${course?.image_url ?? ''})`
                    }}></div>
            </div>

            <h1 className="text-dark-kiwi font-semibold mb-4 text-xl">
                {course.name}
            </h1>
            <p className="mb-8">{course.description}</p>

            <div className="flex flex-col gap-4">
                {course.cb_product_content?.map((l) => (
                    <div
                        key={l.id}
                        className="cursor-pointer rounded-md flex justify-between items-center p-4 border-l-4 border-blue-kiwi text-sm"
                        onClick={() => onLessonDetails(l.id)}>
                        <div className="mr-2">
                            <div>{l.name}</div>
                            <div className="text-gray-500-kiwi text-xs mt-1">
                                Duración {l.duration}
                            </div>
                        </div>
                        <img src={playIcon} alt="icon" />
                    </div>
                ))}
            </div>
        </Fragment>
    )
}
