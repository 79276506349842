import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "../components/Container";
import Success from "../../../assets/onboarding_v3/status/success.svg";
import Warning from "../../../assets/onboarding_v3/status/warning2.svg";

import { ButtonTop } from "../components/ButtonTop";
import { RewardsPaths } from "../types";
export const RewardStatus = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const navigate = useNavigate();
  const result = type === "success";

  return (
    <Container className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center gap-8">
        <img src={result ? Success : Warning} alt="success" />
        <h4 className="font-semibold text-2xl text-center">
          {result
            ? "¡Tu retiro se ha realizado exitosamente!"
            : " No se pudo procesar el pago"}
        </h4>

        <p className="text-base text-center">
          {result
            ? "Tu dinero ha sido transferido de inmediato a tu cuenta de ATH Móvil"
            : "Lo sentimos, hemos tenido un problema al procesar el pago. Por favor, inténtalo nuevamente"}
        </p>
      </div>

      <ButtonTop
        title={result ? "Regresar a rewards" : "Volver a intentar"}
        handleClick={() =>
          result ? navigate(RewardsPaths.INDEX) : navigate(-1)
        }
      />
    </Container>
  );
};
