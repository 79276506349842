import HTMLParser from 'html-react-parser'
import PropTypes from 'prop-types'

export default function SelectItemComponent({
    id,
    name,
    className,
    onChange,
    checked,
    title,
    subtitle
}) {
    return (
        <div className={`relative ${className}`}>
            <input
                className="peer hidden"
                type="radio"
                id={id}
                name={name}
                onChange={onChange}
                checked={checked}
            />
            <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
            <label
                htmlFor={id}
                className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4 pr-12">
                <div>{title}</div>
                <p className="text-xs text-gray-500-kiwi mt-1">
                    {HTMLParser(subtitle)}
                </p>
            </label>
        </div>
    )
}

SelectItemComponent.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired
}
