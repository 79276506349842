import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { CashbackAPI } from "api";
import { CashbackContext } from "context/CashbackContext";
import { CASHBACK_TRANSACTION_STATUS } from "constants";

import BackwardButton from "components/Button/Backward";
import CashbackEmptySVG from "assets/onboarding_v3/cashback_empty.svg";
import PaymentSkeleton from "components/Skeleton/Payment";
import ItemActivityHistory from "components/ItemActivityHistory";
export default function CashbackHistory() {
  const navigate = useNavigate();
  const { state: cashbackState } = useContext(CashbackContext);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  const onBackward = () => {
    navigate("/cashback/list-offers");
  };

  const getTransactions = async () => {
    const { cashback_id } = cashbackState;
    if (!cashback_id) {
      setLoading(false);
      return;
    }

    try {
      const { data: processedOffers } =
        await CashbackAPI.getProcessedOffers(cashback_id);
      const relevantStatuses = [
        CASHBACK_TRANSACTION_STATUS.COMPLETED,
        CASHBACK_TRANSACTION_STATUS.EXPIRED,
        CASHBACK_TRANSACTION_STATUS.CANCELED,
      ];

      const filteredTransactions = processedOffers.filter((transaction) =>
        relevantStatuses.includes(transaction.status),
      );

      setTransactions(filteredTransactions);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]); // Set empty array on error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <>
      <div className="w-full flex min-h-full h-full flex-col px-6 pb-10 pt-10">
        <BackwardButton onBackward={onBackward} />
        <h2 className="font-semibold text-slate-900-kiwi text-2xl">
          Historial de actividad
        </h2>

        {!loading ? (
          transactions?.[0] ? (
            <div className="flex flex-col gap-6 mt-10">
              {transactions.map((transaction, index) => (
                <div
                  key={transaction.id}
                  className={`${index === transactions.length - 1 ? "pb-6" : ""} text-left`}
                >
                  <ItemActivityHistory
                    transaction={transaction}
                    showStatus={true}
                    key={index}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col gap-6 h-full">
              <div className="flex flex-col items-center justify-center gap-8 h-full ">
                <img src={CashbackEmptySVG} />
                <div className="text-center">
                  <p className="text-slate-600 font-semibold">
                    Aún no tienes historial
                  </p>
                  <p className="text-slate-400-kiwi text-sm mt-2">
                    ¿Estás listo para comenzar a generarlo?
                  </p>
                  <p className="text-slate-400-kiwi text-sm">
                    Explora las ofertas y comienza a generar tu historial de
                    cashback.
                  </p>
                </div>
              </div>
              <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                onClick={onBackward}
              >
                Explorar ofertas
              </button>
            </div>
          )
        ) : (
          <div className="flex flex-col gap-4 mt-10">
            {Array.from({ length: 10 }).map((_, index) => (
              <PaymentSkeleton key={index} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}
