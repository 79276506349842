import  { Fragment, useContext, useEffect } from 'react'

import SuccessSVG from 'assets/onboarding_v3/currency_success.svg'
import { useNavigate } from 'react-router-dom'
import { AutopayContext } from 'context/AutopayContext'
import { UserContext } from 'context/UserContext'
import formatDate from 'utils/formatDate'

export default function AutopaySuccess() {
    const { state: autoState } = useContext(AutopayContext)
    const { state: userState, setState: setUserState } = useContext(UserContext)

    const {
        products: {
            personal_loan: {
                loan: { nextPaymentDate }
            }
        }
    } = userState

    const {
        paymentProfile: { last4 }
    } = autoState

    const navigate = useNavigate()

    useEffect(() => {
        if (!last4) {
            onContinue()
        }
    }, [])

    const onContinue = () => {
        userState.products.personal_loan.loan.has_autopay_suspended = false
        setUserState(userState)
        navigate('/active-loan/disbursement')
    }

    return (
        <Fragment>
            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img className="mx-auto mb-8" src={SuccessSVG} />
                <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                    Has reactivado los pagos automáticos
                </h1>
                <p>
                    A partir de ahora, los pagos se harán automáticamente desde
                    tu cuenta terminada en{' '}
                    <span className="font-semibold">***{last4}</span>. Tu
                    próxima fecha de pago será en{' '}
                    <span className="font-semibold">
                        {formatDate(nextPaymentDate, 'MMMM DD, YYYY')}{' '}
                    </span>
                </p>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onContinue}>
                Continuar
            </button>
        </Fragment>
    )
}
