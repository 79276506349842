import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from "components/Button";
import BackwardButton from "components/Button/Backward";
import MapsAutocompleteField from "components/MapsAutocompleteField";

import MapPinLIne from "assets/cashback/map_pin_line.svg";

import { CashbackContext } from "context/CashbackContext";
import { UserContext } from "context/UserContext";

export default function AddLocation() {
  const navigate = useNavigate();
  const { state: userState } = useContext(UserContext);
  const { setState: setCashbackState } = useContext(CashbackContext);

  const onBackward = () => {
    navigate("/cashback/welcome");
  };

  const onSubmit = async (values) => {
    setCashbackState((state) => ({
      ...state,
      user_current_location: values,
      address: {
        user_id: userState.user.id,
        street: values.location,
        zip_code: values.zipcode,
        city: values.city,
        state: values.state,
        latitude: values.lat,
        longitude: values.lng,
      },
    }));

    navigate("/cashback/list-offers");
  };

  const validationSchema = Yup.object().shape({
    location: Yup.string().trim().required("Este campo es requerido"),
    city: Yup.string().optional(),
    zipcode: Yup.string().optional(),
    lat: Yup.string().required("Este campo es requerido"),
    lng: Yup.string().required("Este campo es requerido"),
  });

  const onLocationChange = () => {
    navigate("/cashback/location-map");
  };

  return (
    <div className="flex flex-col w-full min-h-full h-full px-6 py-10">
      <BackwardButton onBackward={onBackward} />
      <h1 className="text-slate-900-kiwi font-semibold text-2xl mb-4">
        Ingresa tu ubicación
      </h1>
      <p className="text-slate-600">
        De esta manera, te mostraremos las ofertas de cashback más cercanas y
        relevantes para ti.
      </p>

      <Formik
        initialValues={{
          location: "",
          city: "",
          zipcode: "",
          lat: "",
          lng: "",
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched }) => {
          useEffect(() => {
            if (values.location === "") {
              values.city = "";
              values.zipcode = "";
              values.lat = "";
              values.lng = "";
            }
          }, [values.location]);

          return (
            <Form className="flex flex-col w-full h-full">
              <div className="mt-6">
                <label
                  htmlFor="location"
                  className="inline-block text-sm mb-2 text-slate-700"
                >
                  Ubicación
                </label>
                <Field name="location">
                  {({ field }) => (
                    <MapsAutocompleteField
                      field={field}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                </Field>
                <button
                  type="button"
                  className="flex items-center gap-2 mt-4"
                  onClick={onLocationChange}
                >
                  <img src={MapPinLIne} className="h-5 w-5" />
                  <p className="text-sm font-semibold text-blue-kiwi">
                    Seleccionar en el mapa
                  </p>
                </button>
              </div>
              <Button
                disabled={!values.location || !values.lat || !values.lng}
                type="submit"
                className="rounded-xl p-3 mt-auto"
              >
                Continuar
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
