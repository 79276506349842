import BaseModal from './../BaseModal'
import Clock from 'assets/Clock.svg'
import PropTypes from 'prop-types'

const ResetApprovedTracking = ({ isVisible, resetTracking, redirectHome }) => {
    return (
        <BaseModal showDetails={false} isVisible={isVisible}>
            <div>
                <img
                    className="shrink-0 grow-0 mx-auto mb-10 grid justify-center"
                    src={Clock}
                    alt="icon-icon"
                />
                <h2 className="font-semibold text-xl text-dark-kiwi text-center">
                    Solicitud de préstamo en pausa
                </h2>
                <p className="mt-5 text-center">
                    Tu solicitud de préstamo ha estado en pausa por más de 7
                    días. Te invitamos a reactivar tu proceso de préstamo ahora
                    mismo.
                </p>
            </div>
            <div className="flex flex-col mt-10">
                <button
                    className="w-full bg-blue-kiwi text-white rounded-xl py-3 px-8"
                    onClick={resetTracking}>
                    Reactivar proceso
                </button>
                <div
                    className="cursor-pointer mx-auto text-blue-kiwi rounded-xl mt-6"
                    onClick={redirectHome}>
                    Cancelar
                </div>
            </div>
        </BaseModal>
    )
}

ResetApprovedTracking.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  resetTracking: PropTypes.func.isRequired,
  redirectHome: PropTypes.func.isRequired,
};

export default ResetApprovedTracking
