import anime from 'animejs'
import  {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState
} from 'react'
import { XIcon } from 'assets/icons_v3/fonts'
import PropTypes from 'prop-types'

const BaseModal = forwardRef(
    (
        { title, description, children, showDetails = true, onCloseModal },
        ref
    ) => {
        const [isVisible, setIsVisible] = useState(false)

        const closeModal = () => {
            setIsVisible(false)
        }

        const openModal = () => {
            setIsVisible(true)
        }

        useEffect(() => {
            if (isVisible) {
                document.body.style.overflow = 'hidden'
                fadeInAnimation()
            } else {
                document.body.style.overflow = 'auto'
            }
        }, [isVisible])

        useEffect(() => {
            return () => {
                document.body.style.overflow = 'auto'
            }
        }, [])

        const fadeInAnimation = () => {
            let container = document.getElementById('container')

            container.style.overflow = 'hidden'

            anime
                .timeline({
                    easing: 'easeOutExpo',
                    duration: 500,
                    complete() {
                        container.style.overflow = 'auto'
                    }
                })
                .add({
                    opacity: [0, 1],
                    targets: container
                })
                .add(
                    {
                        targets: '#content',
                        opacity: [0, 1],
                        translateY: ['100vh', 0]
                    },
                    '-=500'
                )
        }

        const onMouseDown = (event) => {
            if (event && event.currentTarget === event.target) {
                closeModal()
                if (onCloseModal) onCloseModal()
            }
        }

        useImperativeHandle(ref, () => ({
            isVisible,
            closeModal,
            openModal
        }))

        if (isVisible) {
            return (
                <div
                    id="container"
                    className="flex fixed top-0 left-0 bottom-0 right-0 z-20 bg-dark-kiwi/50 overflow-auto pt-20 cursor-pointer sm:w-[450px] mx-auto sm:bottom-6 sm:top-6 sm:rounded-2xl"
                    onMouseDown={onMouseDown}>
                    <div
                        id="content"
                        className=" py-10 px-6 bg-white mt-auto  rounded-tl-2xl rounded-tr-2xl w-full cursor-default relative">
                        <div className="max-w-[450px] mx-auto">
                            {showDetails && (
                                <div
                                    className={`flex justify-between items-start ${title ? 'mb-2' : 'mb-0'
                                        }`}>
                                    {title && (
                                        <h2 className="text-dark-kiwi font-semibold text-xl">
                                            {title}
                                        </h2>
                                    )}
                                    <div
                                        onClick={onMouseDown}
                                        className="cursor-pointer">
                                        <XIcon
                                            className={`${title
                                                    ? 'ml-4 mt-0.5 w-6 shrink-0 self-start'
                                                    : 'top-10 right-6 absolute'
                                                } text-gray-500-kiwi pointer-events-none`}
                                        />
                                    </div>
                                </div>
                            )}

                            {description && (
                                <p className="mb-10">{description}</p>
                            )}

                            {children}
                        </div>
                    </div>
                </div>
            )
        }
    }
)

BaseModal.displayName = 'BaseModal';

BaseModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showDetails: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default BaseModal
