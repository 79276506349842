import { useMemo } from "react";
import Menu from "templates/Menu";
import { Header } from "../components/Header";
import { CardMoney } from "../components/CardMoney";
import { EmptyStateRewards } from "../components/EmptyState";
import { TransactionHistory } from "../components/TransactionHistory";
import { useWallet } from "../hooks/useWallet";
import { useWalletTransactions } from "../hooks/useWalletTransactions";
import LoadingModal from "components/Modals/Loading";

export const HistoryRewardsPage = () => {
  const { wallet, loading } = useWallet();
  const moneyNumber = useMemo(
    () => Number(wallet?.balance ?? "0"),
    [wallet?.balance],
  );
  const { transactionsGroup, loading: loadingTransactions } =
    useWalletTransactions();
  return (
    <div className="w-full min-h-full flex flex-col px-6 pt-10 pb-[136px]">
      <Header disableHelpIcon />
      <h4 className="font-semibold text-slate-900 text-2xl mb-6">Rewards</h4>

      <CardMoney money={moneyNumber} />

      {Object.keys(transactionsGroup).length ? (
        <TransactionHistory transactionsGroup={transactionsGroup} />
      ) : (
        <EmptyStateRewards show />
      )}
      <Menu selected="rewards" />
      <LoadingModal isVisible={loadingTransactions || loading} />
    </div>
  );
};
