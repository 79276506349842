import PropTypes from 'prop-types';

const MobileIcon = ({ className, ...props }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...props}>
        <path
            d="M17 2a2 2 0 0 1 1.995 1.85L19 4v16a2 2 0 0 1-1.85 1.995L17 22H7a2 2 0 0 1-1.995-1.85L5 20V4a2 2 0 0 1 1.85-1.995L7 2h10Zm0 2H7v16h10V4Zm-4.5 12a.5.5 0 0 1 .492.41l.008.09v1a.5.5 0 0 1-.41.492L12.5 18h-1a.5.5 0 0 1-.492-.41L11 17.5v-1a.5.5 0 0 1 .41-.492L11.5 16h1Z"
            fill="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

MobileIcon.displayName = 'MobileIcon';

MobileIcon.propTypes = {
    className: PropTypes.string,
};

export default MobileIcon;
