import  { Fragment, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { ApprovedLoanContext } from 'context/ApprovedLoanContext'
import VeritecTemplate from 'templates/Veritec'

export default function ApprovedLoanVeritec() {
    const navigate = useNavigate()
    const { state: approvedState, saveState: saveApprovedState } =
        useContext(ApprovedLoanContext)

    const onEligible = async () => {
        saveApprovedState({
            ...approvedState,
            veritec: {
                completed: true
            }
        })

        navigate('/approved-loan/contract')
    }

    return (
        <Fragment>
            <VeritecTemplate onEligible={onEligible} />
        </Fragment>
    )
}
