import  { useContext, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'

import HistoryPayments from 'templates/HistoryPayments'
import { ChevronLeftIcon } from 'assets/icons_v3/fonts'

export default function ActiveLoanHistoryPayment() {
    const navigate = useNavigate()

    const { state: userState } = useContext(UserContext)
    const {
        products: { personal_loan }
    } = userState

    const onBackward = () => {
        navigate('/active-loan/disbursement')
    }

    const onContinue = () => {
        navigate('/active-loan/pay-fee')
    }

    return (
        <Fragment>
            <div className="mb-12">
                <ChevronLeftIcon
                    className="w-2 cursor-pointer"
                    onClick={onBackward}
                />
            </div>

            <HistoryPayments
                loan={personal_loan.loan}
                onContinue={onContinue}
            />
        </Fragment>
    )
}
