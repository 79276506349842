import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import Button from "components/Button";
import { ArrowsDownIcon } from "assets/icons_v3/fonts";
import LoadingModal from "components/Modals/Loading";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ContractTemplate({
  signed,
  title,
  fileUrl,
  showLoader,
  signContract,
  buttonTitle,
}) {
  const termsContainer = useRef();

  const [acceptTerms, setAcceptTerms] = useState(signed);
  const [contractPages, setContractPages] = useState(0);

  useEffect(() => {
    termsContainer.current.addEventListener("scroll", onScrollContract);
  }, []);

  const onScrollContract = () => {
    const div = termsContainer.current;
    const scrollTop = div.scrollTop;
    const scrollHeight = div.scrollHeight;
    const clientHeight = div.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 50) {
      setAcceptTerms(true);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setContractPages(numPages);
  };

  return (
    <div className="flex flex-col h-[calc(100vh-144px)] sm:h-[calc(100vh-192px)] overflow-hidden">
      <LoadingModal isVisible={showLoader} />

      <h1 className="text-dark-kiwi font-semibold text-2xl mb-8">{title}</h1>

      <div className="grow overflow-auto relative" ref={termsContainer}>
        <Document
          file={fileUrl}
          error="No se pudo cargar el contrato..."
          loading="Estamos cargando el documento..."
          noData="Estamos cargando el contrato..."
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(Array(contractPages).keys()).map((index) => (
            <Page pageNumber={index + 1} key={index} />
          ))}
        </Document>

        {acceptTerms === false && (
          <div className="fixed px-4 py-1 bottom-[116px] left-1/2 -translate-x-1/2 bg-regal-blue-kiwi text-white rounded-3xl text-xs flex items-center">
            <ArrowsDownIcon className="text-white w-4 mr-2" /> Deslizar para ver
            todo
          </div>
        )}
      </div>

      <Button
        className="rounded-xl p-3 mt-10"
        disabled={acceptTerms === false}
        onClick={signContract}
      >
        {buttonTitle ? buttonTitle : signed ? "Regresar" : "Firmar contrato"}
      </Button>
    </div>
  );
}

ContractTemplate.propTypes = {
  signed: PropTypes.bool,
  title: PropTypes.string,
  fileUrl: PropTypes.string,
  showLoader: PropTypes.bool,
  signContract: PropTypes.func,
  buttonTitle: PropTypes.string,
};
