import { UserContext } from "context/UserContext";
import { useContext, useMemo } from "react";
import { LOANPRO_LOAN_STATUS } from "constants";

/**
 * NOTE: When a client declares bankruptcy in a US court, we must not
 * attempt any collections including autopay reactivation
 * to avoid legal liability.
 */
export function useIsUserBankruptcy(product = "personal_loan") {
  const {
    state: {
      products: {
        [product]: { loan },
      },
    },
  } = useContext(UserContext);

  return useMemo(() => {
    return loan?.status?.name === LOANPRO_LOAN_STATUS.bankruptcy;
  }, []);
}
