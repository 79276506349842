import { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";

import LoadingModal from "components/Modals/Loading";
import Button from "components/Button";
import LocationDark from "assets/cashback/map_pin_line_dark.svg";
import Marker from "assets/cashback/marker.png";
import { CashbackContext } from "context/CashbackContext";
import getAddressFromLatLong from "utils/Maps/getAddressFromLatLong";
import { getWholeAddress } from "utils/Maps/getFullWholeAddress";

export default function LocationMap() {
  const navigate = useNavigate();
  const { setState: setCashbackState } = useContext(CashbackContext);
  const [currentCoordinates, setCurrentCoordinates] = useState({
    lat: 18.4041587,
    lng: -66.1428097,
  });
  const [address, setAddress] = useState("");
  const mapRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const onCloseMap = () => {
    navigate("/cashback/add-location");
  };

  const getMap = async () => {
    setLoading(true);
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: { ...currentCoordinates },
      zoom: 17,
      disableDefaultUI: true,
      styles: [
        {
          featureType: "all",
          elementType: "all",
          stylers: [
            { invert_lightness: false },
            { saturation: 0 },
            { lightness: 0 },
            { gamma: 0 },
          ],
        },
      ],
      mapTypeControlOptions: {
        mapTypeIds: [],
      },
    });

    const markerElement = document.createElement("div");
    markerElement.style.position = "absolute";
    markerElement.style.top = "50%";
    markerElement.style.left = "50%";
    markerElement.style.transform = "translate(-50%, -50%)";
    markerElement.style.width = "40px";
    markerElement.style.height = "40px";
    markerElement.style.backgroundImage = `url(${Marker})`;
    markerElement.style.backgroundSize = "contain";
    markerElement.style.backgroundRepeat = "no-repeat";
    document.getElementById("map").appendChild(markerElement);

    window.google.maps.event.addListener(map, "idle", async () => {
      const center = map.getCenter();
      getAddress(center.lat(), center.lng());
      setCurrentCoordinates({
        lat: center.lat(),
        lng: center.lng(),
      });
    });

    mapRef.current = map;
    setLoading(false);
    return { map };
  };

  const getAddress = async (lat, lng) => {
    try {
      setLoading(true);
      const address = await getAddressFromLatLong(lat, lng);
      setAddress(address);
    } catch (error) {
      console.log(error);
      setAddress("ADDRESS_NOT_FOUND");
    } finally {
      setLoading(false);
    }
  };

  const getAddressInfo = async () => {
    const { street, city, state, zip_code, plus_code, route } =
      await getWholeAddress(address, currentCoordinates);
    return {
      street,
      city,
      state,
      zip_code,
      plus_code,
      route,
      latitude: currentCoordinates.lat,
      longitude: currentCoordinates.lng,
    };
  };

  const onContinue = async () => {
    const wholeAddress = await getAddressInfo();
    setCashbackState((state) => ({
      ...state,
      user_current_location: currentCoordinates,
      address: wholeAddress,
    }));

    navigate("/cashback/list-offers");
  };

  useEffect(() => {
    setLoading(true);
    getMap();
  }, []);

  return (
    <div className="flex flex-col w-full min-h-full overflow-hidden">
      <LoadingModal isVisible={loading} />
      <div className="rounded-b-xl absolute w-full overflow-hidden pb-6 pt-10 gap-4 bg-white z-10">
        <button onClick={onCloseMap} className="px-6 mb-4">
          <XIcon className="w-6" />
        </button>
      </div>
      <div id="map" className="h-screen sm:h-[calc(100vh-48px)] w-full" />
      <div className="bg-white absolute bottom-0 w-full py-12 px-6 rounded-t-2xl flex flex-col gap-10">
        <div className="flex flex-col gap-6">
          <h1 className="text-slate-900-kiwi text-xl font-semibold">
            Elige tu ubicación en el mapa
          </h1>
          <div className="flex items-start gap-2">
            <img src={LocationDark} className="mt-1" />
            <p className="text-slate-700-kiwi">
              {address === "ADDRESS_NOT_FOUND"
                ? "No encontramos una dirección en esta ubicación. Intenta mover el pin a un lugar cercano"
                : address.formatted_address}
            </p>
          </div>
        </div>
        <Button
          loading={loading}
          onClick={onContinue}
          className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto w-full"
        >
          Confirmar ubicación
        </Button>
      </div>
    </div>
  );
}
