module.exports = {
    content: ['./src/**/*.{js,jsx,ts,tsx}'],
    theme: {
        extend: {
            colors: {
                'dark-kiwi': '#011E3D',
                'blue-dark-kiwi': '#002E96',

                'regal-blue-kiwi': '#043960',   
                'regal-blue-kiwi-800': '#00598E',
                'blue-kiwi': '#33CAFF',
                'blue-50-kiwi': '#E0F6FF',
                'blue-25-kiwi': '#F0FBFF',

                'red-kiwi': '#FF616D',
                'red-50-kiwi': '#FFF0F1',

                'green-kiwi': '#34D399',
                'green-50-kiwi': '#EBFBF5',

                'algae-kiwi-400': '#4ABD78',

                'regal-blue-950-kiwi': '#043960',

                'orange-kiwi': '#FF8B00',
                'orange-50-kiwi': '#FFF7ED',
                'orange-90-kiwi': '#FFEDD5',
                'orange-300-kiwi': '#FDBA74',
                'orange-900-kiwi': '#7C2D12',

                'yellow-kiwi': '#FFB84D',
                'yellow-50-kiwi': '#FFF5E5',
                'yellow-900-kiwi': '#431407',

                'golden-500-kiwi': '#F99307',
                'golden-100-kiwi': '#FFF1C6',
                'golden-800-kiwi': '#94380C',

                'amber-100-kiwi': '#FEF3C7',
                'amber-600-kiwi': '#D97706',

                'gray-50-kiwi': '#F6F8FA',
                'gray-100-kiwi': '#F6F7F9',
                'gray-200-kiwi': '#E9ECEF',
                'gray-300-kiwi': '#DEE2E6',
                'gray-400-kiwi': '#CED4DA',
                'gray-500-kiwi': '#ADB5BD',
                'gray-600-kiwi': '#6C757D',
                'gray-700-kiwi': '#495057',

                'slate-50-kiwi': '#F8FAFC',
                'slate-100-kiwi': '#F1F5F9',
                'slate-200-kiwi': '#E2E8F0',
                'slate-300-kiwi': '#CBD5E1',
                'slate-400-kiwi': '#94A3B8',
                'slate-500-kiwi': '#64748B',
                'slate-600-kiwi': '#475569',
                'slate-700-kiwi': '#334155',
                'slate-900-kiwi': '#011E3D',
            },
        },
    },
    plugins: [require('@tailwindcss/forms')],
};
