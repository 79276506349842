import { useState, useEffect, useContext } from "react";
import { usePlaidLink } from "react-plaid-link";
import { NotificationCenterAPI, PlaidAPI } from "api";
import LogRocket from "logrocket";
import mixpanel from "utils/mixpanel";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserContext";

export const usePlaidConnection = ({
  onSuccess,
  onExit,
  onError,
  redirectPath,
  redirectState,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [linkTokenPlaid, setLinkTokenPlaid] = useState("");
  const { setState } = useContext(UserContext);

  useEffect(() => {
    createTokenPlaid();
  }, []);

  const createTokenPlaid = async () => {
    try {
      setLoading(true);
      setShowLoader(true);

      const {
        data: {
          data: { link_token },
        },
      } = await PlaidAPI.generateToken();

      setLinkTokenPlaid(link_token);
    } catch (error) {
      if (onError) onError(error);
    } finally {
      setLoading(false);
      setShowLoader(false);
    }
  };

  const { open: openPlaid } = usePlaidLink({
    token: linkTokenPlaid,
    onSuccess: async (public_token) => {
      try {
        setShowLoader(true);

        const {
          data: { success },
        } = await PlaidAPI.publicTokenExchange({
          public_token,
        });

        if (!success) return;

        await getCheckBankConnection();

        if (onSuccess) {
          await onSuccess();
        }

        if (redirectPath) {
          navigate(redirectPath, { state: redirectState });
        }
      } catch (error) {
        if (onError) onError(error);
      } finally {
        setShowLoader(false);
      }
    },
    onExit: async (error, metadata) => {
      const { status, link_session_id, institution } = metadata;

      setShowLoader(false);

      LogRocket.captureMessage("Error plaid connection", {
        tags: {
          error_name: "plaid_error",
        },
        extra: {
          error,
          institution,
          link_session_id,
          plaid_request_id: metadata.request_id,
          status,
        },
      });

      mixpanel.track("Link Bank Account", {
        Type: "exit",
        Reason: "plaid",
      });

      if (onExit) {
        onExit(error, metadata);
      }
    },
  });

  const startPlaidConnection = async () => {
    await openPlaid();
    setShowLoader(true);
  };

  const getCheckBankConnection = async () => {
    const {
      data: { data: notifications },
    } = await NotificationCenterAPI.checkCurrentNotifications();

    setState((prev) => ({
      ...prev,
      notifications: notifications || [],
    }));
  };

  return {
    loading,
    showLoader,
    startPlaidConnection,
  };
};
