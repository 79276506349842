import PropTypes from 'prop-types';


const MerchantLogo = ({ logoUrl, merchantName }) => (
    <img
        src={logoUrl}
        className="w-14 h-14 rounded-full aspect-square object-contain"
        alt={merchantName}
    />
);

MerchantLogo.propTypes = {
    logoUrl: PropTypes.string.isRequired,
    merchantName: PropTypes.string.isRequired,
};

export default MerchantLogo;