import { useEffect, useContext, useState, Fragment } from "react";
import { UserContext } from "context/UserContext";

import UserWarningSVG from "assets/onboarding_v3/user_warning.svg";
import MagnifyingSVG from "assets/onboarding_v3/magnifying.svg";
import pusher from "utils/pusher";
import sleep from "utils/sleep";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  PERSONA_STATUS,
  PRODUCTS,
  APPLICATION_LAST_STATUS_VALUES,
  APPLICATION_LAST_STATUS_REASONS,
} from "constants";
import { AuthContext } from "context/AuthContext";
import { CreditBuilderContext } from "context/CreditBuilderContext";
import { STATES_AVAILABLES } from "constants";

export default function PersonaWaitingMobileCB() {
  const [searchParams] = useSearchParams();
  const inquiryTag = searchParams.get("inquiry_tag");

  const navigate = useNavigate();
  const { state: userState, setState: setUserState } = useContext(UserContext);
  const { state: currentState, saveState: saveCurrentState } =
    useContext(CreditBuilderContext);
  const { userVerification } = useContext(AuthContext);

  const channel = pusher.subscribe(userState.user.id);

  const [showReviewingDocument, setShowReviewingDocument] = useState(true);
  const [showDeclinedPersona, setShowDeclinedPersona] = useState(false);
  const [showNeedsReviewPersona, setShowNeedsReviewPersona] = useState(false);

  useEffect(() => {
    verifyInquiryStatus();

    return () => {
      channel.unbind("persona");
    };
  }, []);

  const verifyInquiryStatus = async () => {
    const { inquiry_status } = await upToDateUser();

    if (inquiry_status === PERSONA_STATUS.approved) {
      approvedPersona();
    } else if (inquiry_status === PERSONA_STATUS.needsReview) {
      setShowNeedsReviewPersona(true);
      setShowReviewingDocument(false);
    } else if (
      [PERSONA_STATUS.failed, PERSONA_STATUS.declined].includes(inquiry_status)
    ) {
      setShowDeclinedPersona(true);
      setShowReviewingDocument(false);
    } else {
      channel.bind("persona", async ({ message }) => {
        await upToDateUser();
        if (message === PERSONA_STATUS.approved) approvedPersona();
      });

      /**
       * Esperar 10 segundos a la respuesta de pusher...
       * Si no llega el mensaje de approved se muestra mensaje de error
       */
      await sleep(10000);
      channel.unbind("persona");

      setShowNeedsReviewPersona(true);
      setShowReviewingDocument(false);
    }
  };

  const approvedPersona = async () => {
    const isMeSalve = inquiryTag === "personal_loan_immediate";

    if (isMeSalve) {
      const isApplicationVerified = await checkApplicationVerified();

      if (isApplicationVerified) {
        onApproved();
      } else {
        setShowNeedsReviewPersona(true);
        setShowReviewingDocument(false);
      }
    } else {
      onApproved();
    }
  };

  const checkApplicationVerified = async () => {
    const currentProduct =
      PRODUCTS.creditBuilder === inquiryTag
        ? PRODUCTS.creditBuilder
        : PRODUCTS.personalLoan;
    const lastStatus =
      userState.products[currentProduct].application?.last_status;

    const isVerification =
      lastStatus?.value === APPLICATION_LAST_STATUS_VALUES.verification;
    const isVerified =
      lastStatus?.reason === APPLICATION_LAST_STATUS_REASONS.verified;

    return isVerification && isVerified;
  };

  /**
   * Obtiene el usuario y actualiza el contexto de Usuario
   * para tener una copia actualizada del usuario...
   */
  const upToDateUser = async () => {
    const {
      data: { user },
    } = await await userVerification();

    await setUserState((prev) => ({
      ...prev,
      user,
    }));

    return user;
  };

  const onContinue = async () => {
    if (
      userState.user.state === STATES_AVAILABLES.florida &&
      approvedState.veritec.completed === false
    ) {
      return navigate("/approved-loan/veritec");
    }

    navigate("/approved-loan/contract");
  };

  const onApproved = async () => {
    saveCurrentState({
      ...currentState,
      persona: {
        completed: true,
      },
    });

    onContinue();
  };

  return (
    <Fragment>
      {showReviewingDocument && (
        <div className="flex flex-col justify-center min-h-full text-center m-auto">
          <img className="mx-auto mb-8" src={MagnifyingSVG} />
          <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
            Revisando documento
          </h1>
          <p>
            Estamos analizando tu documento, recibirás respuesta en segundos.
          </p>
        </div>
      )}

      {showNeedsReviewPersona && (
        <Fragment>
          <div className="flex flex-col justify-center min-h-full text-center m-auto">
            <img className="mx-auto mb-8" src={MagnifyingSVG} />
            <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
              Estamos verificando tu identidad
            </h1>
            <p>
              Estamos analizando tu documento, recibirás respuesta en minutos.
              Nos pondremos en contacto contigo dentro de las próximas 24 horas.
            </p>
          </div>
          <button
            onClick={() => navigate("/home")}
            className="rounded-xl p-3 mt-10 bg-blue-kiwi text-white"
          >
            Aceptar
          </button>
        </Fragment>
      )}

      {showDeclinedPersona && (
        <Fragment>
          <div className="flex flex-col justify-center items-center min-h-full text-center m-auto">
            <img className="mx-auto mb-8" src={UserWarningSVG} />
            <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
              No pudimos verificar tu identidad
            </h1>
            <p>
              Lamentablemente, no pudimos verificar tu identidad. Por favor,
              revisa tu correo electrónico para obtener más detalles.
            </p>
          </div>
          <button
            onClick={() => navigate("/home")}
            className="rounded-xl p-3 mt-10 bg-blue-kiwi text-white"
          >
            Aceptar
          </button>
        </Fragment>
      )}
    </Fragment>
  );
}
