
import PropTypes from 'prop-types';
import readableFileSize from 'utils/readableFileSize'
import { XIcon, PicIcon, Document2Icon } from 'assets/icons_v3/fonts'

const File = ({ image, index, onImageRemove }) => {
    return (
        <div className="flex p-4 rounded-lg border border-solid border-gray-100-kiwi">
            <div className="bg-slate-100-kiwi rounded-full w-10 h-10 flex items-center justify-center shrink-0 text-regal-blue-kiwi">
                {image.file.type === 'application/pdf' ? (
                    <Document2Icon />
                ) : (
                    <PicIcon />
                )}
            </div>
            <div className="grow ml-4 mr-6">
                <div className="font-semibold leading-5 break-all">
                    {image.file.name}
                </div>
                <small className="text-gray-500-kiwi">
                    {readableFileSize(image.file.size)}
                </small>
            </div>
            <XIcon
                className="cursor-pointer block w-5 text-gray-500-kiwi"
                onClick={() => onImageRemove(index)}
            />
        </div>
    )
}

File.propTypes = {
    image: PropTypes.shape({
        file: PropTypes.shape({
            type: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            size: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
    onImageRemove: PropTypes.func.isRequired,
};

export default File
