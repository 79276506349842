import React from 'react';
import {BankCardIcon, BankIcon} from 'assets/icons_v3/fonts';
import getLastProfileDigits from 'utils/getLastProfileDigits';

export default function PaymentMethod({profile, onClick, ...props}) {
    return (
        <div
            className="p-4 flex items-center rounded-lg cursor-pointer"
            onClick={() => onClick(profile)}
            {...props}>
            <div className="h-10 w-10 bg-white mr-4 grow-0 shrink-0 rounded-full flex">
                {profile.checkingAccountId ? (
                    <BankIcon className="w-6 m-auto text-regal-blue-kiwi" />
                ) : (
                    <BankCardIcon className="w-6 m-auto text-regal-blue-kiwi" />
                )}
            </div>
            <div className="mr-auto">
                <div className="leading-4 font-semibold mb-1">
                    {profile.checkingAccountId ? 'Cuenta' : 'Tarjeta'} **** ****{' '}
                    {getLastProfileDigits(profile)}
                </div>
                <p className="text-xs text-gray-500-kiwi">Cuenta registrada</p>
            </div>
        </div>
    );
}
