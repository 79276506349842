import  { useEffect } from 'react'
import Barcode from 'react-barcode'
import PropTypes from 'prop-types'

import closeIcon from './../../../assets/icons/close.svg'

export default function LoadingModal({ isVisible, closeModal, code }) {
    useEffect(() => {
        if (isVisible === true) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [isVisible])

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    const onCloseModal = () => {
        closeModal()
    }

    if (isVisible) {
        return (
            <div className="w-full h-full p-6 flex flex-col fixed top-0 left-0 z-10 bg-white overflow-auto">
                <img className="ml-auto cursor-pointer" onClick={onCloseModal} src={closeIcon} />
                <div className="flex justify-center items-center flex-1">
                    <div className="rotate-90">
                        <Barcode width={6} height={250} value={code} displayValue={false}></Barcode>
                    </div>
                </div>
            </div>
        )
    }
}

LoadingModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    code: PropTypes.string.isRequired
}
