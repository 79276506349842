import { UserContext } from 'context/UserContext'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowRightUpIcon, InformationIcon } from 'assets/icons_v3/fonts'
import ChartLineSVG from 'assets/icons_v3/chart_line.svg'
import formatDate from 'utils/formatDate'
import ToggleProduct from './ToggleProduct'

export default function HomeStatusCreditBuilder() {
    const navigate = useNavigate();

    const {state: userState} = useContext(UserContext);
    const {
        products: {
            credit_builder: {
                loan: {daysPastDue, nextPaymentDate, amountDue},
                order_active,
            },
        },
    } = userState;

    const isLoanDue = daysPastDue > 0;

    const onSelect = () => {
        navigate('/credit-builder');
    };

    return (
        <ToggleProduct img={ChartLineSVG} title="Credit Builder">
            <div className="p-4 cursor-pointer" onClick={onSelect}>
                <div className="flex items-start justify-between gap-4">
                    <div>
                        <div className="text-sm text-regal-blue-kiwi/50">
                            Curso
                        </div>
                        <div className="font-semibold text-lg leading-6 text-regal-blue-kiwi">
                            {order_active?.product.name}
                        </div>
                    </div>

                    <div className="font-medium shrink-0 grow-0 px-3 py-1 bg-regal-blue-kiwi rounded-2xl text-xs text-white flex items-center gap-1">
                        Ver curso{' '}
                        <ArrowRightUpIcon className="w-4 h-4 text-white" />
                    </div>
                </div>

                {!isLoanDue && (
                    <div className="text-xs mt-4 text-regal-blue-kiwi/50">
                        Próxima fecha de pago:{' '}
                        {formatDate(nextPaymentDate, 'MMMM DD, YYYY')}
                    </div>
                )}
            </div>
            <div className="-ml-[1px] -mb-[1px] w-[calc(100%+2px)]">
                {isLoanDue && (
                    <div
                        className="flex items-center font-semibold text-xs bg-yellow-50-kiwi text-yellow-900-kiwi py-2 px-4 rounded-br-xl rounded-bl-xl"
                        onClick={onSelect}>
                        <InformationIcon className="w-4 mr-2" />
                        Retraso de {daysPastDue}{' '}
                        {daysPastDue > 1 ? 'días' : 'día'} en tu pago | $
                        {amountDue}
                    </div>
                )}
            </div>
        </ToggleProduct>
    );
}
