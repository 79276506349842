import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import InformationIcon from "assets/onboarding_v3/information_circle.svg";
import {
  BankCardIcon,
  BankIcon,
  AddIcon,
  RightIcon,
} from "assets/icons_v3/fonts";
import plusIcon from "assets/icons/plus.svg";
import { UserContext } from "context/UserContext";
import AlertInformationModal from "components/Modals/AlertInformation";
import BackwardButton from "components/Button/Backward";

export default function KiwiPayActivePaymentMethod() {
  const navigate = useNavigate();
  const { getPaymentProfiles } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [hasMoreCreditCards, setHasMoreCreditCards] = useState(false);
  const [showModalAlertInformation, setShowModalAlertInformation] =
    useState(false);

  useEffect(() => {
    getPaymentMethods();
  }, []);

  useEffect(() => {
    countingCreditCards();
  }, [paymentMethods]);

  const getPaymentMethods = async () => {
    const { data } = await getPaymentProfiles();
    setPaymentMethods(data?.results ?? []);
    setLoading(false);
  };

  const onBackward = () => {
    navigate("/kiwi-pay/active");
  };

  const onShowDetails = (method) => {
    navigate("/kiwi-pay/active/payment-method/details", {
      state: {
        ...method,
      },
    });
  };

  const onAddCreditCard = () => {
    if (hasMoreCreditCards) {
      return setShowModalAlertInformation(!showModalAlertInformation);
    }
    navigate("/kiwi-pay/active/payment-method/card");
  };

  const getMethodTitle = (item) => {
    if (item.checkingAccountId) {
      return `Cuenta **** ${String(item.CheckingAccount.accountNumber).slice(
        -4,
      )}`;
    } else {
      return `Tarjeta **** ${String(item.CreditCard.cardNumber).slice(-4)}`;
    }
  };

  const countingCreditCards = () => {
    const count = paymentMethods.filter(
      (paymentMethod) => paymentMethod.checkingAccountId === null,
    ).length;
    if (count >= 2) {
      return setHasMoreCreditCards(true);
    }
    setHasMoreCreditCards(false);
  };

  return (
    <>
      <BackwardButton onBackward={onBackward} />

      <div className="mb-10">
        <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
          Métodos de pago
        </h1>
        <p className="mb-10">
          Los días de pago se ajustarán al próximo día laboral en caso de ser
          fin de semana o día feriado
        </p>

        <div className="flex flex-col gap-6 mb-10">
          {loading === false ? (
            <>
              {paymentMethods?.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center rounded-lg shadow-lg shadow-dark-kiwi/5 p-4 cursor-pointer"
                  onClick={() => {
                    onShowDetails(item);
                  }}
                >
                  <div className="w-10 h-10 grow-0 shrink-0 rounded-full mr-4 flex justify-center items-center bg-white text-regal-blue-kiwi">
                    {item.checkingAccountId ? (
                      <BankIcon className="w-6" />
                    ) : (
                      <BankCardIcon className="w-6" />
                    )}
                  </div>
                  <div className="mr-auto">
                    <div className="leading-4 font-semibold">
                      {getMethodTitle(item)}
                    </div>
                    {item.isPrimary === 1 ? (
                      <p className="text-xs text-gray-500-kiwi mt-1">
                        Método de pago principal
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <RightIcon className="text-gray-500-kiwi" />
                </div>
              ))}
            </>
          ) : (
            <>
              {[...Array(3)].map((item, index) => (
                <div
                  key={index}
                  className="flex items-center rounded-lg shadow-lg shadow-dark-kiwi/5 p-4"
                >
                  <div className="h-10 w-10 bg-gray-50-kiwi mr-4 grow-0 shrink-0 rounded-full"></div>
                  <div className="w-2/3">
                    <div className="bg-gray-50-kiwi mb-2 h-3 w-full rounded-lg"></div>
                    <div className="bg-gray-50-kiwi h-2 w-2/3 rounded-lg"></div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        <button
          className="w-full bg-blue-kiwi/10 text-blue-kiwi rounded-xl p-4 flex items-center justify-center border border-dashed border-blue-kiwi"
          onClick={onAddCreditCard}
        >
          <AddIcon className="mr-4" src={plusIcon} />
          Agregar nueva tarjeta
        </button>
      </div>

      <p className="text-xs text-gray-500-kiwi mt-auto">
        Si quieres cambiar tu tarjeta puedes comunicarte con nosotros al correo{" "}
        <strong>support@kiwicredito.com</strong>
      </p>

      <AlertInformationModal
        isVisible={showModalAlertInformation}
        icon={InformationIcon}
        title="No es posible agregar una nueva tarjeta"
        description="Si deseas agregar una nueva tarjeta. Por favor, ponte en contacto con nuestro equipo de soporte al correo <b>support@kiwicredito.com</b>"
        closeModal={() =>
          setShowModalAlertInformation(!showModalAlertInformation)
        }
      />
    </>
  );
}
