import http from "utils/http";

export const createActivity = async (data) => {
  const response = await http.post("/activity/app", data);
  return response;
};

export default {
  createActivity,
};
