import { useRef, useEffect, useContext, useState } from "react";
import { trackEvent } from "utils/dmp_kiwi";
import { UserContext } from "context/UserContext";
import { useLocation } from "react-router-dom";

export const TrackingProvider = () => {
  const location = useLocation();
  const previousLocation = useRef(null);
  const [utmData, setUtmData] = useState(null);
  const pageReferrer = document.referrer;
  const { state: userState } = useContext(UserContext);
  const user_id = userState?.user?.id;

  const generateUrl = (path) => {
    if (!path) return window.location.origin;
    return new URL(path, window.location.origin).href;
  };

  const getUTMLocalstorage = () => {
    const storedParams = sessionStorage.getItem("urlParams");
    if (storedParams) {
      setUtmData(JSON.parse(storedParams));
    }
  };

  const sendPageViewEvent = async (currentPath, previousPath) => {
    await trackEvent({
      pathname: generateUrl(currentPath),
      place: previousPath || pageReferrer,
      event_type: "page_view",
      user_id,
      utm_data: {
        utm_campaign: utmData?.utm_campaign ?? null,
        utm_content: utmData?.utm_content ?? null,
        utm_id: utmData?.utm_id ?? null,
        utm_medium: utmData?.utm_medium ?? null,
        utm_source: utmData?.utm_source ?? null,
        utm_term: utmData?.utm_term ?? null,
      },
    });
  };

  useEffect(() => {
    const pathname = location.pathname;
    getUTMLocalstorage();
    sendPageViewEvent(pathname, previousLocation.current);
    previousLocation.current = location.pathname;
  }, [location]);

  return null;
};
