import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function CreditMonitoringReport() {
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    init();
    window.addEventListener("array-event", arrayEventListener);

    return () => {
      window.removeEventListener("array-event");
    };
  }, []);

  const init = async () => {
    const userParam = searchParams.get("user");
    setUser(JSON.parse(userParam));
  };

  const arrayEventListener = (arrayEvent) => {
    const event = new CustomEvent("embed-credit-monitoring", arrayEvent);
    window.parent.document.dispatchEvent(event);
  };

  return (
    user && (
      <array-account-enroll
        appKey={process.env.REACT_APP_ARRAY_APP_KEY}
        sandbox={process.env.REACT_APP_ARRAY_SANDBOX}
        uponSuccessShow="successMessage"
        termsOfUseHref="https://www.kiwicredito.com/terms-use"
        privacyPolicyHref="https://www.kiwicredito.com/privacy-policy"
        firstName={user.first_name}
        lastName={user.first_surname}
        streetAddress={user.address}
        state={"PR"}
        city={user.city}
        ssn3={user.identification_number?.slice(-4)}
        zipCode={user.zipcode}
        dobDay={user.birthdate?.split("-")[2]}
        dobMonth={user.birthdate?.split("-")[1]}
        dobYear={user.birthdate?.split("-")[0]}
        emailAddress={user.email}
        phone={user.phone_number?.replace(/\D/g, "").slice(-10)}
      />
    )
  );
}
