import React from "react";
import { useNavigate } from "react-router-dom";
import MaintenanceSVG from "assets/onboarding_v3/maintenance.svg";
import BackwardButton from "components/Button/Backward";

export default function Maintenance() {
  const navigate = useNavigate();

  const onBackward = () => {
    navigate("/home");
  };

  return (
    <div className="px-6 py-10 h-full overflow-hidden">
      <BackwardButton onBackward={onBackward} className="mb-0" />

      <div className="flex flex-col justify-center min-h-full text-center m-auto">
        <img
          className="mx-auto mb-8"
          src={MaintenanceSVG}
          alt="Mantenimiento Kiwi"
        />
        <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
          ¡Estamos en mantenimiento!
        </h1>
        <p>
          En este momento, estamos mejorando nuestra app para ofrecerte una
          experiencia aún mejor. Por favor, inténtalo de nuevo en unos minutos.
        </p>
        <p className="mt-2">¡Gracias por tu comprensión!</p>
      </div>
    </div>
  );
}
