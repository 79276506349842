import PropTypes from 'prop-types';

const LinkButton = ({ children, className, ...props }) => {
    return (
        <div
            className={`text-blue-kiwi inline-block ${className}`}
            {...props}>
            {children}
        </div>
    )
}

LinkButton.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

export default LinkButton;
