import {ChartLineIcon, CurrencyDollarIcon} from 'assets/icons_v3/fonts';
import BackwardButton from 'components/Button/Backward';
import MenuLink from 'components/MenuLink';
import PageTitle from 'components/PageTitle';
import  {Fragment} from 'react';
import {useNavigate} from 'react-router-dom';

export default function DocumentsFiles() {
    const navigate = useNavigate();

    const onBackward = () => {
        navigate('/settings');
    };

    const products = [
        {
            icon: <CurrencyDollarIcon />,
            title: 'Préstamos personales',
            action: function () {
                navigate('/documents/products', {
                    state: 'personal_loan',
                });
            },
        },
        {
            icon: <ChartLineIcon />,
            title: 'Credit Builder',
            action: function () {
                navigate('/documents/products', {
                    state: 'credit_builder',
                });
            },
        },
    ];

    const documents = [
        {
            title: 'Términos y condiciones de referidos',
            action: function () {
                window.open(
                    'https://www.kiwicredito.com/terminos-condiciones-referidos',
                    '_blank',
                );
            },
        },
        {
            title: 'Términos y condiciones ruleta de premios Kiwi',
            action: function () {
                window.open(
                    'https://www.kiwicredito.com/terminos-condiciones-ruleta',
                    '_blank',
                );
            },
        },
        {
            title: 'Aviso de privacidad de aplicaciones móviles',
            action: function () {
                window.open(
                    'https://www.kiwicredito.com/privacy-mobile',
                    '_blank',
                );
            },
        },
        {
            title: 'Licencias',
            action: function () {
                window.open(
                    'https://www.kiwicredito.com/state-licenses',
                    '_blank',
                );
            },
        },
    ];

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />
            <PageTitle>Documentos y contratos</PageTitle>

            <div className="flex flex-col gap-6 mt-8">
                <h2 className="text-slate-500-kiwi uppercase text-xs">
                    Productos
                </h2>

                <div className="flex flex-col gap-2">
                    {products.map(link => (
                        <MenuLink
                            key={link.title}
                            title={link.title}
                            icon={link.icon}
                            onClick={() => link.action.call(link)}
                            description={link.description}
                        />
                    ))}
                </div>

                <h2 className="text-slate-500-kiwi uppercase text-xs">
                    Otros documentos
                </h2>

                <div className="flex flex-col gap-2">
                    {documents.map(link => (
                        <MenuLink
                            key={link.title}
                            title={link.title}
                            onClick={() => link.action.call(link)}
                            description={link.description}
                        />
                    ))}
                </div>
            </div>
        </Fragment>
    );
}
