import  { useState, useEffect } from 'react'
import Emitter from 'utils/emitter'
import anime from 'animejs'
import infoIcon from 'assets/icons/notification/info.svg'
import errorIcon from 'assets/icons/notification/error.svg'
import warningIcon from 'assets/icons/notification/warning.svg'
import successIcon from 'assets/icons/notification/success.svg'
import { XIcon } from '@heroicons/react/outline'

export default function NotificationModal() {
    const [options, setOptions] = useState({})
    const [visible, setVisible] = useState(false)
    const [countdown, setCountDown] = useState(false)

    useEffect(() => {
        Emitter.on('onOpenNotification', onOpenNotification)

        return () => {
            Emitter.off('onOpenNotification', onOpenNotification)
        }
    }, [])

    useEffect(() => {
        if (visible === true) {
            fadeInAnimation()

            let countdown = setTimeout(() => {
                onCloseNotification()
            }, 10000)

            setCountDown(countdown)
        } else {
            clearTimeout(countdown)
        }
    }, [visible])

    const onOpenNotification = (options) => {
        setOptions(options)
        setVisible(true)
    }

    const fadeInAnimation = () => {
        let container = document.getElementById('notification')

        anime
            .timeline({
                easing: 'easeInCubic',
                targets: container
            })
            .add({
                opacity: [0, 1],
                duration: 500
            })
            .add(
                {
                    easing: 'easeOutElastic(1, .6)',
                    translateY: [150, 0],
                    duration: 800
                },
                '-=250'
            )
    }

    const getNotificationDetails = () => {
        if (options.type === 'info') {
            return {
                icon: infoIcon,
                bg: 'bg-blue-50-kiwi',
                circle: 'bg-[#C2EDFF]'
            }
        }

        if (options.type === 'error') {
            return {
                icon: errorIcon,
                bg: 'bg-red-50-kiwi',
                circle: 'bg-[#FFDBE1]'
            }
        }

        if (options.type === 'warning') {
            return {
                icon: warningIcon,
                bg: 'bg-yellow-50-kiwi',
                circle: 'bg-[#FFE9C7]'
            }
        }

        if (options.type === 'success') {
            return {
                icon: successIcon,
                bg: 'bg-green-50-kiwi',
                circle: 'bg-[#C0F1DF]'
            }
        }
    }

    const onCloseNotification = () => {
        setVisible(false)
    }

    if (visible) {
        return (
            <div
                id="notification"
                className={`fixed bottom-6 right-0 left-0 z-50 w-full max-w-[450px] px-6 mx-auto sm:bottom-12`}
                onClick={onCloseNotification}>
                <XIcon className="w-5 absolute right-12 top-6 text-gray-400-kiwi" />
                <div
                    className={`flex items-center p-6 rounded-lg ${
                        getNotificationDetails()?.bg
                    }`}>
                    <div
                        className={`flex items-center justify-center rounded-full h-10 w-10 shrink-0 ${
                            getNotificationDetails()?.circle
                        }`}>
                        <img src={getNotificationDetails()?.icon} />
                    </div>

                    <div className="ml-6">
                        <div className="text-sm font-bold mb-1">
                            {options.title}
                        </div>
                        <div
                            className="text-xs"
                            dangerouslySetInnerHTML={{
                                __html: options.message
                            }}></div>
                    </div>
                </div>
            </div>
        )
    }
}
