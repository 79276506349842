import BaseModal from '../BaseModal'
import PropTypes from 'prop-types'

const PatriotaTermsModal = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="La Ley PATRIOTA de los EE.UU"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6">
                <p>
                    La Ley PATRIOTA de los EE.UU. requiere que Kiwi y sus socios
                    se adhieran a la verificación de las personas que abren una
                    cuenta con nosotros. Como resultado, Kiwi aplica un Programa
                    de Identificación del Cliente (CIP) a todas las cuentas.
                    Recopilamos su nombre, dirección física, fecha de nacimiento
                    y Número de Seguro Social y/o Número de Identificación
                    Personal del Contribuyente (SSN/ITIN).
                </p>
                <p>
                    También podemos solicitar información adicional, como una
                    licencia de conducir u otros documentos de identificación en
                    caso de que no podamos verificar su identidad utilizando la
                    información original proporcionada.
                </p>
                <p>
                    El uso de una cuenta verificada ayuda en los esfuerzos para
                    prevenir el fraude y ayuda al gobierno a combatir la
                    financiación del terrorismo y las actividades de lavado de
                    dinero. Nos tomamos muy en serio su privacidad y tenemos en
                    cuenta sus mejores intereses cuando tratamos con estos datos
                    confidenciales.
                </p>
            </div>
        </BaseModal>
    )
}

PatriotaTermsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default PatriotaTermsModal
