export const TypeNotification = (notification) => {
  switch (notification.type) {
    case "alert":
      return (
        <div className="w-6 h-6 min-w-[1.5rem] min-h-[1.5rem] rounded-full flex items-center justify-center bg-red-500">
          <p className="text-white text-xs line-none">1</p>
        </div>
      );
    default:
      return null;
  }
};

export default TypeNotification;
