
import { useNavigate } from 'react-router-dom'

import BankWarningSVG from 'assets/onboarding_v3/bank_warning.svg'
import supportIcon from 'assets/icons/support.svg'
import { ChevronLeftIcon } from 'assets/icons_v3/fonts'

export default function DuplicatedBankAccount() {
    const navigate = useNavigate()

    const onBackward = () => {
        navigate('/home')
    }

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <ChevronLeftIcon
                className="cursor-pointer w-2 mb-12"
                onClick={onBackward}
            />

            <div className="m-auto flex flex-col justify-center h-full text-center">
                <img className="mx-auto mb-8" src={BankWarningSVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    No pudimos verificar tu cuenta de banco
                </h1>
                <p>
                    Lamentablemente, no pudimos verificar tu cuenta de banco.
                    Por favor, póngase en contacto con nuestro equipo de soporte
                    al correo <strong>support@kiwicredito.com</strong>
                </p>
            </div>

            <a
                href="tel:+17874221861"
                className="flex justify-center items-center rounded-xl p-3 mt-10 bg-blue-kiwi text-white">
                <img className="mr-2" src={supportIcon} />
                ¿Necesitas ayuda?
            </a>
        </div>
    )
}
