import LoanPurposeModal from 'components/Modals/LoanPurpose'
import  { useRef } from 'react'
import { Outlet } from 'react-router-dom'

export default function ActiveLoan() {
    const LoanPurposeRef = useRef()

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <LoanPurposeModal ref={LoanPurposeRef} />
            <Outlet />
        </div>
    )
}
