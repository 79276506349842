import PropTypes from 'prop-types'
import moneyFormat from 'utils/moneyFormat'
import BaseModal from '../BaseModal'

const CreditLimitCancel = ({
    isVisible,
    closeModal,
    cancelSurvey,
    creditLimit
}) => {
    const onCancelSurvey = () => {
        closeModal()
        cancelSurvey()
    }

    return (
        <BaseModal
            title={`Continuar con tu límite actual de $${moneyFormat(
                creditLimit
            )}`}
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col mt-6">
                <p>
                    ¿Te gustaría esperar la respuesta de aumento o continuar con
                    tu límite actual?. Ten en cuenta que al &ldquo;Continuar con
                    límite actual&rdquo;, tu solicitud de aumento de límite se
                    cancelará automáticamente.
                </p>

                <div className="flex flex-col mt-10">
                    <button
                        className="rounded-xl p-3 bg-blue-kiwi text-white"
                        onClick={closeModal}>
                        Esperar verificación
                    </button>
                    <button
                        className="text-blue-kiwi mx-auto mt-6"
                        onClick={onCancelSurvey}>
                        Continuar con límite actual
                    </button>
                </div>
            </div>
        </BaseModal>
    )
}

CreditLimitCancel.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    cancelSurvey: PropTypes.func.isRequired,
    creditLimit: PropTypes.number.isRequired
}

export default CreditLimitCancel
