import BaseModal from '../BaseModal'
import PropTypes from 'prop-types'

const ConsentTermsModal = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="Consentimiento de Verificación de Identidad"
            isVisible={isVisible}
            closeModal={closeModal}>
            <p>
                Para ayudar al gobierno a combatir el financiamiento del
                terrorismo y las actividades de lavado de dinero, las leyes
                federales requieren que todas las instituciones financieras
                obtengan, verifiquen y registren información que identifique a
                cada persona que abre una cuenta. Como resultado, conforme a
                nuestro Programa de Identificación del Cliente (CIP), debemos
                solicitar su nombre, dirección física, fecha de nacimiento,
                Número de Seguro Social y/o Número de Identificación Personal
                del Contribuyente (SSN/ITIN), y otra información que nos permita
                identificarlo. También podemos solicitar ver su licencia de
                conducir u otros documentos de identificación. Usted nos
                autoriza a verificar esta información utilizando bases de datos
                y proveedores de información.
            </p>
        </BaseModal>
    )
}

ConsentTermsModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired
}

export default ConsentTermsModal
