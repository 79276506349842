import BaseModal from '../BaseModal'
import Button from 'components/Button'
import RatingAgentSVG from 'assets/icons_v3/rating.svg'
import PropTypes from 'prop-types'

const RatingThankModal = ({ isVisible, closeModal }) => {
    return (
        <BaseModal isVisible={isVisible} closeModal={closeModal} title=" ">
            <div className="flex flex-col items-center justify-center">
                <img src={RatingAgentSVG} />
                <p className="text-slate-900-kiwi text-xl text-center font-semibold mb-2 mt-10">
                    Gracias por tus comentarios
                </p>
                <p className="text-center text-gray-700-kiwi">
                    Entendemos que no tuviste una experiencia positiva. Nuestro
                    equipo te contactará pronto para ofrecerte una mejor
                    experiencia
                </p>

                <Button
                    className=" bg-blue-kiwi text-white rounded-xl p-3 mt-10 text-base w-full"
                    type="button"
                    onClick={closeModal}>
                    Aceptar
                </Button>
            </div>
        </BaseModal>
    )
}

RatingThankModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default RatingThankModal
