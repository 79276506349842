import  {Fragment, useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {RequestLoanContext} from 'context/RequestLoanContext';

import mixpanel from 'utils/mixpanel';
import AddressTemplate from 'templates/Address';
import ProgressBar from 'components/ProgressBar';
import {UserContext} from 'context/UserContext';

export default function RequestLoanAddress() {
    const {state: userState} = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        dataLayer.push({
            event: 'new_application',
            eventCategory: 'loan_process',
            eventAction: 'loan_process_address',
            dl_user_id: userState.user.id,
        });
    }, []);

    const onContinue = async address => {
        mixpanel.track('Residential Address Input');
        mixpanel.people.set({
            $city: address.city,
            'Survey City': address.city,
            State: address.state,
            Suite: address.suite,
            Address: address.value,
            Zipcode: address.zipcode,
        });
        navigate('/request-loan/ssn');
    };

    const onBackward = () => {
        navigate('/home');
    };

    return (
        <Fragment>
            <ProgressBar progress="1/12" onBackward={onBackward} />

            <AddressTemplate
                context={RequestLoanContext}
                onContinue={onContinue}
            />
        </Fragment>
    );
}
