import PropTypes from 'prop-types';
import RouletteHowWorksComponent from 'components/Roulette/HowWorks'

import BaseModal from '../BaseModal'

const RouletteHowWorks = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="¿Cómo participar?"
            description="Sigue estos pasos para participar:"
            isVisible={isVisible}
            closeModal={closeModal}>
            <RouletteHowWorksComponent />
        </BaseModal>
    )
}

RouletteHowWorks.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default RouletteHowWorks
