import Button from 'components/Button';
import BaseModal from '../BaseModal';
import PlaidImage from 'assets/cashback/plaid.png';
import PropTypes from 'prop-types';
export default function ConnectBankAccount({ isOpen, onClose }) {
    return (
        <BaseModal
            isVisible={isOpen}
            closeModal={onClose}
            title="¿Por qué necesito vincular mi cuenta?">
            <div className="flex flex-col gap-10">
                <div className="flex flex-col items-center gap-8">
                    <p className="text-slate-700-kiwi">
                        Para que tus recompensas se apliquen en tus compras,
                        utilizamos <span className="font-semibold">Plaid</span>,
                        una{' '}
                        <span className="font-semibold">plataforma segura</span>{' '}
                        que nos permite identificar transacciones en comercios
                        afiliados sin acceder a tus credenciales bancarias.
                        <br />
                        Plaid emplea{' '}
                        <span className="font-semibold">
                            tecnología de cifrado avanzada
                        </span>{' '}
                        para proteger tus datos y garantizar la máxima seguridad
                        en todo momento.
                        <br />
                        Con este vínculo, aseguramos que tus recompensas se
                        apliquen de manera sencilla y confiable, manteniendo
                        siempre la{' '}
                        <span className="font-semibold">
                            protección de tu información.
                        </span>
                    </p>
                    <img src={PlaidImage} className="h-14" />
                </div>
                <Button
                    onClick={onClose}
                    className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto w-full">
                    Entendido
                </Button>
            </div>
        </BaseModal>
    );
}

ConnectBankAccount.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};