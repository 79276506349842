import  { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { DownIcon, UpIcon } from 'assets/icons_v3/fonts'
import tailwindConfig from 'utils/tailwindConfig'

export default function HomeStatusToggleProduct({
    img,
    title,
    className,
    children,
}) {
    const cardContent = useRef();
    const cardHeader = useRef();

    const [isOpen, setIsOpen] = useState(() => {
        const isSelected = sessionStorage.getItem(`${title} card`);
        return ['true', null].includes(isSelected);
    });

    useEffect(() => {
        onExpandCard(!isOpen);
    }, []);

    const onExpandCard = isOpen => {
        if (isOpen) {
            cardContent.current.style.display = 'none';
            cardHeader.current.style.backgroundColor = 'transparent';
            cardHeader.current.style.border =
                '1px solid ' +
                tailwindConfig.theme.extend.colors['slate-200-kiwi'];
        } else {
            cardContent.current.style.display = 'block';
            cardHeader.current.style.backgroundColor =
                tailwindConfig.theme.extend.colors['blue-50-kiwi'];
            cardHeader.current.style.border = '1px solid transparent';
        }
    };

    const onToggleCard = () => {
        onExpandCard(isOpen);
        setIsOpen(!isOpen);
        sessionStorage.setItem(`${title} card`, !isOpen);
    };

    return (
        <div className={`bg-white ${className}`}>
            <div
                ref={cardHeader}
                onClick={onToggleCard}
                className="flex items-center p-4 bg-blue-50-kiwi rounded-xl cursor-pointer relative z-10">
                <img src={img} className="w-6 mr-4" />
                <div className="font-semibold text-sm mr-auto">{title}</div>
                {isOpen ? <UpIcon /> : <DownIcon />}
            </div>
            <div
                className="max-h-max relative border border-slate-200-kiwi rounded-bl-xl rounded-br-xl -mt-2 pt-2 z-0"
                ref={cardContent}>
                {children}
            </div>
        </div>
    );
}

HomeStatusToggleProduct.propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
}