import { useContext, useEffect, useState } from "react";
import BackButton from "components/BackButton";
import LoadingModal from "components/Modals/Loading";
import { CreditMonitoringContext } from "context/CreditMonitoringContext";

export default function CreditMonitoringReport() {
  const { state: creditMonitoringState } = useContext(CreditMonitoringContext);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 2000);
  }, []);

  return (
    <>
      <LoadingModal isVisible={showLoader} />
      <div className="flex flex-col w-full pl-6 pt-10 pb-8">
        <BackButton route="/home" />
      </div>
      <div className="flex flex-col w-full flex-1">
        <iframe
          src={`${window.location.origin}/array-report?array_user_token=${creditMonitoringState.arrayData.userToken}`}
          className="w-full flex-1"
        />
      </div>
    </>
  );
}
