import  {
    useState,
    Fragment,
    forwardRef,
    useEffect,
    useContext
} from 'react'

import CreditCardForm from './Form'
import CreditCardValidation from './Validation'
import CreditCardSuccess from './success'
import CreditCardRejected from './rejected'

import LoadingModal from 'components/Modals/Loading'
import BackwardButton from 'components/Button/Backward'
import { PaymentAPI } from 'api'
import { UserContext } from 'context/UserContext'
import { PAYMENT_PROFILE_STATUS } from 'constants'
import PropTypes from 'prop-types'

const AddCreditCard = forwardRef(({ onBackward, product }) => {
    const { state: userState } = useContext(UserContext)

    const initialCreditCard = {
        brand: '',
        name: '',
        number: '',
        expiration: '',
        cvc: '',
        principal: false
    }

    const initialValidationStatus = {
        id: '',
        last4: '',
        status: '',
        attempts: 0
    }

    const [showForm, setShowForm] = useState(false)
    const [showValidation, setShowValidation] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showRejected, setShowRejected] = useState(false)

    const [showLoader, setShowLoader] = useState(false)
    const [creditCard, setCreditCard] = useState(initialCreditCard)
    const [validationStatus, setValidationStatus] = useState(
        initialValidationStatus
    )

    useEffect(() => {
        getCurrentValidation()
    }, [])

    const getCurrentValidation = async () => {
        try {
            setShowLoader(true)

            const {
                data: { data }
            } = await PaymentAPI.cardCurrentEvaluate()

            if (data) {
                setValidationStatus(data)

                if (data.status === PAYMENT_PROFILE_STATUS.pending) {
                    return showValidationView()
                }

                if (data.validation_status === 'MINIMAL_TIME_REQUEST' && ![PAYMENT_PROFILE_STATUS.pending, PAYMENT_PROFILE_STATUS.validated].includes(data.status)) {
                    return showRejectedView()
                }
            }

            showFormView()
        } catch (error) {
            console.error(error)
        } finally {
            setShowLoader(false)
        }
    }

    const onSubmitCreditCard = async (creditCard) => {
        try {
            setShowLoader(true)

            const { number, expiration, name, principal, cvc } = creditCard
            const [month, year] = expiration.split('/')

            const {
                data: { data }
            } = await PaymentAPI.cardValidation({
                cvc,
                loanpro_loan_id: userState.products[product].loan.id,
                cardholder_name: name,
                card_number: number.replace(/\s/g, ''),
                expiration_year: year,
                expiration_month: month,
                is_primary: principal
            })

            setCreditCard(creditCard)
            setValidationStatus(data)
            showValidationView()
        } catch (error) {
            const {
                response: { data }
            } = error

            if (data.messages === 'VALIDATION_ACTIVE') {
                showRejectedView()
            }
        } finally {
            setShowLoader(false)
        }
    }

    const showValidationView = () => {
        setShowValidation(true)
        setShowForm(false)
        setShowRejected(false)
        setShowSuccess(false)
    }

    const showFormView = () => {
        setShowValidation(false)
        setShowForm(true)
        setShowRejected(false)
        setShowSuccess(false)
    }

    const showRejectedView = () => {
        setShowValidation(false)
        setShowForm(false)
        setShowRejected(true)
        setShowSuccess(false)
    }

    const showSuccessView = () => {
        setShowValidation(false)
        setShowForm(false)
        setShowRejected(false)
        setShowSuccess(true)
    }

    const onSuccessValidation = () => {
        showSuccessView()
    }

    const onRejectValidation = () => {
        showRejectedView()
    }

    const onAcceptSuccess = () => {
        onBackward()
    }

    const onAcceptRejected = () => {
        onBackward()
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />
            <LoadingModal isVisible={showLoader} />

            {showForm && (
                <CreditCardForm
                    values={creditCard}
                    onSubmitCreditCard={onSubmitCreditCard}
                />
            )}

            {showValidation && (
                <CreditCardValidation
                    validationStatus={validationStatus}
                    onSuccess={onSuccessValidation}
                    onReject={onRejectValidation}
                />
            )}

            {showSuccess && <CreditCardSuccess accept={onAcceptSuccess} />}
            {showRejected && <CreditCardRejected accept={onAcceptRejected} />}
        </Fragment>
    )
})

AddCreditCard.displayName = 'AddCreditCard'

AddCreditCard.propTypes = {
    onBackward: PropTypes.func.isRequired,
    product: PropTypes.string.isRequired
}

export default AddCreditCard
