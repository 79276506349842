import { pmt, nper } from "financial";

export function calculateLoan(principal, numberOfPayments, annualInterestRate) {
  const monthlyInterestRate = annualInterestRate / 12 / 100;
  const monthlyPayment = pmt(monthlyInterestRate, numberOfPayments, -principal);
  return {
    monthlyInterestRate: monthlyInterestRate,
    monthlyPayment: monthlyPayment,
  };
}
export function calculateNewLoanTerm(
  newLoanAmount,
  monthlyInterestRate,
  monthlyPayment,
  minTerm,
) {
  const termCalculation = nper(
    monthlyInterestRate,
    -monthlyPayment,
    newLoanAmount,
  );
  const roundedTerm = Math.ceil(termCalculation);
  const newTerm = Math.max(roundedTerm, minTerm);
  return newTerm;
}
export function calculateNewMonthlyPayment(
  newLoanAmount,
  newTerm,
  monthlyInterestRate,
) {
  const newMonthlyPayment = pmt(monthlyInterestRate, newTerm, -newLoanAmount);
  return newMonthlyPayment;
}
