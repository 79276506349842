import  { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import BackwardButton from 'components/Button/Backward'
import PlaidTemplate from 'templates/Plaid'
import { VerificationsAPI } from 'api'

export default function AutopayPlaid() {
    const navigate = useNavigate()

    const onBackward = () => {
        navigate('/autopay')
    }

    const onExit = () => {}

    const onSuccess = async () => {
        const { data } = await VerificationsAPI.autoPayVerification()
        console.log(data)
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />
            <PlaidTemplate
                title="Aún no tienes una cuenta bancaria"
                description="Para activar los pagos automáticos, puedes enlazar tu cuenta bancaria o contactar a soporte, asegúrate de que:"
                onSuccess={onSuccess}
                onExit={onExit}
            />
        </Fragment>
    )
}
