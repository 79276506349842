import PropTypes from "prop-types";

const CashbackPendingComponent = ({ offer, onSelectOffer }) => {
  return (
    <div
      onClick={onSelectOffer}
      className="cursor-pointer border border-amber-600 bg-amber-50 flex items-start justify-between rounded-xl p-4 gap-4"
    >
      <div className="flex flex-col gap-2 overflow-hidden text-ellipsis">
        <p className="text-sm text-amber-600 font-semibold uppercase">
          Pendiente
        </p>
        <div>
          <p className="text-sm text-slate-900 line-clamp-2 font-semibold overflow-hidden whitespace-nowrap overflow-ellipsis">
            {offer.description}
          </p>
          <p className="text-sm text-slate-700-kiwi overflow-hidden text-ellipsis whitespace-nowrap">
            {offer.store.merchant.merchant_name}
          </p>
        </div>
      </div>
      <img
        src={offer.store.merchant.logo_url}
        className="w-14 h-14 bg-white rounded-full object-contain"
        alt={offer.store.merchant.merchant_name}
      />
    </div>
  );
};

CashbackPendingComponent.propTypes = {
  offer: PropTypes.object.isRequired,
  onSelectOffer: PropTypes.func.isRequired,
};

export default CashbackPendingComponent;
