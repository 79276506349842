import { RightIcon } from "assets/icons_v3/fonts";
import PropTypes from "prop-types";
import TypeNotification from "./TypeNotification";

export default function MenuLinkComponent({
  title,
  description,
  icon,
  notification,
  ...props
}) {
  return (
    <div
      key={title}
      className="flex items-center cursor-pointer gap-4"
      {...props}
    >
      {icon && (
        <div className="w-10 h-10 min-w-[2.5rem] min-h-[2.5rem] rounded-full flex justify-center items-center text-slate-900-kiwi bg-slate-100-kiwi">
          {icon}
        </div>
      )}

      <div className="mr-auto py-4">
        <p className="font-medium">{title}</p>
        {description && (
          <p className="text-xs text-gray-500-kiwi">{description}</p>
        )}
      </div>

      {notification && TypeNotification(notification)}

      <RightIcon className="shrink-0 grow-0 w-6 h-6 text-gray-500-kiwi" />
    </div>
  );
}

MenuLinkComponent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.element,
  notification: PropTypes.object,
};
