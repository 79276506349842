import { useContext, useEffect, useState, Fragment } from "react";
import { Outlet, Navigate } from "react-router-dom";
import LogRocket from "logrocket";
import { AuthContext } from "context/AuthContext";
import { UserContext } from "context/UserContext";
import { setBearerToken } from "utils/http";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

import SplashComponent from "components/Splash";
import { NotificationCenterAPI, ProductsAPI } from "api";
import { STATES_AVAILABLES } from "constants";

export const FloridaStateMiddleware = () => {
  const {
    state: { user },
  } = useContext(UserContext);

  if (user.state === STATES_AVAILABLES.florida) {
    return <Navigate to="/maintenance" />;
  }

  return <Outlet />;
};

export const BlackListMiddleware = () => {
  const {
    state: { user },
  } = useContext(UserContext);

  return user.black_list === true ? <Navigate to="/black-list" /> : <Outlet />;
};

export const FingerprintBlockedMiddleware = () => {
  const {
    state: { user },
  } = useContext(UserContext);

  return user.fingerprint_blocked === true ? (
    <Navigate to="/fingerprint-blocked" />
  ) : (
    <Outlet />
  );
};

export const AuthMiddleware = () => {
  const [renderView, setRenderView] = useState(false);
  const [initialDataInterval, setInitialDataInterval] = useState(0);

  const {
    state: authState,
    userVerification,
    resetState: resetAuthState,
    fingerprintUtmUpdate,
  } = useContext(AuthContext);
  const {
    setState: setUserState,
    state: userState,
    resetState: resetUserState,
  } = useContext(UserContext);

  const { getData: getDataFingerprint } = useVisitorData(
    { extendedResult: true },
    { immediate: true },
  );

  useEffect(() => {
    let token = authState.accessTokenApp;

    if (token) {
      setBearerToken(token);
      refreshInitialData();
    } else {
      setRenderView(true);
    }

    return () => clearInterval(initialDataInterval);
  }, [authState.accessTokenApp]);

  const getInitialData = () => {
    return new Promise((resolve) => {
      (async () => {
        try {
          const { data } = await userVerification();
          const { success, user } = data;

          if (success) {
            const {
              data: { data: products },
            } = await ProductsAPI.getProducts();

            const {
              data: { data: notifications },
            } = await NotificationCenterAPI.checkCurrentNotifications();

            const payload = {
              products,
              user,
              notifications,
            };

            setUserState((prev) => ({
              ...prev,
              ...payload,
            }));

            setRenderView(true);

            resolve();

            LogRocket.identify(user.id, {
              name: `${user.first_name} ${user.first_surname}`,
              email: user.email,
              state: user.state,
            });

            const dataFPJS = await getDataFingerprint({
              ignoreCache: true,
            });

            await fingerprintUtmUpdate({
              visitor_id: dataFPJS?.visitorId,
              user_id: user.id,
            });
          }
        } catch (error) {
          console.error(error);
        }
      })();
    });
  };

  const refreshInitialData = async () => {
    await getInitialData();
    const userInterval = setInterval(getInitialData, 300000);
    setInitialDataInterval(userInterval);
  };

  const renderOutlet = () => {
    if (
      authState.logged &&
      !userState.user.is_deleted &&
      authState.accessTokenApp
    ) {
      return <Outlet />;
    }

    resetAuthState();
    resetUserState();

    return <Navigate to="/login" />;
  };

  return (
    <Fragment>{renderView ? renderOutlet() : <SplashComponent />}</Fragment>
  );
};
