import axios from "axios";
import { AuthApi } from "api";
import { setBearerTokenRewards } from "./httpRewards";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_KIWI_PAY,
});
let refreshingFunction = undefined;

export const setBearerToken = (token) => {
  http.defaults.headers.common["Authorization"] = token;
  setBearerTokenRewards(token);
};

export const customRefreshToken = async () => {
  const auth = JSON.parse(window.localStorage.getItem("authContext"));
  try {
    const {
      data: {
        data: { access_token },
      },
    } = await AuthApi.newAccessToken(auth?.accessTokenRefresh);
    window.localStorage.setItem(
      "authContext",
      JSON.stringify({
        ...auth,
        accessTokenApp: access_token,
      }),
    );
    setBearerTokenRewards(access_token);
    setBearerToken(access_token);
    return access_token;
  } catch {
    window.localStorage.clear();
    window.location.reload();
  }
};

const checkTokenError = (data) => {
  const errorMessage = data?.message ?? data?.data?.message;
  const isError = [
    "Access token has expired",
    "User not found",
    "get a fresh token from your client",
  ].includes(errorMessage);

  return isError;
};

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { response, config: originalRequest } = error;
    const { data } = response;

    if (!data.success) {
      const isError = checkTokenError(data);
      if (!isError) return Promise.reject(error);

      try {
        if (!refreshingFunction)
          refreshingFunction = await customRefreshToken();

        const newToken = await refreshingFunction;
        originalRequest.headers.Authorization = newToken;

        return await axios.request(originalRequest);
      } catch {
        const isError = checkTokenError(data);
        if (isError) {
          window.localStorage.clear();
          window.location.reload();
        }
      } finally {
        refreshingFunction = undefined;
      }
    }

    return Promise.reject(error);
  },
);

export default http;
