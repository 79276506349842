import PropTypes from "prop-types";
import Button from "components/Button";
import BaseModal from "../BaseModal";
import formatDate from "utils/formatDate";
import BankSuccess from "../../../assets/onboarding_v3/bank_success.svg";
import UserSuccess from "../../../assets/onboarding_v3/users_success.svg";
import SendInfo from "../../../assets/onboarding_v3/send_info.svg";
import { TransactionDetail } from "../TransactionDetail";

const TransactionDescription = ({ isOpen, onClose, transaction }) => {
    const typesTransactions = [
        {
            type: "cashback",
            title: "Detalle de tu cashback",
            image: BankSuccess,
        },
        {
            type: "cashback-received",
            title: "Has recibido tu cashback",
            image: UserSuccess,
        },
        {
            type: "cashback-expired",
            title: "Este cashback ya no está disponible",
            image: SendInfo,
        },
        {
            type: "referrals",
            title: "Detalle de tu pago de referido",
            image: UserSuccess,
        },
        {
            type: "transfer",
            title: "Detalle de tu transferencia ATH Móvil",
            image: SendInfo,
        },
    ];

    function validateAmount() {
        return `$${Math.abs(transaction?.amount).toFixed(2)}`;
    }

    const getBodyInformation = () => {

        const specificDetails = {
            "cashback-received": [
                { label: "Monto cashback", value: validateAmount() },
                { label: "Comercio", value: transaction?.description ?? "---" },
                {
                    label: "Fecha de cashback",
                    value: formatDate(transaction?.date, "MMMM DD, YYYY"),
                },
                { label: "Estado", value: "Completado" },
            ],
            "cashback-expired": [
                { label: "Estado", value: "Expirado" },
                { label: "Comercio", value: transaction?.description ?? "---" },
                {
                    label: "Fecha de cashback",
                    value: formatDate(transaction?.date, "MMMM DD, YYYY"),
                },
                { label: "Estado", value: "Expirado" },
            ],
            cashback: [
                { label: "Monto cashback", value: validateAmount() },
                { label: "Comercio", value: transaction?.description ?? "---" },
                {
                    label: "Fecha de cashback",
                    value: formatDate(transaction?.date, "MMMM DD, YYYY"),
                },
                { label: "Estado", value: "Completado" },
            ],
            referrals: [
                { label: "Monto recibido", value: validateAmount() },
                { label: "Amigo referido", value: transaction?.description ?? "---" },
                {
                    label: "Fecha del pago",
                    value: formatDate(transaction?.date, "MMMM DD, YYYY"),
                },
                { label: "Estado", value: "Completado" },
            ],
            transfer: [
                { label: "Monto transferido", value: validateAmount() },
                { label: "Número de cuenta", value: transaction?.description ?? "---" },
                {
                    label: "Fecha del pago",
                    value: formatDate(transaction?.date, "MMMM DD, YYYY"),
                },
                { label: "Estado", value: "Completado" },
            ],
        };

        const details = specificDetails[transaction?.type] || [];
        return (
            <div className="flex flex-col gap-4 w-full">
                {details.map((detail, index) => (
                    <TransactionDetail key={index} {...detail} />
                ))}
            </div>
        );
    };

    const currentTransaction = typesTransactions.find(
        (item) => item.type === transaction?.type,
    );

    return (
        <BaseModal isVisible={isOpen} closeModal={onClose}>
            <div className="flex flex-col gap-10">
                <div className="flex flex-col items-center text-center gap-8 w-full">
                    <img src={currentTransaction?.image} className="w-44" />
                    <h2 className="text-slate-900 font-semibold text-xl">
                        {currentTransaction?.title ?? "---"}
                    </h2>
                    {getBodyInformation()}
                </div>
                <Button
                    onClick={onClose}
                    className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto w-full"
                >
                    Aceptar
                </Button>
            </div>
        </BaseModal>
    );
};

TransactionDescription.displayName = "TransactionDescription";

TransactionDescription.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    transaction: PropTypes.shape({
        type: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        description: PropTypes.string,
        address: PropTypes.string,
        date: PropTypes.string.isRequired,
    }).isRequired,
};

export default TransactionDescription;