import { useContext, useEffect, useState } from "react";
import { getOrCreateWallet } from "../services/wallet";
import { RewardsContext } from "../store/rewards.context";

export const useWallet = () => {
  const [loading, setLoading] = useState(false);
  const { setWallet, wallet } = useContext(RewardsContext)
  const refetch = async () => {
    setLoading(true);
    try {
      const response = await getOrCreateWallet();
      setWallet(response);
    } catch (error) {
      console.log("🚀 ~ fetchWal ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  return {
    wallet,
    refetch,
    loading,
  };
};
