
import MovePaymentDateTemplate from 'templates/MovePaymentDate'
import { useNavigate } from 'react-router-dom'

export default function ActiveLoanMovePaymentDay() {
    const navigate = useNavigate()

    const onBackward = () => {
        navigate('/active-loan/disbursement')
    }

    const onContinue = () => {
        navigate('/active-loan/disbursement')
    }

    return (
        <MovePaymentDateTemplate onBackward={onBackward} onContinue={onContinue} product="personal_loan" />
    )
}
