import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import PropTypes from "prop-types";

const CashbackPendingSliderComponent = ({ offersPending, onSelectOffer }) => {
  return (
    <Swiper
      className="cursor-grab !px-6"
      slidesPerView={offersPending[1] ? 1.05 : 1}
      spaceBetween={16}
    >
      {offersPending?.map((item) => (
        <SwiperSlide
          className="w-full"
          key={item.offer.id}
          onClick={() => onSelectOffer(item.offer)}
        >
          <div className="cursor-pointer border border-amber-600 bg-amber-50 flex items-start justify-between rounded-xl p-4 gap-4">
            <div className="flex flex-col gap-2 overflow-hidden text-ellipsis">
              <p className="text-sm text-amber-600 font-semibold uppercase">
                Pendiente
              </p>
              <div>
                <p className="text-sm text-slate-900 line-clamp-2 font-semibold overflow-hidden whitespace-nowrap overflow-ellipsis">
                  {item?.offer.description}
                </p>
                <p className="text-sm text-slate-700-kiwi overflow-hidden text-ellipsis whitespace-nowrap">
                  {item?.offer.store.merchant.merchant_name}
                </p>
              </div>
            </div>
            <img
              src={item?.offer.store.merchant.logo_url}
              className="w-14 h-14 bg-white rounded-full object-contain"
              alt={item.offer.store.merchant.merchant_name}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

CashbackPendingSliderComponent.propTypes = {
  offersPending: PropTypes.array.isRequired,
  onSelectOffer: PropTypes.func.isRequired,
};

export default CashbackPendingSliderComponent;
