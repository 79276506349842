import Badge from "components/Badge";
import { BankIcon } from "assets/icons_v3/fonts";
import { dataBanks } from "constants";

const statusBadge = [
  {
    status: "connected",
    text: "Conectado",
    className: "bg-[#D7F4DE] text-[#27A25C]",
  },
  {
    status: "disconnected",
    text: "Desconectado",
    className: "bg-[#FFF1C6] text-[#DD6C02]",
  },
];

const CardAccount = ({ account }) => {
  const status = statusBadge.find((status) => status.status === account.status);
  const currentImageBank = dataBanks.find(
    (bank) => bank.institution_id === account.institution_id,
  )?.image;

  return (
    <div className="p-4 border border-slate-200 rounded-lg flex items-center gap-4">
      <div className="bg-slate-50 rounded-full h-10 w-10 min-w-[2.5rem] min-h-[2.5rem] flex items-center justify-center">
        {currentImageBank ? (
          <img
            src={currentImageBank}
            alt={account.institution_name}
            className="w-full h-full max-w-[2.5rem] max-h-[2.5rem] min-w-[2.5rem] min-h-[2.5rem] object-cover rounded-full"
          />
        ) : (
          <BankIcon className="w-6 h-6 text-regal-blue-kiwi" />
        )}
      </div>
      <p className="font-semibold text-dark-kiwi truncate">
        {account.institution_name}
      </p>
      <Badge text={status?.text} className={`ml-auto ${status?.className}`} />
    </div>
  );
};

export default CardAccount;
