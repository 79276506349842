import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import AddCreditCardTemplate from "templates/AddCreditCard";

export default function KiwiPayActivePaymentMethodCard() {
  const creditCardRef = useRef();
  const navigate = useNavigate();

  const onBackward = () => {
    navigate(-1);
  };

  return (
    <>
      <AddCreditCardTemplate
        product="kiwi_pay"
        onBackward={onBackward}
        ref={creditCardRef}
      />
    </>
  );
}
