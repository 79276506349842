import http from 'utils/http'

const getHudsonModel = async (userId) => {
    const response = await http.get(`/hudson/extended-model/${userId}`)

    return response
}

export default {
    getHudsonModel,
};
