import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserContext";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import Advisement from "components/Advisement";
import formatDate from "utils/formatDate";
import moneyFormat from "utils/moneyFormat";
import CustomPaymentModal from "components/Modals/ChangeAmountPay";
import { CurrencyDollarIcon, RightIcon } from "assets/icons_v3/fonts";
import BackwardButton from "components/Button/Backward";

dayjs.extend(isToday);

export default function KiwiPayActivePayFee() {
  const navigate = useNavigate();
  const [visibleCustomPaymentModal, setVisibleCustomPaymentModal] =
    useState(false);
  const [loanHasDue, setLoanHasDue] = useState(false);
  const [showPaymentWarning, setShowPaymentWarning] = useState(false);
  const [paymentFee, setPaymentFee] = useState("");
  const [remainingPayments, setRemainingPayments] = useState(0);

  const { state: userState } = useContext(UserContext);
  const {
    products: {
      kiwi_pay: {
        loan: {
          balance,
          daysPastDue,
          amountDue,
          nextPaymentDate,
          nextPaymentAmount,
          lastPaymentAmount,
          lastPaymentDate,
          payments,
          term,
        },
      },
    },
  } = userState;

  useEffect(() => {
    const loanHasDue = daysPastDue > 0;

    checkPaymentDate();
    setPaymentFee(Number(loanHasDue ? amountDue : nextPaymentAmount));
    setLoanHasDue(loanHasDue);
    setRemainingPayments(
      term - payments.filter((payment) => payment.status === "Active").length,
    );
  }, []);

  const checkPaymentDate = () => {
    const paymentDate = dayjs.utc(lastPaymentDate).format("YYYY-MM-DD");
    const todayDate = dayjs().format("YYYY-MM-DD");
    const isToday = paymentDate === todayDate;

    setShowPaymentWarning(isToday);
  };

  const onBackward = () => {
    navigate("/kiwi-pay/active");
  };

  const onPayCustomPayment = () => {
    setVisibleCustomPaymentModal(true);
  };

  const onChangeCustomPayment = (amount) => {
    onMakePayment(amount);
  };

  const onPayRemainingBalance = () => {
    onMakePayment(balance);
  };

  const onPayFee = () => {
    onMakePayment(paymentFee);
  };

  const onMakePayment = (paymentAmount) => {
    navigate("/kiwi-pay/active/make-payment", {
      state: {
        paymentAmount,
      },
    });
  };

  return (
    <>
      <BackwardButton onBackward={onBackward} />

      <h1 className="text-dark-kiwi font-semibold text-2xl mb-10">
        Pago de cuota
      </h1>

      <div className="flex flex-col gap-4 text-sm mb-10">
        {loanHasDue === true && (
          <>
            <div className="flex items-center justify-between">
              <div>Cantidad días mora</div>
              <strong>
                {daysPastDue} {daysPastDue > 1 ? "días" : "día"}
              </strong>
            </div>
            <div className="flex items-center justify-between">
              <div>Balance en mora</div>
              <strong>${amountDue}</strong>
            </div>
          </>
        )}
        <div className="flex items-center justify-between">
          <div>Pagos restantes</div>
          <strong>
            {remainingPayments} {remainingPayments > 1 ? "pagos" : "pago"}
          </strong>
        </div>
        <div className="flex items-center justify-between">
          <div>Fecha límite de pago</div>
          <strong>{formatDate(nextPaymentDate, "MMMM DD, YYYY")}</strong>
        </div>
      </div>

      {showPaymentWarning === true && (
        <Advisement className="mb-10">
          Se realizó un pago de ${moneyFormat(lastPaymentAmount)} en esta cuenta
          el {formatDate(lastPaymentDate, "DD [de] MMMM [de] YYYY")}. Es posible
          que esté realizando un <strong>pago duplicado</strong>.
        </Advisement>
      )}

      <h2 className="text-dark-kiwi font-semibold mb-4">
        ¿Cuánto quieres pagar?
      </h2>

      <div className="flex flex-col gap-4">
        <div
          className="flex items-center rounded-lg p-4 cursor-pointer border border-gray-200-kiwi"
          onClick={onPayFee}
        >
          <div className="h-10 w-10 bg-slate-100-kiwi mr-4 grow-0 shrink-0 rounded-full flex">
            <CurrencyDollarIcon className="w-6 m-auto regal-blue-kiwi" />
          </div>
          <div className="mr-auto">
            <div className="leading-4 font-semibold mb-1">
              {loanHasDue ? "Pago deuda" : "Pago cuota"}
            </div>
            <p className="text-xs text-gray-500-kiwi">Balance pendiente</p>
          </div>
          <div className="mr-4 font-semibold">${paymentFee}</div>
          <RightIcon className="w-6 text-gray-300" />
        </div>

        <div
          className="flex items-center rounded-lg p-4 cursor-pointer border border-gray-200-kiwi"
          onClick={onPayRemainingBalance}
        >
          <div className="h-10 w-10 bg-slate-100-kiwi mr-4 grow-0 shrink-0 rounded-full flex">
            <CurrencyDollarIcon className="w-6 m-auto regal-blue-kiwi" />
          </div>
          <div className="mr-auto">
            <div className="leading-4 font-semibold mb-1">Saldo total</div>
            <p className="text-xs text-gray-500-kiwi">Balance completo</p>
          </div>
          <div className="mr-4 font-semibold">${balance}</div>
          <RightIcon className="w-6 text-gray-300" />
        </div>

        <div
          className="flex items-center rounded-lg p-4 cursor-pointer border border-gray-200-kiwi"
          onClick={onPayCustomPayment}
        >
          <div className="h-10 w-10 bg-slate-100-kiwi mr-4 grow-0 shrink-0 rounded-full flex">
            <CurrencyDollarIcon className="w-6 m-auto regal-blue-kiwi" />
          </div>
          <div className="mr-auto">
            <div className="leading-4 font-semibold mb-1">Monto diferente</div>
            <p className="text-xs text-gray-500-kiwi">Valor personalizado</p>
          </div>
          <RightIcon className="w-6 text-gray-300" />
        </div>
      </div>

      <CustomPaymentModal
        minAmount={0}
        maxAmount={balance}
        sendAmount={onChangeCustomPayment}
        isVisible={visibleCustomPaymentModal}
        closeModal={() => setVisibleCustomPaymentModal(false)}
      />
    </>
  );
}
