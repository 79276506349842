import http from "utils/httpCashback";

export const getCashbackId = async (user_id) => {
  const response = await http.get(`/cashback/user/${user_id}`);
  return response.data;
};

export const createCashbackId = async (user_id, amount) => {
  const response = await http.post("/cashback", {
    user_id: user_id,
    incentive_gift: amount,
  });
  return response.data;
};

export const getProcessedOffers = async (cashback_id) => {
  const response = await http.get(`/processed-offers/${cashback_id}`);
  return response.data;
};

export const getProcessedOffersByStatus = async ({ cashback_id, status }) => {
  const response = await http.get(
    `/processed-offers?cashback_id=${cashback_id}&status=${status}`,
  );
  return response.data;
};

export const getOffers = async (user_id) => {
  const response = await http.get(`/offers/all/${user_id}`);
  return response.data;
};

export const getOffersNearByUser = async (user_id, data) => {
  const response = await http.post(`/offers/near_by_user`, {
    user_id: user_id,
    data: {
      latitude: data.latitude,
      longitude: data.longitude,
    },
  });
  return response.data;
};

export const getOffersByCashbackId = async (cashback_id) => {
  const response = await http.get(`/offers/${cashback_id}`);
  return response.data;
};

export const validateConnectionPlaid = async () => {
  return {
    success: true,
    data: {
      connection: true,
    },
  };
};

export const createClaim = async (data) => {
  const response = await http.post("/claim", {
    offer_id: data.offer_id,
    payment_method: data.payment_method,
    cashback_id: data.cashback_id,
  });
  return response.data;
};

export const cancelClaim = async (claim_id) => {
  const response = await http.post(`/claim/cancel`, {
    claim_id: claim_id,
  });
  return response.data;
};

export const checkOfferProcessingStatus = async (cashback_id, offer_id) => {
  const response = await http.get(
    `/processed-offers/${cashback_id}/${offer_id}/pending`,
  );
  return response.data.data;
};

export default {
  getProcessedOffers,
  getOffers,
  validateConnectionPlaid,
  createClaim,
  cancelClaim,
  getCashbackId,
  getOffersByCashbackId,
  checkOfferProcessingStatus,
  createCashbackId,
  getProcessedOffersByStatus,
  getOffersNearByUser,
};
