import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Combina clsx y twMerge en una sola función para simplificar su uso.
 * @param classes - Clases de Tailwind (estáticas o dinámicas).
 * @returns String de clases optimizadas.
 */
export const cs = (...classes) => {
  return twMerge(clsx(...classes));
};