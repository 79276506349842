import  { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import AddCreditCardTemplate from 'templates/AddCreditCard'

export default function CreditBuilderActivePaymentMethodCard() {
    const creditCardRef = useRef()
    const navigate = useNavigate()

    const onBackward = () => {
        navigate(-1)
    }

    return (
            <AddCreditCardTemplate
                product="credit_builder"
                onBackward={onBackward}
                ref={creditCardRef}
            />
    )
}
