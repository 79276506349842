import BaseModal from '../BaseModal'
import PropTypes from 'prop-types';

const AdditionalIncome = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="Ingreso adicional anual no tributable "
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6 mt-6">
                <p>
                    Si está inseguro de si alguno de sus ingresos no está sujeto
                    a impuestos, consulte sus documentos de declaración de
                    impuestos o consulte con su profesional de impuestos.
                </p>
            </div>
        </BaseModal>
    )
}

AdditionalIncome.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired
};

export default AdditionalIncome
