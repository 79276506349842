import BaseModal from '../BaseModal'
import PropTypes from 'prop-types'

const AnnualIncome = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="Ingreso total anual"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6 mt-6">
                <p>
                    Ejemplos: Salarios, intereses, dividendos, ingresos por
                    alquileres, beneficios de jubilación.
                </p>
                <p>
                    No es necesario revelar los ingresos por pensión
                    alimenticia, manutención de los hijos o manutención separada
                    si no desea que se consideren como base para pagar esta
                    obligación.
                </p>
            </div>
        </BaseModal>
    )
}

AnnualIncome.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired
}

export default AnnualIncome
