import { useContext } from "react";
import { UserContext } from "context/UserContext";
import { PERSONAL_LOAN_STATUS, CREDIT_BUILDER_STATUS } from "constants";

import PersonalLoan from "./PersonalLoan";
import KiwiPay from "./KiwiPay";
import CreditBuilder from "./CreditBuilder";
import CreditMonitoring from "./CreditMonitoring";
import { KIWI_PAY_STATUS } from "constants";

export default function HomeStatusActive() {
  const { state: userState } = useContext(UserContext);

  const {
    products: {
      personal_loan: { status: personalLoanStatus },
      credit_builder: { status: creditBuilderStatus },
      kiwi_pay: { status: kiwiPayStatus },
    },
  } = userState;

  const isPersonalLoanActive =
    personalLoanStatus === PERSONAL_LOAN_STATUS.current;

  const isCreditBuilderActive =
    creditBuilderStatus === CREDIT_BUILDER_STATUS.current;

  const isKiwiPayActive = kiwiPayStatus === KIWI_PAY_STATUS.current;

  const isCreditMonitoringActive =
    isPersonalLoanActive || isCreditBuilderActive || isKiwiPayActive;

  return (
    <div className="flex flex-col gap-6">
      {isPersonalLoanActive && <PersonalLoan />}
      {isKiwiPayActive && <KiwiPay />}
      {isCreditBuilderActive && <CreditBuilder />}
      {isCreditMonitoringActive && <CreditMonitoring />}
    </div>
  );
}
