import PropTypes from "prop-types";

const ToggleSwitch = ({ checked, onChange }) => {
  return (
    <div className="flex justify-between">
      <label
        htmlFor="principal"
        className="inline-flex relative items-center cursor-pointer"
      >
        <input
          type="checkbox"
          id="principal"
          className="sr-only peer"
          checked={checked}
          onChange={onChange}
        />
        <div className="flex items-center w-12 h-7 rounded-full peer bg-gray-400-kiwi peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:left-[3px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-500 peer-checked:bg-blue-kiwi"></div>
      </label>
    </div>
  );
};

ToggleSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ToggleSwitch;
