import PropTypes from "prop-types";

const Tab = ({ label, selected, className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <span
        className={
          selected ? "text-regal-blue-950-kiwi" : "text-slate-400-kiwi"
        }
      >
        {label}
      </span>
    </div>
  );
};

Tab.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Tab;
