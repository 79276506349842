import { Outlet } from "react-router-dom";

const BankAccounts = () => {
  return (
    <div className="w-full min-h-full flex flex-col px-6 py-10">
      <Outlet />
    </div>
  );
};

export default BankAccounts;
