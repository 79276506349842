import {CREDIT_BUILDER_STATUS} from 'constants';
import {UserContext} from 'context/UserContext';
import {useContext, useMemo} from 'react';

export function useCreditBuilderStatus() {
    const {state: userState} = useContext(UserContext);
    const {
        products: {
            credit_builder: {status, tracking},
        },
    } = userState;

    const creditBuilderContext = tracking?.context ?? {};

    return useMemo(() => {
        const isCreditBuilderApply = status === CREDIT_BUILDER_STATUS.apply;
        const isCreditBuilderActive = status === CREDIT_BUILDER_STATUS.current;

        return {
            isCreditBuilderApply,
            isCreditBuilderActive,
            creditBuilderContext,
        };
    }, [status, tracking]);
}
