import { useEffect, Fragment, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Cookies from "js-cookie";
import LogRocket from "logrocket";
import { initGA } from "./utils/GA";
import Notification from "./components/Modals/Notification";
import Routes from "./routes";
import { v4 as uuidv4 } from "uuid";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import useUrlParams from "hooks/useUrlParams";
import { getIpInfo } from "./utils/fingerprintjs";

const App = () => {
  useUrlParams();

  const DOMAIN = ".kiwicredito.com";
  zE("messenger", "hide");
  const [isTrackingInitialized, setIsTrackingInitialized] = useState(false);

  const { getData: getDataFingerprint } = useVisitorData(
    { extendedResult: true },
    { immediate: true },
  );

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const referral_code = queryParameters.get("referral_code");
    const kw_source_url = Cookies.get("kw_source_url");
    const kw_referral_code = Cookies.get("kw_referral_code");

    if (!kw_source_url) {
      Cookies.set("kw_source_url", window.location.href);
    }

    if (!kw_referral_code && referral_code) {
      Cookies.set("kw_referral_code", referral_code);
    }
    LogRocket.init("fqaolv/kiwi");
    initGA();
    window.addEventListener("resize", setRootWidth);
    setRootWidth();
    if (process.env.REACT_APP_DMP_TRACKING === "development") {
      setSessionId();
      setDeviceId();
      setLocationVisitor().then(() => {
        setIsTrackingInitialized(true);
      });
    } else {
      setIsTrackingInitialized(true);
    }
  }, []);

  const getExpirationTime = (hours) => {
    return new Date(new Date().getTime() + hours * 60 * 60 * 1000);
  };

  const storeLocationData = (locationData) => {
    const inEightHours = getExpirationTime(8);
    Cookies.set("dmp_location", JSON.stringify(locationData), {
      domain: DOMAIN,
      secure: true,
      expires: inEightHours,
    });
  };

  const getLocationData = () => {
    const locationData = Cookies.get("dmp_location");
    return locationData ? JSON.parse(locationData) : null;
  };

  const getNameCountry = async (name) => {
    if (name) return name;

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000);

    try {
      const response = await fetch("https://ipapi.co/json/", {
        signal: controller.signal,
      });

      if (!response.ok) {
        console.error("Failed to fetch IP information:", response.statusText);
        return "";
      }

      const data = await response.json();
      return data.country_name || "";
    } catch (error) {
      if (error.name === "AbortError") {
        console.error("Request timed out");
      } else {
        console.error("Error fetching IP information:", error);
      }
      return "";
    } finally {
      clearTimeout(timeoutId);
    }
  };

  const setLocationVisitor = async () => {
    if (getLocationData()) return;
    const response = await getDataFingerprint();
    try {
      const ipInfo = await getIpInfo(response.requestId);
      const country = await getNameCountry(ipInfo.country?.name);
      const locationData = {
        country: country,
        state:
          ipInfo.subdivisions && ipInfo.subdivisions[0]?.name
            ? ipInfo.subdivisions[0]?.name
            : ipInfo.country?.name,
        city: ipInfo.city?.name || null,
        postalCode: ipInfo.postalCode || null,
        latitude: ipInfo.latitude || null,
        longitude: ipInfo.longitude || null,
      };

      storeLocationData(locationData);
    } catch (error) {
      console.error("Error fetching IP information:", error);
    }
  };

  const setDeviceId = async () => {
    let deviceId =
      Cookies.get("dmp_device_id") || localStorage.getItem("dmp_device_id");
    if (!deviceId) {
      deviceId = uuidv4();
      Cookies.set("dmp_device_id", deviceId, {
        domain: DOMAIN,
        secure: true,
        expires: 365,
      });
      localStorage.setItem("dmp_device_id", deviceId);
    }

    if (
      !Cookies.get("dmp_device_id") &&
      localStorage.getItem("dmp_device_id")
    ) {
      Cookies.set("dmp_device_id", localStorage.getItem("dmp_device_id"), {
        domain: DOMAIN,
        secure: true,
        expires: 365,
      });
    } else if (
      Cookies.get("dmp_device_id") &&
      !localStorage.getItem("dmp_device_id")
    ) {
      localStorage.setItem("dmp_device_id", Cookies.get("dmp_device_id"));
    }
  };

  const setSessionId = async () => {
    const dmp_session_id = Cookies.get("dmp_session_id");
    const lastActivitySeenDMP = Cookies.get("dmp_last_activity_seen");
    handleLastActivity(lastActivitySeenDMP, dmp_session_id);
  };

  const handleLastActivity = (lastActivitySeenDMP, dmp_session_id) => {
    const lastActivity = new Date(lastActivitySeenDMP);
    const now = new Date();
    const diff = now - lastActivity;
    Cookies.set("dmp_event_duration", diff || null);

    if (diff > 3600000) {
      setNewSessionId();
    }
    if (!dmp_session_id) {
      setNewSessionId();
    }
  };

  const setNewSessionId = () => {
    const expirationTime = getExpirationTime(4);
    Cookies.set("dmp_session_id", uuidv4(), {
      domain: DOMAIN,
      secure: true,
      expires: expirationTime,
    });
  };

  const setRootWidth = () => {
    const root = document.getElementById("root");

    if (window.innerWidth > 480) {
      root.classList.add("desktop");
    } else {
      root.classList.remove("desktop");
    }
  };

  return (
    <Fragment>
      <Notification />
      {isTrackingInitialized && <Routes />}
    </Fragment>
  );
};

export default App;
