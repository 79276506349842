import dayjs from "dayjs";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../components/Header";
import { RewardsPaths } from "../types";
import { DescriptionPaymentRow } from "../components/DescriptionPaymentRow";
import PrimaryButton from "components/Button/Primary";
import { UserContext } from "context/UserContext";
import capitalizeText from "utils/capitalizeText";
import formatToUSD from "utils/formatToUSD";

import Logo from "../../../assets/logos/rewards/method.png";
const localeData = require("dayjs/plugin/localeData");

dayjs.extend(localeData);
dayjs.locale("es");

export const MakePaymentRewards = () => {
  const location = useLocation();
  const { state: userState } = useContext(UserContext);
  const {
    user: { phone_number },
  } = userState;
  const { money } = location.state || {};

  const currentDate = dayjs();
  const navigate = useNavigate();
  const formatDate = currentDate.format("MMM D, YYYY");
  return (
    <div className="w-full min-h-full flex flex-col px-6 pt-10 pb-[136px]">
      <Header backRoute={RewardsPaths.INDEX} />
      <h4 className="font-semibold text-dark-kiwi text-2xl mb-10">
        Retirar tus fondos
      </h4>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1 justify-center items-center">
          <span className="text-gray-600-kiwi font-normal text-sm">
            Monto total a retirar
          </span>
          <h1 className="text-6xl text-[56px] font-bold text-dark-kiwi">
            {formatToUSD(money ?? 0)}
          </h1>
        </div>
        <div className="flex flex-col gap-4">
          <DescriptionPaymentRow
            label="Método de desembolso"
            value="ATH Móvil"
          />
          <DescriptionPaymentRow
            label="Fecha de retiro"
            value={capitalizeText(formatDate)}
            className
          />
        </div>

        <div className="flex  bg-gray-50-kiwi rounded-lg w-auto h-20 items-center p-2">
          <img src={Logo} alt="method" className="w-10 h-10" />
          <div className="m-2">
            <h6 className="font-semibold text-dark-kiwi">ATH Móvil</h6>
            <p className="text-sm text-gray-600-kiwi">{phone_number}</p>
          </div>
        </div>
      </div>
      <div className="p-4 bg-white fixed z-10 bottom-0 left-0 right-0 h-24 flex items-center justify-around text-slate-300-kiwi max-w-[450px] mx-auto sm:bottom-6 sm:rounded-bl-2xl sm:rounded-br-2xl">
        <PrimaryButton
          onClick={() =>
            navigate(RewardsPaths.CONFIRM, { state: { money } })
          }
        >
          Continuar
        </PrimaryButton>
      </div>
    </div>
  );
};
