import  {
    forwardRef,
    useImperativeHandle,
    useRef,
    useContext
} from 'react'
import BaseModal from '../Modal'
import { STATES_AVAILABLES } from 'constants'
import { UserContext } from 'context/UserContext'

const PaymentElectionAuthorizationModal = forwardRef((props, ref) => {
    const BaseModalRef = useRef()
    const { state: userState } = useContext(UserContext)
    useImperativeHandle(ref, () => ({
        ...BaseModalRef.current
    }))

    const urlForState = () => {
        if (userState.user.state === STATES_AVAILABLES.florida) {
            return 'https://kiwi-public-files.s3.amazonaws.com/ELECCI%C3%93N+Y+AUTORIZACI%C3%93N+DEL+PAGO.pdf#toolbar=0&navpanes=0'
        } else {
            return 'https://kiwi-public-files.s3.amazonaws.com/ACH+Payment+Authorization.pdf#toolbar=0&navpanes=0'
        }
    }
    return (
        <BaseModal title="Elección y autorización del pago" ref={BaseModalRef}>
            <div className="flex flex-col h-[calc(100vh-144px)] sm:h-[calc(100vh-192px)] overflow-hidden">
                <div className="grow overflow-auto relative">
                    <iframe
                        className="flex w-full flex-col h-[calc(100vh-144px)] sm:h-[calc(100vh-192px)] overflow-hidden"
                        src={urlForState()}></iframe>
                </div>
            </div>
        </BaseModal>
    )
})

PaymentElectionAuthorizationModal.displayName = 'PaymentElectionAuthorizationModal';

export default PaymentElectionAuthorizationModal
