import PropTypes from 'prop-types';

const RowIcon = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={15}
    fill="none"
    className={className}
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M10.5 3.417a.583.583 0 0 1 .583.583v4.667a.583.583 0 1 1-1.166 0V5.408l-5.805 5.804a.583.583 0 0 1-.824-.825l5.804-5.804H5.833a.583.583 0 1 1 0-1.166H10.5Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
)

RowIcon.propTypes = {
  className: PropTypes.string,
};

export default RowIcon;
