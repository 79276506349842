import { useEffect, useState } from 'react'
import { ChevronLeftIcon } from 'assets/icons_v3/fonts'
import PropTypes from 'prop-types'

export default function ProgressBar({
    progress = '0/0',
    color = 'bg-blue-kiwi',
    arrow = true,
    onBackward
}) {
    const [percent, setPercent] = useState(0)

    useEffect(() => {
        const [step, steps] = progress.split('/')
        const stepPercent = 100 / steps
        const percent = (stepPercent * step).toFixed(2)

        setPercent(percent)
    }, [])

    return (
        <div className="mb-12 flex items-center">
            {arrow && (
                <ChevronLeftIcon
                    className="cursor-pointer w-2"
                    onClick={onBackward}
                />
            )}
            <div className="bg-gray-200-kiwi h-2 w-40 rounded mx-auto -translate-x-[16px]">
                <div
                    className={`${color} h-2 rounded`}
                    style={{ width: `${percent}%` }}></div>
            </div>
        </div>
    )
}

ProgressBar.propTypes = {
    progress: PropTypes.string,
    color: PropTypes.string,
    arrow: PropTypes.bool,
    onBackward: PropTypes.func
}
