import mixpanel from "utils/mixpanel";
import Emitter from "utils/emitter";
import dateDifference from "utils/dateDifference";
import ReactPixel from "react-facebook-pixel";

import phoneFormatter from "phone-formatter";
import { useState, useEffect, useContext, Fragment } from "react";
import { GApageView, GAevent } from "utils/GA";
import { useNavigate } from "react-router-dom";
import { ApprovedLoanContext } from "context/ApprovedLoanContext";
import { CreditLimitContext } from "context/CreditLimitContext";
import { UserContext } from "context/UserContext";

import { ProductsAPI, LoanAPI, UsersAPI, ContractsAPI } from "api";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import {
  TRACKING_EXPIRATION_DAYS,
  DISBURSEMENT_METHODS,
  STATES_AVAILABLES,
  REPAYMENT_OPTIONS,
} from "constants";
import { CheckIcon, RightIcon } from "assets/icons_v3/fonts";

import ProgressBar from "components/ProgressBar";
import LoadingModal from "components/Modals/Loading";
import Button from "components/Button";
import ContractTemplate from "templates/Contract";
import LogRocket from "logrocket";
import { trackEvent } from "utils/dmp_kiwi";
import { ActivityAPI } from "api";
import { getUtmParams } from "hooks/useStoredUrlParams";

export default function ApprovedLoanContract() {
  const lang = "es";
  const navigate = useNavigate();

  const { updateSurvey } = useContext(CreditLimitContext);
  const { state: approvedState, saveState: saveApprovedState } =
    useContext(ApprovedLoanContext);
  const { state: userState, setState: setUserState } = useContext(UserContext);
  const {
    products: { personal_loan },
  } = userState;
  const [utmParams, setUtmParams] = useState({});

  const [contractReload, setContractReload] = useState(false);
  const [contracts, setContracts] = useState([]);

  const [selectedContract, setSelectedContract] = useState({});
  const [showContract, setShowContract] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  const { getData: getDataFingerprint } = useVisitorData(
    { extendedResult: true },
    { immediate: true },
  );

  useEffect(() => {
    if (
      userState.user.inquiry_status !== "approved" ||
      approvedState.persona.completed === false
    ) {
      return navigate("/approved-loan/persona");
    }

    if (approvedState.repaymentTerms.tempLoanId === "") {
      LogRocket.captureMessage("Track Repayment Terms Error", {
        extra: {
          approvedState,
        },
      });

      return navigate("/approved-loan/amount");
    }

    GApageView("pageview", "contract");
    mixpanel.track("Loan Contract Start");

    verifyUpToDateRepaymentTerms();
    checkTempLoanCreatedDate();
    getAllContracts();
    setUtmParams(getUtmParams());
  }, []);

  const verifyUpToDateRepaymentTerms = () => {
    const isOneDayAfter = (() => {
      if (approvedState.repaymentTerms.created_at) {
        const diffDays = dateDifference(
          approvedState.repaymentTerms.created_at,
        );

        if (diffDays >= 1) {
          return true;
        }
      }

      return false;
    })();

    if (isOneDayAfter) {
      navigate("/approved-loan/verify-repayment-details", {
        state: {
          showWarning: true,
        },
      });
    }
  };

  const getIPfingerprintJs = async () => {
    const dataFPJS = await getDataFingerprint({
      ignoreCache: true,
    });

    return dataFPJS?.ip;
  };

  const checkTempLoanCreatedDate = () => {
    if (approvedState.repaymentTerms.created_at) {
      const diffDays = dateDifference(approvedState.repaymentTerms.created_at);
      if (diffDays < TRACKING_EXPIRATION_DAYS) {
        validateDuplicatedAccount();
      }
    }
  };

  const getAllContracts = async () => {
    try {
      setShowLoader(true);

      const {
        data: { data: contracts },
      } = await LoanAPI.getContractByType("personal");

      setContracts(
        contracts.map((contract) => ({
          ...contract,
          signed: false,
        })),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const onSelectContract = async (contract) => {
    if (loading) return;

    try {
      setShowLoader(true);
      const ip = await getIPfingerprintJs();
      const basePayload = {
        path: contract.path,
        lang,
        ip,
        loanpro_tmp_loan_id: approvedState.repaymentTerms.tempLoanId.toString(),
        repayment_method: approvedState.repaymentOption.value,
      };

      const additionalPayload = {
        "loans/fl/contract/deferred-agreement": {
          disbursement_method: approvedState.disbursement.value,
        },
      };

      let payload = {
        ...basePayload,
        ...additionalPayload[contract.path],
      };

      if (userState.user.state === STATES_AVAILABLES.florida)
        return onSelectContractFlorida(contract, payload);

      const {
        data: {
          success,
          data: { file_url },
        },
      } = await ContractsAPI.getCurrentContract(contract.path, payload);

      if (!success) throw new Error();

      setFileUrl(file_url);
      setSelectedContract(contract);
      setShowContract(true);
    } catch (error) {
      if (!contractReload) onRecreateTempLoan();
    } finally {
      setShowLoader(false);
    }
  };

  const onSelectContractFlorida = async (contract, payload) => {
    try {
      const { data } = await LoanAPI.getContractDocument(payload);
      if (data) {
        const reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => {
          setFileUrl(reader.result);
          setSelectedContract(contract);
          setShowContract(true);
        };
      }
    } catch (error) {
      if (!contractReload) onRecreateTempLoan();
    } finally {
      setShowLoader(false);
    }
  };

  const onSignIndividualContract = async () => {
    setContracts((prevItems) =>
      prevItems.map((item) =>
        item.id === selectedContract.id ? { ...item, signed: true } : item,
      ),
    );

    resetContractView();
  };

  const validateEveryContractSigned = () => {
    return contracts.every((contract) => contract.signed);
  };

  const validateDuplicatedAccount = async () => {
    const { verification } = personal_loan;

    const hasDuplicatedAccount = verification.find(
      (item) =>
        item.value === "duplicated_bank_account" && item.status === "pending",
    );

    if (hasDuplicatedAccount) {
      navigate("/duplicated-bank-account");
    }
  };

  const fingerprintSetData = async () => {
    try {
      await getDataFingerprint({
        ignoreCache: true,
        tag: {
          env: process.env.REACT_APP_FINGERPRINT_ENV,
          user_id: userState.user.id,
          place: "signed_contract",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onBackward = () => {
    if (showContract) {
      return resetContractView();
    }

    if (approvedState.repaymentOption.value === REPAYMENT_OPTIONS.paperCheck) {
      return navigate("/approved-loan/repayment-options");
    }

    navigate("/approved-loan/repayment-profile");
  };

  const resetContractView = () => {
    setShowContract(false);
    setSelectedContract({});
    setFileUrl("");
  };

  const onSignAllContracts = async () => {
    try {
      setLoading(true);
      setShowLoader(true);

      if (userState.user.state === STATES_AVAILABLES.florida) {
        await UsersAPI.checkEligibilityEvaluateVeritec(userState.user.id);
      }

      const ip = await getIPfingerprintJs();

      const athVerification = approvedState.athVerification;

      const {
        disbursement: { value: disbursementMethod },
        disbursementProfile: { id: disbursementValue },
        repaymentOption: { value: repaymentMethod },
        repaymentProfile: { id: repaymentValue },
      } = approvedState;

      const {
        data: {
          loan: { need_verification_call },
        },
      } = await LoanAPI.createLoan({
        user_id: userState.user.id,
        loanpro_tmp_loan_id: approvedState.repaymentTerms.tempLoanId.toString(),
        amount: approvedState.amount.value,
        type: disbursementMethod === "immediate" ? "mesalve" : "",
        ip,
        lang,
        disbursement_method: disbursementMethod,
        disbursement_value: disbursementValue.toString(),
        disbursement_id: athVerification?.disbursementId ?? "",
        disbursement_token: athVerification?.disbursementToken ?? "",
        repayment_method: repaymentMethod,
        repayment_value: repaymentValue.toString(),
      });

      fingerprintSetData();
      contractSignedEvents();

      const {
        data: { data: personal_loan },
      } = await ProductsAPI.getPersonalLoan();

      if (personal_loan?.credit_limit?.id) {
        await updateSurvey(personal_loan.credit_limit.id, {
          closed: true,
        });
      }

      setUserState((prev) => ({
        ...prev,
        products: {
          ...prev.products,
          personal_loan: {
            ...personal_loan,
            credit_limit: {
              last_date_credit_limit: null,
              last_date_plaid_connection: null,
            },
            application: {
              ...prev.products.personal_loan.application,
              need_verification_call,
            },
          },
        },
      }));

      await ActivityAPI.createActivity({
        name: "Sign Contracts Personal Loan",
        payload: {
          ip,
          origin: window.location.origin,
        },
      });

      if (
        approvedState.disbursement.value === "transfer" &&
        need_verification_call
      ) {
        navigate("/approved-loan/need-verification-call");
      } else if (
        [
          DISBURSEMENT_METHODS.transfer,
          DISBURSEMENT_METHODS.paperCheck,
        ].includes(approvedState.disbursement.value)
      ) {
        return navigate("/moneys-on-the-way");
      }

      if (approvedState.disbursement.value === DISBURSEMENT_METHODS.immediate) {
        return navigate("/mesalve-code");
      }
    } catch (error) {
      const { data } = error.response;

      if (data.messages === "ACTIVE_LOAN_ALREADY_EXISTS") {
        return Emitter.emit("onOpenNotification", {
          type: "error",
          title: "Tienes un contrato activo",
          message: `Parece que actualmente tienes un contrato activo, puedes escribir a nuestro equipo de soporte al correo support@kiwicredito.com`,
        });
      }

      if (data.messages === "LOAN_LESS_7_DAYS") {
        return Emitter.emit("onOpenNotification", {
          type: "error",
          title: "Días de espera...",
          message: `Deben de pasar 7 días para volver a solicitar otro préstamo, puedes escribir a nuestro equipo de soporte al correo support@kiwicredito.com`,
        });
      }

      Emitter.emit("onOpenNotification", {
        type: "error",
        title: "Error al firmar tu contrato",
        message: `Parece que hubo un error, póngase en contacto con nuestro equipo de soporte al correo support@kiwicredito.com`,
      });
    } finally {
      setLoading(false);
      setShowLoader(false);
    }
  };

  const contractSignedEvents = () => {
    GAevent("user_action", "signed_contract");

    window.dataLayer.push({
      event: "signed_contract",
      email: userState.user.email,
      phone_number: phoneFormatter.format(
        userState.user.phone_number,
        "+1NNNNNNNNNN",
      ),
    });

    window.dataLayer.push({
      event: "purchase",
      email: userState.user.email,
      phone_number: phoneFormatter.format(
        userState.user.phone_number,
        "+1NNNNNNNNNN",
      ),
      ecommerce: {
        currency: "USD",
        value: parseInt(approvedState.amount.value),
        transaction_id: approvedState.repaymentTerms.tempLoanId,
        items: [
          {
            item_name: `Loan ${approvedState.disbursement.value}`,
            price: parseInt(approvedState.amount.value),
            quantity: 1,
          },
        ],
      },
    });

    ReactPixel.track("Purchase", {
      value: Number(approvedState.amount.value),
      currency: "USD",
    });

    trackEvent({
      place: "/approved-loan/contract",
      event_type: "button",
      user_id: userState.user.id,
      pathname: window.location.href,
      utm_data: utmParams,
    });

    if (
      [
        DISBURSEMENT_METHODS.transfer,
        DISBURSEMENT_METHODS.paperCheck,
        DISBURSEMENT_METHODS.ath,
      ].includes(approvedState.disbursement.value)
    ) {
      GAevent("user_action", "select_transfer");
      window.dataLayer.push({
        event: "select_transfer",
        email: userState.user.email,
        phone_number: phoneFormatter.format(
          userState.user.phone_number,
          "+1NNNNNNNNNN",
        ),
      });
    } else if (
      approvedState.disbursement.value === DISBURSEMENT_METHODS.immediate
    ) {
      GAevent("user_action", "select_mesalve");
      window.dataLayer.push({
        event: "select_mesalve",
        email: userState.user.email,
        phone_number: phoneFormatter.format(
          userState.user.phone_number,
          "+1NNNNNNNNNN",
        ),
      });
    }
  };

  const onRecreateTempLoan = async () => {
    try {
      const {
        data: {
          data: { isActive },
        },
      } = await LoanAPI.checkContractActive({
        tempLoanId: approvedState.repaymentTerms.tempLoanId,
      });

      if (!isActive) {
        const { data } = await LoanAPI.createTempLoan({
          user_id: userState.user.id,
          amount: approvedState.amount.value,
          paymentDay: approvedState.paymentDay.value,
        });

        setContractReload(true);

        saveApprovedState({
          ...approvedState,
          repaymentTerms: {
            ...approvedState.repaymentTerms,
            ...data.loan,
          },
        });
      }
    } catch (error) {
      console.error(error, "onRecreateTempLoan ERROR");
    }
  };

  return (
    <Fragment>
      <ProgressBar progress="10/10" onBackward={onBackward} />
      <LoadingModal isVisible={showLoader} />

      {showContract ? (
        <ContractTemplate
          signed={selectedContract.signed}
          title={selectedContract.name}
          fileUrl={fileUrl}
          signContract={onSignIndividualContract}
        />
      ) : (
        <Fragment>
          <div className="mb-10">
            <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
              Contratos de préstamo
            </h1>
            <p>Conoce los documentos de tu préstamo:</p>
          </div>

          <div className="mb-10 flex flex-col gap-6">
            {contracts.map((contract, index) => (
              <div
                className="flex cursor-pointer rounded-lg border border-solid border-gray-200-kiwi p-4"
                onClick={() => onSelectContract(contract)}
                key={index}
              >
                <div
                  className={`flex shrink-0 w-6 h-6 rounded-lg mr-4 ${
                    contract.signed
                      ? "bg-blue-kiwi"
                      : "border border-solid border-gray-200-kiwi"
                  }`}
                >
                  {contract.signed && (
                    <CheckIcon className="text-white w-4 m-auto" />
                  )}
                </div>
                <div className="mr-auto">{contract.name}</div>
                {!contract.signed && (
                  <RightIcon className="text-slate-300-kiwi" />
                )}
              </div>
            ))}
          </div>

          <Button
            className="rounded-xl p-3 mt-auto"
            loading={loading}
            disabled={
              validateEveryContractSigned() === false || contracts.length === 0
            }
            onClick={onSignAllContracts}
          >
            Firmar contrato
          </Button>
        </Fragment>
      )}
    </Fragment>
  );
}
