import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { UserContext } from 'context/UserContext'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'
import { REPAYMENT_OPTIONS } from 'constants'
import { useIsSecondMoneyOnTheWay } from "hooks/personalLoan";

/**
 * Middleware para prevenir que el usuario entre a esta vista
 * sin que su status sea igual a approved...
 */
export const ApprovedLoanStatus = () => {
    const {state: userState} = useContext(UserContext);
    const {
        products: {
            personal_loan: {status},
        },
    } = userState;

    return status === 'approved' ? <Outlet /> : <Navigate to="/home" />;
};

/**
 * Si ya se firmo contrato, y tiene objeto loan,
 * redirigir a Mesalve si se seleccionó
 */
export const MesalveMiddlware = () => {
    const {state: userState} = useContext(UserContext);
    const {
        products: {
            personal_loan: {loan},
        },
    } = userState;

    const isMesalve = loan && loan.type === 'mesalve';
    return isMesalve ? <Navigate to="/mesalve-code" /> : <Outlet />;
};

/**
 * Si ya se firmo contrato, y tiene objeto loan,
 * redirigir a dinero en camino si se seleccionó transferencia.
 */
export const MoneysOnTheWayMiddlware = () => {
    
    const isSecondMoneyOnTheWay = useIsSecondMoneyOnTheWay();

    return isSecondMoneyOnTheWay ? (
        <Navigate to="/moneys-on-the-way" />
    ) : (
        <Outlet />
    );
};

/*
 * Se creó el middelware para bloquear al usuario en la vista de
 * paper check, solo cuando call_paper_check_validation sea true...
 */
export const PaperCheckCallMiddleware = () => {
    const {state: approvedState} = useContext(ApprovedLoanContext);

    const isReviewing =
        approvedState.repaymentOption?.value === REPAYMENT_OPTIONS.paperCheck &&
        approvedState.repaymentOptionReviewing?.completed === false;

    if (isReviewing)
        return <Navigate to="/approved-loan/repayment-options/reviewing" />;

    return <Outlet />;
};

/**
 * Validación para bloquear que el usuario regrese al flujo
 * cuando su validación de Plaid este en curso...
 */
export const PaperCheckPlaidMiddleware = () => {
    const {state: approvedState} = useContext(ApprovedLoanContext);

    const isReviewing =
        approvedState.paperCheckPlaid?.completed === true &&
        approvedState.paperCheckReviewing?.completed === false;

    if (isReviewing)
        return <Navigate to="/approved-loan/paper-check/reviewing" />;

    return <Outlet />;
};

/**
 * Validación para bloquear que el usuario cuando entró en una
 * validción de identidad en el flujo de ME SALVE, se sale de
 * este flujo por medio del CMS
 */
export const MesalveVerificationMiddleware = () => {
    const isReviewing = false;

    if (isReviewing)
        return <Navigate to="/approved-loan/mesalve-verification" />;

    return <Outlet />;
};
