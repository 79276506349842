import  { useState, useEffect, createContext } from 'react'
import PropTypes from 'prop-types'
import http from 'utils/http'

export const CreditMonitoringContext = createContext()

export const CreditMonitoringProvider = ({ children }) => {
    const initialState = {
        arrayData: {
            clientKey: '',
            userToken: ''
        }
    }

    const [state, setState] = useState(() => {
        const state = JSON.parse(
            sessionStorage.getItem('CreditMonitoringContext')
        )

        if (state === null) {
            return initialState
        }

        return state
    })

    useEffect(() => {
        sessionStorage.setItem('CreditMonitoringContext', JSON.stringify(state))
    }, [state])

    const resetState = () => {
        sessionStorage.removeItem('CreditMonitoringContext')
        setState(initialState)
    }

    const getCreditMonitoring = async (userId) => {
        const response = await http.get(`/credit-monitoring/${userId}`)

        return response
    }

    const addCreditMonitoring = async ({ clientKey, userToken, userId }) => {
        const response = await http.post(`/credit-monitoring`, {
            client_key: clientKey,
            user_token: userToken,
            user_id: userId
        })

        return response
    }

    const updateCreditMonitoring = async ({ userToken, userId }) => {
        const response = await http.put(`/credit-monitoring`, {
            user_id: userId,
            user_token: userToken
        })

        return response
    }

    const refreshUserToken = async ({ userId }) => {
        const response = await http.post(
            `/credit-monitoring/refresh-user-token`,
            {
                user_id: userId
            }
        )

        return response
    }

    const providerValues = {
        /** State */
        state,
        setState,
        resetState,

        /** Handlers */
        getCreditMonitoring,
        addCreditMonitoring,
        updateCreditMonitoring,
        refreshUserToken
    }

    return (
        <CreditMonitoringContext.Provider value={providerValues}>
            {children}
        </CreditMonitoringContext.Provider>
    )
}

CreditMonitoringProvider.propTypes = {
    children: PropTypes.node.isRequired
}