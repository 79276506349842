import { useState } from "react";
import { createWithdrawalTransaction } from "../services/walletTransactions";

export const useWalletWithdraw = (callbackSuccess, callbackError) => {
  const [loading, setLoading] = useState(false);
  const onWithdraw = async (amount, phone_number) => {
    setLoading(true);
    try {
      await createWithdrawalTransaction({ amount, label: `***${phone_number.slice(-4)}` });
      callbackSuccess?.();
    } catch (error) {
      console.log("🚀 ~ onWithdraw ~ error:", error);
      callbackError?.();
    } finally {
      setLoading(false);
    }
  };
  return {
    onWithdraw,
    loading,
  };
};
