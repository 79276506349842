import { useState } from "react";
import PropTypes from "prop-types";
import { cs } from "utils/cs";
import ItemTransactionHistory from "components/ItemTransactionHistory";
import TransactionDescription from "components/Modals/TransactionDescription";
import formatDate from "utils/formatDate";
import Store2Line from "assets/icons_v3/fonts/store_2_line";
import UserAdd2Line from "assets/icons_v3/fonts/user_add_2_line";
import SendLine from "assets/icons_v3/fonts/send_line";

export const TransactionHistory = ({ transactionsGroup }) => {
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = (transaction) => {
    setSelectedTransaction(transaction);
    setIsOpen(true);
  };

  const formatTransactions = (transaction) => {
    const iconMapper = {
      cashback: Store2Line,
      transfer: SendLine,
      referrals: UserAdd2Line,
    };
    return {
      type: transaction.type,
      amount: transaction.amount,
      description: transaction.label,
      status: transaction.status.toLowerCase(),
      icon: iconMapper[transaction.type ?? "cashback"],
      date: formatDate(transaction?.createdAt, "YYYY-MM-DD"),
    };
  };

  return Object.keys(transactionsGroup)?.map?.((year, index) => (
    <div key={year + index}>
      {Object.keys(transactionsGroup?.[year])?.map?.((month, index) => (
        <div key={month + index}>
          <h4 className="font-semibold text-slate-900 text-2xl my-8 capitalize">
            {month} {year}
          </h4>
          <div className="flex flex-col gap-6">
            {transactionsGroup?.[year]?.[month]?.map?.((transaction, index) => (
              <div
                key={transaction.id}
                className={cs([
                  {
                    "pb-6": index === transaction.length - 1,
                  },
                ])}
              >
                <ItemTransactionHistory
                  transaction={formatTransactions(transaction)}
                  showStatus={false}
                  key={transaction?.id}
                  callback={openModal}
                />
              </div>
            ))}
          </div>
        </div>
      ))}

      <TransactionDescription
        isOpen={isOpen}
        transaction={selectedTransaction}
        onClose={() => setIsOpen(false)}
      />
    </div>
  ));
};

TransactionHistory.propTypes = {
  transactionsGroup: PropTypes.object,
};

TransactionHistory.defaultProps = {
  transactionsGroup: {},
};
