import PropTypes from 'prop-types';

const GasStationIcon = ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M13 3a2 2 0 0 1 1.995 1.85L15 5v7h1a2 2 0 0 1 1.995 1.85L18 14v2.5a.5.5 0 0 0 .992.09L19 16.5V12a2.017 2.017 0 0 1-1.123-.345 2 2 0 0 1-.558-2.738l.075-.109-1.101-1.1a1 1 0 0 1 1.32-1.498l.094.083L20.414 9a2 2 0 0 1 .578 1.238l.008.176V16.5a2.5 2.5 0 0 1-4.995.164L16 16.5V14h-1v5a1 1 0 0 1 .117 1.993L15 21H3a1 1 0 0 1-.117-1.993L3 19V5a2 2 0 0 1 1.85-1.995L5 3h8Zm0 10H5v6h8v-6Zm0-8H5v6h8V5Z"
        />
    </svg>
);

GasStationIcon.displayName = 'GasStationIcon';

GasStationIcon.propTypes = {
    className: PropTypes.string,
};

export default GasStationIcon;
