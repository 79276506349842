import PropTypes from 'prop-types';

const ChevronLeftIcon = ({ className, ...props }) => (
    <svg
        width="9"
        height="16"
        viewBox="0 0 9 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...props}>
        <path
            d="M8 15L1 8L8 1"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

ChevronLeftIcon.displayName = 'ChevronLeftIcon';

ChevronLeftIcon.propTypes = {
    className: PropTypes.string,
};

export default ChevronLeftIcon;
