import { forwardRef, useImperativeHandle, useRef } from 'react'
import BaseModal from '../Modal'

const APRModal = forwardRef((props, ref) => {
    const BaseModalRef = useRef()

    useImperativeHandle(ref, () => ({
        ...BaseModalRef.current
    }))

    return (
        <BaseModal ref={BaseModalRef} title="¿Qué es el APR?">
            <p>
                La Tasa de Porcentaje Anual (APR) es el costo total que se paga
                cada año por obtener un préstamo, incluyendo tarifas y otros
                cargos, expresado como un porcentaje.
            </p>
        </BaseModal>
    )
})

APRModal.displayName = 'APRModal'

export default APRModal
