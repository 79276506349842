import { Route } from "react-router-dom";

/**
 * Context Providers
 */
import { ApprovedLoanProvider } from "context/ApprovedLoanContext";
import { CreditLimitProvider } from "context/CreditLimitContext";

/**
 * Middlewwares
 */
import {
  ApprovedLoanStatus,
  MesalveMiddlware,
  MesalveVerificationMiddleware,
  MoneysOnTheWayMiddlware,
  PaperCheckCallMiddleware,
  PaperCheckPlaidMiddleware,
} from "./middlewares/ApprovedLoanMiddleware";
import {
  BlackListMiddleware,
  FingerprintBlockedMiddleware,
  FloridaStateMiddleware,
} from "./middlewares/AuthMiddleware";
import { NeedVerificationMiddleware } from "./middlewares/NeedVerificationCallMiddleware";

/**
 * Routes
 */
import ApprovedLoan from "views/mobile/ApprovedLoan";
import ApprovedLoanAmount from "views/mobile/ApprovedLoan/Amount";
import ApprovedLoanRepaymentDetails from "views/mobile/ApprovedLoan/RepaymentDetails";
import ApprovedLoanPaymentProfile from "views/mobile/ApprovedLoan/PaymentProfile";
import ApprovedLoanDisbursement from "views/mobile/ApprovedLoan/Disbursement";
import ApprovedLoanATHVerification from "views/mobile/ApprovedLoan/ATHVerification";
import ApprovedLoanDisbursementProfile from "views/mobile/ApprovedLoan/DisbursementProfile";
import ApprovedLoanStores from "views/mobile/ApprovedLoan/Stores";

import ApprovedLoanRepaymentOptions from "views/mobile/ApprovedLoan/RepaymentOptions";
import ApprovedLoanRepaymentOptionsSelect from "views/mobile/ApprovedLoan/RepaymentOptions/Select";
import ApprovedLoanRepaymentOptionsReviewing from "views/mobile/ApprovedLoan/RepaymentOptions/Reviewing";

import ApprovedLoanRepaymentProfile from "views/mobile/ApprovedLoan/RepaymentProfile";

import ApprovedLoanPaperCheck from "views/mobile/ApprovedLoan/PaperCheck";
import ApprovedLoanPaperCheckPlaid from "views/mobile/ApprovedLoan/PaperCheck/Plaid";
import ApprovedLoanPaperCheckReview from "views/mobile/ApprovedLoan/PaperCheck/Reviewing";

import ApprovedLoanMesalve from "views/mobile/ApprovedLoan/Mesalve";
import ApprovedLoanMesalveReviewing from "views/mobile/ApprovedLoan/Mesalve/Reviewing";
import ApprovedLoanMesalveError from "views/mobile/ApprovedLoan/Mesalve/Error";

import ApprovedLoanPersona from "views/mobile/ApprovedLoan/Persona";
import ApprovedLoanVerifyRepaymentDetails from "views/mobile/ApprovedLoan/VerifyRepaymentDetails";
import ApprovedLoanVeritec from "views/mobile/ApprovedLoan/Veritec";
import ApprovedLoanContract from "views/mobile/ApprovedLoan/Contract";
import ApprovedLoanNeedVerificationCall from "views/mobile/ApprovedLoan/VerificationCall/NeedVerification";
import PersonaWaitingMobile from "templates/Persona/WaitingMobile";

const ApprovedLoanRoutes = (
  <Route element={<FingerprintBlockedMiddleware />}>
    <Route element={<FloridaStateMiddleware />}>
      <Route element={<BlackListMiddleware />}>
        <Route
          element={
            <CreditLimitProvider>
              <ApprovedLoanProvider>
                <ApprovedLoanStatus />
              </ApprovedLoanProvider>
            </CreditLimitProvider>
          }
        >
          <Route exact path="approved-loan" element={<ApprovedLoan />}>
            <Route element={<NeedVerificationMiddleware />}>
              <Route element={<MoneysOnTheWayMiddlware />}>
                <Route element={<MesalveMiddlware />}>
                  <Route element={<PaperCheckPlaidMiddleware />}>
                    <Route element={<PaperCheckCallMiddleware />}>
                      <Route element={<MesalveVerificationMiddleware />}>
                        <Route
                          exact
                          path="amount"
                          element={<ApprovedLoanAmount />}
                        />
                        <Route
                          exact
                          path="repayment-details"
                          element={<ApprovedLoanRepaymentDetails />}
                        />
                        <Route
                          exact
                          path="payment-profile"
                          element={<ApprovedLoanPaymentProfile />}
                        />
                        <Route
                          exact
                          path="disbursement"
                          element={<ApprovedLoanDisbursement />}
                        />
                        <Route
                          exact
                          path="ath-verification"
                          element={<ApprovedLoanATHVerification />}
                        />
                        <Route
                          exact
                          path="disbursement-profile"
                          element={<ApprovedLoanDisbursementProfile />}
                        />
                        <Route
                          exact
                          path="stores"
                          element={<ApprovedLoanStores />}
                        />
                        <Route
                          exact
                          path="repayment-profile"
                          element={<ApprovedLoanRepaymentProfile />}
                        />
                        <Route
                          exact
                          path="persona"
                          element={<ApprovedLoanPersona />}
                        />
                        <Route
                          exact
                          path="persona-waiting"
                          element={<PersonaWaitingMobile />}
                        />
                        <Route
                          exact
                          path="veritec"
                          element={<ApprovedLoanVeritec />}
                        />
                        <Route
                          exact
                          path="contract"
                          element={<ApprovedLoanContract />}
                        />
                        <Route
                          exact
                          path="verify-repayment-details"
                          element={<ApprovedLoanVerifyRepaymentDetails />}
                        />
                      </Route>
                    </Route>

                    <Route
                      exact
                      path="paper-check"
                      element={<ApprovedLoanPaperCheck />}
                    >
                      <Route
                        exact
                        path=""
                        element={<ApprovedLoanPaperCheckPlaid />}
                      />
                      <Route
                        exact
                        path="reviewing"
                        element={<ApprovedLoanPaperCheckReview />}
                      />
                    </Route>

                    <Route
                      exact
                      path="repayment-options"
                      element={<ApprovedLoanRepaymentOptions />}
                    >
                      <Route
                        exact
                        path=""
                        element={<ApprovedLoanRepaymentOptionsSelect />}
                      />
                      <Route
                        exact
                        path="reviewing"
                        element={<ApprovedLoanRepaymentOptionsReviewing />}
                      />
                    </Route>

                    <Route
                      exact
                      path="mesalve-verification"
                      element={<ApprovedLoanMesalve />}
                    >
                      <Route
                        exact
                        path=""
                        element={<ApprovedLoanMesalveReviewing />}
                      />
                      <Route
                        exact
                        path="error"
                        element={<ApprovedLoanMesalveError />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route
              exact
              path="need-verification-call"
              element={<ApprovedLoanNeedVerificationCall />}
            />
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
);

export default ApprovedLoanRoutes;
