import PropTypes from 'prop-types'
import { ArrowRightIcon } from 'assets/icons_v3/fonts'

const CourseComponent = ({ course, onSelectCourse }) => {
    const getCoursePrice = (country, prices) => {
        const price = prices.find((p) => p.country_code === country)
        return price
    }

    const monthlyPayment = (country, prices, financial_months) => {
        const price = getCoursePrice(country, prices)
        return (price.value / financial_months).toFixed(2)
    }

    return (
        <div className="cursor-pointer" onClick={() => onSelectCourse(course)}>
            <div className="h-40 bg-gray-200-kiwi rounded-t-lg">
                <div
                    className="h-40 w-full rounded-t-lg bg-no-repeat bg-center bg-cover"
                    style={{
                        backgroundImage: `url(${course.image_url})`
                    }}></div>
            </div>
            <div className="bg-regal-blue-kiwi px-4 py-2 text-white text-sm">
                Construye {course.financial_months} meses de historial de
                crédito
            </div>
            <div className="px-4 flex justify-between items-center rounded-b-lg py-2 border border-slate-200-kiwi border-t-0">
                <div className="text-regal-blue-kiwi font-semibold text-sm">
                    $
                    {monthlyPayment(
                        'us',
                        course.cb_prices,
                        course.financial_months
                    )}{' '}
                    al mes
                </div>
                <ArrowRightIcon className="w-4 h-4" />
            </div>
        </div>
    )
}

CourseComponent.propTypes = {
    course: PropTypes.shape({
        image_url: PropTypes.string.isRequired,
        cb_prices: PropTypes.arrayOf(
            PropTypes.shape({
                country_code: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired
            })
        ).isRequired,
        financial_months: PropTypes.number.isRequired
    }).isRequired,
    onSelectCourse: PropTypes.func.isRequired
}

export default CourseComponent
