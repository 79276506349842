import PropTypes from 'prop-types';

const ListCheckLine = ({ className, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M4.5 17.5C5.32843 17.5 6 18.1716 6 19C6 19.8284 5.32843 20.5 4.5 20.5C3.67157 20.5 3 19.8284 3 19C3 18.1716 3.67157 17.5 4.5 17.5ZM20 18C20.5523 18 21 18.4477 21 19C21 19.5523 20.5523 20 20 20H9C8.44772 20 8 19.5523 8 19C8 18.4477 8.44772 18 9 18H20ZM4.5 10.5C5.32843 10.5 6 11.1716 6 12C6 12.8284 5.32843 13.5 4.5 13.5C3.67157 13.5 3 12.8284 3 12C3 11.1716 3.67157 10.5 4.5 10.5ZM20 11C20.5523 11 21 11.4477 21 12C21 12.5128 20.614 12.9355 20.1166 12.9933L20 13H9C8.44772 13 8 12.5523 8 12C8 11.4872 8.38604 11.0645 8.88338 11.0067L9 11H20ZM4.5 3.5C5.32843 3.5 6 4.17157 6 5C6 5.82843 5.32843 6.5 4.5 6.5C3.67157 6.5 3 5.82843 3 5C3 4.17157 3.67157 3.5 4.5 3.5ZM20 4C20.5523 4 21 4.44772 21 5C21 5.51283 20.614 5.93551 20.1166 5.99327L20 6H9C8.44772 6 8 5.55228 8 5C8 4.48717 8.38604 4.06449 8.88338 4.00673L9 4H20Z"
      fill="#fff"
    />
  </svg>
);

ListCheckLine.propTypes = {
    className: PropTypes.string,
};

export default ListCheckLine;