import  {useContext, Fragment, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {ApprovedLoanContext} from 'context/ApprovedLoanContext';

import ProgressBar from 'components/ProgressBar';
import PaymentProfile from 'templates/PaymentProfile';
import {UserContext} from 'context/UserContext';

export default function ApprovedLoanRepaymentProfile() {
    const navigate = useNavigate();

    const {state: approvedState, saveState: saveApprovedState} =
        useContext(ApprovedLoanContext);
    const {state: userState} = useContext(UserContext);

    useEffect(() => {
        dataLayer.push({
            event: 'new_application',
            eventCategory: 'loan_process',
            eventAction: 'loan_process_repayment_profile',
            dl_user_id: userState.user.id,
        });
    }, []);

    const onBackward = () => {
        navigate('/approved-loan/repayment-options');
    };

    const onSelectProfile = ({id, last4, bankName}) => {
        saveApprovedState({
            ...approvedState,
            repaymentProfile: {
                id,
                last4,
                bankName,
                completed: true,
            },
        });

        navigate('/approved-loan/persona');
    };

    return (
        <Fragment>
            <ProgressBar progress="8/10" onBackward={onBackward} />
            <PaymentProfile
                onSelectProfile={onSelectProfile}
                selectedProfile={approvedState.repaymentProfile.id ?? ''}
                selectProfileTitle="Selecciona tu perfil de repago"
            />
        </Fragment>
    );
}
