
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { GiftIcon } from 'assets/icons_v3/fonts'

import SocialSVG from 'assets/onboarding_v3/recommended/social.png'

export default function HomeRecommendedSocial({ className }) {
    const navigate = useNavigate()

    const onClick = () => {
        navigate('/roulette')
    }

    return (
        <div
            className={`p-4 bg-regal-blue-kiwi text-white rounded-2xl flex justify-between ${className}`}
            onClick={onClick}>
            <div>
                <p className="text-blue-kiwi mb-2 text-xs font-medium flex items-center">
                    <GiftIcon className="w-4 mr-2" /> Redes sociales
                </p>
                <p className="font-bold mb-1 text-sm">
                    Gira y gana en la ruleta
                </p>
                <p className="text-xs">
                    Únete cada jueves en nuestro live y gana increíbles premios
                    😎🎁
                </p>
            </div>

            <img src={SocialSVG} className="shrink-0 self-center" />
        </div>
    )
}

HomeRecommendedSocial.propTypes = {
    className: PropTypes.string
}