import PropTypes from 'prop-types';

const MortarboardIcon = ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M2.278 8.994c-.84-.397-.84-1.591 0-1.988l9.08-4.294a1.5 1.5 0 0 1 1.283 0l9.08 4.294c.734.346.827 1.302.279 1.803V13a1 1 0 1 1-2 0V9.81l-1 .473V14a7 7 0 1 1-14 0v-3.719L2.278 8.994ZM17 9h.031l2.115-1L12 4.62 4.854 8l2.115 1H7v.015l5 2.364 5-2.364V9Zm-5.641 4.288L7 11.228V14a5 5 0 0 0 10 0v-2.773l-4.359 2.061a1.5 1.5 0 0 1-1.282 0Z"
        />
    </svg>
);

MortarboardIcon.displayName = 'MortarboardIcon';

MortarboardIcon.propTypes = {
    className: PropTypes.string,
};

export default MortarboardIcon;
