import BaseModal from '../BaseModal'
import PropTypes from 'prop-types';

const NSFFee = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="Cargo por falta de fondos"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6 mt-6">
                <p>
                    El &quot;cargo por falta de fondos&quot; o &quot;NSF Fee&quot; se activa cuando
                    una cuenta no dispone de los fondos necesarios para cubrir
                    una transacción, y la entidad financiera aplica una tarifa
                    como compensación por la insuficiencia de fondos.
                </p>
            </div>
        </BaseModal>
    )
}

NSFFee.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default NSFFee
