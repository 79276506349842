import PlaidLogoSVG from "assets/logos/plaid.svg";
import Button from "components/Button";
import Advisement from "components/Advisement";
import LoadingModal from "components/Modals/Loading";
import {
  BankIcon,
  Wallet3Icon,
  BalanceIcon,
  TimeIcon,
} from "assets/icons_v3/fonts";
import BackwardButton from "components/Button/Backward";
import { usePlaidConnection } from "hooks/usePlaidConnection";

const bankRequirements = [
  {
    icon: Wallet3Icon,
    text: "Esté a tu nombre",
  },
  {
    icon: BalanceIcon,
    text: "No esté sobregirada actualmente",
  },
  {
    icon: TimeIcon,
    text: "Tenga al menos tres meses de transacciones",
  },
  {
    icon: BankIcon,
    text: "Sea donde obtienes tu principal fuente de ingresos",
  },
];

export default function PlaidTemplateV2({
  title,
  description,
  onSuccess,
  onExit,
  onError,
  redirectPath,
  redirectState,
  onBackward,
}) {
  const { loading, showLoader, startPlaidConnection } = usePlaidConnection({
    redirectPath,
    redirectState,
    onSuccess,
    onExit,
    onError,
  });

  return (
    <>
      <BackwardButton onBackward={onBackward} />
      <LoadingModal isVisible={showLoader} />
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <h1 className="font-semibold text-2xl text-dark-kiwi">{title}</h1>
          <p className="text-slate-600">{description}</p>
        </div>

        <div className="flex flex-col gap-6 text-slate-600">
          {bankRequirements.map((requirement, index) => (
            <div key={index} className="flex items-center gap-4">
              <div className="flex h-10 w-10 bg-slate-100-kiwi rounded-full flex-none">
                <requirement.icon className="m-auto text-regal-blue-kiwi" />
              </div>
              <div className="text-sm">{requirement.text}</div>
            </div>
          ))}
        </div>

        <img className="max-h-14 w-max mx-auto" src={PlaidLogoSVG} />
      </div>

      <div className="flex flex-col mt-auto">
        <Advisement icon="shield_check">
          Utilizamos Plaid para vincular tu cuenta bancaria de manera segura,
          empleando protocolos de cifrado avanzados para garantizar la
          protección de tus datos.
        </Advisement>

        <Button
          className="bg-blue-kiwi text-white rounded-xl p-3 flex items-center justify-center mt-10"
          onClick={startPlaidConnection}
          loading={loading}
        >
          Continuar
        </Button>
      </div>
    </>
  );
}
