import React from "react";
import { useNavigate } from "react-router-dom";

import ChatDisabledSVG from "assets/onboarding_v3/chat_disabled.svg";
import supportIcon from "assets/icons/support.svg";
import BackwardButton from "components/Button/Backward";

export default function LiveChatDisabled() {
  const navigate = useNavigate();

  const onBackward = () => {
    navigate("/home");
  };

  return (
    <div className="w-full min-h-full flex flex-col px-6 py-10">
      <BackwardButton onBackward={onBackward} />

      <div className="m-auto flex flex-col justify-center h-full text-center">
        <img className="mx-auto mb-8" src={ChatDisabledSVG} />
        <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
          Chat no está disponible
        </h1>
        <p>
          Lo sentimos, en este momento nuestro chat no está disponible. Por
          favor, envía tus preguntas al correo{" "}
          <a href="mailto:support@kiwicredito.com" className="font-semibold">
            support@kiwicredito.com
          </a>
          .
        </p>
      </div>

      <a
        href="tel:+17874221861"
        className="flex justify-center items-center rounded-xl p-3 mt-10 bg-blue-kiwi text-white"
      >
        <img className="mr-2" src={supportIcon} alt="Support Kiwi" />
        ¿Necesitas ayuda?
      </a>
    </div>
  );
}
