import Emitter from 'utils/emitter'
import dayjs from 'dayjs'
import  { useState, useEffect, useContext, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreditBuilderContext } from 'context/CreditBuilderContext'
import { UserContext } from 'context/UserContext'

import Button from 'components/Button'
import LoadingModal from 'components/Modals/Loading'

import moneyFormat from 'utils/moneyFormat'
import ProgressBar from 'components/ProgressBar'
import STATE_CONSTANTS from 'constants/states'
import APRModal from 'components/Modals/APR'
import ToggleInput from 'components/ToggleInput'
import { Calendar2Icon } from 'assets/icons_v3/fonts'
import { LoanAPI } from 'api'
import formatDate from 'utils/formatDate'

export default function CreditBuilderRepaymentTerms() {
    const navigate = useNavigate()

    const {
        state: creditState,
        saveState: saveCreditState,
        createTempLoan,
        createLoanproCustomer
    } = useContext(CreditBuilderContext)
    const { state: userState } = useContext(UserContext)
    const [APRVisible, setAPRVisible] = useState(false)
    const { repaymentTerms, course, paymentDay } = creditState
    const [loading, setLoading] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [schedulesPayments, setSchedulesPayments] = useState([])

    useEffect(() => {
        onCreateTempLoan()
    }, [])

    const onCreateTempLoan = async () => {
        try {
            setLoading(true)
            setShowLoader(true)

            let tempLoanId = repaymentTerms.tempLoanId

            if (tempLoanId === '') {
                const { data } = await createTempLoan({
                    user_id: userState.user.id,
                    price_id: course.price_id,
                    preferred_payment_date: paymentDay.value,
                    product_id: course.id
                })

                saveCreditState({
                    ...creditState,
                    repaymentTerms: {
                        ...data.loan,
                        completed: false
                    }
                })

                tempLoanId = data.loan.tempLoanId
            }

            await onGetSchedulesPayments(tempLoanId)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
            setShowLoader(false)
        }
    }

    const onGetSchedulesPayments = async (loanpro_tmp_loan_id) => {
        const { data } = await LoanAPI.getScheduledPayments({
            loanpro_tmp_loan_id
        })

        setSchedulesPayments(data.data)
    }

    const onContinue = async () => {
        try {
            setLoading(true)

            await createLoanproCustomer(userState.user.id)
            await saveCreditState({
                ...creditState,
                repaymentTerms: {
                    ...creditState.repaymentTerms,
                    completed: true
                }
            })

            navigate('/credit-builder/payment-profile')
        } catch (error) {
            const { response } = error

            if (response && response.data) {
                if (response.data.error.includes('duplicate found')) {
                    await saveCreditState({
                        ...creditState,
                        repaymentTerms: {
                            ...creditState.repaymentTerms,
                            completed: true
                        }
                    })

                    navigate('/credit-builder/payment-profile')
                }

                if (response.data.error.includes('zip')) {
                    Emitter.emit('onOpenNotification', {
                        type: 'error',
                        title: 'Error al crear tu préstamo',
                        message:
                            'Parece que tuvimos problemas al crear tu préstamo. Por favor inténtalo nuevamente, si el problema persiste contacta a support@kiwicredito.com'
                    })
                }
            }
        } finally {
            setLoading(false)
        }
    }

    const onBackward = () => {
        if (loading === false) {
            navigate('/credit-builder/ssn')
        }
    }

    const paymentFrequency = STATE_CONSTANTS(userState.user.state).approved
        .paymentFrequency

    const formatPaymentDate = (date) => {
        const format = Number(date.replace(/^.*?(\d+).*/, '$1'))
        return dayjs.unix(format).add(1, 'day').format('MMM DD, YYYY')
    }

    const toggleAPRModal = () => {
        setAPRVisible(!APRVisible)
    }

    function transformDate(date) {
        const [month, day, year] = date.split('/')
        return formatDate(year + '/' + day + '/' + month, 'MMMM DD, YYYY')
    }

    return (
        <Fragment>
            <ProgressBar progress="5/8" onBackward={onBackward} />
            <LoadingModal isVisible={showLoader} />
            <APRModal isVisible={APRVisible} closeModal={toggleAPRModal} />

            <div className="text-sm relative mb-6 flex flex-col items-center">
                <div className="text-gray-500-kiwi text-xs mb-1">
                    Monto de préstamo
                </div>

                <div className="text-dark-kiwi font-semibold mb-6 text-6xl">
                    ${moneyFormat(Number(course.price).toFixed(2))}
                </div>

                <div className="w-full flex flex-col gap-4 mb-6">
                    <div className="flex justify-between gap-2">
                        <div>Pago {paymentFrequency}</div>
                        <b className="text-right">
                            {showLoader
                                ? '...'
                                : `$${repaymentTerms.monthlyPayment?.toFixed(
                                      2
                                  )}`}
                        </b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div>Monto total a repagar</div>
                        <b className="text-right">
                            {showLoader
                                ? '...'
                                : `$${moneyFormat(
                                      Number(
                                          repaymentTerms.totalAmountIncludingFee
                                      ).toFixed(2)
                                  )}`}
                        </b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div>Pago total de interés</div>
                        <b className="text-right">
                            {showLoader
                                ? '...'
                                : `$${moneyFormat(
                                      (
                                          repaymentTerms.totalAmountIncludingFee -
                                          Number(course.price)
                                      )?.toFixed(2)
                                  )}`}
                        </b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div>Primera fecha de pago</div>
                        <b className="text-right">
                            {showLoader
                                ? '...'
                                : formatPaymentDate(
                                      repaymentTerms.firstPaymentDate
                                  )}
                        </b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div>Tasa de interés</div>
                        <b className="text-right">
                            {showLoader
                                ? '...'
                                : `${repaymentTerms.interestRate?.toFixed(2)}%`}
                        </b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div
                            className="text-blue-kiwi underline cursor-pointer"
                            onClick={toggleAPRModal}>
                            APR
                        </div>
                        <b className="text-right">
                            {showLoader
                                ? '...'
                                : `${repaymentTerms.APR?.toFixed(2)}%`}
                        </b>
                    </div>
                </div>

                <ToggleInput
                    IconHeader={Calendar2Icon}
                    title={`Calendario de ${repaymentTerms.termMonths} pagos`}
                    saveState={false}>
                    <div className="flex flex-col gap-4">
                        {schedulesPayments?.map((payment) => (
                            <div
                                className="flex items-center justify-between capitalize"
                                key={payment.date}>
                                <div>{transformDate(payment.date)}</div>
                                <div className="font-semibold">
                                    ${Number(payment.chargeAmount).toFixed(2)}
                                </div>
                            </div>
                        ))}
                    </div>
                </ToggleInput>
            </div>

            <div className="border border-slate-100-kiwi rounded-xl mb-10">
                <div className="font-bold p-4 bg-slate-100-kiwi rounded-t-xl text-dark-kiwi w-[calc(100%+2px)]">
                    Curso seleccionado
                </div>
                <div className="text-sm p-4">
                    <div className="mb-2 text-gray-500-kiwi">
                        Construye {course.financial_months} Meses de Historial
                        Crediticio
                    </div>
                    <div className="flex justify-between mb-2">
                        <div className="mr-2">{course.name}</div>
                        <div className="font-semibold">
                            ${Number(course.price).toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                loading={loading}
                onClick={onContinue}>
                Continuar
            </Button>
        </Fragment>
    )
}
