import { useState, useContext, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import Emitter from 'utils/emitter'
import { UserContext } from 'context/UserContext'
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { BankIcon } from 'assets/icons_v3/fonts'

import Button from 'components/Button'
import validateRoutingNumber from 'utils/validateRoutingNumber'

export default function PaymentProfileSelectToAddTemplate({
    selectProfileTitle = 'Selecciona tu perfil de pago',
    onAddProfile,
    plaidAccounts,
    setShowLoader
}) {
    const { state: userState, addBankAccount } = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const [accountProfileID, setAccountProfileID] = useState(null)
    const [accountProfile, setAccountProfile] = useState(null)


    const { getData: getDataFingerprint } = useVisitorData(
        { extendedResult: true },
        { immediate: true }
    )

    useEffect(() => {
        if (plaidAccounts.length !== 0) {
            setAccountProfileID(plaidAccounts[0]?.account_number)
            setAccountProfile(plaidAccounts[0])
        }
    }, [])

    const fingerprintSetData = async () => {
        try {
            await getDataFingerprint({
                ignoreCache: true,
                tag: {
                    env: process.env.REACT_APP_FINGERPRINT_ENV,
                    user_id: userState.user.id,
                    place: 'payment_profile_success'
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const isValidRoutingNumber = async (routingNumber) => {
        return await validateRoutingNumber(routingNumber)
    }

    const onSubmit = async () => {

        if (loading === true) {
            return
        }

        const { type, routing_number, account_number } = accountProfile

        try {
            setLoading(true)
            setShowLoader(true)

            let validationRoutingNumber = await isValidRoutingNumber(
                routing_number
            )

            if (!validationRoutingNumber) {
                Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Error al agregar tu perfil de pago',
                    message: `Parece que tu número de ruta no es correcto`
                })
                return
            }

            const {
                data: {
                    data: { last4 }
                }
            } = await addBankAccount({
                accountType: type,
                accountNumber: account_number,
                routingNumber: routing_number
            })

            if (last4 && last4.numbers) {
                await onAddProfile({
                    type,
                    account_number,
                    routing_number,
                    last4: last4.numbers,
                    bankName: last4.bankName
                })
                await fingerprintSetData()
            }
        } catch (error) {
            if (error.response.data.data === 'No coincide') {
                Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Error al agregar tu perfil de pago',
                    message: `Parece que tu número de cuenta no coincide con la proporcionada anteriormente`
                })
            }
        } finally {
            setLoading(false)
            setShowLoader(false)
        }
    }

    const getAccountTitle = (item) => {
        return `Cuenta **** ${String(item.account_number).slice(
            -4
        )}`
    }

    const onSelectAccountProfile = (accountInfo) => {
        setAccountProfile(accountInfo)
        setAccountProfileID(accountInfo.account_number)
    }

    return (
        <Fragment>
            <div className="flex flex-col mb-10">
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    {selectProfileTitle}
                </h1>

                <div className="flex flex-col gap-6">
                    {plaidAccounts.map((profile, index) => (
                        <div key={index} className="relative">
                            <input
                                className="peer hidden"
                                type="radio"
                                id={`account-${index}`}
                                name="accountProfile"
                                onChange={() =>
                                    onSelectAccountProfile(profile)
                                }
                                checked={accountProfileID === profile.account_number}
                            />
                            <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                            <label
                                htmlFor={`account-${index}`}
                                className={`min-h-[78px] flex cursor-pointer  rounded-lg border border-solid border-gray-200-kiwi p-4 ${'peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 '
                                    }`}>
                                <div
                                    className={`h-10 w-10 mr-4 grow-0 shrink-0 rounded-full flex items-center justify-center ${accountProfileID === profile.account_number
                                        ? 'bg-blue-50-kiwi'
                                        : 'bg-slate-100-kiwi'
                                        }`}>
                                    <BankIcon
                                        className={`w-6  ${accountProfileID === profile.account_number
                                            ? 'text-blue-kiwi'
                                            : 'text-regal-blue-kiwi'
                                            }`}
                                    />
                                </div>
                                <div className="my-auto">
                                    <div className="leading-5">
                                        {getAccountTitle(profile)}
                                    </div>
                                </div>
                            </label>
                        </div>
                    ))}
                </div>
            </div>

            <Button
                className={`rounded-xl p-3 mt-auto ${accountProfileID === undefined ||
                    accountProfileID === '' ||
                    'bg-blue-kiwi text-white '
                    }`}
                onClick={onSubmit}>
                Continuar
            </Button>

        </Fragment>
    )
}

PaymentProfileSelectToAddTemplate.propTypes = {
    selectProfileTitle: PropTypes.string,
    onAddProfile: PropTypes.func.isRequired,
    plaidAccounts: PropTypes.arrayOf(PropTypes.shape({
        account_number: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        routing_number: PropTypes.string.isRequired
    })).isRequired,
    setShowLoader: PropTypes.func.isRequired
}