import ReactPixel from 'react-facebook-pixel';
import  { useEffect, useContext, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreditBuilderContext } from 'context/CreditBuilderContext'
import mixpanel from 'utils/mixpanel'
import ProgressBar from 'components/ProgressBar'
import PaymentProfile from 'templates/PaymentProfile'

export default function CreditBuilderPaymentProfile() {
    const navigate = useNavigate()

    const {
        state: creditState,
        saveState: saveCreditState
    } = useContext(CreditBuilderContext)

    useEffect(() => {

    }, [])

    const onBackward = () => {
        navigate('/credit-builder/repayment-terms')
    }

    const onAddProfile = ({ accountType, last4 }) => {
        saveCreditState({
            ...creditState,
            paymentProfile: {
                last4,
                completed: true
            }
        })

        ReactPixel.trackCustom('Payment_Profile_CB');

        window.dataLayer.push({
            event: 'Payment_Profile_CB'
        })

        mixpanel.track('Input Payment Method - CB', {
            'Payment Methods Added': accountType
        })

        navigate('/credit-builder/persona')
    }

    const onSelectProfile = ({ id, last4, bankName }) => {
        saveCreditState({
            ...creditState,
            paymentProfile: {
                id,
                last4,
                bankName,
                completed: true
            }
        })

        navigate('/credit-builder/persona')
    }

    return (
        <Fragment>
            <ProgressBar progress="6/8" onBackward={onBackward} />
            <PaymentProfile
                onAddProfile={onAddProfile}
                onSelectProfile={onSelectProfile}
                selectedProfile={creditState.paymentProfile.id ?? ''}
            />
        </Fragment>
    )
}
