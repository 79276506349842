import { useEffect, useContext, useState, Fragment, useRef } from "react";
import PatternFormat from "react-number-format";
import { WhatsappShareButton, FacebookShareButton } from "react-share";

import { UserContext } from "context/UserContext";

import {
  ChevronLeftIcon,
  LinkIcon,
  CurrencyDollarIcon,
  MailOpenIcon,
  UserAddIcon,
} from "@heroicons/react/outline";
import WhatsappImage from "assets/social/whatsapp.svg";
import FacebookImage from "assets/social/facebook.svg";

import ReferralTermsModal from "components/Modals/ReferralTerms";
import LoadingModal from "components/Modals/Loading";
import { REFERRAL_AMOUNT } from "constants";
import { trackEvent } from "utils/dmp_kiwi";
import { getUtmParams } from "hooks/useStoredUrlParams";

export default function ShareReferralCode() {
  const location = window.location.href;
  const REFERRAL_COMMISSION = 15;
  const REFERRAL_URL = "https://www.kiwicredito.com?referral_code=";
  const REFERRAL_TEXT = () =>
    `¡Hola! ${currentUser} te ha recomendado Kiwi Crédito, la forma más rápida y segura de solicitar tú préstamo, solicita el tuyo aquí: `;
  const REFERRAL_FEATURES = [
    {
      icon: MailOpenIcon,
      accent: "Envía tu invitación o comparte",
      description: "tu enlace con amigos y familiares",
    },
    {
      icon: UserAddIcon,
      accent: "Tu amigo se registra",
      description: "y solicita un préstamo con kiwi",
    },
    {
      icon: CurrencyDollarIcon,
      accent: `Recibe $${REFERRAL_AMOUNT} por cada referido`,
      description: "con préstamo activo y gana hasta $500 al año",
    },
  ];

  let interval = {}; // USED IN onCopyReferralCode

  const { state: userState, getReferrals } = useContext(UserContext);

  const [referralTermsVisible, setReferralTermsVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState("invitations");
  const [loading, setLoading] = useState(true);

  const [referralsPaid, setReferralsPaid] = useState(0);
  const [referrals, setReferrals] = useState([]);
  const [referralCode, setReferralCode] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [utmParams, setUtmParams] = useState({});

  const cellphoneInput = useRef();
  const [cellphone, setCellphone] = useState("");
  const [error, setError] = useState("");

  const getUserReferrals = async () => {
    try {
      const { data } = await getReferrals(userState.user.id);
      if (data.data.referrals) {
        setReferrals(data.data.referrals);
        setReferralsPaid(data.data.referrals_paid);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUtmParams(getUtmParams());
    getUserReferrals();
    setReferralCode(`${userState.user.phone_number.replace(/\D+/g, "")}`);
    setCurrentUser(userState.user.first_name);
  }, []);

  const onCopyReferralCode = () => {
    const elem = document.createElement("textarea");
    const copied = document.getElementById("copied");

    clearInterval(interval);

    copied.classList.remove("opacity-0");

    interval = setTimeout(() => {
      copied.classList.add("opacity-0");
    }, 500);

    elem.value = `${REFERRAL_URL}${referralCode}`;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  };

  const onSetCellphone = (values) => {
    const { value } = values;

    setError("");
    setCellphone(value);
  };

  const sendReferralEvent = () => {
    try {
      if (cellphone == "" || cellphone.length < 10) {
        setError("Número inválido");
      } else {
        trackEvent({
          place: "/share-referral-code",
          event_type: "button",
          pathname: location,
          user_id: userState.user.id,
          utm_data: utmParams,
        });
        _cio.track("referral_code_input", {
          from_referrer: `+${userState.user.phone_number.replace(/\D+/g, "")}`,
          to_referred: `+1${cellphone.replace(/\D+/g, "")}`,
        });
        setCellphone("");
        cellphoneInput.current.blur();

        const copied = document.getElementById("sent-invite");
        clearInterval(interval);

        copied.classList.remove("opacity-0");

        interval = setTimeout(() => {
          copied.classList.add("opacity-0");
        }, 500);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const BackwardButton = () => {
    return (
      <button
        onClick={() => {
          window.history.back();
        }}
      >
        <ChevronLeftIcon className="h-5 w-5 ml-1" />
      </button>
    );
  };
  return (
    <div className="min-h-full flex flex-col">
      <LoadingModal isVisible={loading} />

      <ReferralTermsModal
        isVisible={referralTermsVisible}
        closeModal={() => setReferralTermsVisible(false)}
      />

      {!loading && (
        <div
          className={`${
            referrals?.length == 0 &&
            "w-full px-6 pt-10 pb-6 flex flex-col justify-center items-start relative"
          }`}
        >
          {/* Header, checks if user has referrals and show a different component according to that */}
          {referrals?.length > 0 ? (
            <div className="relative px-8 pt-10 pb-16 text-white bg-blue-dark-kiwi">
              <BackwardButton />
              <div className="flex w-full h-[100px] mt-10">
                <div className="flex justify-between w-full content-center">
                  <div className="py-3 gap-2 flex flex-col">
                    <p className="text-white/60">Cantidad de ingresos</p>
                    <h3 className="font-medium text-4xl">
                      {referrals && loading === false ? (
                        `$${referralsPaid * REFERRAL_COMMISSION}`
                      ) : (
                        <div className="flex rounded-lg animate-pulse duration-300">
                          ...
                        </div>
                      )}
                    </h3>
                  </div>
                  <div className="py-3 gap-2 flex flex-col">
                    <p className="text-white/60"># de referidos</p>
                    <h3 className="font-medium text-4xl">
                      {referrals && loading === false ? (
                        referrals.length
                      ) : (
                        <div className="flex rounded-lg animate-pulse duration-300">
                          ...
                        </div>
                      )}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="absolute w-full -bottom-7 left-0 right-0 ml-0 mr-0 h-16  mx-auto items-center justify-center flex">
                <div className="flex p-1 gap-2 w-[90%] bg-white shadow-lg shadow-dark-kiwi/5 rounded-2xl">
                  <div
                    className={
                      selectedTab === "invitations"
                        ? `w-1/2 justify-center flex bg-blue-kiwi rounded-2xl px-4 md:px-9 py-4 cursor-pointer ease-in duration-300`
                        : `w-1/2 justify-center flex bg-white text-gray-500-kiwi rounded-2xl px-4 md:px-9 py-4 cursor-pointer ease-out duration-300`
                    }
                    onClick={() => setSelectedTab("invitations")}
                  >
                    Invitaciones
                  </div>
                  <div
                    className={
                      selectedTab !== "invitations"
                        ? `w-1/2 justify-center flex bg-blue-kiwi rounded-2xl px-4 md:px-9 py-4 cursor-pointer ease-in duration-300`
                        : `w-1/2 justify-center flex bg-white text-gray-500-kiwi rounded-2xl px-4 md:px-9 py-4 cursor-pointer ease-out duration-300`
                    }
                    onClick={() => {
                      if (loading === false) {
                        setSelectedTab("community");
                      }
                    }}
                  >
                    Comunidad
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <BackwardButton />
          )}

          {/* Content, invitations tab is selected by default. In case the user doesn't have any referrals they won't be able to change the tab */}
          <div
            className={`grow flex flex-col ${
              referrals?.length > 0 ? "px-6 pt-16" : "pt-10"
            }`}
          >
            {selectedTab === "invitations" ? (
              <Fragment>
                <div className="mb-2">
                  <h1 className="text-2xl font-semibold text-dark-kiwi mb-2">
                    ¡Invita a tus amigos y gana hasta $500 dólares!
                  </h1>
                  <p>
                    Inscribe a tus amigos agregando su teléfono para que sean
                    parte de Kiwi
                  </p>
                </div>

                <div className="flex flex-col">
                  <div className="relative w-full mt-4">
                    <div
                      className={`absolute top-1/2 left-4 -translate-y-1/2 ${
                        error ? "text-red-kiwi" : ""
                      }`}
                    >
                      +1
                    </div>
                    <PatternFormat
                      className={`w-full rounded-lg py-2 px-4 pl-10 ${
                        error
                          ? "border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50"
                          : "border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi"
                      }`}
                      format="(###) ### ####"
                      placeholder="(000) 000 0000"
                      mask=""
                      getInputRef={cellphoneInput}
                      onValueChange={onSetCellphone}
                      value={cellphone}
                    />
                  </div>
                  {error !== "" && (
                    <small className="text-red-kiwi inline-block text-xs w-full mt-2">
                      {error}
                    </small>
                  )}
                  <div className="relative">
                    <div
                      id="sent-invite"
                      className="transition-all ease-out pointer-events-none opacity-0 absolute -top-2 left-1/2 -translate-x-1/2 text-xs py-1 px-2 bg-blue-kiwi text-white rounded-md"
                    >
                      Invitación enviada!
                    </div>
                    <button
                      className="text-white rounded-lg my-6 p-3 w-full bg-blue-kiwi"
                      onClick={sendReferralEvent}
                    >
                      Enviar invitación
                    </button>
                  </div>
                  <div id="share-box">
                    <p className="mb-4">Comparte vía:</p>
                    <div className="flex gap-6">
                      <div className="relative">
                        <button
                          onClick={onCopyReferralCode}
                          className="flex flex-col items-center justify-center"
                        >
                          <span className="bg-blue-50-kiwi text-blue-kiwi p-2 rounded-full m-auto mb-2">
                            <LinkIcon className="h-6 w-6" />
                          </span>
                          <label className="text-center text-xs">
                            Copiar enlace
                          </label>
                        </button>
                        <div
                          id="copied"
                          className="transition-all ease-out pointer-events-none opacity-0 absolute -top-10 left-1/2 -translate-x-1/2 text-xs py-1 px-2 bg-blue-kiwi text-white rounded-md"
                        >
                          ¡Copiado!
                        </div>
                      </div>
                      <WhatsappShareButton
                        onClick={() => {
                          trackEvent({
                            place: "/share-referral-code/whatsapp",
                            event_type: "button",
                            pathname: location,
                            user_id: userState.user.id,
                            utm_data: utmParams,
                          });
                        }}
                        className="text-center"
                        title={REFERRAL_TEXT()}
                        url={
                          "https://www.kiwicredito.com?referral_code=" +
                          referralCode
                        }
                      >
                        <div className="text-center text-xs">
                          <img className="m-auto mb-2" src={WhatsappImage} />
                          <label>Whastapp</label>
                        </div>
                      </WhatsappShareButton>

                      <FacebookShareButton
                        onClick={() => {
                          trackEvent({
                            place: "/share-referral-code/facebook",
                            event_type: "button",
                            pathname: location,
                            user_id: userState.user.id,
                            utm_data: utmParams,
                          });
                        }}
                        className="text-center"
                        title={REFERRAL_TEXT()}
                        url={
                          "https://www.kiwicredito.com?referral_code=" +
                          referralCode
                        }
                      >
                        <div className="text-center text-xs">
                          <img className="m-auto mb-2" src={FacebookImage} />
                          <label>Facebook</label>
                        </div>
                      </FacebookShareButton>
                    </div>
                  </div>

                  <div className="mt-12 flex flex-col gap-6">
                    <h2 className="text-2xl font-semibold text-dark-kiwi">
                      ¿Cómo funciona?
                    </h2>

                    {REFERRAL_FEATURES.map((feature, index) => (
                      <div className="flex gap-4 items-start" key={index}>
                        <div className="rounded-full bg-blue-50-kiwi p-3">
                          <feature.icon className="h-6 w-6 text-blue-kiwi" />
                        </div>
                        <p>
                          <span className="font-semibold">
                            {feature.accent}
                          </span>{" "}
                          {feature.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="grow flex flex-col pb-5">
                  <div className="mt-5 text-gray-500-kiwi">
                    <small>
                      Al participar en nuestro programa de referidos, confirmo
                      que he leído y estoy de acuerdo con los{" "}
                      <span
                        className="text-blue-kiwi underline cursor-pointer"
                        onClick={() => setReferralTermsVisible(true)}
                      >
                        Términos y condiciones
                      </span>{" "}
                      del programa de referidos.
                    </small>
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="gap-6 flex flex-col">
                {referrals.map((user, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between"
                  >
                    <div className="flex gap-5">
                      <span className="w-10 h-10 p-2 bg-blue-50-kiwi rounded-full flex shrink-0 items-center justify-center">
                        <UserAddIcon className="text-blue-kiwi" />
                      </span>
                      <label
                        htmlFor={user.first_name}
                        className="flex text-lg font-medium text-dark-kiwi items-center"
                      >
                        {`${user.first_name} ${user.second_name}`}
                      </label>
                    </div>
                    {user.hasActiveLoan ? (
                      <label className="bg-green-50-kiwi text-sm text-green-kiwi px-3 py-1 rounded-3xl text-right ml-auto whitespace-nowrap">
                        Crédito activo
                      </label>
                    ) : (
                      <label className="bg-gray-100-kiwi text-sm text-gray-400-kiwi px-3 py-1 rounded-3xl text-right whitespace-nowrap">
                        Sin crédito
                      </label>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
