import http from "utils/http";

const createRating = async ({ score, description, rating_reason }) => {
  const response = await http.post("/rating", {
    score,
    description,
    rating_reason,
  });

  return response;
};

export default {
  createRating,
};
