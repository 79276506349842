import Button from "components/Button";
import BaseModal from "../BaseModal";
import PropTypes from "prop-types";
import dayjs from "dayjs";

export default function AlertClaimCadenceOffer({
  isVisible,
  logo_url,
  next_claim_date,
  merchant_name,
  closeModal,
}) {
  return (
    <BaseModal isVisible={isVisible} closeModal={closeModal}>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col items-center text-center gap-8">
          <img
            src={logo_url}
            className="w-[72px] h-[72px] bg-white rounded-full object-contain"
          />
          <div className="flex flex-col gap-6">
            <h2 className="text-slate-900 font-semibold text-xl">
              ¡Tu reclamo de cashback fue exitoso!
            </h2>
            <div>
              <p className="text-base font-normal text-gray-700-kiwi">
                Podrás reclamar nuevo cashback en {merchant_name} a partir de{" "}
                <span className="font-semibold">
                  {dayjs(next_claim_date)
                    .format("MMMM DD, YYYY")
                    .replace(/^\w/, (c) => c.toUpperCase())}
                  .
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-6">
          <Button
            onClick={closeModal}
            className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto w-full"
          >
            Entendido
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}

AlertClaimCadenceOffer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  logo_url: PropTypes.string.isRequired,
  next_claim_date: PropTypes.string.isRequired,
  merchant_name: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};
