import  { useState, createContext } from 'react'
import PropTypes from 'prop-types'
export const RouletteContext = createContext()

export const RouletteProvider = ({ children }) => {
    const initialState = {
        events: [],
        lastEvent: {
            name: '',
            start: '',
            end: '',
            winners: []
        }
    }

    const [state, setState] = useState(initialState)

    const resetState = () => {
        setState(initialState)
    }

    const providerValues = {
        initialState,
        state,
        setState,
        resetState
    }

    return (
        <RouletteContext.Provider value={providerValues}>
            {children}
        </RouletteContext.Provider>
    )
}

RouletteProvider.propTypes = {
    children: PropTypes.node.isRequired
}