import PropTypes from 'prop-types';

const ChartLineIcon = ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M4 4a1 1 0 0 1 .993.883L5 5v13h15a1 1 0 0 1 .117 1.993L20 20H4a1 1 0 0 1-.993-.883L3 19V5a1 1 0 0 1 1-1m16.194 2.818c.9 0 1.35 1.088.714 1.724l-5.57 5.57a1.1 1.1 0 0 1-1.555 0l-2.758-2.758-3.535 3.535a1 1 0 0 1-1.415-1.414l4.172-4.172a1.1 1.1 0 0 1 1.556 0l2.758 2.758 3.242-3.243h-.414a1 1 0 1 1 0-2z"
        />
    </svg>
);

ChartLineIcon.displayName = 'ChartLineIcon';

ChartLineIcon.propTypes = {
    className: PropTypes.string,
};

export default ChartLineIcon;
