import axios from 'axios';

export default async function getAddressFromLatLong(lat, lng) {
    const API_KEY_SANDBOX = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const url = `${process.env.REACT_APP_GOOGLE_MAPS_API_URL}?latlng=${parseFloat(lat)},${parseFloat(lng)}&key=${API_KEY_SANDBOX}`;
    try {
        const {data} = await axios.get(url);

        if (data.status === "OK") {
            return data.results[0];
        } else {
            return "ADDRESS_NOT_FOUND";
        }
    } catch (error) {
        console.error('Error fetching address:', error);
        return "ADDRESS_NOT_FOUND";
    }
};