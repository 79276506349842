import {useMemo} from 'react';
import {useCreditBuilderStatus} from './useCreditBuilderStatus';

export function useCreditBuilderBadgeText() {
    const {isCreditBuilderActive} = useCreditBuilderStatus();

    return useMemo(() => {
        if (isCreditBuilderActive) return 'Activo';
        return '';
    }, [isCreditBuilderActive]);
}
