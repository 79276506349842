import mixpanel from "utils/mixpanel";
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "assets/icons_v3/fonts";
import Menu from "templates/Menu";

import MoneySVG from "assets/icons_v3/money.svg";
import ChartLineSVG from "assets/icons_v3/chart_line.svg";
import TachometerSVG from "assets/icons_v3/tachometer.svg";

import { useCreditBuilderStatus } from "hooks/creditBuilder/useCreditBuilderStatus";
import {
  usePersonalLoanBadgeText,
  usePersonalLoanRedirect,
  usePersonalLoanStatus,
} from "hooks/personalLoan";
import { useCreditBuilderBadgeText } from "hooks/creditBuilder";

export default function Products() {
  const navigate = useNavigate();

  const { isPersonalLoanApply } = usePersonalLoanStatus();
  const { isCreditBuilderActive } = useCreditBuilderStatus();

  const personalLoanBadgeText = usePersonalLoanBadgeText();
  const crditBuilderBadgeText = useCreditBuilderBadgeText();

  const personalLoanRedirect = usePersonalLoanRedirect();

  const onCreditBuilderRedirect = () => {
    mixpanel.track("Credit Builder");
    navigate("/credit-builder");
  };

  const onCreditMonitoringRedirect = async () => {
    mixpanel.track("Credit Monitoring");
    navigate("/credit-monitoring");
  };

  return (
    <div className="w-full min-h-full flex flex-col px-6 pt-10 pb-[136px]">
      <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
        Explorar productos
      </h1>

      <div className="flex flex-col gap-6 mt-10">
        <div
          className="p-4 rounded-2xl border border-slate-200 cursor-pointer"
          onClick={personalLoanRedirect}
        >
          <div className="flex justify-between items-center mb-4">
            <img src={MoneySVG} alt="MoneySVG Kiwi" />
            {isPersonalLoanApply ? (
              <ArrowRightIcon className="w-4 h-4 text-regal-blue-kiwi" />
            ) : (
              <div className="font-medium shrink-0 grow-0 px-3 py-1 bg-regal-blue-kiwi rounded-2xl text-xs text-white flex items-center gap-1">
                {personalLoanBadgeText}{" "}
                <ArrowRightIcon className="w-4 h-4 text-white" />
              </div>
            )}
          </div>
          <div className="font-semibold text-sm mb-2">¿Necesitas dinero?</div>
          <div className="text-xs text-gray-600-kiwi">
            Puedes solicitar tu préstamo hasta de $3,000 ¡Ahora mismo!
          </div>
        </div>

        <div
          className="p-4 rounded-2xl border border-slate-200 cursor-pointer"
          onClick={onCreditBuilderRedirect}
        >
          <div className="flex justify-between items-center mb-4">
            <img src={ChartLineSVG} alt="ChartLineSVG Kiwi" />
            {isCreditBuilderActive ? (
              <div className="font-medium shrink-0 grow-0 px-3 py-1 bg-regal-blue-kiwi rounded-2xl text-xs text-white flex items-center gap-1">
                {crditBuilderBadgeText}{" "}
                <ArrowRightIcon className="w-4 h-4 text-white" />
              </div>
            ) : (
              <ArrowRightIcon className="w-4 h-4 text-regal-blue-kiwi" />
            )}
          </div>
          <div className="font-semibold text-sm mb-2">Credit Builder</div>
          <div className="text-xs text-gray-600-kiwi">
            Construye tu historial de crédito por tan solo $5/mes
          </div>
        </div>

        <div
          className="p-4 rounded-2xl border border-slate-200 cursor-pointer"
          onClick={onCreditMonitoringRedirect}
        >
          <div className="flex justify-between items-center mb-4">
            <img src={TachometerSVG} alt="TachometerSVG Kiwi" />
            <ArrowRightIcon className="w-4 h-4 text-regal-blue-kiwi" />
          </div>
          <div className="font-semibold text-sm mb-2">Credit Monitoring</div>
          <div className="text-xs text-gray-600-kiwi">
            Protégete contra el fraude y verifica tu reporte de crédito
          </div>
        </div>
      </div>

      <Menu selected="products" />
    </div>
  );
}
