import { cs } from "utils/cs";
import formatDate from "utils/formatDate";
import PropTypes from "prop-types";

export default function ItemTransactionHistory({
  transaction,
  showStatus = false,
  callback,
}) {
  const statusTransaction = [
    "earned",
    "withdrawn",
    "completed",
    "expired",
    "canceled",
  ];

  const offerTransaction = [
    {
      status: "earned",
      title: "Completado",
      class: "text-algae-kiwi-400",
    },
    {
      status: "withdrawn",
      title: "Retirado",
      class: "text-slate-700",
    },
    {
      status: "canceled",
      title: "Cancelado",
      class: "text-slate-700",
    },
    {
      status: "expired",
      title: "Expirado",
      class: "text-slate-700",
    },
    {
      status: "default",
      title: "---",
      class: "text-slate-700-kiwi",
    },
  ];

  const currentItemTransaction =
    offerTransaction.find(
      (item) =>
        statusTransaction.includes(item.status) &&
        item.status === transaction?.status,
    ) || offerTransaction.find((item) => item.status === "default");

  function validateAmount() {
    const amountResert =
      Number(transaction?.amount) === 0
        ? ""
        : Number(transaction?.amount) > 0
          ? "+"
          : "-";
    return `${amountResert}$${Math.abs(transaction?.amount).toFixed(2)}`;
  }

  return (
    <div
      role="button"
      className="flex items-center w-full justify-between gap-4"
      onClick={() => callback(transaction)}
    >
      <div className="flex items-center gap-4">
        <div className="flex justify-center items-center shrink-0 bg-slate-100-kiwi  h-10 w-10 rounded-full">
          <transaction.icon className="w-6 text-regal-blue-kiwi" />
        </div>
        <div className="grow flex flex-col w-full mr-4">
          <p className="text-md line-clamp-1">
            {transaction?.description ?? "---"}
          </p>
          <p className="text-sm text-gray-500-kiwi capitalize">
            {formatDate(transaction?.date, "MMMM DD, YYYY")}
          </p>
        </div>
      </div>
      <div className="text-right">
        <p
          className={cs([
            "text-slate-700-kiwi font-semibold",
            currentItemTransaction.class,
          ])}
        >
          {validateAmount()}
        </p>
        {showStatus && (
          <p className="text-slate-500-kiwi text-xs">
            {currentItemTransaction.title}
          </p>
        )}
      </div>
    </div>
  );
}
ItemTransactionHistory.propTypes = {
  transaction: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    description: PropTypes.string,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  showStatus: PropTypes.bool,
  callback: PropTypes.func,
};