import PropTypes from 'prop-types';

export default function PageTitle({className, children}) {
    return (
        <h1 className={`text-dark-kiwi font-semibold text-2xl ${className}`}>
            {children}
        </h1>
    );
}

PageTitle.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};
