import {
  bankPopular,
  bankScotiabank,
  bankSantander,
  bankCaribeFederal,
  bankFirstBank,
  bankCitibank,
  bankJetstream,
  bankOriental,
  bankPentagonFederal,
} from "assets/cashback/bank_logos";
import defaultBank from "assets/cashback/bank-default.svg";

const findLogoBank = (bankName) => {
  if (!bankName) {
    return defaultBank;
  }

  const bankLogoMap = {
    Popular: bankPopular,
    Santander: bankSantander,
    "Caribe Federal": bankCaribeFederal,
    "First Bank": bankFirstBank,
    Citibank: bankCitibank,
    Jetstream: bankJetstream,
    Oriental: bankOriental,
    "Pentagon Federal": bankPentagonFederal,
    Scotiabank: bankScotiabank,
  };
  const matchedBank = Object.keys(bankLogoMap).find((bankNameFound) => {
    const bankNameParts = bankName.split(" ");
    return bankNameParts.some((part) => bankNameFound.includes(part));
  });
  return matchedBank ? bankLogoMap[matchedBank] : defaultBank;
};

export default findLogoBank;
