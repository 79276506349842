import httpRewards from "utils/httpRewards";

const getWallet = async () => {
  const { data } = await httpRewards.get("/wallets/me");
  return data;
};

const createWallet = async () => {
  const { data } = await httpRewards.post("/wallets", {});
  return data;
};

const getOrCreateWallet = async () => {
  try {
    return await getWallet();
  } catch (error) {
    console.info("🚀 ~ getOrCreateWal ~ error:", error)
    return await createWallet();
  }
};

export { getWallet, createWallet, getOrCreateWallet };
