import  {Fragment, useContext, useEffect, useState} from 'react';

import SuccessSVG from 'assets/onboarding_v3/success.svg';
import WarningSVG from 'assets/onboarding_v3/warning.svg';
import {useNavigate, useParams} from 'react-router-dom';
import {UsersAPI} from 'api';
import LoadingModal from 'components/Modals/Loading';
import {AuthContext} from 'context/AuthContext';

export default function CombineAccountsSuccess() {
    const navigate = useNavigate();
    const {token} = useParams();
    const {resetState: resetAuthState} = useContext(AuthContext);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [showLoader, setShowLoader] = useState(true);
    const [validationError, setValidationError] = useState('');

    useEffect(() => {
        makeMergeAccount();
    }, []);

    const makeMergeAccount = async () => {
        try {
            const {
                data: {
                    data: {phoneNumber},
                },
            } = await UsersAPI.mergeAccount(token);

            resetAuthState();
            setPhoneNumber(phoneNumber);
        } catch (error) {
            const {
                response: {
                    data: {messages},
                },
            } = error;

            setValidationError(messages);
        } finally {
            setShowLoader(false);
        }
    };

    const onContinue = () => {
        navigate('/login');
    };

    return (
        <div className="w-full min-h-full flex flex-col justify-center text-center px-6 py-10">
            <LoadingModal isVisible={showLoader} />
            {!showLoader && (
                <Fragment>
                    {validationError ? (
                        <Fragment>
                            <div className="my-auto">
                                <img
                                    className="mx-auto mb-10"
                                    src={WarningSVG}
                                />
                                <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                                    No pudimos actualizar tu cuenta
                                </h1>
                                <p>
                                    El enlace para cambiar tu cuenta ya no es
                                    válido o ha expirado por seguridad. Por
                                    favor, solicita un nuevo enlace para
                                    continuar con el proceso.
                                </p>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="my-auto">
                                <img
                                    className="mx-auto mb-10"
                                    src={SuccessSVG}
                                />
                                <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                                    ¡Tu cuenta ha sido actualizada!
                                </h1>
                                <p>
                                    Tu número de celular ha sido cambiado
                                    exitosamente. Ahora toda la información y el
                                    historial de tu cuenta están vinculados a tu
                                    nuevo número:{' '}
                                    <span className="font-semibold">
                                        {phoneNumber}
                                    </span>
                                    .
                                </p>
                            </div>
                        </Fragment>
                    )}
                    <button
                        className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                        onClick={onContinue}>
                        Continuar
                    </button>
                </Fragment>
            )}
        </div>
    );
}
