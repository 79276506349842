import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { UserContext } from "context/UserContext";
import BackwardButton from "components/Button/Backward";

import Button from "components/Button";
import RepaymentTermsTemplate from "templates/RepaymentTerms";

export default function ActiveLoanLoansDisbursed() {
  const navigate = useNavigate();

  const { state: userState } = useContext(UserContext);
  const {
    products: {
      personal_loan: { loan },
    },
  } = userState;

  const onBackward = () => {
    navigate("/active-loan/disbursement");
  };

  const onContract = () => {
    navigate("/active-loan/loans-disbursed/contract");
  };

  return (
    <>
      <BackwardButton onBackward={onBackward} />
      <RepaymentTermsTemplate loan={loan} />

      <Button
        className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
        onClick={onContract}
      >
        Visualizar contrato
      </Button>
    </>
  );
}
