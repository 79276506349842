import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserContext";

import HistoryPayments from "templates/HistoryPayments";
import BackwardButton from "components/Button/Backward";

export default function KiwiPayActiveHistoryPayment() {
  const navigate = useNavigate();

  const { state: userState } = useContext(UserContext);
  const {
    products: { kiwi_pay },
  } = userState;

  const onBackward = () => {
    navigate("/kiwi-pay/active");
  };

  const onContinue = () => {
    navigate("/kiwi-pay/active/pay-fee");
  };

  return (
    <>
      <BackwardButton onBackward={onBackward} />
      <HistoryPayments loan={kiwi_pay.loan} onContinue={onContinue} />
    </>
  );
}
