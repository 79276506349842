import  { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

export default function Datepicker({ field, form }) {
    const dayInput = useRef()
    const [currentDate, setCurrentDate] = useState(field.value || new Date())

    /**
     * Si el input no trae valor, establecer la fecha actual como valor
     */
    useEffect(() => {
        if (field.value === '') form.setFieldValue(field.name, currentDate)
    }, [])

    /**
     * Listado de los días partiendo del mes seleccionado en currentDate
     */
    const DAYS = new Array(
        new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            0
        ).getDate()
    )
        .fill(1)
        .map((v, i) => v + i)

    /**
     * Listado de todos los meses desplegados en el input
     */
    const MONTHS = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ].map((month) => {
        /** Abreviar los meses cuando la pantalla sea menor 380px */
        if (window.innerWidth < 380) {
            return month.substring(0, 3)
        }

        return month
    })

    /**
     * Generar listado de los últimos 100 años apartir del actual
     */
    const YEARS = []
    const currentYear = new Date().getFullYear()
    for (let i = currentYear; i > currentYear - 100; i--) YEARS.push(i)

    const onChangeDate = (type, event) => {
        let newDate
        let changedData = Number(event.currentTarget.value)

        if (type === 'day') {
            newDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                changedData
            )
        } else if (type === 'month') {
            const selectedMonth = new Date(
                currentDate.getFullYear(),
                changedData,
                0
            )

            let maxDayInSelectedMonth = selectedMonth.getDate()
            let getMaxDay = Math.min(
                currentDate.getDate(),
                maxDayInSelectedMonth
            )
            newDate = new Date(
                currentDate.getFullYear(),
                changedData - 1,
                getMaxDay
            )
        } else if (type === 'year') {
            const selectedYear = new Date(
                changedData,
                currentDate.getMonth() + 1,
                0
            )
            let maxDayInSelectedMonth = selectedYear.getDate()
            let day = Math.min(currentDate.getDate(), maxDayInSelectedMonth)
            newDate = new Date(changedData, currentDate.getMonth(), day)
        }

        dayInput.current.value = newDate.getDate()

        setCurrentDate(newDate)
        form.setFieldValue(field.name, newDate)
    }

    return (
            <div className="flex gap-4">
                <div className="w-1/2">
                    <select
                        onChange={(event) => onChangeDate('month', event)}
                        defaultValue={currentDate.getMonth() + 1}
                        id="month"
                        className={`focus:ring-0 w-full rounded-lg py-2 px-4 border border-solid ${
                            form.errors[field.name] && form.touched[field.name]
                                ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                        }`}>
                        {MONTHS.map((month, index) => {
                            return (
                                <option key={month} value={index + 1}>
                                    {month}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <div className="w-1/4 min-w-[65px]">
                    <select
                        ref={dayInput}
                        onChange={(event) => onChangeDate('day', event)}
                        defaultValue={currentDate.getDate()}
                        id="day"
                        className={`focus:ring-0 w-full rounded-lg py-2 px-4 border border-solid ${
                            form.errors[field.name] && form.touched[field.name]
                                ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                        }`}>
                        {DAYS.map((day) => {
                            return (
                                <option key={day} value={day}>
                                    {day}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <div className="w-1/4 min-w-[85px]">
                    <select
                        onChange={(event) => onChangeDate('year', event)}
                        defaultValue={currentDate.getFullYear()}
                        id="year"
                        className={`focus:ring-0 w-full rounded-lg py-2 px-4 border border-solid ${
                            form.errors[field.name] && form.touched[field.name]
                                ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                        }`}>
                        {YEARS.map((year) => {
                            return <option key={year}>{year}</option>
                        })}
                    </select>
                </div>
            </div>
    )
}

Datepicker.propTypes = {
    field: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        name: PropTypes.string.isRequired
    }).isRequired,
    form: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        errors: PropTypes.object,
        touched: PropTypes.object
    }).isRequired
}