import EmptyAccountsSVG from "assets/empty_states/bank_accounts.svg";
import PrimaryButton from "components/Button/Primary";
import { useNavigate } from "react-router-dom";

const EmptyAccounts = () => {
  const navigate = useNavigate();

  const handleConnectAccount = () => {
    navigate("/bank-accounts/plaid", {
      state: {
        redirectPath: "/bank-accounts/success",
        redirectToBack: "/bank-accounts",
      },
    });
  };

  return (
    <div className="flex flex-col gap-2 my-auto">
      <div className="flex flex-col gap-8 items-center justify-center">
        <div className="flex flex-col gap-6 w-full">
          <img src={EmptyAccountsSVG} className="mx-auto" />
          <div className="flex flex-col gap-2 w-full text-center">
            <div className="font-semibold">
              Aún no tienes una cuenta vinculada
            </div>
            <div className="text-sm text-slate-500-kiwi">
              Conecta tu cuenta de banco para gestionar tus pagos sin
              complicaciones y acceder a más beneficios.
            </div>
          </div>
        </div>
        <PrimaryButton onClick={handleConnectAccount}>
          Conectar cuenta de banco
        </PrimaryButton>
      </div>
    </div>
  );
};

export default EmptyAccounts;
