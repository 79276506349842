import  { Fragment } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import BaseModal from './../BaseModal'
import Button from 'components/Button'
import NumberFormat from 'react-number-format'

const ChangeAmountPayModal = ({
    isVisible,
    closeModal,
    sendAmount,
    minAmount,
    maxAmount
}) => {
    const initialValues = {
        amount: ''
    }

    const onSubmit = (event) => {
        sendAmount(parseFloat(event.amount.replace('$', '').trim()))
        closeModal()
    }

    const validationSchema = Yup.object().shape({
        amount: Yup.string()
            .required('Este campo es requerido')
            .test(
                'min-amount',
                `El monto debe ser mayor a $${minAmount} `,
                (money) => {
                    if (money) {
                        let amount = Number(
                            money.replace(',', '').replace('$ ', '')
                        )

                        if (amount > minAmount) {
                            return true
                        }
                    }
                }
            )
            .test(
                'max-amount',
                `El monto debe ser igual o menor a $${maxAmount} `,
                (money) => {
                    if (money) {
                        let amount = Number(
                            money.replace(',', '').replace('$ ', '')
                        )

                        if (amount <= maxAmount) {
                            return true
                        }
                    }
                }
            )
    })

    return (
        <BaseModal
            title="Cambiar monto a pagar"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}>
                    {({ errors, touched }) => (
                        <Fragment>
                            <Form
                                className="flex flex-col grow"
                                autoComplete="off">
                                <div className="mb-auto mt-5">
                                    <label
                                        htmlFor="amount"
                                        className="inline-block text-sm mb-2">
                                        Ingrese el nuevo monto
                                    </label>
                                    <div className="mb-2">
                                        <Field name="amount" id="amount">
                                            {({ field, ...props }) => (
                                                <NumberFormat
                                                    id="amount"
                                                    {...field}
                                                    {...props}
                                                    thousandSeparator={true}
                                                    prefix={'$ '}
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    placeholder="$ 0.00"
                                                    className={`w-full rounded-lg py-2 px-4 border ${
                                                        errors.amount &&
                                                        touched.amount
                                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                            : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                                    }`}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="amount"
                                            component="small"
                                            className="text-red-kiwi inline-block text-xs w-full mt-2"
                                        />
                                    </div>
                                </div>

                                <Button
                                    className="bg-blue-kiwi text-white rounded-xl p-3 mt-5 text-base"
                                    type="submit">
                                    Cambiar
                                </Button>

                                <div
                                    onClick={closeModal}
                                    className="justify-center mt-6 mx-auto cursor-pointer inline-block text-blue-kiwi text-base">
                                    Cancelar
                                </div>
                            </Form>
                        </Fragment>
                    )}
                </Formik>
            </div>
        </BaseModal>
    )
}

ChangeAmountPayModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    sendAmount: PropTypes.func.isRequired,
    minAmount: PropTypes.number.isRequired,
    maxAmount: PropTypes.number.isRequired
}

export default ChangeAmountPayModal
