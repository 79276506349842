import { UserContext } from "context/UserContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRightUpIcon, InformationIcon } from "assets/icons_v3/fonts";
import MoneySVG from "assets/icons_v3/money.svg";
import formatDate from "utils/formatDate";
import ToggleProduct from "./ToggleProduct";
import moneyFormat from "utils/moneyFormat";
import AutopayCard from "components/AutopayCard";
import { useShowAutopay } from "hooks/useIsAutopaySuspended";


export default function HomeStatusPersonalLoan() {
  const navigate = useNavigate();
  const showAutopay = useShowAutopay();


  const { state: userState } = useContext(UserContext);
  const {
    products: {
      personal_loan: {
        loan: { daysPastDue, balance, nextPaymentDate, amountDue },
      },
    },
  } = userState;

  const isLoanDue = daysPastDue > 0;
  const isLoanPaid = balance === 0;

  const onSelect = () => {
    if (isLoanDue) {
      return navigate("/active-loan/pay-fee");
    }

    navigate("/active-loan/disbursement");
  };

  return (
    <ToggleProduct img={MoneySVG} title="Préstamo personal">
      <div className="p-4 cursor-pointer" onClick={onSelect}>
        <div className="flex items-start justify-between gap-4">
          <div>
            <div className="text-sm text-regal-blue-kiwi/50">Tu balance</div>
            <div className="font-semibold text-3xl text-regal-blue-kiwi">
              ${moneyFormat(balance)}
            </div>
          </div>

          <div className="font-medium shrink-0 grow-0 px-3 py-1 bg-regal-blue-kiwi rounded-2xl text-xs text-white flex items-center gap-1">
            Pagar <ArrowRightUpIcon className="w-4 h-4 text-white" />
          </div>
        </div>

        {!isLoanDue && !isLoanPaid && (
          <div className="text-xs text-regal-blue-kiwi/50 mt-4">
            Próxima fecha de pago:{" "}
            {formatDate(nextPaymentDate, "MMMM DD, YYYY")}
          </div>
        )}
      </div>

      <div className="-ml-[1px] -mb-[1px] w-[calc(100%+2px)]">
        {isLoanDue && (
          <div
            className="flex items-center font-semibold text-xs bg-orange-90-kiwi text-yellow-900-kiwi rounded-br-xl rounded-bl-xl relative py-2 px-4 z-10"
            onClick={onSelect}
          >
            <InformationIcon className="w-4 mr-2" />
            Días de mora: {daysPastDue} {daysPastDue > 1 ? "días" : "día"} | ${amountDue}
          </div>
        )}

        {showAutopay && (
          <AutopayCard
            className={`relative -z-0 rounded-tl-none rounded-tr-none ${isLoanDue ? "-mt-2 pt-4" : ""}`}
          />
        )}
      </div>
    </ToggleProduct>
  );
}
