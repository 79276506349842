import PropTypes from 'prop-types';

const BoxAll = ({ className, ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 8.83333C6.4205 8.83333 7.16667 9.5795 7.16667 10.5V13.8333C7.16667 14.7538 6.4205 15.5 5.5 15.5H2.16667C1.24619 15.5 0.5 14.7538 0.5 13.8333V10.5C0.5 9.5795 1.24619 8.83333 2.16667 8.83333H5.5ZM13.8333 8.83333C14.712 8.83333 15.4318 9.51321 15.4954 10.3756L15.5 10.5V13.8333C15.5 14.712 14.8201 15.4318 13.9577 15.4954L13.8333 15.5H10.5C9.62134 15.5 8.90153 14.8201 8.8379 13.9577L8.83333 13.8333V10.5C8.83333 9.62134 9.51321 8.90153 10.3756 8.8379L10.5 8.83333H13.8333ZM5.5 10.5H2.16667V13.8333H5.5V10.5ZM13.8333 10.5H10.5V13.8333H13.8333V10.5ZM13.8333 0.5C14.712 0.5 15.4318 1.1799 15.4954 2.04228L15.5 2.16667V5.5C15.5 6.37866 14.8201 7.09847 13.9577 7.1621L13.8333 7.16667H10.5C9.62134 7.16667 8.90153 6.48679 8.8379 5.62439L8.83333 5.5V2.16667C8.83333 1.28803 9.51321 0.568196 10.3756 0.504571L10.5 0.5H13.8333ZM5.5 0.5C6.37866 0.5 7.09847 1.1799 7.1621 2.04228L7.16667 2.16667V5.5C7.16667 6.37866 6.48679 7.09847 5.62439 7.1621L5.5 7.16667H2.16667C1.28803 7.16667 0.568196 6.48679 0.504571 5.62439L0.5 5.5V2.16667C0.5 1.28803 1.1799 0.568196 2.04228 0.504571L2.16667 0.5H5.5ZM13.8333 2.16667H10.5V5.5H13.8333V2.16667ZM5.5 2.16667H2.16667V5.5H5.5V2.16667Z"
      fill="#043960"
    />
  </svg>
);

BoxAll.propTypes = {
    className: PropTypes.string,
};

export default BoxAll;
