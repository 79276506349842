import PropTypes from 'prop-types';

const ForkKnife = ({ className, ...props }) => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3417 0.739166C13.6092 1.11368 13.5224 1.63414 13.1479 1.90165L11.3965 3.15265C10.8225 3.56267 10.6009 4.26365 10.7618 4.89304L13.5474 2.10742C13.8728 1.78198 14.4005 1.78198 14.7259 2.10742C15.0513 2.43286 15.0513 2.96049 14.7259 3.28593L11.9403 6.07155C12.5697 6.23246 13.2707 6.01089 13.6807 5.43685L14.9317 3.68545C15.1992 3.31093 15.7197 3.22419 16.0942 3.4917C16.4687 3.75921 16.5554 4.27967 16.2879 4.65417L15.0369 6.40558C14.0181 7.83192 12.1091 8.17983 10.6757 7.33617L9.22907 8.78275L13.2319 12.7857C13.5573 13.1111 13.5573 13.6387 13.2319 13.9642C12.9065 14.2896 12.3788 14.2896 12.0534 13.9642L8.05057 9.96133L4.11932 13.8925C3.79388 14.218 3.26625 14.218 2.94081 13.8925C2.61537 13.5671 2.61537 13.0394 2.94081 12.714L6.87207 8.78275L5.5716 7.48233L4.39308 8.66083C4.06765 8.98633 3.54001 8.98633 3.21457 8.66083L1.37788 6.82417C0.109714 5.55601 -0.204678 3.61862 0.59738 2.01451C0.754114 1.70103 0.933664 1.41808 1.31331 1.35647C1.57773 1.31357 1.84665 1.40038 2.03606 1.5898L6.15756 5.71129L6.16086 5.71457L8.05057 7.60425L9.49716 6.15767C8.65349 4.72431 9.0014 2.81526 10.4277 1.79642L12.1792 0.545425C12.5537 0.277917 13.0742 0.364658 13.3417 0.739166ZM1.82793 3.73869C1.78936 4.43397 2.04221 5.13149 2.55639 5.64567L3.80382 6.89308L4.39308 6.30384L1.82793 3.73869Z"
      fill="#043960"
    />
  </svg>
);

ForkKnife.propTypes = {
    className: PropTypes.string,
};

export default ForkKnife;