import PropTypes from "prop-types";

const ContentTab = ({ selectedTab, tabs }) => {
  const selectedContent = tabs.find((t) => t.key === selectedTab);

  if (!selectedContent) {
    return null;
  }

  return <div>{selectedContent.content}</div>;
};

ContentTab.propTypes = {
  selectedTab: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      content: PropTypes.node,
    }),
  ),
};
export default ContentTab;
