import BaseModal from './../BaseModal'
import WomanHappyMoneyImg from 'assets/women_happy_money.svg'
import {
    ShareIcon,
    AtSymbolIcon,
    CurrencyDollarIcon
} from '@heroicons/react/outline'
import Advisement from 'components/Advisement'
import { REFERRAL_AMOUNT } from 'constants'
import PropTypes from 'prop-types';

const CuponModal = ({ isVisible, closeModal, acceptModal }) => {
    return (
        <BaseModal isVisible={isVisible} closeModal={closeModal} title=" ">
            <div
                className={`w-full flex flex-col justify-center items-center relative bg-white rounded-tr-xl rounded-tl-xl h-fit`}>
                <div className="text-center">
                    <img
                        src={WomanHappyMoneyImg}
                        alt="Bono image"
                        className="w-32 mb-6 mx-auto"
                    />
                    <h4 className="text-xl font-bold text-slate-900-kiwi mb-2">
                        Comparte tu historial y gana
                    </h4>
                    <p className="text-5xl font-bold text-slate-900-kiwi mb-6">
                        ${REFERRAL_AMOUNT}
                    </p>
                </div>

                <div className="text-sm max-w-md flex flex-col gap-6">
                    <div className="inline-flex items-start gap-6 w-full">
                        <div className="rounded-full bg-slate-100 w-10 h-10 shrink-0 flex items-center justify-center">
                            <ShareIcon className="w-6 text-regal-blue-kiwi" />
                        </div>
                        <p className="text-gray-700-kiwi">
                            <strong className="font-semibold">
                                Para la story puedes subir la foto que desees,
                                solo asegúrate de que sea cool 😎
                            </strong>
                            Por ejemplo: una foto de: la playa 🌴, paisaje
                            favorito 🏞️, almuerzo 🍔, o hasta de tu mascota 🐶
                        </p>
                    </div>
                    <div className="inline-flex items-start gap-6 w-full">
                        <div className="rounded-full bg-slate-100 w-10 h-10 shrink-0 flex items-center justify-center">
                            <AtSymbolIcon className="w-6 text-regal-blue-kiwi" />
                        </div>
                        <p className="text-gray-700-kiwi">
                            <strong className="font-semibold">
                                {' '}
                                Menciona @Kiwicredito
                            </strong>{' '}
                            y usa nuestro{' '}
                            <strong className="font-semibold">
                                #KiwiResuelve
                            </strong>
                        </p>
                    </div>
                    <div className="inline-flex items-start gap-6 w-full">
                        <div className="rounded-full bg-slate-100 w-10 h-10 shrink-0 flex items-center justify-center">
                            <CurrencyDollarIcon className="w-6 text-regal-blue-kiwi" />
                        </div>
                        <p className="text-gray-700-kiwi">
                            <strong className="font-semibold">
                                Recibe tus ${REFERRAL_AMOUNT}
                            </strong>{' '}
                            una vez que hayamos verificado tu historia
                        </p>
                    </div>
                </div>

                <Advisement
                    icon="locked_user"
                    className="mt-10 text-slate-900-kiwi">
                    <strong className="">Si tu cuenta es privada</strong>,
                    envíanos un screenshot por Mensaje Directo para verificar tu
                    participación
                </Advisement>

                <button
                    onClick={acceptModal}
                    className="rounded-2xl p-3 mt-10 bg-blue-kiwi text-white w-full">
                    Compartir ahora
                </button>
            </div>
        </BaseModal>
    )
}

CuponModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    acceptModal: PropTypes.func.isRequired,
};

export default CuponModal
