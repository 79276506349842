import CurrencyDollarSVG from "assets/icons_v3/currency_dollar.svg";
import { RightIcon } from "assets/icons_v3/fonts";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "context/UserContext";
import PropTypes from "prop-types";
import mixpanel from "utils/mixpanel";

const AutopayCard = ({ className, goBack }) => {
  const navigate = useNavigate();
  const { state: userState } = useContext(UserContext);
  const {
    products: {
      personal_loan: {
        loan: { balance },
      },
    },
  } = userState;

  const handleReactiveAutopay = () => {
    mixpanel.track("Reactivate Autopay", {
      Balance: balance,
    });

    navigate("/autopay", {
      state: {
        goBack,
      },
    });
  };

  return (
    <div
      onClick={handleReactiveAutopay}
      className={`flex items-center gap-4 px-4 py-3 bg-orange-50-kiwi rounded-xl ${className}`}
    >
      <img src={CurrencyDollarSVG} />
      <p className="text-dark-kiwi text-sm">
        <span className="font-semibold ">Reactiva tus pagos automáticos</span> y
        facilita tus pagos a tiempo.
      </p>
      <RightIcon className="text-orange-900-kiwi w-6 h-6 shrink-0" />
    </div>
  );
};

AutopayCard.propTypes = {
  className: PropTypes.string,
  goBack: PropTypes.string,
};

export default AutopayCard;
