import {KIWI_PAY_STATUS} from 'constants';
import {UserContext} from 'context/UserContext';
import {useContext, useMemo} from 'react';

export function useKiwiPayStatus() {
    const {state: userState} = useContext(UserContext);
    const {
        products: {
            kiwi_pay: {status},
        },
    } = userState;

    return useMemo(() => {
        const isKiwiPayActive = status === KIWI_PAY_STATUS.current;

        return {
            isKiwiPayActive,
        };
    }, [status]);
}
