import { useEffect } from "react";

const useUrlParams = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const obj = {};

    if (params.toString() === "") {
      const storedParams = sessionStorage.getItem("urlParams");
      if (storedParams) return;
    }

    params.forEach((value, key) => {
      obj[key] = value;
    });

    obj.full_url_landing = `${process.env.REACT_APP_URL_LANDING_PAGE}/${window.location.search}`;

    if (Object.keys(obj).length > 0) {
      sessionStorage.setItem("urlParams", JSON.stringify(obj));
    }
  }, []);
};

export default useUrlParams;
