import {useState, useEffect, createContext} from 'react';
import PropTypes from 'prop-types';

export const CashbackContext = createContext();

export const CashbackProvider = ({children}) => {
    const initialState = {
        selectedOffer: null,
        cashback_id: "",
        user_current_location:{
            lat:'',
            lng:'',
        },
        address: {
            value: '',
            completed: false,
        },
        date: {
            value: '',
            completed: false,
        },
        totalAmount: {
            value: '',
            completed: false,
        },
        balance: {},
        transactions: {},
        offers: [],
        payment_method:{
            bank_name:"Test Bank",
            account_number:"123456789",
            routing_number:"123456789",
        }
    };

    const [state, setState] = useState(() => {
        const state = JSON.parse(sessionStorage.getItem('CashbackContext'));
        if (state === null) {
            return initialState;
        }

        return state;
    });

    useEffect(() => {
        sessionStorage.setItem('CashbackContext', JSON.stringify(state));
    }, [state]);

    const resetState = () => {
        sessionStorage.removeItem('CashbackContext');
        setState(initialState);
    };

    const providerValues = {
        /** State */
        state,
        setState,
        resetState,

        /** Handlers */
    };

    return (
        <CashbackContext.Provider value={providerValues}>
            {children}
        </CashbackContext.Provider>
    );
};

CashbackProvider.propTypes = {
    children: PropTypes.node.isRequired,
};