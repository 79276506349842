import {UserContext} from 'context/UserContext';
import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {ArrowRightIcon} from 'assets/icons_v3/fonts';
import dayjs from 'dayjs';
import mixpanel from "utils/mixpanel";

export default function HomeStatusRejected() {
    const MAX_DAYS_DATE = 120;
    const navigate = useNavigate();
    const {state: userState} = useContext(UserContext);
    const {
        products: {
            personal_loan: {rejected_application},
        },
    } = userState;

    const onContinue = () => {
        mixpanel.track("Loan rejected");
        navigate('/rejected-loan');
    };
    

    return (
        <div className="p-4 rounded-xl bg-slate-100-kiwi flex flex-col">
            <h3 className="font-semibold text-dark-kiwi mb-1">
                Tu solicitud no ha sido aprobada
            </h3>
            <p className="text-xs mb-4 text-slate-700-kiwi">
                Lo sentimos, tu solicitud de préstamo no ha sido aprobada,
                puedes solicitar nuevamente a partir de{' '}
                <span className="font-semibold">
                    {dayjs(rejected_application.created_at)
                        .add(MAX_DAYS_DATE, 'day')
                        .format('MMMM DD, YYYY')}
                </span>
            </p>
            <div
                onClick={onContinue}
                className="rounded-xl px-4 py-1 bg-regal-blue-kiwi text-xs text-white font-medium flex justify-center items-center cursor-pointer">
                Mas detalles <ArrowRightIcon className="w-5 ml-1" />
            </div>
        </div>
    );
}
