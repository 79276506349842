import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserContext";
import ChatSVG from "assets/onboarding_v3/chat.svg";
import BackwardButton from "components/Button/Backward";
import PrimaryButton from "components/Button/Primary";

export default function LiveChat() {
  const navigate = useNavigate();
  const {
    state: { user, zendesk },
    createTokenGpt,
    updateUserZendesk,
  } = useContext(UserContext);

  useEffect(() => {
    if (!zendesk?.token) createToken();
  }, []);

  useEffect(() => {
    if (zendesk && zendesk.token) startChatLive();
  }, [zendesk]);

  const createToken = async () => {
    await createTokenGpt({
      email: user.email,
      name: `${user.first_name} ${user.second_name} ${user.first_surname} ${user.second_surname}`,
    });
  };

  const updateUser = async () => {
    await updateUserZendesk({
      external_id: zendesk.external_id,
      phone_number: user.phone_number,
    });
  };

  const startChatLive = async () => {
    zE("messenger", "show");

    zE("messenger", "loginUser", function (callback) {
      callback(zendesk.token);
    });

    zE("messenger:on", "open", async function () {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      updateUser();
    });

    zE("messenger:on", "close", function () {
      onBackward();
    });
  };

  const onBackward = () => {
    zE("messenger", "hide");
    navigate("/home");
  };

  return (
    <div className="w-full min-h-full flex flex-col px-6 py-10">
      <BackwardButton onBackward={onBackward} />

      <div className="m-auto flex flex-col justify-center h-full text-center">
        <img className="mx-auto mb-8" src={ChatSVG} alt="Chat kiwi" />
        <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
          Live Chat
        </h1>
        <p>
          ¡Bienvenido a nuestro canal de ayuda! Por favor, escríbenos en la
          ventana de la derecha y cuéntanos cómo podemos ayudarte.
        </p>
      </div>

      <PrimaryButton
        className="rounded-xl p-3 mt-10 bg-blue-kiwi text-white"
        onClick={onBackward}
      >
        Volver
      </PrimaryButton>
    </div>
  );
}
