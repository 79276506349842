import Button from "components/Button";
import BaseModal from "../BaseModal";
import CancelClaim from "assets/cashback/cancel_claim.svg";
import PropTypes from "prop-types";

export default function CancelClaimOffer({ isOpen, onClose, cancelOffer }) {
  return (
    <BaseModal isVisible={isOpen} closeModal={onClose}>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col items-center text-center gap-8">
          <img src={CancelClaim} className="w-44" />
          <div className="flex flex-col gap-6">
            <h2 className="text-slate-900 font-semibold text-xl">
              ¿Estás seguro que deseas cancelar tu reclamo?
            </h2>
            <p>Al cancelar, perderás la oportunidad de obtener tu cashback.</p>
          </div>
        </div>
        <div className="w-full flex flex-col gap-6">
          <Button
            onClick={cancelOffer}
            className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto w-full"
          >
            Sí, cancelar reclamo
          </Button>
          <Button
            onClick={onClose}
            className="cursor-pointer !bg-white !text-blue-kiwi w-full  rounded-xl"
          >
            No, volver
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}

CancelClaimOffer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  cancelOffer: PropTypes.func,
};
