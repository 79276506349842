import './style.css'
import PropTypes from 'prop-types';
import  {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react'
import BaseModal from '../Modal'
import Calendar from 'react-calendar'
import { LeftIcon, RightIcon } from 'assets/icons_v3/fonts'
import dayjs from 'dayjs'
const isBetween = require('dayjs/plugin/isBetween')
import STATE_CONSTANTS from 'constants/states'
import { UserContext } from 'context/UserContext'
import Button from 'components/Button'
import { STATES_AVAILABLES } from 'constants'
import dateDifference from 'utils/dateDifference'
import capitalizeText from 'utils/capitalizeText'

dayjs.extend(isBetween)

const FirstPaymentDayModal = forwardRef(
    ({ onSelectDate, selectedPaymentDate }, ref) => {
        const { state: userState } = useContext(UserContext)

        const BaseModalRef = useRef()
        const currentDate = dayjs()

        const [selectedDate, setSelectedDate] = useState(null)
        const [startPaymentDate, setStartPaymentDay] = useState(null)
        const [endPaymentDate, setEndPaymentDay] = useState(null)
        const [lastDayChecked, setLastDayChecked] = useState(false)
        const [showLastDay, setShowLastDay] = useState(false)

        const { daysOptionsList, lastDayOption } = STATE_CONSTANTS(
            userState.user.state
        ).approved

        const DATE_THRESHOLD_15 = 15
        const DATE_THRESHOLD_45 = 45
        const USER_DAY_LIST =
            userState.user.uw_details?.default_payment_dates ?? []
        const USER_HAS_DAY_LIST =
            USER_DAY_LIST &&
            USER_DAY_LIST.length > 0 &&
            userState.user.state !== STATES_AVAILABLES.florida

        useEffect(() => {
            const paymentDaysRange = createPaymentDaysRange()
            const formattedPaymentDaysRange =
                formatPaymentDaysRange(paymentDaysRange)

            setInitialValues(formattedPaymentDaysRange)
        }, [])

        const createPaymentDaysRange = () => {
            let daysRange = daysOptionsList

            if (USER_HAS_DAY_LIST) {
                daysRange = USER_DAY_LIST

                if (USER_DAY_LIST.length === 1) {
                    const day = USER_DAY_LIST[0]
                    daysRange = [day - 1, day, day + 1, day + 2]
                }
            }

            return daysRange
        }

        const formatPaymentDaysRange = (days) => {
            if (USER_HAS_DAY_LIST) {
                return formatUserPaymentDays(days)
            }

            return formatDefaultPaymentDays(days)
        }

        const formatDefaultPaymentDays = (days) => {
            return days
                .map((day) => currentDate.startOf('day').add(day, 'day'))
                .filter((date) => date.get('date') < (lastDayOption ? 28 : 31))
        }

        const formatUserPaymentDays = (days) => {
            return days.map((day) => {
                const dayItem = currentDate.startOf('day').date(day)
                const dayPlusOneMonth = dayItem.add(1, 'month')
                const dayPlusTwoMonths = dayItem.add(2, 'month')
                const daysDiffOneMonth = dateDifference(
                    dayPlusOneMonth,
                    currentDate
                )

                if (daysDiffOneMonth < DATE_THRESHOLD_15) {
                    return dayPlusTwoMonths
                }

                if (daysDiffOneMonth > DATE_THRESHOLD_45) {
                    return dayItem
                }

                return dayPlusOneMonth
            })
        }

        const setInitialValues = (days) => {
            const startDate = days[0]
            const endDate = days[days.length - 1]

            setStartPaymentDay(startDate)
            setEndPaymentDay(endDate)
            setInitialSelectedDate(startDate)
            setLastDayVisible()
        }

        const setLastDayVisible = () => {
            if (USER_HAS_DAY_LIST) {
                return setShowLastDay(false)
            }

            setShowLastDay(lastDayOption)
        }

        const setInitialSelectedDate = (startDate) => {
            if (selectedPaymentDate) {
                const initialDate = dayjs(selectedPaymentDate).toDate()

                setSelectedDate(initialDate)
                onSelectDate(initialDate)
            } else {
                setSelectedDate(startDate.toDate())
            }
        }

        const disabledDays = ({ date }) => {
            const calendarDate = dayjs(date)
            return !calendarDate.isBetween(
                startPaymentDate.add(-1, 'day'),
                endPaymentDate.add(1, 'day'),
                'day'
            )
        }

        const onSelect = () => {
            onSelectDate(selectedDate, lastDayChecked)
            BaseModalRef.current.closeModal()
        }

        const onChangeLastDay = (e) => {
            const checked = e.target.checked

            setLastDayChecked(checked)

            if (!checked) {
                return setSelectedDate(startPaymentDate.toDate())
            }

            let lastDayCurrentMonth = currentDate.endOf('month')
            const dayDiffLastDay = dateDifference(
                lastDayCurrentMonth,
                currentDate
            )

            if (dayDiffLastDay < DATE_THRESHOLD_15) {
                const dayPlusMonth = lastDayCurrentMonth.add(1, 'month')
                lastDayCurrentMonth = dayPlusMonth.endOf('month')
            }

            setSelectedDate(lastDayCurrentMonth.toDate())
        }

        useImperativeHandle(ref, () => ({
            ...BaseModalRef.current
        }))

        return (
            <BaseModal showDetails={false} ref={BaseModalRef}>
                <h2 className="text-center text-dark-kiwi font-semibold text-xl mb-6">
                    Seleccionar el primer día <br /> de pago
                </h2>

                <Calendar
                    formatMonthYear={(formatter, date) => {
                        const selectedDate = dayjs(date)
                        const month = selectedDate.format('MMMM')
                        const year = selectedDate.format('YYYY')
                        return capitalizeText(month) + ' de ' + year
                    }}
                    onChange={(date) => {
                        setLastDayChecked(false)
                        setSelectedDate(date)
                    }}
                    value={selectedDate}
                    nextLabel={<RightIcon />}
                    prevLabel={<LeftIcon />}
                    tileDisabled={disabledDays}
                />

                {showLastDay && (
                    <div className="mt-4 flex items-center gap-4 pl-4">
                        <label
                            className="relative flex items-center"
                            htmlFor="lastDayMonth">
                            <input
                                type="checkbox"
                                className="peer cursor-pointer w-6 h-6 rounded-md border border-gray-400-kiwi bg-gray-100-kiwi checked:bg-regal-blue-kiwi checked:hover:bg-regal-blue-kiwi checked:focus:bg-regal-blue-kiwi"
                                id="lastDayMonth"
                                onChange={onChangeLastDay}
                                checked={lastDayChecked === true}
                            />
                        </label>
                        <label
                            className="text-sm text-slate-600-kiwi"
                            htmlFor="lastDayMonth">
                            Último día del mes
                        </label>
                    </div>
                )}

                <Button
                    disabled={!selectedDate}
                    onClick={onSelect}
                    className={`rounded-xl p-3 w-full mt-6 ${
                        selectedDate
                            ? 'bg-blue-kiwi text-white'
                            : 'bg-gray-200-kiwi text-gray-500-kiwi'
                    }`}>
                    Seleccionar fecha
                </Button>
            </BaseModal>
        )
    }
)

FirstPaymentDayModal.displayName = 'FirstPaymentDay';

FirstPaymentDayModal.propTypes = {
  onSelectDate: PropTypes.func.isRequired,
  selectedPaymentDate: PropTypes.instanceOf(Date).isRequired,
};

export default FirstPaymentDayModal
