import LoadingModal from 'components/Modals/Loading'
import { CreditMonitoringContext } from 'context/CreditMonitoringContext'
import { UserContext } from 'context/UserContext'
import mixpanel from 'utils/mixpanel'

import  { useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { PERSONAL_LOAN_STATUS, CREDIT_BUILDER_STATUS } from 'constants'
import CreditMonitoringDisabled from './Disabled'

export default function CreditMonitoring() {
    const navigate = useNavigate()
    const {
        setState: setCreditMonitoringState,
        addCreditMonitoring,
        getCreditMonitoring,
        updateCreditMonitoring,
        refreshUserToken
    } = useContext(CreditMonitoringContext)
    const { state: userState } = useContext(UserContext)
    const {
        user,
        products: {
            personal_loan: { status: personalLoanStatus },
            credit_builder: { status: creditBuilderStatus }
        }
    } = userState

    const [showLoader, setShowLoader] = useState(false)

    const isPersonalLoanActive =
        personalLoanStatus === PERSONAL_LOAN_STATUS.current

    const isCreditBuilderActive =
        creditBuilderStatus === CREDIT_BUILDER_STATUS.current

    const isCreditMonitoringActive =
        isPersonalLoanActive || isCreditBuilderActive

    useEffect(() => {
        if (isCreditMonitoringActive) {
            initCreditMonitoring()

            window.document.addEventListener(
                'embed-credit-monitoring',
                arrayEventListener,
                false
            )
        }
    }, [])

    const arrayEventListener = (arrayEvent) => {
        const { metadata = {}, event } = arrayEvent.detail

        switch (event) {
            case 'logout':
                refreshToken()
                break
            case 'signup':
                if (metadata?.userToken && metadata?.userId) {
                    addUserData(metadata?.userToken, metadata?.userId)
                    mixpanel.track('Credit Monitoring Register Completed')
                }
                break
            case 'loaded':
                setShowLoader(false)
                break
        }
    }

    const initCreditMonitoring = async () => {
        setShowLoader(true)

        const {
            data: { data }
        } = await getCreditMonitoring(user.id)

        if (data?.credit_monitoring_user?.user_token) {
            const {
                credit_monitoring_user: { user_token, client_key }
            } = data

            await updateCreditMonitoringState({
                userToken: user_token,
                clientKey: client_key
            })

            mixpanel.track('Credit Monitoring Report')
            navigate('/credit-monitoring/report')
        } else {
            mixpanel.track('Credit Monitoring Register Start')
            navigate('/credit-monitoring/register')
        }
    }

    const addUserData = async (userToken, clientKey) => {
        setShowLoader(true)

        const response = await addCreditMonitoring({
            clientKey,
            userToken,
            userId: user.id
        })

        const {
            data: { success, data }
        } = response

        if (success) {
            updateCreditMonitoringState({
                userToken: data?.credit_monitoring?.user_token,
                clientKey: data?.credit_monitoring?.client_key
            })

            setShowLoader(false)
            navigate('/credit-monitoring/report')
        } else {
            setShowLoader(false)
            navigate('/home')
        }
    }

    const refreshToken = async () => {
        setShowLoader(true)

        try {
            const {
                data: { data }
            } = await refreshUserToken({
                userId: user.id
            })

            if (data?.userToken) {
                const { userToken } = data

                await updateCreditMonitoring({
                    userToken,
                    userId: user.id
                })

                updateCreditMonitoringState({
                    userToken
                })

                setShowLoader(false)
            } else {
                setShowLoader(false)
                navigate('/home')
            }
        } catch (error) {
            console.error(error)
            navigate('/home')
        }
    }

    const updateCreditMonitoringState = async ({ userToken, clientKey }) => {
        await setCreditMonitoringState((prev) => ({
            ...prev,
            arrayData: {
                ...prev.arrayData,
                userToken:
                    userToken !== undefined
                        ? userToken
                        : prev.arrayData.userToken,
                clientKey:
                    clientKey !== undefined
                        ? clientKey
                        : prev.arrayData.clientKey
            }
        }))
    }

    return (
        <div className="flex flex-col w-full min-h-full">
            <LoadingModal isVisible={showLoader} />

            {isCreditMonitoringActive ? (
                <Outlet />
            ) : (
                <CreditMonitoringDisabled />
            )}
        </div>
    )
}
