import React from 'react';
import MovePaymentDateTemplate from 'templates/MovePaymentDate';
import {useNavigate} from 'react-router-dom';

export default function KiwiPayActiveMovePaymentDay() {
    const navigate = useNavigate();

    const onBackward = () => {
        navigate('/kiwi-pay/active');
    };

    const onContinue = () => {
        navigate('/kiwi-pay/active');
    };

    return (
        <MovePaymentDateTemplate
            onBackward={onBackward}
            onContinue={onContinue}
            product="kiwi_pay"
        />
    );
}
