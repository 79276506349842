import PropTypes from "prop-types";

const MedalIcon = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.42 2a2 2 0 0 1 1.736 1.008L12 4.484l.844-1.476A2 2 0 0 1 14.58 2h4.558a1.5 1.5 0 0 1 1.303 2.244l-3.382 5.918a7 7 0 1 1-10.118 0L3.559 4.244A1.5 1.5 0 0 1 4.862 2zM12 10a5 5 0 1 0 0 10 5 5 0 0 0 0-10m0 3a2 2 0 1 1 0 4 2 2 0 0 1 0-4m6.277-9H14.58l-1.428 2.5 1.06 1.857c.44.146.86.335 1.255.56zM9.42 4H5.723l2.81 4.917a6.961 6.961 0 0 1 3.176-.911z"
    />
  </svg>
);

MedalIcon.displayName = "MedalIcon";

MedalIcon.propTypes = {
  className: PropTypes.string,
};

export default MedalIcon;
