
import {
    CamcorderIcon,
    CheckCircleIcon,
    TargetIcon
} from 'assets/icons_v3/fonts'

export default function RouletteHowWorksComponent() {
    return (
        <div className="flex flex-col gap-4">
            <div className="flex items-center gap-6 text-sm">
                <CamcorderIcon className="w-6 h-6 shrink-0" />
                <div>
                    <span className="font-semibold">
                        Estar en el live de cada jueves
                    </span>{' '}
                    y participar en las dinámicas en vivo.
                </div>
            </div>
            <div className="flex items-center gap-6 text-sm">
                <CheckCircleIcon className="w-6 h-6 shrink-0" />
                <div>
                    <span className="font-semibold">
                        Responde correctamente las preguntas
                    </span>{' '}
                    y ser el primero en hacerlo.
                </div>
            </div>
            <div className="flex items-center gap-6 text-sm">
                <TargetIcon className="w-6 h-6 shrink-0" />
                <div>
                    <span className="font-semibold">Gira la ruleta</span> y
                    espera con emoción un gran premio.
                </div>
            </div>
        </div>
    )
}
