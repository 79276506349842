import { Fragment } from 'react'
import PropTypes from 'prop-types'

import SuccessSVG from 'assets/onboarding_v3/success.svg'

export default function AddCreditCardSuccess({ accept }) {
    return (
        <Fragment>
            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img className="mx-auto mb-8" width="218" src={SuccessSVG} />
                <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                    Verificación exitosa
                </h1>
                <p>
                    Tu nueva tarjeta agregada está lista para usar. Recuerda que
                    el monto será reembolsado a tu cuenta
                </p>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={accept}>
                Continuar
            </button>
        </Fragment>
    )
}

AddCreditCardSuccess.propTypes = {
    accept: PropTypes.func.isRequired,
}
