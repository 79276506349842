import PropTypes from "prop-types";

const UserAdd2Line = ({ className, ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0C6.23858 0 4 2.23858 4 5C4 7.76142 6.23858 10 9 10C11.7614 10 14 7.76142 14 5C14 2.23858 11.7614 0 9 0ZM6 5C6 3.34315 7.34315 2 9 2C10.6569 2 12 3.34315 12 5C12 6.65685 10.6569 8 9 8C7.34315 8 6 6.65685 6 5ZM2 16.5C2 16.3206 2.08967 16.0111 2.41339 15.6013C2.72913 15.2015 3.21714 14.7735 3.86402 14.3788C5.15705 13.5898 6.97719 13 9 13C9.3754 13 9.7444 13.0203 10.1048 13.0586C10.654 13.117 11.1465 12.7191 11.2048 12.1699C11.2632 11.6207 10.8653 11.1282 10.3161 11.0698C9.887 11.0242 9.4475 11 9 11C6.60453 11 4.42467 11.6937 2.82227 12.6715C2.02143 13.1602 1.33788 13.7363 0.84397 14.3615C0.35805 14.9767 0 15.713 0 16.5C0 17.3449 0.41114 18.0111 1.00266 18.4859C1.56324 18.9359 2.30182 19.2336 3.08716 19.4416C4.66466 19.8593 6.77101 20 9 20L9.6854 19.9953C10.2376 19.9877 10.679 19.5338 10.6714 18.9815C10.6637 18.4293 10.2098 17.9879 9.6576 17.9955L9 18C6.81071 18 4.91706 17.8572 3.59914 17.5082C2.93676 17.3328 2.50376 17.1262 2.2547 16.9263C2.03657 16.7512 2 16.6216 2 16.5ZM16 12C16.5523 12 17 12.4477 17 13V15H19C19.5523 15 20 15.4477 20 16C20 16.5523 19.5523 17 19 17H17V19C17 19.5523 16.5523 20 16 20C15.4477 20 15 19.5523 15 19V17H13C12.4477 17 12 16.5523 12 16C12 15.4477 12.4477 15 13 15H15V13C15 12.4477 15.4477 12 16 12Z"
      fill="currentColor"
    />
  </svg>
);

UserAdd2Line.propTypes = {
  className: PropTypes.string,
};

export default UserAdd2Line;
