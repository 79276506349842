import BaseModal from '../BaseModal'
import RouletteLiveSVG from 'assets/roulette_live.svg'
import PropTypes from 'prop-types'

const RouletteActiveModal = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            isVisible={isVisible}
            closeModal={closeModal}
            showDetails={false}>
            <img src={RouletteLiveSVG} className="mx-auto mb-10" />
            <div className="mb-10 text-center">
                <h1 className="text-dark-kiwi font-semibold text-2xl">
                    ¡Atrévete a girar la ruleta y ganar increíbles premios!
                </h1>

                <div className="mt-4">
                    ¿Qué estas esperando? conéctate ahora mismo a nuestro
                    Facebook live
                </div>
            </div>
            <a
                href="https://www.facebook.com/kiwicredito"
                target="_blank"
                className="bg-blue-kiwi text-white rounded-xl p-3 w-full block text-center"
                onClick={closeModal} rel="noreferrer">
                Jugar ahora
            </a>
        </BaseModal>
    )
}

RouletteActiveModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default RouletteActiveModal
