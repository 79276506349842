import {useState, createContext, useContext} from 'react';
import PropTypes from 'prop-types';
import {UserContext} from './UserContext';
import http from '../utils/http';

export const CreditBuilderContext = createContext();

export const CreditBuilderProvider = ({children}) => {
    const {
        state: userState,
        userTracking,
        deleteUserTracking,
    } = useContext(UserContext);

    const initialState = {
        build: {
            completed: false,
        },
        course: {
            id: null,
            financial_months: 0,
            name: '',
            price: 0,
            price_id: 0,
            completed: false,
        },
        selectedCourse: {
            completed: false,
        },
        address: {
            value: '',
            suite: '',
            state: 'Puerto Rico',
            city: '',
            zipcode: '',
            completed: false,
        },
        ssn: {
            completed: false,
        },
        paymentDay: {
            value: '',
            completed: false,
        },
        repaymentTerms: {
            APR: 0,
            interestRate: 0,
            firstPaymentDate: '',
            originationFee: 0,
            tempLoanId: '',
            termMonths: 0,
            totalAmountIncludingFee: 0,
            completed: false,
            created_at: '',
        },
        paymentProfile: {
            last4: '',
            completed: false,
        },
        persona: {
            completed: false,
        },
        contract: {
            completed: false,
        },
    };

    const [state, setState] = useState(() => {
        const {
            credit_builder: {tracking},
        } = userState.products;

        const state = tracking?.context;

        if (state === undefined) {
            return initialState;
        }

        return state;
    });

    const saveState = async state => {
        setState(state);
        const response = await userTracking({
            status: 'credit',
            context: state,
        });

        return response;
    };

    const resetState = async () => {
        setState(initialState);

        const response = await deleteUserTracking({
            status: 'credit',
        });

        return response;
    };

    const getCourses = async () => {
        let {data} = await http.get(`/cb/products/${userState.user.id}`);

        return data;
    };

    const getUserCourses = async () => {
        let {data} = await http.get(
            `/cb/user-orders?user=${userState.user.id}`,
        );

        return data;
    };

    const getCourse = async id => {
        let {data} = await http.get(`/cb/products/filter/${id}`);

        return data;
    };

    const getLesson = async id => {
        let {data} = await http.get(`/cb/product/content/${id}`);

        return data;
    };

    const createTempLoan = async ({
        user_id,
        product_id,
        price_id,
        preferred_payment_date,
    }) => {
        const response = await http.post('/loans/cb/quick-quote', {
            user_id,
            product_id,
            price_id,
            preferred_payment_date,
        });

        return response;
    };

    const checkContractActive = ({tempLoanId}) => {
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const response = await http.get(
                        '/loans/contract/check-loan',
                        {
                            params: {
                                loanpro_tmp_loan_id: tempLoanId,
                            },
                        },
                    );

                    resolve(response);
                } catch (error) {
                    reject(error);
                }
            })();
        });
    };

    const createLoanproCustomer = async user_id => {
        const response = await http.post('/users/loanpro', {
            user_id,
        });

        return response;
    };

    const createLoan = async ({
        user_id,
        product_id,
        loanpro_tmp_loan_id,
        amount,
    }) => {
        const response = await http.post('/cb/sign-contract', {
            user_id,
            product_id,
            loanpro_tmp_loan_id,
            amount,
        });

        return response;
    };

    const getBankAccount = async () => {
        const response = await http.get('/payment-profiles/account_primary');

        return response;
    };

    const addBankAccount = async ({
        accountType,
        accountNumber,
        routingNumber,
    }) => {
        const response = await http.post('/payment-profiles/account', {
            account_type: accountType,
            account_number: accountNumber,
            routing_number: routingNumber,
            is_cb_account: true,
        });

        return response;
    };

    const getContractPreview = async ({
        user_id,
        loanpro_tmp_loan_id,
        product_id,
    }) => {
        const response = await http.get('/loans/contract', {
            responseType: 'blob',
            params: {
                user_id,
                loanpro_tmp_loan_id,
                product_id,
            },
        });

        return response;
    };

    const providerValues = {
        /** State */
        state,
        resetState,
        setState,
        saveState,
        initialState,

        /** Methods */
        getCourses,
        getUserCourses,
        getCourse,
        getLesson,
        createTempLoan,
        checkContractActive,
        createLoanproCustomer,
        getBankAccount,
        addBankAccount,
        getContractPreview,
        createLoan,
    };

    return (
        <CreditBuilderContext.Provider value={providerValues}>
            {children}
        </CreditBuilderContext.Provider>
    );
};

CreditBuilderProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
