import React, {useState, useEffect, useContext, Fragment} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from 'context/AuthContext';

import Button from 'components/Button';
import {UserContext} from 'context/UserContext';
import BackwardButton from 'components/Button/Backward';
import LoadingModal from 'components/Modals/Loading';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ActiveLoanContract() {
    const navigate = useNavigate();
    const {state: authState} = useContext(AuthContext);
    const {state: userState} = useContext(UserContext);
    const {
        products: {
            personal_loan: {loan},
        },
    } = userState;
    const [contractPages, setContractPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [downloadingContract, setDownloadingContract] = useState(false);
    const [fileUrl, setFileUrl] = useState('');

    useEffect(() => {
        setFileUrl(
            `${process.env.REACT_APP_API_KIWI_PAY}/loans/download-contract/${authState.accessTokenApp}?loan_id=${loan.id}`,
        );
    }, []);

    const onDocumentLoadSuccess = ({numPages}) => {
        setContractPages(numPages);
        setLoading(false);
    };

    const onBackward = () => {
        navigate('/active-loan/loans-disbursed');
    };

    const onOpenContract = async () => {
        setDownloadingContract(true);

        const response = await fetch(fileUrl);
        const fileBlob = await response.blob();
        const alink = document.createElement('a');

        alink.href = window.URL.createObjectURL(fileBlob);
        alink.download = 'Contract.pdf';
        alink.click();

        setDownloadingContract(false);
    };

    return (
        <div className="flex flex-col h-[calc(100vh-80px)] sm:h-[calc(100vh-128px)] overflow-hidden">
            <BackwardButton onBackward={onBackward} />
            <LoadingModal isVisible={loading || downloadingContract} />

            <h1 className="text-dark-kiwi font-semibold mb-8 text-2xl">
                Contrato de préstamo
            </h1>

            <div className="grow overflow-auto relative">
                <Document
                    file={fileUrl}
                    error="No se pudo cargar el contrato..."
                    loading=""
                    noData="Estamos cargando el contrato..."
                    onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(Array(contractPages).keys()).map(index => (
                        <Page pageNumber={index + 1} key={index} />
                    ))}
                </Document>
            </div>

            <Button
                className="rounded-xl p-3 mt-10 bg-blue-kiwi text-white"
                loading={loading || downloadingContract}
                onClick={onOpenContract}>
                Descargar contrato
            </Button>
        </div>
    );
}
