import  { useState, useEffect, useContext, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'
import { UserContext } from 'context/UserContext'

import ProgressBar from 'components/ProgressBar'
import SelectCity from 'components/Modals/SelectCity'
import STORES_CITIES from 'data/stores_cities'

import ShopIcon from 'assets/shop_gray.svg'

export default function ApprovedLoanStores() {
    const navigate = useNavigate()

    const { state: approvedState, saveState: saveApprovedState } =
        useContext(ApprovedLoanContext)
    const { state: userState } = useContext(UserContext)

    const [stores, setStores] = useState([])
    const [cities, setCities] = useState([])
    const [city, setCity] = useState('')

    const [cityVisible, setCityVisible] = useState(false)

    useEffect(() => {
        if (approvedState.disbursement.completed === false) {
            return onBackward()
        }

        getCitiesList()
    }, [])

    const getCitiesList = async () => {
        const {
            user: { city }
        } = userState

        let cities = STORES_CITIES.reduce((list, row) => {
            const city = row.city

            list[city] = list[city] ?? []
            list[city].push(row)

            return list
        }, {})

        const stores = cities[city]

        setCities(cities)

        /**
         * NOTE: Crear empty state, para los usuarios que en su ciudad
         * de residencia no haya tiendas
         */

        if (stores) {
            setCity(city)
            setStores(stores)
        }
    }

    const onSelectCity = (city) => {
        setStores(cities[city])
        setCity(city)
    }

    const onContinue = () => {
        saveApprovedState({
            ...approvedState,
            stores: {
                completed: true
            }
        })

        navigate('/approved-loan/repayment-options')
    }

    const onBackward = () => {
        navigate('/approved-loan/disbursement')
    }

    return (
        <Fragment>
            <div className="flex flex-col mb-10">
                <ProgressBar progress="6/10" onBackward={onBackward} />

                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Lugares donde recoger tu efectivo
                </h1>
                <p>
                    Ubica la tienda más cerca de ti, puedes recoger el efectivo
                    en cualquiera de nuestros comercios <b>Me salvé</b>
                </p>
            </div>

            <div className="mb-10">
                <label className="inline-block text-sm mb-2">
                    Selecciona tu ciudad
                </label>

                <div
                    className={`h-[42px] focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi`}
                    onClick={() => setCityVisible(true)}>
                    {city}
                </div>

                {cityVisible && (
                    <SelectCity
                        data={Object.keys(cities).map((name) => ({ name }))}
                        selectedCity={city}
                        isVisible={cityVisible}
                        closeModal={() => setCityVisible(false)}
                        selectCity={(city) => onSelectCity(city)}
                    />
                )}

                {stores?.length > 0 ? (
                    <div className="flex flex-col mt-6">
                        {stores.map((store) => (
                            <div
                                key={store.id}
                                className="[&:not(:last-child)]:border-b border-gray-200-kiwi p-4">
                                <div className="leading-5 mb-1 capitalize">
                                    {store.name.toLowerCase()}
                                </div>
                                <p className="text-xs text-gray-500-kiwi">
                                    {store.address}
                                </p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="mt-14 flex flex-col gap-2 items-center justify-center text-center max-w-xs mx-auto text-gray-500">
                        <img src={ShopIcon} />
                        <p>
                            No hay tiendas disponibles en tu ciudad, puedes ir a
                            cualquier tienda más cercana
                        </p>
                    </div>
                )}
            </div>

            <button
                className="rounded-xl p-3 bg-blue-kiwi text-white mt-auto"
                onClick={onContinue}>
                Continuar
            </button>
        </Fragment>
    )
}
