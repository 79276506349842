import Tab from "./tab";
import PropTypes from "prop-types";
import ContentTab from "./ContentTab";

const TabsComponent = ({ tabs, selectedTab, handleTabChange }) => {
  return (
    <div className="grid">
      <div className="flex">
        {tabs.map(({ key, label }) => (
          <Tab
            key={key}
            label={label}
            selected={selectedTab === key}
            onClick={() => handleTabChange(key)}
            className={`inline-block px-4 py-2 cursor-pointer tab-item ${
              selectedTab === key
                ? "border-b border-regal-blue-950-kiwi text-regal-blue-950-kiwi font-medium"
                : ""
            }`}
          />
        ))}
      </div>
      <div className="grid py-8">
        <ContentTab tabs={tabs} selectedTab={selectedTab} />
      </div>
    </div>
  );
};

TabsComponent.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      content: PropTypes.node,
    }),
  ),
  handleTabChange: PropTypes.func,
  selectedTab: PropTypes.string,
};

export default TabsComponent;
