import ReactPixel from "react-facebook-pixel";
import { useEffect, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import phoneFormatter from "phone-formatter";
import { ApprovedLoanContext } from "context/ApprovedLoanContext";
import { UserContext } from "context/UserContext";
import ProgressBar from "components/ProgressBar";
import PaymentProfile from "templates/PaymentProfile";

export default function ApprovedLoanPaymentProfile() {
  const navigate = useNavigate();

  const { state: approvedState, saveState: saveApprovedState } =
    useContext(ApprovedLoanContext);
  const { state: userState } = useContext(UserContext);

  useEffect(() => {
    dataLayer.push({
      event: "new_application",
      eventCategory: "loan_process",
      eventAction: "loan_process_payment_profile",
      dl_user_id: userState.user.id,
    });

    if (approvedState.repaymentTerms.completed === false) {
      return onBackward();
    }
  }, []);

  const onBackward = () => {
    navigate("/approved-loan/repayment-details?showTerms=true");
  };

  const onAddProfile = ({ last4, bankName }) => {
    saveApprovedState({
      ...approvedState,
      paymentProfile: {
        last4,
        bankName,
        completed: true,
      },
    });

    const {
      personal_loan: {
        application: { credit_limit },
      },
    } = userState.products;

    dataLayer.push({
      event: "add_payment_info_value",
      order_value: parseInt(approvedState.amount.value),
      order_limit: parseInt(credit_limit),
      order_currency: "USD",
      formid: 63,
      uniqueEventId: 14,
      inputs: [
        {
          name: "name",
          value: `${userState.user.first_name} ${userState.user.last_name}`,
        },
        { name: "email", value: userState.user.email },
        {
          name: "phone",
          value: phoneFormatter.format(
            userState.user.phone_number,
            "+1NNNNNNNNNN",
          ),
        },
      ],
    });

    ReactPixel.track("AddPaymentInfo");

    navigate("/approved-loan/disbursement");
  };

  const onSelectProfile = ({ id, last4, bankName }) => {
    saveApprovedState({
      ...approvedState,
      paymentProfile: {
        id,
        last4,
        bankName,
        completed: true,
      },
    });

    navigate("/approved-loan/disbursement");
  };

  return (
    <Fragment>
      <ProgressBar progress="4/10" onBackward={onBackward} />
      <PaymentProfile
        onAddProfile={onAddProfile}
        onSelectProfile={onSelectProfile}
        selectedProfile={approvedState.paymentProfile.id ?? ""}
        showSelectProfileView={false}
      />
    </Fragment>
  );
}
