import PropTypes from "prop-types";
import Button from "components/Button";
import NotificationSuccessIcon from "assets/icons_v3/fonts/notifications_success";
import { changeStatusNotificationsBulk } from "api/Notifications";

const PromotionalConsentModal = ({ closeModal }) => {
  const handleConsent = async () => {
    try {
      await changeStatusNotificationsBulk({
        values: [
          { type: "sms", on: true },
          { type: "email", on: true },
        ],
      });
    } catch (error) {
      console.error(error);
    } finally {
      closeModal();
    }
  };

  const handleCancel = async () => {
    try {
      await changeStatusNotificationsBulk({
        values: [
          { type: "sms", on: false },
          { type: "email", on: true },
        ],
      });
    } catch (error) {
      console.error(error);
    } finally {
      closeModal();
    }
  };

  return (
    <div className="grid grid-cols-1 grid-rows-[1fr,auto] mt-4 fixed bottom-0 left-0 right-0 top-0 bg-white p-6 z-50  sm:w-[450px] mx-auto">
      <div className="grid ">
        <div className="flex justify-center items-center flex-col text-center">
          <NotificationSuccessIcon className="mb-8 mx-auto" />
          <div className="text-2xl font-semibold text-center text-dark-kiwi mb-4">
            ¡Para comunicarnos contigo!
          </div>
          <div className="mt-4 text-gray-700 text-base">
            Acepto recibir mensajes de texto y llamadas promocionales sobre
            Kiwi. Pueden aplicarse cargos por mensajes y datos. Podrás cancelar
            el consentimiento de comunicación en cualquier momento.
          </div>
        </div>
      </div>
      <div className="self-baseline grid gap-4">
        <Button className="rounded-xl" onClick={handleConsent}>
          Sí, aceptar
        </Button>
        <Button
          className="rounded-xl mb-4 bg-transparent !text-blue-kiwi font-medium"
          onClick={handleCancel}
        >
          No, volver al inicio
        </Button>
      </div>
    </div>
  );
};

PromotionalConsentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default PromotionalConsentModal;
