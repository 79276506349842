import http from "utils/http";

const getProducts = async () => {
  const response = await http.get("/products");

  return response;
};

export const getPersonalLoan = async () => {
  const response = await http.get("/products/personal-loan");

  return response;
};

const getCreditBuilder = async () => {
  const response = await http.get("/products/credit-builder");

  return response;
};

export default {
  getPersonalLoan,
  getCreditBuilder,
  getProducts,
};
