import  { useEffect, Fragment, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import InformationCircleSVG from 'assets/onboarding_v3/information_circle.svg'

const states = {
    no_multi_payments_in_48_hours: {
        title: 'No se pudo realizar pago',
        description:
            'Lo sentimos. No podemos procesar dos pagos en un mismo día, puedes  intentarlo en 24 horas'
    },
    no_balance: {
        title: 'Tarjeta sin fondos',
        description:
            'No podemos completar su pago por falta de fondos. Elige otra forma de pago o comuníquese con su proveedor de tarjeta'
    },
    error: {
        title: 'Error al procesar el pago',
        description:
            'Lo sentimos, hemos tenido un problema al procesar el pago. Por favor, inténtalo nuevamente'
    }
}

export default function CreditBuilderFailedPaymment() {
    const navigate = useNavigate()
    const location = useLocation()
    const {
        state: { message }
    } = location

    const [content, setContent] = useState(null)

    useEffect(() => {
        setContent(states[message] ?? states['error'])
    }, [])

    const onContinue = () => {
        navigate(-1)
    }

    return (
        content && (
            <Fragment>
                <div className="flex flex-col justify-center min-h-full text-center m-auto">
                    <img className="mx-auto mb-8" src={InformationCircleSVG} />
                    <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                        {content.title}
                    </h1>
                    <p>{content.description}</p>
                </div>

                <button
                    className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                    onClick={onContinue}>
                    Aceptar
                </button>
            </Fragment>
        )
    )
}
