import PropTypes from "prop-types";

const Button = ({
  className,
  onClick,
  children,
  loading = false,
  disabled = false,
  ...props
}) => {
  const onHandleClick = () => {
    if (!loading && onClick) {
      onClick();
    }
  };

  const showContent = () => {
    if (loading === true) {
      return (
        <span>
          <b></b>
          <b></b>
          <b></b>
        </span>
      );
    }

    return children;
  };

  return (
    <button
      disabled={loading || disabled}
      className={`button flex justify-center items-center h-12 ${className} ${
        disabled
          ? "bg-gray-200-kiwi text-gray-500-kiwi"
          : "bg-blue-kiwi text-white"
      }`}
      onClick={onHandleClick}
      {...props}
    >
      {showContent()}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
