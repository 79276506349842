import axios from "axios";
import Cookies from "js-cookie";
import { UAParser } from "ua-parser-js";
import Ajv from "ajv";
import ajvFormats from "ajv-formats";
import { jsonSchema } from "./dmp_json_schema";
import { v4 as uuidv4 } from "uuid";

const ajv = new Ajv();
ajvFormats(ajv);
const DOMAIN = ".kiwicredito.com";

const getLocationData = () => {
  const locationData = Cookies.get("dmp_location");
  return locationData ? JSON.parse(locationData) : null;
};

const getActivityDuration = () => {
  const lastActivitySeenDMP = Cookies.get("dmp_last_activity_seen");
  const now = new Date();
  const lastActivity = new Date(lastActivitySeenDMP);
  const diff = now - lastActivity;
  Cookies.set("dmp_event_duration", diff || null);
};

const getExpirationTime = (hours) => {
  return new Date(new Date().getTime() + hours * 60 * 60 * 1000);
};

const setNewSessionId = () => {
  const expirationTime = getExpirationTime(4);
  Cookies.set("dmp_session_id", uuidv4(), {
    domain: DOMAIN,
    secure: true,
    expires: expirationTime,
  });
};

const trackEvent = async ({
  event_type,
  place = "app",
  pathname = null,
  user_id = null,
  utm_data = null,
}) => {
  if (process.env.REACT_APP_DMP_TRACKING === "production") {
    return;
  }
  try {
    getActivityDuration();

    if (user_id && !Cookies.get("dmp_user_id")) {
      Cookies.set("dmp_user_id", user_id, {
        domain: DOMAIN,
        secure: true,
      });
    }
    const dateActivity = new Date().toISOString();

    if (
      !Cookies.get("dmp_device_id") &&
      !localStorage.getItem("dmp_device_id")
    ) {
      const deviceId = uuidv4();
      Cookies.set("dmp_device_id", deviceId, {
        domain: DOMAIN,
        secure: true,
        expires: 365,
      });
      localStorage.setItem("dmp_device_id", deviceId);
    }

    const deviceId =
      Cookies.get("dmp_device_id") || localStorage.getItem("dmp_device_id");
    const eventDuration =
      event_type === "button" ? 0 : Cookies.get("dmp_event_duration");
    const parser = new UAParser();
    const resultNavigator = parser.getResult();
    const userdmp = Cookies.get("dmp_user_id");

    if (!event_type) {
      return;
    }

    const location = getLocationData();
    if (!Cookies.get("dmp_session_id")) setNewSessionId();
    const sessionId = Cookies.get("dmp_session_id");

    const userCountry = location?.country
      ? location.country
      : location?.state?.includes("Puerto Rico")
        ? "Puerto Rico"
        : "Unknown";
    const userState = location?.state;
    const userCity = location?.city;
    const userZip = location?.postalCode;
    const userLatitude = location?.latitude;
    const userLongitude = location?.longitude;

    const event_id = uuidv4();
    const logEvent = {
      user_information: {
        visitor_id: deviceId,
        user_id: userdmp ?? null,
        location: {
          country: userCountry,
          state: userState,
          city: userCity,
          zip: userZip,
          latitude: userLatitude,
          longitude: userLongitude,
        },
      },
      device_information: {
        device_id: deviceId,
        device_type: resultNavigator.device.type || "desktop",
        os: resultNavigator.os.name,
        os_version: resultNavigator.os.version,
        app_version: resultNavigator.browser.version,
        browser: resultNavigator.browser.name,
      },
      event_information: {
        event_type: event_type,
        event_id: event_id,
        session_id: sessionId,
        event_timestamp: dateActivity,
        event_duration: parseInt(eventDuration) || null,
        reference_page: place || "direct",
        event_url: pathname,
        utm_parameters: {
          utm_campaign: utm_data?.utm_campaign,
          utm_content: utm_data?.utm_content,
          utm_id: utm_data?.utm_id,
          utm_medium: utm_data?.utm_medium,
          utm_source: utm_data?.utm_source,
          utm_term: utm_data?.utm_term,
        },
      },
    };

    const validate = ajv.compile(jsonSchema);
    const valid = validate(logEvent);

    if (!valid) {
      console.debug("[ERROR_TRACK_EVENT_DMP]: ", validate.errors);
    }

    const SQS_URL = process.env.REACT_APP_SQS_URL;
    if (SQS_URL) {
      try {
        const response = await axios.post(
          `${SQS_URL}/send-message`,
          {
            ...logEvent,
          },
          {
            headers: {
              Authorization: `${process.env.REACT_APP_DMP_API_KEY}`,
            },
          },
        );

        console.log("Event tracked:", response.data);
      } catch (error) {
        console.log("Error tracking event:", error);
      }
    }

    Cookies.set("dmp_last_activity_seen", dateActivity, {
      domain: DOMAIN,
      secure: true,
    });
  } catch (error) {
    console.error("Error tracking event:", error);
  }
};

export { trackEvent };
