import Emitter from "utils/emitter";

import { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserContext";

import { ProductsAPI, LoanAPI, CreditBuilderAPI, ContractsAPI } from "api";
import { CheckIcon, RightIcon } from "assets/icons_v3/fonts";
import { CreditBuilderContext } from "context/CreditBuilderContext";

import ProgressBar from "components/ProgressBar";
import LoadingModal from "components/Modals/Loading";
import Button from "components/Button";
import ContractTemplate from "templates/Contract";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { REPAYMENT_OPTIONS, STATES_AVAILABLES } from "constants";
import dateDifference from "utils/dateDifference";
import { trackEvent } from "utils/dmp_kiwi";
import { getUtmParams } from "hooks/useStoredUrlParams";

export default function CreditBuilderContract() {
  const navigate = useNavigate();

  const {
    state: creditState,
    saveState: saveCreditState,
    resetState: resetCreditState,
  } = useContext(CreditBuilderContext);

  const { state: userState, setState: setUserState } = useContext(UserContext);

  const { getData: getDataFingerprint } = useVisitorData(
    { extendedResult: true },
    { immediate: true },
  );

  const {
    user: { id: user_id },
  } = userState;

  const { course, repaymentTerms, paymentDay } = creditState;
  const { tempLoanId } = repaymentTerms;

  const [contracts, setContracts] = useState([]);

  const [selectedContract, setSelectedContract] = useState({});
  const [showContract, setShowContract] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [contractReload, setContractReload] = useState(false);
  const [lang] = useState("es");
  const [utmParams, setUtmParams] = useState({});

  useEffect(() => {
    if (tempLoanId === "") {
      return navigate("/credit-builder/repayment-terms");
    }

    verifyUpToDateRepaymentTerms();

    if (creditState.persona.completed === false) {
      return navigate("/credit-builder/persona");
    }

    getAllContracts();
    setUtmParams(getUtmParams());
  }, []);

  const validateEveryContractSigned = () => {
    return contracts.every((contract) => contract.signed);
  };

  const verifyUpToDateRepaymentTerms = async () => {
    const isOneDayAfter = (() => {
      if (creditState.repaymentTerms.created_at) {
        const diffDays = dateDifference(creditState.repaymentTerms.created_at);

        if (diffDays >= 2) {
          return true;
        }
      }

      return false;
    })();

    if (isOneDayAfter) {
      await resetCreditState();
      navigate("/credit-builder/buy-course");
    }
  };

  const getIPfingerprintJs = async () => {
    const dataFPJS = await getDataFingerprint({
      ignoreCache: true,
    });

    return dataFPJS?.ip;
  };

  const getAllContracts = async () => {
    try {
      setShowLoader(true);

      const {
        data: { data: contracts },
      } = await LoanAPI.getContractByType("cb");

      setContracts(
        contracts.map((contract) => ({
          ...contract,
          signed: false,
        })),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const onBackward = () => {
    if (showContract) {
      return resetContractView();
    }

    navigate("/credit-builder/payment-profile");
  };

  const resetContractView = () => {
    setShowContract(false);
    setSelectedContract({});
    setFileUrl("");
  };

  const onSignIndividualContract = async () => {
    setContracts((prevItems) =>
      prevItems.map((item) =>
        item.id === selectedContract.id ? { ...item, signed: true } : item,
      ),
    );

    resetContractView();
  };

  const onSignAllContract = async () => {
    try {
      setLoading(true);
      setShowLoader(true);

      const ip = await getIPfingerprintJs();
      await CreditBuilderAPI.signContract({
        user_id,
        loanpro_tmp_loan_id: tempLoanId.toString(),
        product_id: course.id,
        amount: course.price.toString(),
        ip,
      });

      await resetCreditState();

      const {
        data: { data: credit_builder },
      } = await ProductsAPI.getCreditBuilder();

      setUserState({
        ...userState,
        products: {
          ...userState.products,
          credit_builder,
        },
      });

      trackEvent({
        place: "/credit-builder/contract",
        event_type: "button",
        pathname: window.location.href,
        user_id: user_id,
        utm_data: utmParams,
      });

      navigate("/credit-builder/success");
    } catch (error) {
      const { data } = error.response;

      if (data.messages === "ACTIVE_LOAN_ALREADY_EXISTS") {
        return Emitter.emit("onOpenNotification", {
          type: "error",
          title: "Tienes un contrato activo",
          message: `Parece que actualmente tienes un contrato activo, puedes escribir a nuestro equipo de soporte al correo support@kiwicredito.com`,
        });
      }

      if (data.messages === "LOAN_LESS_7_DAYS") {
        return Emitter.emit("onOpenNotification", {
          type: "error",
          title: "Días de espera...",
          message: `Deben de pasar 7 días para volver a solicitar otro préstamo, puedes escribir a nuestro equipo de soporte al correo support@kiwicredito.com`,
        });
      }

      Emitter.emit("onOpenNotification", {
        type: "error",
        title: "Error al firmar tu contrato",
        message: `Parece que hubo un error, póngase en contacto con nuestro equipo de soporte al correo support@kiwicredito.com`,
      });
    } finally {
      setLoading(false);
      setShowLoader(false);
    }
  };

  const onSelectContract = async (contract) => {
    if (loading) return;

    try {
      setShowLoader(true);

      const ip = await getIPfingerprintJs();
      const basePayload = {
        path: contract.path,
        lang,
        ip,
        loanpro_tmp_loan_id: creditState.repaymentTerms.tempLoanId.toString(),
        repayment_method: REPAYMENT_OPTIONS.ACH,
        product_id: course.id,
      };

      if (userState.user.state === STATES_AVAILABLES.florida) {
        return onSelectContractFlorida(contract, basePayload);
      }

      const {
        data: {
          success,
          data: { file_url },
        },
      } = await ContractsAPI.getCurrentContract(contract.path, basePayload);
      if (!success) throw new Error();

      setFileUrl(file_url);
      setSelectedContract(contract);
      setShowContract(true);
    } catch (error) {
      if (!contractReload) onRecreateTempLoan();
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const onSelectContractFlorida = async (contract, payload) => {
    try {
      const { data } = await LoanAPI.getContractDocument(payload);
      if (data) {
        const reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => {
          setFileUrl(reader.result);
          setSelectedContract(contract);
          setShowContract(true);
        };
      }
    } catch (error) {
      if (!contractReload) onRecreateTempLoan();
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const onRecreateTempLoan = async () => {
    try {
      const {
        data: {
          data: { isActive },
        },
      } = await LoanAPI.checkContractActive({ tempLoanId });

      if (!isActive) {
        const { data } = await LoanAPI.createTempLoanCreditBuilder({
          user_id,
          price_id: course.price_id,
          preferred_payment_date: paymentDay.value,
          product_id: course.id,
        });

        setContractReload(true);

        saveCreditState({
          ...creditState,
          repaymentTerms: {
            ...creditState.repaymentTerms,
            ...data.loan,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <ProgressBar progress="7/7" onBackward={onBackward} />
      <LoadingModal isVisible={showLoader} />

      {showContract ? (
        <ContractTemplate
          signed={selectedContract.signed}
          title={selectedContract.name}
          fileUrl={fileUrl}
          signContract={onSignIndividualContract}
        />
      ) : (
        <Fragment>
          <div className="mb-10">
            <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
              Contratos de préstamo
            </h1>
            <p>Conoce los documentos de tu préstamo:</p>
          </div>

          <div className="mb-10 flex flex-col gap-6">
            {contracts.map((contract) => (
              <div
                className="flex cursor-pointer rounded-lg border border-solid border-gray-200-kiwi p-4"
                onClick={() => onSelectContract(contract)}
                key={contract.type}
              >
                <div
                  className={`flex shrink-0 w-6 h-6 rounded-lg mr-4 ${
                    contract.signed
                      ? "bg-blue-kiwi"
                      : "border border-solid border-gray-200-kiwi"
                  }`}
                >
                  {contract.signed && (
                    <CheckIcon className="text-white w-4 m-auto" />
                  )}
                </div>
                <div className="mr-auto">{contract.name}</div>
                {!contract.signed && (
                  <RightIcon className="text-slate-300-kiwi" />
                )}
              </div>
            ))}
          </div>

          <Button
            className="rounded-xl p-3 mt-auto"
            loading={loading}
            disabled={!validateEveryContractSigned()}
            onClick={onSignAllContract}
          >
            Firmar contrato
          </Button>
        </Fragment>
      )}
    </Fragment>
  );
}
