export default function formatToUSD(value) {
    if (isNaN(value)) {
      throw new Error("El valor proporcionado no es un número válido.");
    }
  
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  
    return formatter.format(Number(value));
  }