import { useState, Fragment } from "react";
import ImageUploading from "react-images-uploading";
import Emitter from "utils/emitter";
import http from "utils/http";
import PropTypes from "prop-types";

import File from "components/File";
import Button from "components/Button";
import { AddIcon } from "assets/icons_v3/fonts";
import mixpanel from "utils/mixpanel";

const UploadFileComponent = ({
  documentType,
  acceptType,
  onUploaded,
  loading,
  setLoading,
}) => {
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState("");

  const onUploadDocuments = async () => {
    if (documents.length === 0) {
      return;
    }

    try {
      setLoading(true);

      const formData = new FormData();

      documents.forEach((e) => {
        formData.append("upload", e.file);
      });

      formData.append("type", documentType);

      mixpanel.track("Bank Statements Sent");

      const { data } = await http.post("/files/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (data.success === true) {
        onUploaded(documents);
      }
    } catch {
      Emitter.emit("onOpenNotification", {
        type: "error",
        title: "Error al subir tus documentos",
        message: `Parece que hubo un error, por favor vuelve a intentar subir tus documentos`,
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangeFiles = (imageList) => {
    let totalSize = 0;

    imageList.forEach((archive) => (totalSize = totalSize + archive.file.size));

    /** 100MB en Bites */
    if (totalSize > 100000000) {
      return setError(
        "Excediste los 100MB permitidos por subida de archivos a la vez. Elimina algunos documentos e inténtalo de nuevo",
      );
    }

    if (imageList.length > 25) {
      return setError(
        "Solo tienes permitido subir como máximo 25 archivos a la vez. Elimina algunos documentos e inténtalo de nuevo",
      );
    }

    setDocuments(imageList);
    setError("");
  };

  const onErrorFiles = (errors) => {
    if (errors.acceptType) {
      setError(
        "Uno de los archivos seleccionados que intentas subir no es permitido. Inténtalo con otro archivo",
      );
    }
  };

  return (
    <Fragment>
      <div className="w-full flex flex-col gap-4 mb-10">
        <ImageUploading
          multiple
          allowNonImageType
          value={documents}
          onError={onErrorFiles}
          onChange={onChangeFiles}
          acceptType={acceptType}
        >
          {({ imageList, onImageUpload, onImageRemove }) => (
            <Fragment>
              {imageList.map((image, index) => (
                <File
                  key={index}
                  image={image}
                  index={index}
                  onImageRemove={() => {
                    if (loading === false) onImageRemove(index);
                  }}
                ></File>
              ))}

              {loading === false && (
                <Fragment>
                  <button
                    className="w-full bg-blue-kiwi/10 text-blue-kiwi rounded-xl p-4 flex items-center justify-center border border-dashed border-blue-kiwi"
                    onClick={onImageUpload}
                  >
                    <AddIcon className="mr-4" />
                    Agregar archivo o imagen
                  </button>

                  {error !== "" && (
                    <small className="text-red-kiwi inline-block text-xs w-full mt-2">
                      {error}
                    </small>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </ImageUploading>
      </div>

      <Button
        className="w-full rounded-xl p-3 mt-auto"
        disabled={!documents?.length}
        loading={loading}
        onClick={onUploadDocuments}
      >
        Enviar archivos
      </Button>
    </Fragment>
  );
};

UploadFileComponent.propTypes = {
  documentType: PropTypes.string.isRequired,
  acceptType: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUploaded: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default UploadFileComponent;
