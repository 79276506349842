import { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import PropTypes from "prop-types";
import "swiper/css";

import { ListCheckLine } from "assets/icons_v3/fonts";
import InformationIcon from "assets/onboarding_v3/information_circle.svg";

import CashbackOfferComponent from "./CashbackOffer";
import AlertClosedOffer from "./Modals/AlertClosedOffer";
import AlertPendingOffer from "./Modals/AlertPendingOffer";
import AlertClaimCadenceOffer from "./Modals/AlertClaimCadenceOffer";
import createCustomMarker from "./Maps";

import { getOpeningClosingTimes } from "utils/formatOpeningClosingTimes";
import { CashbackContext } from "context/CashbackContext";

const MapLocationsComponent = ({ locations, loading, onClickShowMap }) => {
  const { setState: setCashbackState } = useContext(CashbackContext);
  const navigate = useNavigate();

  const [showModalInformation, setShowModalInformation] = useState(false);
  const [currentInformationAlert, setCurrentInformationAlert] = useState(null);
  const [offerSelected, setOfferSelected] = useState(null);
  const [showAlertPendingOffer, setShowAlertPendingOffer] = useState(false);
  const [showAlertClaimCadenceOffer, setShowAlertClaimCadenceOffer] =
    useState(false);

  const swiperRef = useRef(null);
  const mapRef = useRef(null);
  const markersRef = useRef([]);

  const handleModalInformation = () => {
    setShowModalInformation(!showModalInformation);
    setCurrentInformationAlert(null);
  };

  const onSelectOffer = async (offer) => {
    if (offer.is_waiting_merchant_cadence) {
      setOfferSelected(offer);
      setShowAlertClaimCadenceOffer(true);
      return;
    }

    if (offer.is_merchant_pending) {
      setOfferSelected(offer);
      setShowAlertPendingOffer(true);
      return;
    }
    const { isOpen } = getOpeningClosingTimes(offer);
    if (isOpen) {
      navigate("/cashback/offer-details", {
        state: {
          from: "/cashback/map-offers",
        },
      });
      setCashbackState((prevState) => ({
        ...prevState,
        selectedOffer: offer,
      }));
    } else {
      setCurrentInformationAlert(offer);
      setShowModalInformation(true);
    }
  };

  const redirectOfferSelected = () => {
    setCashbackState((prevState) => ({
      ...prevState,
      selectedOffer: currentInformationAlert,
    }));
    navigate("/cashback/offer-details");
  };

  const getMap = (data) => {
    if (!data?.[0]) return;

    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: {
        lat: parseFloat(data[0]?.store?.latitude),
        lng: parseFloat(data[0]?.store?.longitude),
      },
      zoom: 17,
      disableDefaultUI: true,
      styles: [
        {
          featureType: "all",
          elementType: "all",
          stylers: [
            { invert_lightness: false },
            { saturation: 0 },
            { lightness: 0 },
            { gamma: 0 },
          ],
        },
      ],
      mapTypeControlOptions: {
        mapTypeIds: [],
      },
    });

    mapRef.current = map;

    setCustomMarkers(map, data);
  };

  const setCustomMarkers = (map, data) => {
    markersRef.current = data.map((offer) => {
      const customMarkerHTML = `
                <div class='flex items-center justify-center h-12 w-12 ${"bg-[#64748B]"} rounded-full relative z-50'>
                    <img src='${offer.store.merchant.logo_url}' class='h-10 w-10 bg-white rounded-full object-contain'/>
                    <div  class="marker-arrow w-0 h-0 border-l-8 border-r-8 border-t-8 border-l-transparent border-r-transparent border-t-[#64748B] absolute -bottom-1.5"/>
                </div>
            `;

      return createCustomMarker({
        map,
        position: {
          lat: parseFloat(offer?.store.latitude),
          lng: parseFloat(offer?.store.longitude),
        },
        contentHTML: customMarkerHTML,
        offer,
        onSelectOffer,
      });
    });
  };

  const centerMapOnMarker = (index) => {
    const marker = markersRef.current[index];
    const map = mapRef.current;
    const location = locations[index]?.store;

    if (marker && map && location) {
      markersRef.current.forEach((marker) => {
        const markerElement = marker.div.firstElementChild;
        const markerArrows = markerElement.querySelectorAll(".marker-arrow");

        markerArrows.forEach((arrow) => {
          arrow.classList.replace(
            "border-t-regal-blue-kiwi",
            "border-t-[#64748B]",
          );
        });

        markerElement.classList.replace("bg-regal-blue-kiwi", "bg-[#64748B]");
        markerElement.classList.replace("h-12", "h-8");
        markerElement.classList.replace("w-12", "w-8");

        const imgElement = markerElement.querySelector("img");
        imgElement.classList.replace("h-10", "h-6");
        imgElement.classList.replace("w-10", "w-6");
      });

      const position = new window.google.maps.LatLng(
        parseFloat(location.latitude),
        parseFloat(location.longitude),
      );
      map.setCenter(position);
      const markerElement = marker.div.firstElementChild;
      const markerArrowElements =
        markerElement.querySelectorAll(".marker-arrow");

      markerArrowElements.forEach((arrow) => {
        arrow.classList.replace(
          "border-t-[#64748B]",
          "border-t-regal-blue-kiwi",
        );
      });

      markerElement.classList.replace("bg-[#64748B]", "bg-regal-blue-kiwi");
      markerElement.classList.replace("h-8", "h-12");
      markerElement.classList.replace("w-8", "w-12");

      const imgElement = markerElement.querySelector("img");
      imgElement.classList.replace("h-6", "h-10");
      imgElement.classList.replace("w-6", "w-10");
    }
  };

  useEffect(() => {
    if (locations) {
      markersRef.current.forEach((marker) => {
        if (marker.onRemove) {
          marker.onRemove();
        }
      });
      markersRef.current = [];
      getMap(locations);
    }
  }, [locations]);

  return (
    <>
      <div id="map" className="h-screen sm:h-[calc(100vh-48px)] w-full" />
      <AlertPendingOffer
        logo_url={offerSelected?.store?.merchant?.logo_url}
        merchant_name={offerSelected?.store?.merchant?.merchant_name}
        isVisible={showAlertPendingOffer}
        closeModal={() => setShowAlertPendingOffer(false)}
      />
      <AlertClaimCadenceOffer
        logo_url={offerSelected?.store?.merchant?.logo_url}
        next_claim_date={offerSelected?.next_claim_date}
        merchant_name={offerSelected?.store?.merchant?.merchant_name}
        isVisible={showAlertClaimCadenceOffer}
        closeModal={() => setShowAlertClaimCadenceOffer(false)}
      />
      <div className="fixed w-full max-w-[450px] bottom-10 flex flex-col items-center gap-8 pointer-events-auto">
        <div className="w-full">
          {!loading && (
            <Swiper
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              onSlideChange={(swiper) => centerMapOnMarker(swiper.activeIndex)}
              className="cursor-grab pointer-events-auto"
              centeredSlides={true}
              slidesPerView={1.2}
              spaceBetween={16}
            >
              {locations?.map((offer, index) => (
                <SwiperSlide key={index}>
                  <CashbackOfferComponent
                    showStatus={false}
                    key={offer.id}
                    offer={offer}
                    onSelectOffer={() => {
                      onSelectOffer(offer);
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>

        <button
          onClick={onClickShowMap}
          className="flex items-center gap-2 px-4 py-1 bottom-14 h-7 bg-regal-blue-kiwi text-white rounded-full text-xs"
        >
          <ListCheckLine className="text-white fill-white w-4 h-4" />
          Ver listado
        </button>
      </div>
      <AlertClosedOffer
        icon={InformationIcon}
        isVisible={showModalInformation}
        closeModal={handleModalInformation}
        offer={currentInformationAlert}
        redirectOfferSelected={redirectOfferSelected}
      />
    </>
  );
};

MapLocationsComponent.propTypes = {
  locations: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onClickShowMap: PropTypes.func.isRequired,
};

export default MapLocationsComponent;
