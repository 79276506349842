import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend';
import { NameSpaces } from './name-spaces';

i18n.use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        ns: [
            NameSpaces.COMMON,
            NameSpaces.HOME,
            NameSpaces.DOCUMENTS,
        ],
        defaultNS: [NameSpaces.COMMON],
        debug: true,
        fallbackLng: 'es',
        lng: 'es',
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
    })