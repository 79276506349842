import  { useState, Fragment, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { BankCardIcon, BankIcon, ChevronLeftIcon } from 'assets/icons_v3/fonts'


export default function ActiveLoanPaymentMethodDetails() {
    const navigate = useNavigate()
    const location = useLocation()

    const { state: method } = location
    const [type, setType] = useState('')

    useEffect(() => {
        if (method.checkingAccountId) {
            setType('bank')
        }
    }, [])

    const onBackward = () => {
        navigate('/active-loan/payment-method')
    }

    const getMethodTitle = () => {
        if (type === 'bank') {
            return String(method.CheckingAccount.accountNumber).slice(-4)
        } else {
            return String(method.CreditCard.cardNumber).slice(-4)
        }
    }

    return (
        <Fragment>
            <ChevronLeftIcon
                className="w-2 cursor-pointer mb-12"
                onClick={onBackward}
            />

            <h1 className="text-dark-kiwi font-semibold mb-10 text-2xl">
                Detalles del método de pago
            </h1>
            <div className="w-full flex flex-col gap-4 mb-auto">
                <div className="flex items-center rounded-lg bg-gray-50-kiwi p-4 mb-10">
                    <div className="w-10 h-10 grow-0 shrink-0 rounded-full mr-4 flex justify-center items-center bg-white text-regal-blue-kiwi">
                        {type === 'bank' ? (
                            <BankIcon className="w-6" />
                        ) : (
                            <BankCardIcon className="w-6" />
                        )}
                    </div>
                    <div className="">
                        <div className="leading-5 font-semibold">
                            {type === 'bank'
                                ? 'Cuenta de banco'
                                : 'Tarjeta crédito'}
                        </div>
                        <p className="text-xs text-gray-500-kiwi mt-1">
                            **** **** **** {getMethodTitle()}
                        </p>
                    </div>
                </div>

                {type === 'bank' ? (
                    <Fragment>
                        <div className="flex justify-between gap-2">
                            <div>Tipo de cuenta</div>
                            <b className="text-right">
                                {
                                    method?.CheckingAccount.accountType.split(
                                        '.'
                                    )[2]
                                }
                            </b>
                        </div>
                        <div className="flex justify-between gap-2">
                            <div>Banco</div>
                            <b className="text-right">
                                {method?.CheckingAccount.bankName}
                            </b>
                        </div>
                        <div className="flex justify-between gap-2">
                            <div>Número de ruta</div>
                            <b className="text-right">
                                {method?.CheckingAccount.routingNumber}
                            </b>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="flex justify-between gap-2">
                            <div>Últimos 4 digitos</div>
                            <b className="text-right">
                                {String(method?.CreditCard?.cardNumber).slice(
                                    -4
                                )}
                            </b>
                        </div>
                        <div className="flex justify-between gap-2">
                            <div>Franquicia</div>
                            <b className="text-right">
                                {method?.CreditCard?.cardData?.brand}
                            </b>
                        </div>
                    </Fragment>
                )}
            </div>

            {method.isPrimary === 1 && (
                <p className="text-xs text-gray-500-kiwi mt-10">
                    Esta cuenta esta registrada como su método de pago principal
                </p>
            )}
        </Fragment>
    )
}
