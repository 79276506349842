
import BaseModal from '../BaseModal'
import PropTypes from 'prop-types'

const RejectedLoanReason = ({ isVisible, closeModal, reason, email }) => {
    return (
        <BaseModal
            title="Conoce los detalles sobre nuestra decisión"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6 mt-6">
                <p
                    dangerouslySetInnerHTML={{
                        __html:
                            reason.subreason?.description ?? reason.description
                    }}></p>
                <p>
                    Encontrarás información detallada sobre la decisión en tu
                    correo electrónico <strong>{email}</strong>.
                </p>
            </div>
        </BaseModal>
    )
}

RejectedLoanReason.propTypes = {
    isVisible: PropTypes.bool,
    closeModal: PropTypes.func,
    reason: PropTypes.object,
    email: PropTypes.string
}

export default RejectedLoanReason
