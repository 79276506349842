import  { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import SuccessMoneySVG from 'assets/onboarding_v3/success_money.svg'

export default function CreditBuilderSuccessPaymment() {
    const navigate = useNavigate()

    const onContinue = () => {
        navigate('/credit-builder/active')
    }

    return (
        <Fragment>
            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img className="mx-auto mb-8" src={SuccessMoneySVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Pago realizado exitosamente
                </h1>
                <p>Se ha realizado tu pago exitosamente</p>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onContinue}>
                Continuar
            </button>
        </Fragment>
    )
}
