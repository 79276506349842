import PropTypes from 'prop-types';

const BillIcon = ({ className, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5C4 3.34315 5.34315 2 7 2H17C18.6569 2 20 3.34315 20 5V21C20 21.3844 19.7797 21.7348 19.4332 21.9013C19.0867 22.0678 18.6755 22.021 18.3753 21.7809L16.5 20.2806L14.6247 21.7809C14.2268 22.0992 13.6532 22.0674 13.2929 21.7071L12 20.4142L10.7071 21.7071C10.3468 22.0674 9.7732 22.0992 9.37531 21.7809L7.5 20.2806L5.6247 21.7809C5.32453 22.021 4.91328 22.0678 4.56681 21.9013C4.22035 21.7348 4 21.3844 4 21V5ZM7 4C6.44772 4 6 4.44772 6 5V18.9194L6.8753 18.2191C7.24052 17.927 7.75948 17.927 8.12469 18.2191L9.92578 19.66L11.2929 18.2929C11.6834 17.9024 12.3166 17.9024 12.7071 18.2929L14.0742 19.66L15.8753 18.2191C16.2405 17.927 16.7595 17.927 17.1247 18.2191L18 18.9194V5C18 4.44772 17.5523 4 17 4H7ZM8 9C8 8.44772 8.44772 8 9 8H15C15.5523 8 16 8.44772 16 9C16 9.55228 15.5523 10 15 10H9C8.44772 10 8 9.55228 8 9ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H12C12.5523 14 13 13.5523 13 13C13 12.4477 12.5523 12 12 12H9Z"
      fill="#043960"
    />
  </svg>
);

BillIcon.propTypes = {
    className: PropTypes.string,
};

export default BillIcon;