import PropTypes from 'prop-types';

const HomeIcon = ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M10.772 2.688a2 2 0 0 1 2.456 0l8.384 6.52c.753.587.337 1.792-.615 1.792H20v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-8h-.997c-.953 0-1.367-1.206-.615-1.791l8.384-6.52ZM5.625 9.225c.229.185.375.468.375.785V19h12v-8.99c0-.317.146-.6.375-.785L12 4.267 5.625 9.225Z"
        />
    </svg>
);

HomeIcon.displayName = 'HomeIcon';

HomeIcon.propTypes = {
    className: PropTypes.string,
};

export default HomeIcon;
