import BackButton from "components/BackButton";
import { UserContext } from "context/UserContext";
import { useContext } from "react";

export default function CreditMonitoringRegister() {
  const { state: userState } = useContext(UserContext);
  const { user } = userState;
  delete user.source_url;

  return (
    <>
      <div className="flex flex-col w-full pl-6 pt-10 pb-8">
        <BackButton route="/home" />
      </div>
      <div className="flex flex-col w-full flex-1">
        <iframe
          src={`${window.location.origin}/array-register?user=${JSON.stringify(user)}`}
          className="w-full flex-1"
        />
      </div>
    </>
  );
}
