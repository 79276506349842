import banks from "data/banks/PR";
import CITIES from "data/cities";

export default {
  create: {
    allowedAge: {
      text: "Debes tener 21 años para poder registrarte",
      number: 21,
    },
  },
  home: {
    creditBuilderAvailable: true,
    creditMonitoringAvailable: true,
  },
  address: {
    cities: CITIES.PR,
  },
  approved: {
    minTerm: 6,
    banks,
    termsMonths(options, amount) {
      const terms =
        options.find((offer) => offer.amount >= amount)?.month ?? 24;
      return terms;
    },
    daysOptionsList: [
      15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
      33, 34, 35,
    ],
    lastDayOption: true,
    onCalculateInstallment(loanAmount, interestRate, months) {
      const monthlyInterestRate = interestRate / 100 / 12;

      const monthlyPayment =
        (loanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -months));

      return Number(monthlyPayment.toFixed(2));
    },
    paymentFrequency: "mensual",
    personaTemplate: "itmpl_J3L1kSS65gYtxCT5Meo914fY",
  },
};
