import PropTypes from "prop-types";

const Store2Line = ({ className, ...props }) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 3C0 1.34315 1.34315 0 3 0H16C17.6569 0 19 1.34315 19 3V16C19 17.6569 17.6569 19 16 19H3C1.34315 19 0 17.6569 0 16V3ZM17 3V4C17 5.10457 16.1046 6 15 6C13.8954 6 13 5.10457 13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4V4.5C11 5.32843 10.3284 6 9.5 6C8.6716 6 8 5.32843 8 4.5V4C8 3.44772 7.55228 3 7 3C6.44772 3 6 3.44772 6 4C6 5.10457 5.10457 6 4 6C2.89543 6 2 5.10457 2 4V3C2 2.44772 2.44772 2 3 2H16C16.5523 2 17 2.44772 17 3ZM15 8C13.8796 8 12.8669 7.53938 12.1407 6.7972C11.499 7.53418 10.5539 8 9.5 8C8.4461 8 7.50097 7.53418 6.85931 6.7972C6.13315 7.53939 5.12037 8 4 8C3.27143 8 2.58835 7.80521 2 7.46487V16C2 16.5523 2.44772 17 3 17H6V13C6 11.3431 7.34315 10 9 10H10C11.6569 10 13 11.3431 13 13V17H16C16.5523 17 17 16.5523 17 16V7.46487C16.4117 7.80521 15.7286 8 15 8ZM11 17V13C11 12.4477 10.5523 12 10 12H9C8.4477 12 8 12.4477 8 13V17H11Z"
      fill="currentColor"
    />
  </svg>
);

Store2Line.propTypes = {
  className: PropTypes.string,
};

export default Store2Line;
