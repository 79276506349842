import { useEffect, Fragment, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CreditBuilderContext } from "context/CreditBuilderContext";

import Persona from "templates/Persona";
import ProgressBar from "components/ProgressBar";
import dateDifference from "utils/dateDifference";

export default function CreditBuilderPersona() {
  const { state: creditState, resetState: resetCreditState } =
    useContext(CreditBuilderContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  /**
   * Se necesita esperar a que el componente padre
   * este renderizado antes de renderizar los hijos...
   * Por una validacion en Persona...
   */
  useEffect(() => {
    setLoading(false);
    verifyUpToDateRepaymentTerms();
  }, []);

  const verifyUpToDateRepaymentTerms = async () => {
    const isOneDayAfter = (() => {
      if (creditState.repaymentTerms.created_at) {
        const diffDays = dateDifference(creditState.repaymentTerms.created_at);

        if (diffDays >= 2) {
          return true;
        }
      }

      return false;
    })();

    if (isOneDayAfter) {
      setLoading(true);
      await resetCreditState();
      navigate("/credit-builder/buy-course");
    }
  };

  const onBackward = () => {
    navigate("/credit-builder/payment-profile");
  };

  const onContinue = () => {
    navigate("/credit-builder/contract");
  };

  return (
    <Fragment>
      <ProgressBar progress="7/8" onBackward={onBackward} />

      {loading === false && (
        <Persona
          origin="credit-builder"
          template="itmpl_J3L1kSS65gYtxCT5Meo914fY"
          inquiryTag="credit_builder"
          context={CreditBuilderContext}
          onBackward={onBackward}
          onContinue={onContinue}
        />
      )}
    </Fragment>
  );
}
