import PropTypes from 'prop-types';

const Wallet3 = ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M16 4a1 1 0 0 1 .117 1.993L16 6H5.5a.5.5 0 0 0-.09.992L5.5 7H19a2 2 0 0 1 1.995 1.85L21 9v9a2 2 0 0 1-1.85 1.995L19 20H5a2 2 0 0 1-1.995-1.85L3 18V6.5a2.5 2.5 0 0 1 2.336-2.495L5.5 4zM5 8.95V18h14V9H5.5c-.114 0-.227-.008-.337-.022zM15.5 12a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3"
        />
    </svg>
);

Wallet3.displayName = 'Wallet3';

Wallet3.propTypes = {
    className: PropTypes.string,
};

export default Wallet3;
