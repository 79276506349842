import formatDate from 'utils/formatDate';
import PropTypes from 'prop-types';
import TRANSACTION_CONFIG from './utils';
import { formatAmount } from './utils';
import { useEffect, useState } from 'react';

const ItemActivityHistory =  ({
    transaction,
    showStatus = false,
} ) => {

    const [currentConfig, setCurrentConfig] = useState(TRANSACTION_CONFIG.default);
    const [transactionDate, setTransactionDate] = useState('');
    const [merchantName, setMerchantName] = useState('');

    useEffect(() => {
        setCurrentConfig(getTransactionConfig());
        setTransactionDate(formatDate(transaction?.created_at, 'MMMM DD, YYYY'));
        setMerchantName(transaction?.offer?.store?.merchant?.merchant_name ?? '---');
    }, [transaction]);


    const getTransactionConfig = () => {
        const status = transaction?.status;
        if (!status || !TRANSACTION_CONFIG[status]) {
            return TRANSACTION_CONFIG.default;
        }
        return TRANSACTION_CONFIG[status];
    };


    return (
        <div className="flex items-center w-full justify-between gap-4">
            <div className="flex items-center gap-4">
                <div className="flex justify-center items-center shrink-0 bg-slate-100-kiwi h-10 w-10 rounded-full">
                    <currentConfig.icon className="w-6 text-regal-blue-kiwi" />
                </div>
                <div className="grow flex flex-col w-full mr-4">
                    <p className="text-base line-clamp-1">
                        {merchantName}
                    </p>
                    <p className="text-xs font-normal text-gray-500-kiwi capitalize">
                        {transactionDate}
                    </p>
                </div>
            </div>
            <div className="text-right">
                <p className={`text-slate-700-kiwi font-semibold ${currentConfig.class}`}>
                    {formatAmount(transaction.cashback_amount, transaction.status)}
                </p>
                {showStatus && (
                    <p className="text-slate-500-kiwi text-xs">
                        {currentConfig.title}
                    </p>
                )}
            </div>
        </div>
    );
}


export default ItemActivityHistory;

ItemActivityHistory.propTypes = {
    transaction: PropTypes.shape({
        status: PropTypes.string,
        cashback_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        created_at: PropTypes.string,
        offer: PropTypes.shape({
            store: PropTypes.shape({
                merchant: PropTypes.shape({
                    merchant_name: PropTypes.string
                })
            })
        })
    }),
    showStatus: PropTypes.bool
};