import BaseModal from '../BaseModal'
import PropTypes from 'prop-types';

const ConsentTermsModal = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="Términos y condiciones"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6 text-justify mt-10">
                <div className="font-semibold">Pagos:</div>
                <p>
                    Puede realizar un máximo de dos pagos en cualquier día.
                    Puede hacerlo realizando o programando hasta dos pagos cada
                    día de su ciclo de facturación actual. También puede
                    programar hasta cuatro pagos para que se realicen en ciclos
                    de facturación posteriores, pero nunca más de dos por día, y
                    nunca más de 90 días antes del día en que está programando
                    los pagos. También pueden aplicarse otras restricciones.
                </p>
                <p>
                    La cantidad que pague en cualquier día no puede exceder su
                    saldo actual más un 7.5%. Si la cantidad que paga excede el
                    saldo actual más un 7.5% en la fecha de pago, reduciremos la
                    cantidad que retiramos para que sea igual al saldo actual en
                    el día en que retiramos los fondos.
                </p>
                <div className="font-semibold">Pagos el mismo día:</div>
                <p>
                    La hora límite para los pagos de facturas en línea es
                    medianoche. Esto significa que acreditaremos su cuenta a
                    partir del día del calendario, según la Hora del Este, en
                    que recibimos su solicitud de pago. Verá una reducción en su
                    saldo actual y en su pago mínimo adeudado tan pronto como
                    recibamos su solicitud de pago en línea.
                </p>
                <p>
                    Sin embargo, su crédito disponible puede no aumentar
                    inmediatamente por el monto de su pago y puede tomar hasta 5
                    días hábiles (en algunos casos hasta 14 días hábiles)
                    reflejar el monto de su pago.
                </p>
                <p>
                    Tenga en cuenta que cualquier pago realizado después de las
                    5 p. m. AST en la fecha de cierre de su estado de cuenta no
                    se incluirá en el estado de cuenta de facturación de ese
                    ciclo, sino que se incluirá en el estado de cuenta de
                    facturación del próximo ciclo.
                </p>
                <div className="font-semibold">Pagos devueltos:</div>
                <p>
                    En caso de que un pago electrónico sea devuelto impagado por
                    su institución financiera por cualquier motivo, nos
                    reservamos el derecho de cobrar y usted acepta pagar una
                    tarifa por pago devuelto. Además, podrían aplicarse cargos
                    por mora según los términos y condiciones de su Acuerdo de
                    Tarjeta.
                </p>
                <div className="font-semibold">Limitaciones:</div>
                <p>
                    Si no realiza un pago en línea al menos una vez cada
                    dieciocho (18) meses, es posible que se elimine su cuenta de
                    pago y deba agregarla nuevamente. Puede editar o cancelar un
                    pago hasta las 5 p. m. AST en su fecha programada, a menos
                    que al editar el pago su saldo actual exceda su límite de
                    crédito.
                </p>
            </div>
        </BaseModal>
    )
}

ConsentTermsModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default ConsentTermsModal
