import PropTypes from "prop-types";
import PrimaryButton from "components/Button/Primary";
import Logo from "../../../assets/rewards/not_rewards.png";
import { useNavigate } from "react-router-dom";
export const EmptyStateRewards = ({ show }) => {
  const navigate = useNavigate()
  if (!show) return null;

  return (
    <div className=" flex align-center flex-col justify-center mt-0 text-center items-center gap-6">
      <img src={Logo} alt="logo" className="mt-14" />

      <div className="flex flex-col gap-3">
        <p className="text-base font-semibold  text-slate-600">
          Aún no tienes recompensas
        </p>
        <p className="text-sm text-slate-500">
          ¿Estás listo para comenzar a ganarlas?<br />Invita a tus amigos y empieza a generar tu<br />historial de cashback.
        </p>
      </div>
      <PrimaryButton onClick={() => navigate("/referrals")}>Invitar ahora</PrimaryButton>
    </div>
  );
};

EmptyStateRewards.propTypes = {
  show: PropTypes.bool,
};

EmptyStateRewards.defaultProps = {
  show: false,
};
