const Badge = ({ text, icon, className }) => {
  return (
    <div
      className={`px-3 py-1 rounded-full text-xs flex items-center gap-1 ${className}`}
    >
      {icon && <img src={icon} className="h-4 w-4" />}
      {text}
    </div>
  );
};

export default Badge;
