import {
  CurrencyDollar2Icon,
  CurrencyDollarIcon,
  Wallet3Icon,
  QuestionIcon,
} from "assets/icons_v3/fonts";
import BackwardButton from "components/Button/Backward";
import RouletteHowWorks from "components/Modals/RouletteHowWorks";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

const prizes = [
  {
    title: "Efectivo de $1,000",
    subtitle: "Premio de mayor valor",
    icon: CurrencyDollar2Icon,
    isPrincipal: true,
    bgColor: "bg-golden-100-kiwi",
    textColor: "text-golden-500-kiwi",
  },
  {
    title: "Efectivo desde $50 a $1,000",
    icon: CurrencyDollarIcon,
    isPrincipal: false,
  },
  {
    title: "Abonos desde $50 a ¡Saldo de préstamo!",
    icon: Wallet3Icon,
    isPrincipal: false,
  },
];

export default function RoulettePrizes() {
  const navigate = useNavigate();
  const [howWorksVisible, setHowWorksVisible] = useState(false);

  const onBackward = () => {
    navigate("/roulette");
  };

  const onToggleHowWorks = () => {
    setHowWorksVisible((visible) => !visible);
  };

  return (
    <Fragment>
      <div className="flex justify-between">
        <BackwardButton onBackward={onBackward} />

        <div onClick={onToggleHowWorks} className="cursor-pointer ">
          <QuestionIcon />
        </div>
      </div>

      <h1 className="text-dark-kiwi font-semibold text-2xl mb-8">
        Premios de la semana
      </h1>

      <div className="grid grid-cols-2 gap-4 text-regal-blue-950-kiwi">
        {prizes.map((prize, index) => {
          const Icon = prize.icon;
          return (
            <div
              key={index}
              className={`border border-slate-200-kiwi rounded-xl p-4 ${
                prize.isPrincipal
                  ? "flex items-center gap-4 col-span-2"
                  : "text-center"
              }`}
            >
              <div
                className={`w-10 h-10 rounded-full ${
                  prize.isPrincipal
                    ? `${prize.bgColor} ${prize.textColor}`
                    : "bg-slate-100-kiwi mx-auto mb-4"
                } flex`}
              >
                <Icon className="m-auto" />
              </div>
              <div>
                <div
                  className={`font-semibold ${!prize.isPrincipal ? "text-sm" : ""}`}
                >
                  {prize.title}
                </div>
                {prize.subtitle && (
                  <div className="text-gray-600-kiwi text-xs">
                    {prize.subtitle}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <RouletteHowWorks
        isVisible={howWorksVisible}
        closeModal={onToggleHowWorks}
      />
    </Fragment>
  );
}
