import { ArrowRightIcon, CheckCircleIcon } from 'assets/icons_v3/fonts'
import VideoSVG from 'assets/icons_v3/video.svg'
import PropTypes from 'prop-types';

const PaidoutCourseComponent = ({ course, onCourseDetails }) => {
    return (
        <div
            className="flex cursor-pointer"
            onClick={() => onCourseDetails(course)}>
            <div className="rounded-l-2xl p-4 bg-blue-25-kiwi w-20 shrink-0 flex">
                <img src={VideoSVG} className="m-auto" />
            </div>
            <div className="p-4 border border-l-0 border-slate-200-kiwi rounded-r-2xl w-full">
                <div className="flex justify-between gap-4 text-sm font-semibold">
                    {course.product.name}
                    <ArrowRightIcon className="w-5 text-slate-400-kiwi shrink-0" />
                </div>
                <div>
                    <div className="text-xs text-green-kiwi flex items-center gap-2">
                        <CheckCircleIcon className="w-5 shrink-0" />
                        Finalizado
                    </div>
                </div>
            </div>
        </div>
    )
}

PaidoutCourseComponent.propTypes = {
    course: PropTypes.shape({
        product: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    onCourseDetails: PropTypes.func.isRequired,
};

export default PaidoutCourseComponent
