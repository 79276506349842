import { forwardRef, useImperativeHandle, useRef } from 'react'
import BaseModal from '../Modal'

const ACHModal = forwardRef((props, ref) => {
    const BaseModalRef = useRef()

    useImperativeHandle(ref, () => ({
        ...BaseModalRef.current
    }))

    return (
        <BaseModal
            title="¿Cómo funcionan los pagos automáticos mediante las transferencias electrónicas de fondos (ACH)?"
            ref={BaseModalRef}>
            <p>
                Los pagos automáticos pueden ser a través de transferencias ACH.
                Un ACH es una transferencia electrónica de fondos desde tu
                cuenta bancaria. Si optas por el método de pago automático, tus
                pagos se deducirán automáticamente de tu cuenta bancaria
                mediante ACH cuando sea el momento de pagar. Para más detalles,
                consulta el Acuerdo de Elección y Autorización de Pago.
            </p>
        </BaseModal>
    )
})

ACHModal.displayName = 'ACHModal'

export default ACHModal
