import PropTypes from 'prop-types';

const HeadphoneIcon = ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M5 11a7 7 0 0 1 14 0v2.035c1.696.243 3 1.702 3 3.465v.25A3.25 3.25 0 0 1 18.75 20 1.75 1.75 0 0 1 17 18.25V11a5 5 0 0 0-10 0v7.25A1.75 1.75 0 0 1 5.25 20 3.25 3.25 0 0 1 2 16.75v-.25a3.5 3.5 0 0 1 3-3.465V11Zm0 4.085A1.5 1.5 0 0 0 4 16.5v.25c0 .605.43 1.11 1 1.225v-2.89Zm14 0v2.89c.57-.116 1-.62 1-1.225v-.25a1.5 1.5 0 0 0-1-1.415Z"
        />
    </svg>
);

HeadphoneIcon.displayName = 'HeadphoneIcon';

HeadphoneIcon.propTypes = {
    className: PropTypes.string,
};

export default HeadphoneIcon;
