import React, { useContext, useEffect, useState } from "react";
import { UsersAPI } from "api";
import { useNavigate } from "react-router-dom";

import FacebookLogoSVG from "assets/logos/social/facebook.svg";
import InstagramLogoSVG from "assets/logos/social/instagram.svg";
import GoogleLogoSVG from "assets/logos/social/google.svg";
import FamilyLogoSVG from "assets/logos/social/family.svg";
import YoutubeLogoSVG from "assets/logos/social/youtube.svg";
import TiktokLogoSVG from "assets/logos/social/tiktok.svg";
import RadioLogoSVG from "assets/logos/social/radio.svg";
import TVLogoSVG from "assets/logos/social/tv.svg";
import XLogoSVG from "assets/logos/social/x.svg";
import OtherLogoSVG from "assets/logos/social/other.svg";
import LoadingModal from "components/Modals/Loading";
import { UserContext } from "context/UserContext";

export default function CreateAccountAttribution() {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const { state: userState } = useContext(UserContext);

  useEffect(() => {
    if (userState.user.id === undefined) {
      return navigate("/create-account");
    }
  }, []);

  const social = [
    {
      icon: FacebookLogoSVG,
      title: "Facebook",
      description: "Publicidad en Facebook",
    },
    {
      icon: InstagramLogoSVG,
      title: "Instagram",
      description: "Publicidad en Instagram",
    },
    {
      icon: GoogleLogoSVG,
      title: "Google",
      description: "Búsqueda en Google",
    },
    {
      icon: FamilyLogoSVG,
      title: "Amigo o Familiar",
      description: "Amigo, familiar, vecino o compañero de trabajo",
    },
    {
      icon: YoutubeLogoSVG,
      title: "Youtube",
      description: "Publicidad en YouTube",
    },
    {
      icon: TiktokLogoSVG,
      title: "TikTok",
      description: "Publicidad en TikTok",
    },
    {
      icon: RadioLogoSVG,
      title: "Radio",
      description: "Publicidad en radio",
    },
    {
      icon: TVLogoSVG,
      title: "TV",
      description: "Publicidad en TV",
    },
    {
      icon: XLogoSVG,
      title: "X",
      description: " Publicidad en X",
    },
    {
      icon: OtherLogoSVG,
      title: "Otra fuente",
    },
  ];

  const onSelectSocial = async (title) => {
    try {
      setShowLoader(true);

      await UsersAPI.update(userState.user.id, {
        attribution_source: title,
      });

      navigate("/create-account/success");
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      <LoadingModal isVisible={showLoader} />

      <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
        ¿Cómo nos encontraste?
      </h1>

      <p className="mb-10">
        Saber cómo llegaste a Kiwi nos ayudará a ofrecer asesoramiento
        financiero a más personas.
      </p>

      <div className="flex flex-col gap-6">
        {social.map((item) => (
          <div
            key={item.title}
            className="p-4 bg-slate-50-kiwi rounded-lg cursor-pointer flex items-center"
            onClick={() => onSelectSocial(item.title)}
          >
            <img src={item.icon} className="shrink-0 mr-4" alt={item.title} />
            <div>
              <div className="font-semibold">{item.title}</div>
              <div className="text-xs text-gray-500-kiwi">
                {item.description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
