import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserContext";
import mixpanel from "utils/mixpanel";
import PrivacyTermsModal from "components/Modals/PrivacyTerms";
import PaymentTermsModal from "components/Modals/PaymentTerms";
import Cookies from "js-cookie";
import Subtract from "assets/subtractDynamic";

import { LogoutIcon } from "@heroicons/react/outline";
import Menu from "templates/Menu";
import {
  Document2Icon,
  GiftIcon,
  MailSendIcon,
  NotificationIcon,
  SafetyCertificateIcon,
  User3Icon,
  BankIcon,
} from "assets/icons_v3/fonts";
import MenuLink from "components/MenuLink";
import useNotificationsCenter from "hooks/useNotificationsCenter";

export default function Settings() {
  const navigate = useNavigate();
  const notifications = useNotificationsCenter();
  const { state: userState } = useContext(UserContext);
  const [privacyTermsVisible, setPrivacyTermsVisible] = useState(false);
  const [termsConditionsVisible, setTermsConditionsVisible] = useState(false);

  const baseMenuList = [
    [
      {
        icon: <User3Icon />,
        title: "Información personal",
        action: function () {
          navigate("/personal-information");
        },
      },
      {
        icon: <GiftIcon />,
        title: "Referir a un amigo",
        action: function () {
          mixpanel.track("Referrals Page", {
            Type: "Profile",
          });
          navigate("/referrals");
        },
      },
      {
        icon: <MailSendIcon />,
        title: "Contáctanos por correo",
        description: "support@kiwicredito.com",
        action: function () {
          const mail = document.createElement("a");
          mail.href = "mailto:support@kiwicredito.com";
          mail.click();
          mail.remove();
        },
      },
    ],
    [
      {
        icon: <SafetyCertificateIcon />,
        title: "Política de privacidad",
        action: function () {
          setPrivacyTermsVisible(true);
        },
      },
      {
        icon: <Document2Icon />,
        title: "Términos y condiciones",
        action: function () {
          setTermsConditionsVisible(true);
        },
      },
    ],
    [
      {
        icon: <Document2Icon />,
        title: "Documentos y contratos",
        action: function () {
          navigate("/documents");
        },
      },
    ],
    [
      {
        section: "bank-accounts",
        icon: <BankIcon />,
        title: "Cuentas bancarias",
        action: function () {
          navigate("/bank-accounts");
        },
      },
    ],
    [
      {
        icon: <NotificationIcon />,
        title: "Notificaciones",
        action: function () {
          navigate("/notifications");
        },
      },
    ],
  ];
  const [menuList, setMenuList] = useState(baseMenuList);

  const onLogout = async () => {
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove("dmp_user_id");
    window.open("/login", "_self");
  };

  useEffect(() => {
    setMenuList(
      menuList.map((submenu) =>
        submenu.map((item) => {
          const notification = notifications.find((n) =>
            n.sections.includes(item.section),
          );
          if (notification)
            return {
              ...item,
              notification: {
                type: notification.type,
              },
            };
          return item;
        }),
      ),
    );
  }, []);

  return (
    <div className="w-full min-h-full flex flex-col px-6 pt-24 pb-[136px]">
      <PrivacyTermsModal
        isVisible={privacyTermsVisible}
        closeModal={() => setPrivacyTermsVisible(false)}
      />
      <PaymentTermsModal
        isVisible={termsConditionsVisible}
        closeModal={() => setTermsConditionsVisible(false)}
      />

      <div className="flex flex-col justify-center items-center text-center mb-12">
        <div className="shrink-0 grow-0 w-15 h-15 text-blue-kiwi">
          <Subtract />
        </div>
        <h2 className="font-semibold text-xl mt-4 capitalize">
          {userState.user.first_name} {userState.user.first_surname}
        </h2>
        <p className="text-sm">{userState.user.email}</p>
      </div>

      <div className="flex flex-col gap-10">
        {menuList.map((submenu, index) => (
          <div className="flex flex-col gap-2" key={index}>
            {submenu.map((link) => (
              <MenuLink
                key={link.title}
                title={link.title}
                onClick={() => link.action.call(link)}
                icon={link.icon}
                description={link.description}
                notification={link.notification}
              />
            ))}
          </div>
        ))}

        <div>
          <div className="flex items-center cursor-pointer w-max gap-4">
            <div className="bg-gray-100 w-10 h-10 rounded-full flex justify-center items-center">
              <LogoutIcon className="w-6 text-gray-500-kiwi" />
            </div>
            <p className="font-medium" onClick={onLogout}>
              Cerrar sesión
            </p>
          </div>
        </div>
      </div>

      <Menu selected="profile" />
    </div>
  );
}
