import PropTypes from 'prop-types';

const createCustomMarker = ({ map, position, contentHTML, offer, onSelectOffer }) => {
    class CustomOverlay extends window.google.maps.OverlayView {
        constructor(position, content) {
            super();
            this.position = position;
            this.content = content;
        }

        onAdd() {
            const panes = this.getPanes();
            this.div = document.createElement('div');
            this.div.innerHTML = this.content;
            this.div.style.position = 'absolute';
            this.div.style.cursor = 'pointer';
            this.div.style.transform = 'translate(-50%, -50%)';
            panes.overlayMouseTarget.appendChild(this.div);

            this.div.addEventListener('click', () => {
                onSelectOffer(offer);
            });
        }

        draw() {
            const overlayProjection = this.getProjection();
            const position = overlayProjection.fromLatLngToDivPixel(
                this.position,
            );
            if (this.div) {
                this.div.style.left = `${position.x}px`;
                this.div.style.top = `${position.y}px`;
            }
        }

        onRemove() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            }
        }
    }

    const overlay = new CustomOverlay(
        new window.google.maps.LatLng(position.lat, position.lng),
        contentHTML,
    );
    overlay.setMap(map);

    return overlay;
};

createCustomMarker.propTypes = {
    map: PropTypes.object.isRequired,
    position: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired
    }).isRequired,
    contentHTML: PropTypes.string.isRequired,
    offer: PropTypes.object.isRequired,
    onSelectOffer: PropTypes.func.isRequired
};

export default createCustomMarker;
