import Persona from "persona";
import PropTypes from "prop-types";
import { useState, useContext, useEffect, Fragment } from "react";
import { UserContext } from "context/UserContext";

import LoadingModal from "components/Modals/Loading";
import { ActivityAPI } from "api";

export default function PersonaVerificationTemplate({
  template,
  onBackward,
  onVerification,
}) {
  const { state: userState, getSessionTokenInquiryId } =
    useContext(UserContext);
  const [personaClient, setPersonaClient] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    openPersonaVerification();
  }, []);

  useEffect(() => {
    return () => {
      if (
        personaClient &&
        (personaClient._isOpen === true || personaClient._isLoading === true)
      ) {
        personaClient.destroy();
      }
    };
  }, [personaClient]);

  const getSessionToken = async () => {
    const { id } = userState?.user ?? undefined;
    const {
      data: { data },
    } = await getSessionTokenInquiryId(id);

    return data;
  };

  const openPersonaVerification = async () => {
    const {
      id: referenceId,
      phone_number,
      email,
      zipcode,
    } = userState?.user ?? undefined;
    const { id: inquiryId, sessionToken } = await getSessionToken();

    const client = new Persona.Client({
      inquiryId,
      sessionToken,
      referenceId,
      templateId: inquiryId ? null : template,
      environmentId: process.env.REACT_APP_PERSONA_ENV,
      fields: {
        api_address_postal_code: zipcode,
        addressPostalCode: zipcode,
        phoneNumber: phone_number,
        emailAddress: email,
      },
      onReady: () => {
        client.open();
        setShowLoader(false);
      },
      onComplete: async ({ inquiryId }) => {
        onVerification(inquiryId);
        await ActivityAPI.createActivity({
          name: "Persona Validation Completed",
        });
      },
      onCancel: async ({ inquiryId }) => {
        onBackward();
        await ActivityAPI.createActivity({
          name: "Persona Validation Canceled",
          payload: {
            inquiry_id: inquiryId,
          },
        });
      },
      onError: async (error) => {
        onVerification();
        await ActivityAPI.createActivity({
          name: "Persona Validation Error",
          payload: error,
        });
      },
    });

    setPersonaClient(client);
  };

  return (
    <Fragment>
      <LoadingModal isVisible={showLoader} />

      <div className="flex flex-col items-center justify-center justify-self-stretch flex-1 text-center">
        <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
          Validación de identidad
        </h1>
        <p>
          Espera unos momentos, estamos conectandonos con Persona para verificar
          tu identidad...
        </p>
      </div>
    </Fragment>
  );
}

PersonaVerificationTemplate.propTypes = {
  template: PropTypes.string.isRequired,
  onBackward: PropTypes.func.isRequired,
  onVerification: PropTypes.func.isRequired,
};
