import PropTypes from "prop-types";
import ToggleSwitch from "components/ToggleSwitch";

const ContentNotifications = ({ title, description, checked, handleCheck }) => {
  return (
    <div className="grid  grid-cols-[1fr_45px] gap-6">
      <div>
        <p className="text-base mb-4 text-slate-900-kiwi">{title}</p>
        <p className="text-sm text-slate-500-kiwi">{description}</p>
      </div>
      <div>
        <ToggleSwitch checked={checked} onChange={handleCheck} />
      </div>
    </div>
  );
};

ContentNotifications.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  handleCheck: PropTypes.func.isRequired,
};

export default ContentNotifications;
