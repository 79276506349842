import { CreditLimitContext } from 'context/CreditLimitContext'
import  { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export default function CreditLimit() {
    const { resetState } = useContext(CreditLimitContext)

    useEffect(() => {
        /**
         * Al salir del flujo de credit_limit
         * se reinicia el contexto...
         */
        return () => {
            resetState()
        }
    }, [])

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <Outlet />
        </div>
    )
}
