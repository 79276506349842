import PropTypes from "prop-types";

const Container = ({ children, ...props }) => {
  return (
    <div
      className={`w-full min-h-full flex flex-col px-6 pt-10 pb-[136px] ${props.className}`}
    >
      {children}
    </div>
  );
};

Container.displayName = "Container";

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Container.defaultProps = {
  className: "",
};

export default Container;
