import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CashbackContext } from "context/CashbackContext";
import { UserContext } from "context/UserContext";
import { CashbackAPI } from "api";

import Button from "components/Button";
import BackwardButton from "components/Button/Backward";
import LoadingModal from "components/Modals/Loading";

import defaultBank from "assets/cashback/bank-default.svg";
import findLogoBank from "utils/Cashback/findLogoBanks";

export default function CurrentBank() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state: stateLocation } = location;
  const { state: cashbackState } = useContext(CashbackContext);
  const { state: userState } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [logoBank, setLogoBank] = useState("");

  useEffect(() => {
    initLogoBank();
  }, []);

  const initLogoBank = () => {
    const matchedBank = findLogoBank(stateLocation.current_bank.name);
    setLogoBank(matchedBank ? matchedBank : defaultBank);
  };

  const onBackward = async () => {
    if (userState.user.plaid_token !== null) {
      navigate("/cashback/offer-details");
    } else {
      navigate("/cashback/activate");
    }
  };

  const onContinue = async () => {
    setLoading(true);
    try {
      await CashbackAPI.createClaim({
        offer_id: cashbackState.selectedOffer.id,
        payment_method: stateLocation,
        cashback_id: cashbackState.cashback_id,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    navigate("/cashback/list-offers");
  };

  return (
    <div className="w-full min-h-full flex flex-col px-6 py-10">
      <LoadingModal isVisible={loading} />
      <BackwardButton onBackward={onBackward} />
      <div className="flex flex-col gap-10 w-full mb-6">
        <img
          src={logoBank}
          className="h-[124px] w-[124px] mx-auto rounded-full object-cover bg-white"
        />
        <div className="flex flex-col gap-4 text-center">
          <p className="text-dark-kiwi font-semibold text-2xl">
            Banco seleccionado para tu compra
          </p>
          <p className="text-slate-600-kiwi">
            Asegúrate de usar tu tarjeta de{" "}
            <span className="font-semibold">
              {stateLocation.current_bank.name}
            </span>{" "}
            al realizar tu compra.
          </p>
        </div>
      </div>
      <Button
        onClick={onContinue}
        className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto w-full"
      >
        Continuar
      </Button>
    </div>
  );
}
