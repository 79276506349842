import BaseModal from './../BaseModal'
import PropTypes from 'prop-types'

const ReferralTermsModal = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="Programa “Refiere a un amigo/a”"
            description="Actualizado: Mayo 11, 2023"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6 text-justify">
                <p>
                    A continuación, se describen los términos de servicio que se
                    aplican a la participación en el programa de referido (el
                    &quot;Programa de Referido&quot;) que actualmente ofrece
                    Kiwi Financial, Inc. (“Kiwi”) y sus afiliados. Si desea
                    participar en cualquier Programa de Referido descrito a
                    continuación, acepta los términos de dicho Programa de
                    Referido descritos en la Parte I a continuación, así como
                    los Términos y Condiciones Generales en la Parte II, que se
                    aplican al Programa de Referido.
                </p>

                <p>Tenga en cuenta que:</p>

                <ul className="list-disc pl-12">
                    <li>
                        Cualquier amigo a quien le gustaría recomendar debe usar
                        el enlace de referido de su producto específico para
                        registrarse en el producto que desea.
                    </li>
                    <li>
                        Solo una recompensa de referido por nuevo cliente. Usted
                        no recibirá múltiples recompensas de referido por el
                        mismo amigo/a que saca múltiples préstamos o productos.
                    </li>
                </ul>

                <p>Se aplican términos y condiciones adicionales.</p>

                <h5 className="underline">
                    Cada persona referida debe ser alguien con quien tenga una
                    relación directa y personal. No puede distribuir un enlace
                    de referido ni solicitar a nadie a través de correo
                    electrónico masivo, ninguna forma de publicidad comercial u
                    otros medios similares.
                </h5>
                <p>
                    Kiwi se reserva el derecho de revisar, investigar y
                    descalificar a cualquier persona para que no participe en el
                    Programa de Referido en cualquier momento, y se reserva el
                    derecho de no aceptar ningún referido que, a nuestro
                    exclusivo criterio, haya sido solicitado de manera
                    incompatible con el espíritu o los términos y condiciones
                    del Programa de Referido.
                </p>
                <p>
                    Cualquier persona referida a un préstamo Kiwi puede no tener
                    una cuenta Kiwi registrada existente. Para proteger la
                    privacidad de aquellos a quienes refiera, la información de
                    la cuenta o la elegibilidad de prospectos referidos no se
                    permite, en ninguna circunstancia, compartirse con los
                    recomendantes de referidos. Un referido exitoso se define
                    como: es nuevo en Kiwi, usa su enlace de referido personal y
                    único para crear una cuenta en la aplicación Kiwi y
                    registrarse en{' '}
                    <a
                        className="text-[#56D2FF] underline"
                        href="https://www.kiwicredito.com/"
                        target="_blank"
                        rel="noreferrer">
                        www.kiwicredito.com
                    </a>
                    , o en las respectivas tiendas de aplicativos, por primera
                    vez y dentro de las 24 horas de hacerle clic en su enlace de
                    referido. Las personas referidas deben abrir una cuenta Kiwi
                    elegible dentro de los 90 días posteriores al registro en
                    Kiwi para que se publiquen las bonificaciones respectivas.
                    Las cuentas elegibles incluyen un préstamo Kiwi Personal. No
                    se realizarán pagos por la apertura de cualquier tipo de
                    cuenta Kiwi que no sea un préstamo Kiwi Personal.
                </p>

                <h5 className="text-xl">Programa de Referidos — Parte I</h5>
                <p className="underline">Programa de Referido de Préstamos</p>
                <p>
                    Los términos a continuación y los establecidos en la Parte
                    II rigen su participación en el Programa de Referido de
                    Préstamos de Kiwi Financial, Inc (&quot;Kiwi&quot;) (el
                    &quot;Programa de Referido de Préstamos&quot;) que se
                    describe a continuación. El Programa de Referido de
                    Préstamos está abierto a cualquier consumidor que se
                    registre en el sitio web o aplicación móvil de Kiwi y que
                    resida en el Estado Libre Asociado de Puerto Rico (los
                    &quot;Recomendante(s) de Préstamos Elegibles&quot;).
                </p>
                <p>
                    Los Recomendantes de Préstamos Elegibles se limitan a
                    recomendar amigos, colegas, familiares y otras personas que
                    ellos (en conjunto, los &quot;Beneficiarios de Préstamos
                    Elegibles&quot;) creen que pueden estar interesados en
                    solicitar un producto de préstamo personal como se describe
                    en una campaña promocional específica (colectivamente, el
                    “Préstamo(s) Elegible(s)”). Kiwi se reserva el derecho de
                    descalificar a cualquier persona para que no participe en el
                    Programa de Referido de Préstamos en cualquier momento. Los
                    Recomendantes de Préstamos Elegibles NO recibirán una
                    bonificación por recomendación de referidos para cualquier
                    otro producto de Kiwi. Cualquier persona que viole estos
                    términos y condiciones no es elegible para el pago de ningún
                    bono.
                </p>
                <p>
                    Para recibir crédito por un referido bajo el Programa de
                    Referido de Préstamos, los Recomendantes de Préstamos
                    Elegibles deben enviar su enlace de referido único a un
                    Beneficiario de Préstamo Elegible, el Beneficiario de
                    Préstamo Elegible debe usar el enlace de referido único de
                    su Recomendante de Préstamo Elegible dentro de los 120 días
                    posteriores a la recepción del enlace único del Recomendante
                    de Préstamo Elegible y el Beneficiario de Préstamo Elegible
                    debe cumplir con los términos y condiciones de la campaña
                    promocional específica asociada con el enlace de referido
                    único que se utilizó para la referencia. Si el Beneficiario
                    de Préstamo Elegible utiliza el enlace de referencia único
                    del Recomendante de Préstamo Elegible y cumple con los
                    términos y condiciones de la campaña promocional específica
                    asociada con ese enlace de referencia único, Kiwi o su
                    designado pagará a (a) el Recomendante de Préstamo Elegible,
                    en caso que no tenga un préstamo activo con Kiwi, una
                    bonificación por recomendación en su cuenta corriente o de
                    ahorros personal por el monto especificado en la campaña
                    promocional asociada con el enlace de recomendación único
                    que se utilizó para la recomendación (la &quot;Bonificación
                    por Recomendación de Préstamo&quot;); o (b) si el
                    Recomendante de Préstamo Elegible, que tiene un préstamo
                    activo con Kiwi, la bonificación por recomendación se
                    acreditará en su saldo de préstamo pendiente. Solo se
                    otorgará una Bonificación por Recomendación de Préstamo por
                    cada Beneficiario de Préstamo Elegible recomendado,
                    independientemente de si el Beneficiario de Préstamo
                    Elegible obtiene múltiples Préstamos Elegibles. Además, y
                    sin perjuicio de lo anterior, se aplicará la siguiente
                    restricción adicional (1) no se pagará ninguna Bonificación
                    por Recomendación de Préstamos a los Recomendantes de
                    Préstamos Elegibles que no sean residentes del Estado Libre
                    Asociado de Puerto Rico. Un Recomendante de Préstamos
                    Elegible no ganará una Bonificación por Recomendación de
                    Préstamo si el Beneficiario de Préstamo Elegible tiene o
                    tenía una cuenta de préstamo Kiwi existente, o si el
                    Beneficiario de Préstamo Elegible no utiliza el enlace de
                    referido único proporcionado por el Recomendante de Préstamo
                    Elegible.
                </p>
                <p>
                    Si el Beneficiario de Préstamo Elegible ya tiene una cuenta
                    de préstamo de Kiwi, está en nuestra lista de exclusión
                    voluntaria o ya ha utilizado el enlace de referencia único
                    de otro Recomendante de Préstamo Elegible, es posible que no
                    se le envíe un correo electrónico al Beneficiario de
                    Préstamo Elegible. No podemos divulgarle ninguna información
                    sobre los préstamos financiados abiertos por los
                    Beneficiarios de Préstamo Elegible que nos refiera. La
                    participación en el Programa de Referido de Préstamos sirve
                    como una renuncia limitada por única vez a los derechos de
                    privacidad por parte de ambas partes, por lo que cada una
                    puede darse cuenta de la presencia de una relación de cuenta
                    con Kiwi.
                </p>

                <h5 className="title text-xl">
                    Términos y Condiciones Generales del Programa de Referidos —
                    Parte II
                </h5>
                <p className="underline">Límite de Referidos</p>
                <p>
                    En ningún caso, ninguna persona tendrá derecho a recibir más
                    de $500 en referidos acumulados bajo este Programa de
                    Referidos dentro de cualquier año calendario. Además, Kiwi
                    se reserva el derecho de, en cualquier momento, limitar la
                    cantidad máxima de veces que se puede usar un Enlace de
                    Referidos. Las Bonificaciones por Recomendación de Préstamos
                    se consideran ingresos diversos y pueden declararse al IRS
                    en el formulario 1099-MISC (o el formulario 1042- S, si
                    corresponde).
                </p>
                <p className="underline">Acuerdo de No Captación</p>
                <p>
                    Si participa en el Programa de Referido de Préstamos
                    descrito anteriormente (el &quot;Programa de
                    Referidos&quot;), acepta no participar en ninguna otra
                    solicitud de productos Kiwi. Aparte de lo establecido en
                    este documento, usted acepta no compensar ni aceptar ninguna
                    compensación de los solicitantes a quienes refiera a Kiwi a
                    través de cualquiera de los Programas de Referidos
                    correspondientes descritos anteriormente. Además, acepta que
                    sus actividades se limitarán a referir clientes potenciales
                    como se describe anteriormente, por lo que pueden estos
                    potenciales clientes decidir de forma independiente si
                    presentan una solicitud. No puede recopilar información de
                    destinatarios elegibles bajo ningún Programa de Referido,
                    ayudar a completar una solicitud o discutir términos
                    específicos del producto.
                </p>
                <p className="underline">Cero “Spam&quot;</p>
                <p>
                    Debe cumplir con todas las leyes y regulaciones aplicables
                    al solicitar referidos. Los Recomendantes de Préstamos
                    Elegibles no pueden comprar términos de búsqueda en Internet
                    ni anunciar públicamente los productos o el nombre de Kiwi
                    de ninguna manera. Además, cualquier distribución de un
                    enlace del programa que pueda constituir correo electrónico
                    comercial no solicitado o &quot;spam&quot;, cualquier
                    contenido que suplante o implique una afiliación o respaldo
                    por parte de Kiwi o cualquier subsidiaria de Kiwi, o
                    cualquier acción que viole los términos o condiciones
                    impuestos por Kiwi, están prohibidos y serán motivo de
                    terminación inmediata del Programa de Referido. No
                    honrraremos los pagos por referidos que se generen
                    utilizando cualquiera de los medios anteriores.
                </p>
                <p className="underline">Contratista Independiente</p>
                <p>
                    Su participación en el Programa de Referido es únicamente
                    como contratista independiente. Ni usted ni Kiwi tienen la
                    intención de establecer una relación laboral y no tienen
                    derecho a ningún beneficio. Si los bonos acumulados de
                    referidos pagados a una persona en un año calendario superan
                    los $599, entonces podemos solicitar que se complete un
                    Formulario W-9 antes del desembolso de fondos. Cualquier
                    pago que no se reclame dentro de los 180 días posteriores a
                    la fecha en que los fondos estuvieron disponibles debido a
                    que no se proporcionó la cuenta bancaria de depósito directo
                    o la información fiscal requerida está sujeto a
                    confiscación.
                </p>
                <p className="underline">Conflicto</p>
                <p>
                    En caso de que alguno de los términos de estos Términos de
                    Servicio entre en conflicto con cualquier término de una
                    campaña promocional específica asociada con un enlace de
                    referido único, prevalecerán los términos de estos Términos
                    de Servicio.
                </p>
                <p className="underline">
                    Descontinuación del Programa de Referidos Anterior
                </p>
                <p>
                    Los Programas de Referidos descritos en este documento
                    reemplazan todas las versiones anteriores de dichos
                    programas de referidos de Kiwi. Kiwi se reserva el derecho
                    de modificar o interrumpir temporal o permanentemente los
                    Programas de Referidos en cualquier momento, por cualquier
                    motivo, a nuestro exclusivo criterio y sin previo aviso.
                </p>
            </div>
        </BaseModal>
    )
}

ReferralTermsModal.propTypes = {
    isVisible: PropTypes.bool,
    closeModal: PropTypes.func,
}

export default ReferralTermsModal
