
import { Outlet } from 'react-router-dom'

export default function CombineAccounts() {
    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <Outlet />
        </div>
    )
}
