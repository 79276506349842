import mixpanel from "utils/mixpanel";
import phoneFormatter from "phone-formatter";
import PatternFormat from "react-number-format";
import { GAevent } from "utils/GA";
import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/AuthContext";

import LogoKiwiSVG from "assets/logo.svg";
import Button from "components/Button";
import Advisement from "components/Advisement";
import SMSTermsModal from "components/Modals/SMSTerms";
import Cookies from "js-cookie";

export default function Login() {
  const SMSTermsRef = useRef();
  const navigate = useNavigate();
  const cellphoneInput = useRef();

  const { login, setState: setAuthState } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [cellphone, setCellphone] = useState("");
  const [error, setError] = useState("");
  const [isUserDeleted, setIsUserDeleted] = useState(false);

  useEffect(() => {
    const {
      dl_utm_source,
      dl_utm_medium,
      dl_utm_campaign,
      dl_utm_term,
      dl_utm_content,
    } = getQueryParams();

    dataLayer.push({
      event: "new_application",
      eventCategory: "register_process",
      eventAction: "register_process_start",
      dl_utm_source: dl_utm_source,
      dl_utm_medium: dl_utm_medium,
      dl_utm_campaign: dl_utm_campaign,
      dl_utm_term: dl_utm_term,
      dl_utm_content: dl_utm_content,
    });

    setAuthState((prev) => ({
      ...prev,
      firstTime: false,
    }));
  }, []);

  const getQueryParams = () => {
    const kw_source_url = Cookies.get("kw_source_url");
    if (!kw_source_url) {
      return {
        dl_utm_source: null,
        dl_utm_medium: null,
        dl_utm_campaign: null,
        dl_utm_term: null,
        dl_utm_content: null,
      };
    }

    const urlObj = new URL(kw_source_url);
    const params = new URLSearchParams(urlObj.search);

    return {
      dl_utm_source: params.get("utm_source"),
      dl_utm_medium: params.get("utm_medium"),
      dl_utm_campaign: params.get("utm_campaign"),
      dl_utm_term: params.get("utm_term"),
      dl_utm_content: params.get("utm_content"),
    };
  };

  const validateCellphone = (phone) => {
    if (phone.length !== 10) {
      setError("Ingresa un número de celular válido");
      return false;
    }
    return true;
  };

  const formatCellphone = (phone) => {
    return phoneFormatter.format(phone, "+1(NNN)NNN-NNNN");
  };

  const trackUserAction = (formattedPhone) => {
    GAevent("user_action", "send_cellphone_code");
    mixpanel.identify(
      phoneFormatter.format(formattedPhone, "+1 (NNN) NNN-NNNN"),
    );
    mixpanel.track("Phone Verification Started");
  };

  const handleLoginSuccess = (data, formattedCellphone) => {
    const { is_new_user, first_consent_promotional_check } = data.data;

    setAuthState((prev) => ({
      ...prev,
      cellphone: formattedCellphone,
      is_new_user,
      first_consent_promotional_check,
    }));

    trackUserAction(formattedCellphone);
    navigate("/code-verification");
  };

  const handleLoginError = (error) => {
    const { data } = error.response ?? {};
    if (data?.messages?.includes("ERROR_USER")) {
      showUserDeletedError();
    } else {
      setError("Parece que hubo un problema. Inténtalo de nuevo por favor");
    }
  };

  const onSendCellphoneCode = async (event) => {
    event.preventDefault();

    if (loading) return;
    if (!validateCellphone(cellphone)) return;

    try {
      setLoading(true);
      const formattedCellphone = formatCellphone(cellphone);

      const { data } = await login({ cellphone: formattedCellphone });

      if (data.success) {
        handleLoginSuccess(data, formattedCellphone);
      }
    } catch (error) {
      handleLoginError(error);
    } finally {
      setLoading(false);
    }
  };

  const showUserDeletedError = () => {
    setIsUserDeleted(true);

    setTimeout(() => {
      setIsUserDeleted(false);
    }, 10000);
  };

  const onSetCellphone = (values) => {
    const { value } = values;

    if (value.length === 10) {
      setCellphone(value);
      cellphoneInput.current.blur();
    } else {
      setCellphone("");
    }

    setError("");
  };

  const redirectToChangeNumber = () => {
    navigate("/change-phone");
  };

  return (
    <form
      className="w-full min-h-full flex flex-col px-6 pb-10"
      onSubmit={onSendCellphoneCode}
    >
      <img className="mx-auto mt-24 mb-10" width="150" src={LogoKiwiSVG} />

      <SMSTermsModal ref={SMSTermsRef} />

      <div className="mb-10">
        <h1 id="login" className="text-dark-kiwi font-semibold mb-4 text-2xl">
          Ingresa tu número de celular
        </h1>
        <p className="mb-10">
          Te enviaremos un código de 6 dígitos para verificar tu cuenta
        </p>
        <div className="relative">
          <div
            className={`absolute top-1/2 left-4 -translate-y-1/2 ${
              error ? "text-red-kiwi" : ""
            }`}
          >
            +1
          </div>
          <PatternFormat
            className={`w-full rounded-lg py-2 px-4 pl-10 ${
              error
                ? "border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50"
                : "border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi"
            }`}
            format="(###) ### ####"
            placeholder="(000) 000 0000"
            mask=""
            getInputRef={cellphoneInput}
            onValueChange={onSetCellphone}
          />
        </div>
        {error !== "" && (
          <small className="text-red-kiwi inline-block text-xs w-full mt-2">
            {error}
          </small>
        )}
        <button
          onClick={redirectToChangeNumber}
          className="block ml-auto text-sm mt-4 text-blue-kiwi font-semibold"
        >
          ¿Nuevo número? Actualiza tu cuenta
        </button>
      </div>

      <div className="mt-auto">
        {isUserDeleted && (
          <Advisement className="mb-10" icon="locked_user">
            <strong>Tu cuenta ha sido deshabilitada</strong>
            <p>
              Por favor, comunícate con nuestro equipo de soporte o escríbenos
              al correo <strong>support@kiwicredito.com</strong>
            </p>
          </Advisement>
        )}

        <div className="text-slate-500-kiwi text-xs mb-6">
          Al continuar, recibirás un código de verificación por mensaje de texto
          y aceptas nuestro{" "}
          <span
            className="text-blue-kiwi underline cursor-pointer"
            onClick={() => SMSTermsRef.current.openModal()}
          >
            Consentimiento de Comunicación
          </span>
          . Pueden aplicarse tarifas estándar de mensajes y datos.
        </div>

        <Button
          id="send_cellphone_code-gtm"
          className="w-full bg-blue-kiwi text-white rounded-xl p-3"
          loading={loading}
          type="submit"
        >
          Continuar
        </Button>
      </div>
    </form>
  );
}
