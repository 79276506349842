import  { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { UserContext } from 'context/UserContext'

/**
 * Este middleware agrega una validación a las rutas anidadas,
 * previene que, mientras haya un curso activo (por pagar),
 * no permite acceder a las vistas. Evita entrar aun flujo que no debería.
 */
export const CreditBuilderActiveMiddleware = () => {
    const {
        state: {
            products: { credit_builder }
        }
    } = useContext(UserContext)

    /**
     * Si no tiene cursos activos, hacer renderización y dejar pasar,
     * si no, redireccionar a la página de "inicio".
     */
    const activeCourses = credit_builder.courses.active

    return activeCourses === 0 ? <Outlet /> : <Navigate to="/credit-builder" />
}
