import { CASHBACK_TRANSACTION_STATUS } from 'constants';
const { EXPIRED,COMPLETED,CANCELED } = CASHBACK_TRANSACTION_STATUS
import {CertificateIcon, TimeIcon} from 'assets/icons_v3/fonts';

const TRANSACTION_CONFIG = {
    [COMPLETED]: {
        title: 'Completado',
        class: 'text-algae-kiwi-400',
        icon: CertificateIcon
    },
    [CANCELED]: {
        title: 'Cancelado', 
        class: 'text-slate-700',
        icon: TimeIcon
    },
    [EXPIRED]: {
        title: 'Expirado',
        class: 'text-slate-700',
        icon: TimeIcon
    },
    default: {
        title: '---',
        class: 'text-slate-700',
        icon: TimeIcon
    }
};

const formatAmount = (amount, status) => {
    const amountValue = Number(amount || 0);
    const isClaimed = status === CASHBACK_TRANSACTION_STATUS.CLAIMED;
    
    if (!isClaimed || amountValue === 0) {
        return '$0.00';
    }

    const prefix = amountValue > 0 ? '+' : '-';
    const formattedAmount = Math.abs(amountValue).toFixed(2);
    return `${prefix}$${formattedAmount}`;
};

export { formatAmount };

export default TRANSACTION_CONFIG;