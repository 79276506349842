import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import { setBearerToken } from "utils/http";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

import Button from "components/Button";
import ResendCodeModal from "components/Modals/ResendCode";
import phoneFormatter from "phone-formatter";
import mixpanel from "mixpanel-browser";

import BackwardButton from "components/Button/Backward";

export default function CodeVerification() {
  const navigate = useNavigate();

  const {
    codeVerification,
    login,
    state: authState,
    setState: setAuthState,
  } = useContext(AuthContext);

  const { getData: getDataFingerprint } = useVisitorData(
    { extendedResult: true },
    { immediate: true },
  );

  const { cellphone } = authState;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [codeValues, setCodeValues] = useState(["", "", "", "", "", ""]);

  useEffect(() => {
    dataLayer.push({
      event: "new_application",
      eventCategory: "register_process",
      eventAction: "register_process_phone_validation",
    });

    if (cellphone === "") {
      navigate("/login");
    }
    setAuthState((prev) => ({ ...prev, acceptTerms: false }));
  }, []);

  /**
   * Manejar el ingreso del código...
   */
  const onKeyDownCode = (event, codeIndex) => {
    let isDeleteKey =
      event.code === "Backspace" ||
      event.key === "Backspace" ||
      event.keyCode === 8;
    let currentInput = event.target;
    let code = codeValues[codeIndex];

    if (isDeleteKey) {
      /**  Al borrar número, recorrer el focus del input... */
      let previousInput = currentInput.previousSibling;
      if (previousInput !== null && code === "") {
        previousInput.focus();
      }
    } else {
      /** Validación permitir solo un dígito... */
      if (currentInput.value.length > 0) {
        event.preventDefault();
      }

      /** Validar que el input sea numérico... */
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }
  };

  const onChangeCode = (event, codeIndex) => {
    let newCodeValues = [...codeValues];
    let currentInput = event.target;

    newCodeValues[codeIndex] = currentInput.value;

    setCodeValues(newCodeValues);
    setError("");

    if (currentInput.value === "") {
      return;
    }

    /** Al escribir un dígito brincar al siguiente input... */
    let nextInput = currentInput.nextSibling;
    if (nextInput !== null) {
      nextInput.focus();
    } else {
      currentInput.blur();
    }
  };

  /**
   * Manejar el reenvio del código...
   */
  const [resendCount, setResendCount] = useState(30);
  const [resendCountStart, setResendCountStart] = useState(false);

  const onResendCode = async (type) => {
    setResendVisible(false);
    setResendCountStart(true);

    let timeLeft = resendCount;
    let resendInterval = setInterval(() => {
      timeLeft = timeLeft - 1;

      if (timeLeft <= 0) {
        clearInterval(resendInterval);
        setResendCount(30);
        setResendCountStart(false);
      } else {
        setResendCount(timeLeft);
      }
    }, 1000);

    try {
      if (type === "phone") {
        navigate("/login");
      } else if (type === "sms") {
        login({ cellphone });
      } else if (type === "whatsapp") {
        login({ cellphone, service: "whatsapp" });
      }
    } catch {
      setError(
        "Parece que hubo un problema al reenviar tu código. Inténtalo de nuevo por favor",
      );
    }
  };

  /**
   * Enviar el código de verificación...
   */
  const onVerifyCode = async () => {
    let code = codeValues.join("");

    if (code === "") {
      return setError("Ingresa tu código de verificación");
    }

    if (code.length < 6) {
      return setError("Ingresa todos los dígitos de verificación");
    }

    try {
      setLoading(true);
      setAuthState((prev) => ({ ...prev, code }));

      const codeResponse = await codeVerification({ cellphone, code });

      if (codeResponse.data.success) {
        const accessTokenApp = codeResponse.data.data.access_token;
        const accessTokenRefresh = codeResponse.data.data.refresh_token;
        setBearerToken(accessTokenApp);

        setAuthState((prev) => ({
          ...prev,
          accessTokenApp,
          accessTokenRefresh,
          logged: true,
        }));

        mixpanel.track("Phone Verification Completed", {
          $phone: phoneFormatter.format(cellphone, "+1 (NNN) NNN-NNNN"),
        });
        mixpanel.track("Login In Completed");
        mixpanel.people.increment({ "Sign In Count": 1 });
        await getDataFingerprint({
          ignoreCache: true,
          tag: {
            env: process.env.REACT_APP_FINGERPRINT_ENV,
            cellphone,
            place: "login_success",
          },
        });
      }
    } catch (error) {
      const { response } = error;

      if (response && response.data) {
        const { messages } = response.data;

        if (messages === "Validation code error") {
          setError(
            `El código de validación es incorrecto. Inténtalo de nuevo por favor.`,
          );
        } else if (messages.includes("no user record")) {
          mixpanel.track("Phone Verification Completed", {
            $phone: phoneFormatter.format(cellphone, "+1 (NNN) NNN-NNNN"),
          });
          _cio.track("phone_verification_completed", {
            phone_number: phoneFormatter.format(cellphone, "+1 (NNN) NNN-NNNN"),
          });

          await getDataFingerprint({
            ignoreCache: true,
            tag: {
              env: process.env.REACT_APP_FINGERPRINT_ENV,
              cellphone,
              place: "login_new_user_success",
            },
          });

          navigate("/create-account");
        } else {
          setError("Parece que hubo un problema. Inténtalo de nuevo por favor");
        }
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Funcionalidad para abrir y cerrar el modal de reenvio del código...
   */
  const [resendVisible, setResendVisible] = useState(false);

  const onOpenResendModal = () => {
    if (resendCountStart === false) {
      setResendVisible(true);
    }
  };

  const onCloseResendModal = () => {
    setResendVisible(false);
  };

  const onBackward = () => {
    navigate("/login");
  };

  const getCodeNumber = () => {
    const inputs = Array.from({ length: 6 });

    return (
      <>
        {inputs.map((_, index) => (
          <input
            key={`input-code-${index}`}
            className={`py-0 px-0 text-dark-kiwi font-semibold text-2xl text-center w-1/6 max-w-[56px] grow-0 h-16 rounded-lg ${
              error
                ? "border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50"
                : "border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi"
            }`}
            type="number"
            inputMode="numeric"
            onKeyDown={(e) => onKeyDownCode(e, index)}
            onChange={(e) => onChangeCode(e, index)}
          />
        ))}
      </>
    );
  };

  const handleTerms = (e) => {
    const { checked } = e.target;
    setAuthState((prev) => ({ ...prev, acceptTerms: checked }));
  };

  return (
    <>
      <ResendCodeModal
        cellphone={cellphone}
        isVisible={resendVisible}
        closeModal={onCloseResendModal}
        resendCode={onResendCode}
      />

      <div className="w-full min-h-full grid grid-rows-[auto_1fr_auto] px-6 pb-10 pt-10">
        <BackwardButton onBackward={onBackward} />

        <div className="mb-10">
          <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
            Código de verificación
          </h1>

          <p>
            Ingresa el código de 6 dígitos que enviamos por SMS al número{" "}
            {phoneFormatter.format(cellphone, "+1 (NNN) NNN-NNNN")}
          </p>

          <div className="flex gap-4 mt-10">{getCodeNumber()}</div>

          {error !== "" && (
            <small className="text-red-kiwi inline-block text-xs w-full mt-2">
              {error}
            </small>
          )}

          <p className="mt-14 text-sm">
            ¿No recibiste el código?{" "}
            <button
              className={`cursor-pointer text-blue-kiwi`}
              onClick={onOpenResendModal}
            >
              {resendCountStart === false
                ? "Reenviar"
                : `Espera ${resendCount}s para volver a reenviar`}
            </button>
          </p>
        </div>
        {authState.is_new_user && (
          <div className="flex flex-col mb-6 mt-auto">
            <label className="flex items-start cursor-pointer gap-4">
              <input
                type="checkbox"
                className="peer cursor-pointer w-6 h-6 rounded-md border border-gray-400-kiwi bg-gray-100-kiwi checked:bg-blue-kiwi checked:hover:bg-blue-kiwi checked:focus:bg-blue-kiwi"
                id="agreeTerms"
                onChange={handleTerms}
              />
              <span className="text-gray-600-kiwi text-xs">
                Acepto recibir mensajes de texto y llamadas promocionales sobre
                Kiwi. Pueden aplicarse cargos por mensajes y datos. Podrás
                cancelar el consentimiento de comunicación en cualquier momento.
              </span>
            </label>
          </div>
        )}

        <Button
          className={`rounded-xl p-3`}
          loading={loading}
          onClick={onVerifyCode}
          disabled={!authState.acceptTerms && authState.is_new_user}
        >
          Continuar
        </Button>

        <p className="text-sm mt-6 mx-auto">
          ¿Número incorrecto?{" "}
          <button
            className="cursor-pointer text-blue-kiwi"
            onClick={onBackward}
          >
            Editar
          </button>
        </p>
      </div>
    </>
  );
}
