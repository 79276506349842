import PropTypes from 'prop-types';

const EyeIcon = ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M4 12.001V12c.003-.016.017-.104.095-.277.086-.191.225-.431.424-.708.398-.553.993-1.192 1.745-1.798C7.777 7.996 9.812 7 12 7c2.188 0 4.223.996 5.736 2.216.752.606 1.347 1.245 1.745 1.798.2.277.338.517.424.708.078.173.092.261.095.277V12c-.003.016-.017.104-.095.277-.086.191-.225.431-.424.708-.398.553-.993 1.192-1.745 1.798C16.224 16.004 14.189 17 12 17c-2.188 0-4.223-.996-5.736-2.216-.752-.606-1.347-1.245-1.745-1.798a4.226 4.226 0 0 1-.424-.708A1.115 1.115 0 0 1 4 12.001ZM12 5C9.217 5 6.752 6.254 5.009 7.659c-.877.706-1.6 1.474-2.113 2.187-.257.356-.471.713-.625 1.055C2.123 11.23 2 11.611 2 12c0 .388.123.771.27 1.099.155.342.37.7.626 1.055.513.713 1.236 1.48 2.113 2.187C6.752 17.746 9.217 19 12 19c2.783 0 5.248-1.254 6.991-2.659.877-.706 1.6-1.474 2.113-2.187.257-.356.471-.713.625-1.055.148-.328.271-.71.271-1.099 0-.388-.123-.771-.27-1.099a6.197 6.197 0 0 0-.626-1.055c-.513-.713-1.236-1.48-2.113-2.187C17.248 6.254 14.783 5 12 5Zm-1 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
        />
    </svg>
);

EyeIcon.displayName = 'EyeIcon';

EyeIcon.propTypes = {
    className: PropTypes.string,
};

export default EyeIcon;
