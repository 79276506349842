import { useState, useEffect } from "react";

const useStoredUrlParams = () => {
  const [params, setParams] = useState({});

  useEffect(() => {
    const storedParams = sessionStorage.getItem("urlParams");
    if (storedParams) {
      setParams(JSON.parse(storedParams));
    }
  }, []);

  return params;
};
  const getUtmParams = () => {
    const storedParams = sessionStorage.getItem("urlParams");
    if (!storedParams) return {};

    const params = JSON.parse(storedParams);
    return {
      utm_campaign: params.utm_campaign || null,
      utm_content: params.utm_content || null, 
      utm_id: params.utm_id || null,
      utm_medium: params.utm_medium || null,
      utm_source: params.utm_source || null,
      utm_term: params.utm_term || null
    };
  };

export { getUtmParams };

export default useStoredUrlParams;
