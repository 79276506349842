import LoadingModal from "components/Modals/Loading";
import delay from "delay";
import {
  forwardRef,
  Fragment,
  useContext,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  BankIcon,
  CarIcon,
  CurrencyDollarIcon,
  DotsVerticalIcon,
  GasStationIcon,
  GiftIcon,
  HeartIcon,
  Home2Icon,
  MortarboardIcon,
  Presentation2Icon,
  ShoppingCart2Icon,
  Umbrella2Icon,
} from "assets/icons_v3/fonts";
import BaseModal from "./Modal";
import { UserContext } from "context/UserContext";
import { LoanAPI } from "api";
import mixpanel from "utils/mixpanel";

const LoanPurposeModal = forwardRef((props, ref) => {
  const BaseModalRef = useRef();
  const [showLoader, setShowLoader] = useState(false);
  const { state: userState, setState: setUserState } = useContext(UserContext);
  const {
    products: {
      personal_loan: { loan },
    },
  } = userState;

  useLayoutEffect(() => {
    initialSetupModal();
  }, []);

  const initialSetupModal = async () => {
    if (loan.db.purpose === null) {
      await delay(500);
      BaseModalRef.current.openModal();
    }
  };

  const setEmptyPurpose = async () => {
    await savePurpose("Sin propósito");
  };

  const onSelectPurpose = async ($event) => {
    setShowLoader(true);
    mixpanel.track("Loan Purpose Survey");
    mixpanel.people.set({
      "Loan purpose": $event.target.value,
    });
    await savePurpose($event.target.value);
    BaseModalRef.current.closeModal();
    setShowLoader(false);
  };

  const savePurpose = async (purpose) => {
    await LoanAPI.updateLoanPurpose({
      loanId: loan.id,
      purpose,
    });

    setUserState({
      ...userState,
      products: {
        ...userState.products,
        personal_loan: {
          ...userState.products.personal_loan,
          loan: {
            ...userState.products.personal_loan.loan,
            db: {
              ...userState.products.personal_loan.loan.db,
              purpose,
            },
          },
        },
      },
    });
  };

  const OPTIONS = [
    { title: "Crear historial crediticio", icon: <Presentation2Icon /> },
    { title: "Gastos médicos", icon: <HeartIcon /> },
    { title: "Gastos de hogar", icon: <Home2Icon /> },
    { title: "Regalos", icon: <GiftIcon /> },
    { title: "Arreglo de vehículo", icon: <CarIcon /> },
    { title: "Pago hipoteca o renta", icon: <BankIcon /> },
    { title: "Supermercado", icon: <ShoppingCart2Icon /> },
    { title: "Gasolina", icon: <GasStationIcon /> },
    { title: "Estudios académicos", icon: <MortarboardIcon /> },
    { title: "Pago otras deudas", icon: <CurrencyDollarIcon /> },
    { title: "Vacaciones", icon: <Umbrella2Icon /> },
    { title: "Otro", icon: <DotsVerticalIcon /> },
  ];

  useImperativeHandle(ref, () => ({
    ...BaseModalRef.current,
  }));

  return (
    <Fragment>
      <BaseModal
        title="¿En qué has utilizado los fondos de tu préstamo?"
        ref={BaseModalRef}
        onCloseModal={setEmptyPurpose}
      >
        <div className="grid grid-cols-2 gap-4 mt-8">
          {OPTIONS.map((option) => (
            <div className="relative" key={option.title}>
              <input
                className="peer hidden"
                type="radio"
                id={option.title}
                name="value"
                value={option.title}
                onClick={onSelectPurpose}
              />
              <label
                htmlFor={option.title}
                className="text-center flex flex-col items-center gap-4 h-32 font-medium cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 rounded-lg border border-solid border-gray-200-kiwi p-4"
              >
                <span className="text-regal-blue-kiwi w-10 h-10 bg-slate-100-kiwi rounded-full grow-0 shrink-0 flex items-center justify-center">
                  {option.icon}
                </span>
                <span className="text-gray-600-kiwi">{option.title}</span>
              </label>
            </div>
          ))}
        </div>
      </BaseModal>
      <LoadingModal isVisible={showLoader} />
    </Fragment>
  );
});

LoanPurposeModal.displayName = "LoanPurposeModal";

export default LoanPurposeModal;
