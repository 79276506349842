import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import LIST_STATES_US from "data/list_states_usa.json";
import Button from "components/Button";
import { UserContext } from "context/UserContext";
import { ChevronLeftIcon, SearchIcon } from "assets/icons_v3/fonts";
import { STATES_AVAILABLES } from "constants";

export default function SelectState() {
  const navigate = useNavigate();

  const { setState: setUserState } = useContext(UserContext);
  const [location, setLocation] = useState("");
  const [query, setQuery] = useState("");

  const filteredPeople =
    query === ""
      ? LIST_STATES_US
      : LIST_STATES_US.filter((state) => {
          return state.name.toLowerCase().includes(query.toLowerCase());
        });

  const onContinue = () => {
    if (location === "") {
      return;
    }

    setUserState((prev) => ({
      ...prev,
      location,
    }));

    /** Guardar ubicación para bloquear cuando !== Puerto Rico */
    localStorage.setItem("selectedLocation", location);
    if (
      [
        STATES_AVAILABLES.puertoRico,
        // STATES_AVAILABLES.florida
      ].includes(location)
    ) {
      const redirect = sessionStorage.getItem("redirectCreateAccount");

      if (redirect) {
        navigate("/create-account");
      } else {
        navigate("/login");
      }
    } else {
      navigate("/location/not-available");
    }
  };

  const onBackward = () => {
    navigate("/location");
  };

  return (
    <div className="w-full min-h-full flex flex-col px-6 py-10">
      <div className="mb-12">
        <ChevronLeftIcon className="cursor-pointer w-2" onClick={onBackward} />
      </div>

      <h1 className="text-dark-kiwi font-semibold mb-8 text-2xl">
        Selecciona tu estado
      </h1>

      <div className="flex items-center border border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi rounded-lg py-2 px-4">
        <SearchIcon className="w-6 text-gray-400-kiwi mr-4" />
        <div className="w-full">
          <input
            placeholder="Buscar"
            className="border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi"
            onChange={(event) => setQuery(event.target.value)}
          />
        </div>
      </div>

      <div className="flex flex-col gap-6 flex-auto h-[150px] overflow-y-auto mt-6">
        {filteredPeople.map((state, index) => (
          <div key={index} className="relative">
            <input
              className="peer hidden "
              type="radio"
              id={state.abbreviation}
              name={state.abbreviation}
              onChange={() => {
                setLocation(state.name);
              }}
              checked={location === state.name}
            />
            <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
            <label
              htmlFor={state.abbreviation}
              className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4"
            >
              {state.name}
            </label>
          </div>
        ))}
      </div>

      <Button
        className="rounded-xl p-3 mt-10"
        disabled={!location}
        onClick={onContinue}
      >
        Continuar
      </Button>
    </div>
  );
}
