import BaseModal from './../BaseModal';
import militar_check from 'assets/militar_check.svg';
import PropTypes from 'prop-types';

const MilitaryActiveModal = ({isVisible, closeModal, onSelect}) => {
    return (
        <BaseModal isVisible={isVisible} closeModal={closeModal}>
            <div className="flex justify-center">
                <img className="w-44 h-44" src={militar_check} alt="" />
            </div>
            <div className="mb-10">
                <div className="font-semibold text-xl text-dark-kiwi text-center mt-10">
                    <h3>Confirma tu estado activo de las Fuerzas Armadas</h3>
                </div>
                <p className="mb-6 mt-6 text-gray-700-kiwi">
                    Has seleccionado la opción de{' '}
                    <span className="font-semibold">&quot;Sí soy&quot;</span> miembro
                    activo de las Fuerzas Armadas
                </p>

                <p className="mt-5  text-gray-700-kiwi">
                    ¿Confirmas que elegiste ser considerado como miembro activo
                    de las Fuerzas Armadas?
                </p>
            </div>
            <button
                className="w-full bg-blue-kiwi text-white rounded-xl py-3 px-8 "
                onClick={() => {
                    onSelect('Si soy');
                }}>
                Sí, confirmo que soy militar activo
            </button>
            <button
                className="w-full text-blue-kiwi rounded-xl py-3 px-8 mt-6"
                onClick={closeModal}>
                No, cancelar
            </button>
        </BaseModal>
    );
};

MilitaryActiveModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default MilitaryActiveModal;
