import PropTypes from 'prop-types';

const DotsVerticalIcon = ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M12 7.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM4.5 15a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm7.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm6 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM16.5 9a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM6 7.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
        />
    </svg>
);

DotsVerticalIcon.displayName = 'DotsVerticalIcon';

DotsVerticalIcon.propTypes = {
    className: PropTypes.string,
};

export default DotsVerticalIcon;
