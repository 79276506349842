import PropTypes from 'prop-types';

const UmbrellaIcon = ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="m15.607 3.753.027-.047a1 1 0 0 1 1.732 1l-.027.047a9.004 9.004 0 0 1 2.956 10.741c-.482 1.14-1.81 1.405-2.733.872l-5.186-2.994L9.21 19H20a1 1 0 1 1 0 2H4a1 1 0 0 1 0-2h2.915l3.729-6.628-5.206-3.006c-.924-.533-1.358-1.816-.611-2.803a9.004 9.004 0 0 1 10.78-2.81Zm2.1 4.192-.055.38c-.219 1.317-.738 2.845-1.509 4.407l-.198.391 2.556 1.476c.9-2.242.557-4.751-.793-6.654Zm-2.18-1.986-.027-.021-.032-.014c-.06-.018-.2-.036-.477.057-.356.119-.822.385-1.364.832-.831.683-1.745 1.707-2.605 2.98l-.233.353 3.422 1.976c.781-1.521 1.275-2.966 1.468-4.124.115-.694.113-1.23.038-1.597-.059-.287-.144-.4-.19-.442ZM6.5 7.669l2.556 1.476c1.029-1.611 2.185-2.959 3.301-3.877.1-.082.2-.162.302-.239a7.007 7.007 0 0 0-6.159 2.64Z" />
    </svg>
);

UmbrellaIcon.displayName = 'UmbrellaIcon';

UmbrellaIcon.propTypes = {
    className: PropTypes.string,
};

export default UmbrellaIcon;
