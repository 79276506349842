import  { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { UserContext } from 'context/UserContext'
import { CREDIT_LIMIT_STATUS } from 'constants'
import { CreditLimitContext } from 'context/CreditLimitContext'

/**
 * Validar que el usuario tenga un application
 * para poder renderizar las vistas del credit-limit,
 * esto porque necesitamos el application.credit_limit internamente...
 */
export const CreditLimitApplication = () => {
    const {
        state: {
            products: { personal_loan }
        }
    } = useContext(UserContext)

    const hasApplication = personal_loan.application
    return hasApplication ? <Outlet /> : <Navigate to="/home" />
}

/**
 * Protege las vistas que utilizan el objeto credit_limit...
 * Solo se podrá acceder a ellas cuando el usuario este en proceso de reviewing o approved...
 * el rejected es manejado en la calculador...
 */
export const CreditLimitOpen = () => {
    const {
        state: {
            products: { personal_loan }
        }
    } = useContext(UserContext)

    const hasCreditLimitActive = ['pending', 'approved'].includes(
        personal_loan.credit_limit.status
    )
    return hasCreditLimitActive === true ? <Outlet /> : <Navigate to="/home" />
}

/**
 * Permite acceder a las vistas solo si cumple con las reglas para mostrar el card...
 */
export const CreditLimitShow = () => {
    const { showCreditLimit } = useContext(CreditLimitContext)
    const { value } = showCreditLimit()
    return value === true ? <Outlet /> : <Navigate to="/home" />
}

/**
 * Evitar entrar a las vistias del flujo de approved loan...
 *
 * Dejarlo entrar si no hay ninguna solicitud de credit limit
 * o si el estado es rejected (para mostrar la card de error)...
 */
export const CreditLimitStatus = () => {
    const {
        state: {
            products: { personal_loan }
        }
    } = useContext(UserContext)

    const hasNotCreditLimitActive =
        Boolean(personal_loan.credit_limit.status) === false
    const creditLimitRejected =
        personal_loan.credit_limit.status === CREDIT_LIMIT_STATUS.rejected

    const showCreditLimitFlow = creditLimitRejected || hasNotCreditLimitActive

    return showCreditLimitFlow === true ? <Outlet /> : <Navigate to="/home" />
}
