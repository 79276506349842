import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePlaidLink } from "react-plaid-link";
import { PlaidAPI } from "api";

import BackwardButton from "components/Button/Backward";
import Button from "components/Button";
import ConnectBankAccount from "components/Modals/Cashback/ConnectBankAccount";
import ActivateIcon from "assets/cashback/activate.svg";
import DollarImage from "assets/cashback/dollar_blue.svg";
import TimeImage from "assets/cashback/time_dark.svg";
import GiftImage from "assets/cashback/gift_dark.svg";
import PresentationImage from "assets/cashback/presentation_dark.svg";

export default function ActivateCashback() {
  const navigate = useNavigate();
  const [linkTokenPlaid, setLinkTokenPlaid] = useState("");
  const [showModal, setShowModal] = useState(false);
  const activateBenefit = false;

  const onBackward = () => {
    navigate("/cashback/offer-details");
  };

  const { open: openPlaid } = usePlaidLink({
    token: linkTokenPlaid,
    onSuccess: async (public_token, metadata) => {
      try {
        const {
          data: { success },
        } = await PlaidAPI.publicTokenExchange({
          public_token,
        });
        if (success)
          return navigate("/cashback/current-bank", {
            state: {
              current_bank: metadata?.institution,
              account: metadata?.accounts[0],
            },
          });
      } catch (error) {
        console.error(error);
      }
    },
  });

  const createTokenPlaid = async () => {
    try {
      const {
        data: {
          data: { link_token },
        },
      } = await PlaidAPI.generateToken();

      setLinkTokenPlaid(link_token);
    } catch (error) {
      console.error(error);
    }
  };

  const onContinue = async () => {
    await openPlaid();
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    createTokenPlaid();
  }, []);

  return (
    <>
      <ConnectBankAccount isOpen={showModal} onClose={handleModal} />
      <div className="w-full min-h-full flex flex-col px-6 py-10">
        <BackwardButton onBackward={onBackward} />
        <div className="flex flex-col gap-9 w-full mb-6">
          <img
            src={ActivateIcon}
            className="max-h-[8rem] max-w-[8rem] w-full h-full mx-auto"
          />
          <div className="text-center flex flex-col gap-4">
            <h1 className="text-2xl font-semibold text-dark-kiwi">
              ¡Activa tu Cashback!
            </h1>
            <p className="text-gray-700-kiwi">
              Vincula una cuenta bancaria a tu nombre para comenzar a recibir
              reembolsos en tus compras.
            </p>
          </div>
          {activateBenefit && (
            <div className="w-full flex items-start gap-4 p-4 bg-blue-kiwi/10 rounded-xl">
              <img src={DollarImage} />
              <p className="text-slate-900-kiwi font-semibold text-sm">
                ¡Recibe $2 adicionales en tu primer cashback cuando vincules tu
                cuenta bancaria!
              </p>
            </div>
          )}
          <div className="flex flex-col gap-4 w-full">
            <div className="flex items-center gap-4">
              <div className="bg-slate-100-kiwi p-2 w-max rounded-full">
                <img src={TimeImage} />
              </div>
              <p className="text-slate-700-kiwi text-sm">
                Acelera tus reembolsos
              </p>
            </div>
            <div className="flex items-center gap-4">
              <div className="bg-slate-100-kiwi p-2 w-max rounded-full">
                <img src={GiftImage} />
              </div>
              <p className="text-slate-700-kiwi text-sm">
                Recibe ofertas personalizadas
              </p>
            </div>
            <div className="flex items-center gap-4">
              <div className="bg-slate-100-kiwi p-2 w-max rounded-full">
                <img src={PresentationImage} />
              </div>
              <p className="text-slate-700-kiwi text-sm">
                Incrementa tu límite de cashback
              </p>
            </div>
          </div>
          <button
            onClick={handleModal}
            className="text-blue-kiwi text-sm text-left underline"
          >
            ¿Por qué necesito vincular mi cuenta?
          </button>
        </div>
        <Button
          onClick={onContinue}
          className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto w-full"
        >
          Conectar cuenta de banco
        </Button>
      </div>
    </>
  );
}
