import axios from 'axios'
import { customRefreshToken } from './http';

const httpRewards = axios.create({
    baseURL: process.env.REACT_APP_API_KIWI_REWARDS
})
let refreshingFunction;

export const setBearerTokenRewards = (token) => {
    httpRewards.defaults.headers.common['Authorization'] = `Bearer ${token}`
}


const checkTokenError = (data) => {
    const errorMessage = data?.message ?? data?.data?.message
    const isError = [
        'Access token has expired',
        'User not found',
        'get a fresh token from your client'
    ].includes(errorMessage)

    return isError
}



httpRewards.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        const { response, config: originalRequest } = error
        const { data } = response

        if (!data.success) {
            const isError = checkTokenError(data)
            if (!isError) return Promise.reject(error)

            try {
                if (!refreshingFunction)
                    refreshingFunction = await customRefreshToken()

                const newToken = await refreshingFunction
                originalRequest.headers.Authorization = newToken

                return await axios.request(originalRequest)
            } catch {
                const isError = checkTokenError(data)
                if (isError) {
                    window.localStorage.clear()
                    window.location.reload()
                }
            } finally {
                refreshingFunction = undefined
            }
        }

        return Promise.reject(error)
    }
)

export default httpRewards
