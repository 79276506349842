import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import UploadFiles from "components/UploadFiles";
import ProgressBar from "components/ProgressBar";
import { ActivityAPI } from "api";

export default function RequestLoanBankStatementsDocuments() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onBackward = () => {
    if (loading === false) {
      navigate("/request-loan/bank-statements");
    }
  };

  const onUploadedDocuments = async () => {
    try {
      setLoading(true);
      _cio.track("link_bank_account", {
        status: "review_bank_statements",
      });
      navigate("/request-loan/reviewing");

      await ActivityAPI.createActivity({
        name: "Send Bank Statements",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <ProgressBar progress="13/13" onBackward={onBackward} />

      <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
        Archivos subidos
      </h1>
      <p className="mb-10">
        Puedes estar más cerca de adquirir tu préstamo completando tu
        información
      </p>
      <UploadFiles
        onUploaded={onUploadedDocuments}
        loading={loading}
        setLoading={setLoading}
        documentType="preapproved/bank-statements"
        acceptType={["jpg", "jpeg", "png", "pdf"]}
      />
    </Fragment>
  );
}
