import PropTypes from 'prop-types';
import MerchantLogo from './Cashback/Offer/MerchantLogo';
import OfferDetails from './Cashback/Offer/OfferDefault';
const CashbackOfferComponent = ({ offer, onSelectOffer }) => {
    return (
        <div
            onClick={onSelectOffer}
            className="cursor-pointer border border-slate-200 flex items-start rounded-xl bg-white p-4 gap-4 min-h-[7rem]"
        >
            <MerchantLogo logoUrl={offer?.store?.merchant.logo_url} merchantName={offer.store.merchant.merchant_name} />
            <OfferDetails offer={offer} />
        </div>
    );
};

CashbackOfferComponent.propTypes = {
    offer: PropTypes.object.isRequired,
    onSelectOffer: PropTypes.func.isRequired,
};

export default CashbackOfferComponent;
