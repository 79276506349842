import BaseModal from '../BaseModal'
import PropTypes from 'prop-types'

export default function CourseLessonsModal({
    isVisible,
    closeModal,
    lessons,
    description
}) {
    return (
        <BaseModal
            title="Detalle de contenido"
            description={description}
            isVisible={isVisible}
            closeModal={closeModal}>
            {lessons?.map((lesson) => (
                <div
                    key={lesson.id}
                    className="flex justify-between items-center p-4 rounded-md border-l-4 border-blue-kiwi [&:not(:last-child)]:mb-4 text-sm">
                    <div>
                        <div>{lesson.name}</div>
                        <div className="text-gray-500-kiwi text-xs mt-1">
                            Duración {lesson.duration}
                        </div>
                    </div>
                </div>
            ))}
        </BaseModal>
    )
}

CourseLessonsModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    lessons: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            duration: PropTypes.string.isRequired
        })
    ).isRequired,
    description: PropTypes.string
}
