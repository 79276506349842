import http from "utils/http";

export const getNotificationsStatus = async () => {
  const response = await http.get(`/notification-consent`, {
    headers: {
      type: "app",
    },
  });

  return response.data;
};

export const changeStatusNotifications = async ({ type, on }) => {
  const response = await http.post(
    `/notification-consent`,
    {
      type,
      on,
    },
    {
      headers: {
        type: "app",
      },
    },
  );

  return response.data;
};

export const changeStatusNotificationsBulk = async ({ values }) => {
  const response = await http.post(
    `/notification-consent/bulk`,
    {
      values,
    },
    {
      headers: {
        type: "app",
      },
    },
  );

  return response.data;
};
