import { LoanAPI } from 'api';
import BackwardButton from 'components/Button/Backward';
import MenuLink from 'components/MenuLink';
import LoadingModal from 'components/Modals/Loading';
import PageTitle from 'components/PageTitle';
import PaymentSkeleton from 'components/Skeleton/Payment';
import { NameSpaces } from 'config/i18n/name-spaces';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import formatDate from 'utils/formatDate';
import moneyFormat from 'utils/moneyFormat';

export default function DocumentsLoan() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { t } = useTranslation(NameSpaces.DOCUMENTS);

    const [showLoader, setShowLoader] = useState(true);
    const [selectedProduct] = useState(location.state);
    const [loan, setLoan] = useState({});
    const [documents, setDocuments] = useState({});

    useEffect(() => {
        if (!selectedProduct) {
            onBackward();
        }

        getLoanDetails();
    }, []);

    const getLoanDetails = async () => {
        try {
            const {
                data: { loan },
            } = await LoanAPI.getLoan(params.loanId);

            const {
                data: { data: documents },
            } = await LoanAPI.getLoanDocuments(loan.loanpro_id);

            setLoan(loan);
            setDocuments(documents);
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false);
        }
    };

    const onBackward = () => {
        navigate('/documents/products', {
            state: selectedProduct,
        });
    };

    const translateContractName = name => {
        const foundKey = name.lastIndexOf('_');
        const depureKey = foundKey > -1 ? name.slice(0, foundKey) : name;
        const contractName = t(depureKey);
        return contractName;
    };

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />
            <LoadingModal isVisible={showLoader} />

            <PageTitle>
                {selectedProduct === 'personal_loan'
                    ? 'Préstamo personal'
                    : 'Financiamiento'}
            </PageTitle>

            <div className="flex flex-col gap-2 mt-8 text-sm">
                <div className="flex items-center justify-between">
                    <div>Estado</div>
                    <strong>
                        {loan.loanpro_loan?.balance === 0 ? 'Pagado' : 'Activo'}
                    </strong>
                </div>
                <div className="flex items-center justify-between">
                    <div>Monto de préstamo</div>
                    <strong>
                        $
                        {!showLoader
                            ? moneyFormat(loan.loanpro_loan.totalLoanAmount)
                            : '0'}
                    </strong>
                </div>
                <div className="flex items-center justify-between">
                    <div>Fecha del préstamo</div>
                    <strong>
                        {!showLoader
                            ? formatDate(
                                loan.loanpro_loan.contracDate,
                                'MMM DD, YYYY',
                            )
                            : '...'}
                    </strong>
                </div>
                <div className="flex items-center justify-between">
                    <div>Duración del préstamo</div>
                    <strong>
                        {showLoader ? (
                            '...'
                        ) : (
                            <Fragment>
                                {loan.loanpro_loan.term}{' '}
                                {loan.loanpro_loan?.paymentFrequency ===
                                    'monthly'
                                    ? 'meses'
                                    : 'bisemanas'}
                            </Fragment>
                        )}
                    </strong>
                </div>
            </div>

            <div className="h-[1px] my-6 bg-slate-200-kiwi"></div>

            <h2 className="text-slate-500-kiwi uppercase text-xs mb-4">
                Documentos del{' '}
                {selectedProduct === 'personal_loan'
                    ? 'Préstamo personal'
                    : 'Financiamiento'}
            </h2>

            <div className="flex flex-col gap-2">
                {showLoader ? (
                    <Fragment>
                        {[...Array(10)].map((item, index) => (
                            <PaymentSkeleton key={index} className="mt-4" />
                        ))}
                    </Fragment>
                ) : (
                    <Fragment>
                        {documents?.map(document => (
                            <MenuLink
                                key={
                                    document.file_attachment.file_original_name
                                }
                                title={translateContractName(
                                    document.file_attachment.file_name,
                                )}
                                description={formatDate(
                                    document.created_at,
                                    'MMMM DD, YYYY',
                                )}
                                onClick={() => {
                                    navigate(
                                        `/documents/loan/${params.loanId}/contract`,
                                        {
                                            state: {
                                                document_id:
                                                    document.document_id,
                                                file_original_name:
                                                    document.file_attachment
                                                        .file_original_name,
                                                file_name: translateContractName(
                                                    document.file_attachment
                                                        .file_name,
                                                ),
                                            },
                                        },
                                    );
                                }}
                            />
                        ))}
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
}
