import { ChevronLeftIcon } from 'assets/icons_v3/fonts'
import PropTypes from 'prop-types'

const BackwardButton = ({ onBackward, className }) => {
    return (
        <ChevronLeftIcon
            className={`shrink-0 grow-0 cursor-pointer w-2 mb-12 ${className}`}
            onClick={onBackward}
        />
    )
}

BackwardButton.propTypes = {
    onBackward: PropTypes.func.isRequired,
    className: PropTypes.string
}

export default BackwardButton
