export const getOpeningClosingTimes = (offer) => {
    if (!offer?.store) {
        return {
            openingTime: '',
            closingTime: '',
            isOpen: false
        };
    }

    const { store } = offer;
    const closeDays = store.open_days?.split('-') || [];
    const currentDay = new Date().toLocaleString('en-US', { weekday: 'long' });

    if (closeDays.includes(currentDay)) {
        return {
            openingTime: store.open_hours,
            closingTime: store.close_hours,
            isOpen: false
        };
    }

    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    
    try {
        const [openHour, openMinutes] = store.open_hours.split(':').map(Number);
        const [closeHour, closeMinutes] = store.close_hours.split(':').map(Number);
        
        const currentTimeInMinutes = currentHour * 60 + currentMinutes;
        const openTimeInMinutes = openHour * 60 + openMinutes;
        const closeTimeInMinutes = closeHour * 60 + closeMinutes;
        
        const isWithinHours = currentTimeInMinutes >= openTimeInMinutes && currentTimeInMinutes <= closeTimeInMinutes;

        return {
            openingTime: store.open_hours,
            closingTime: store.close_hours,
            isOpen: isWithinHours
        };

    } catch (error) {
        console.error('Invalid time format in store hours:', error);
        return {
            openingTime: store.open_hours,
            closingTime: store.close_hours,
            isOpen: false
        };
    }
};
