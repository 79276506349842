import { Route } from "react-router-dom";
/**
 * Context Providers
 */
import { CashbackProvider } from "context/CashbackContext";

/**
 * Middlewares
 */
/**
 * Routes
 */
import Cashback from "views/mobile/Cashback";
import GetCashback from "views/mobile/Cashback/GetCashback";
import ListOffers from "views/mobile/Cashback/ListOffers";
import MapViewCashback from "views/mobile/Cashback/MapView";
import OfferDetails from "views/mobile/Cashback/OfferDetails";
import CashbackHistory from "views/mobile/Cashback/History";
import AddLocation from "views/mobile/Cashback/AddLocation";
import LocationMap from "views/mobile/Cashback/LocationMap";
import ActivateCashback from "views/mobile/Cashback/Activate";
import CurrentBank from "views/mobile/Cashback/CurrentBank";
import PendingOfferList from "views/mobile/Cashback/PendingOfferList";

const CashbackRoutes = (
  <Route
    exact
    path="cashback"
    element={
      <CashbackProvider>
        <Cashback />
      </CashbackProvider>
    }
  >
    <Route exact path="welcome" element={<GetCashback />} />
    <Route exact path="list-offers" element={<ListOffers />} />
    <Route exact path="map-offers" element={<MapViewCashback />} />
    <Route exact path="offer-details" element={<OfferDetails />} />
    <Route exact path="add-location" element={<AddLocation />} />
    <Route exact path="location-map" element={<LocationMap />} />
    <Route exact path="activate" element={<ActivateCashback />} />
    <Route exact path="current-bank" element={<CurrentBank />} />
    <Route exact path="history" element={<CashbackHistory />} />
    <Route exact path="pending-offers" element={<PendingOfferList />} />
  </Route>
);

export default CashbackRoutes;
