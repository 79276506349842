import {RightIcon} from 'assets/icons_v3/fonts';
import PropTypes from 'prop-types';

export default function LoanLinkComponent({
    isActive = false,
    title,
    badgeTitle,
    description,
    icon,
    ...props
}) {
    return (
        <div
            key={title}
            className="flex items-center cursor-pointer gap-4"
            {...props}>
            {icon && (
                <div className="w-10 h-10 rounded-full flex justify-center items-center text-slate-900-kiwi bg-slate-100-kiwi">
                    {icon}
                </div>
            )}

            <div className="mr-auto py-4">
                <p className="font-medium">{title}</p>
                {description && (
                    <p className="text-xs text-gray-500-kiwi">{description}</p>
                )}
                {isActive && badgeTitle && (
                    <div className="mt-2 text-xs py-1 px-4 bg-regal-blue-950-kiwi rounded-2xl text-white text-center inline-block font-semibold">
                        {badgeTitle}
                    </div>
                )}
            </div>

            <RightIcon className="shrink-0 grow-0 w-6 h-6 text-gray-500-kiwi" />
        </div>
    );
}

LoanLinkComponent.propTypes = {
    isActive: PropTypes.bool,
    title: PropTypes.string.isRequired,
    badgeTitle: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.element
};

