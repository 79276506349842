import mixpanel from "utils/mixpanel";
import React from "react";
import { useNavigate } from "react-router-dom";
import ChartLineSVG from "assets/icons_v3/chart_line.svg";
import TachometerSVG from "assets/icons_v3/tachometer.svg";
import MoneySVG from "assets/icons_v3/money.svg";

import {
  usePersonalLoanStatus,
  usePersonalLoanRedirect,
} from "hooks/personalLoan";
import { useCreditBuilderStatus } from "hooks/creditBuilder";
import { useKiwiPayStatus } from "hooks/kiwiPay";
import { useIsMobile } from "hooks/useIsMobile";

export default function HomeProducts({ className }) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const { isPersonalLoanActive } = usePersonalLoanStatus();
  const { isCreditBuilderActive } = useCreditBuilderStatus();
  const { isKiwiPayActive } = useKiwiPayStatus();

  const showCreditBuilderCard = !isCreditBuilderActive;

  const showCreditMonitoringCard =
    !isPersonalLoanActive &&
    !isCreditBuilderActive &&
    !isKiwiPayActive &&
    !isMobile;

  const onCreditBuilderRedirect = () => {
    mixpanel.track("Credit Builder");
    navigate("/credit-builder");
  };

  const onCreditMonitoringRedirect = () => {
    mixpanel.track("Credit Monitoring");
    navigate("/credit-monitoring");
  };

  const onProducts = () => {
    navigate("/products");
  };

  return (
    <>
      {(showCreditBuilderCard || showCreditMonitoringCard) && (
        <div className={className}>
          <div className="flex items-center justify-between mb-4">
            <div className="font-semibold text-sm">Otros productos</div>
            <div
              className="text-blue-kiwi text-xs cursor-pointer"
              onClick={onProducts}
            >
              Ver todos
            </div>
          </div>

          <div className="flex gap-4">
            {showCreditBuilderCard && (
              <div
                className="w-1/2 flex-auto p-4 rounded-2xl border border-slate-200-kiwi cursor-pointer"
                onClick={onCreditBuilderRedirect}
              >
                <img
                  src={ChartLineSVG}
                  className="mb-4"
                  alt="Chart Line Kiwi"
                />
                <div className="font-semibold text-sm mb-2">Credit Builder</div>
                <div className="text-xs">
                  Construye tu historial de crédito por tan solo $5/mes
                </div>
              </div>
            )}

            {showCreditMonitoringCard && (
              <div
                className="w-1/2 flex-auto p-4 rounded-2xl border border-slate-200-kiwi cursor-pointer"
                onClick={onCreditMonitoringRedirect}
              >
                <img
                  src={TachometerSVG}
                  className="mb-4"
                  alt="Tachometer Kiwi"
                />
                <div className="font-semibold text-sm mb-2">
                  Credit Monitoring
                </div>
                <div className="text-xs">
                  Protégete contra el fraude y verifica tu reporte de crédito
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
