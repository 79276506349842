import InformationCircleSVG from "assets/icons_v3/information_circle.svg";
import ShieldCheckSVG from "assets/icons_v3/shield_check.svg";
import BulbMoneySVG from "assets/icons_v3/bulb_money.svg";
import MoneyShopSVG from "assets/icons_v3/money_shop.svg";
import LockedUserSVG from "assets/icons_v3/locked_user.svg";
import EmailSVG from "assets/icons_v3/email.svg";
import BankSVG from "assets/icons_v3/bank.svg";

const ICON_MAP = {
  default: InformationCircleSVG,
  bulb_money: BulbMoneySVG,
  shield_check: ShieldCheckSVG,
  money_shop: MoneyShopSVG,
  locked_user: LockedUserSVG,
  email: EmailSVG,
  bank: BankSVG,
};

const BASE_CLASSES =
  "flex items-start p-4 rounded-lg border border-slate-200-kiwi";
const ICON_CLASSES = "mr-4 shrink-0";

export default function Advisement({
  children,
  className = "",
  icon = "default",
  ariaLabel = "Advisement information",
}) {
  const iconSrc = ICON_MAP[icon] || ICON_MAP.default;

  return (
    <div
      className={`${BASE_CLASSES} ${className}`}
      role="alert"
      aria-label={ariaLabel}
    >
      <img className={ICON_CLASSES} src={iconSrc} alt={`${icon} icon`} />
      <small className="text-xs">{children}</small>
    </div>
  );
}
