import PropTypes from 'prop-types';

const Whatsapp = ({ className, ...props }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...props}>
        <path
            d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.958 9.958 0 0 1-4.863-1.26l-.305-.178-3.032.892a1.01 1.01 0 0 1-1.28-1.145l.026-.109.892-3.032A9.958 9.958 0 0 1 2 12C2 6.477 6.477 2 12 2Zm0 2a8 8 0 0 0-6.759 12.282c.198.312.283.696.216 1.077l-.039.163-.441 1.501 1.501-.441c.433-.128.883-.05 1.24.177A8 8 0 1 0 12 4ZM9.102 7.184a.695.695 0 0 1 .684.075c.504.368.904.862 1.248 1.344l.327.474.153.225a.712.712 0 0 1-.046.864l-.075.076-.924.686a.227.227 0 0 0-.067.291c.21.38.581.947 1.007 1.373.427.426 1.02.822 1.426 1.055.088.05.194.034.266-.031l.038-.045.601-.915a.711.711 0 0 1 .973-.158l.543.379c.54.385 1.059.799 1.47 1.324a.696.696 0 0 1 .089.703c-.396.924-1.399 1.711-2.441 1.673l-.159-.01-.191-.018a4.966 4.966 0 0 1-.108-.014l-.238-.04c-.924-.174-2.405-.698-3.94-2.232-1.534-1.535-2.058-3.016-2.232-3.94l-.04-.238-.025-.208-.013-.175a3.76 3.76 0 0 1-.004-.075c-.038-1.044.753-2.047 1.678-2.443Z"
            fill="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

Whatsapp.displayName = 'Whatsapp';

Whatsapp.propTypes = {
    className: PropTypes.string,
};

export default Whatsapp;
