import { PERSONAL_LOAN_BADGE_TEXT } from "constants";
import { useMemo } from "react";
import { usePersonalLoanStatus } from "./usePersonalLoanStatus";

export function usePersonalLoanBadgeText() {
  const {
    isPersonalLoanActive,
    isPersonalLoanReviewing,
    isPersonalLoanNeedVerificationCall,
    isPersonalLoanMoneyOnWay,
    isPersonalLoanMesalve,
    isPersonalLoanApproved,
    isPersonalLoanRejected,
  } = usePersonalLoanStatus();

  return useMemo(() => {
    if (isPersonalLoanActive) return PERSONAL_LOAN_BADGE_TEXT.active;
    if (isPersonalLoanReviewing) return PERSONAL_LOAN_BADGE_TEXT.reviewing;
    if (isPersonalLoanNeedVerificationCall)
      return PERSONAL_LOAN_BADGE_TEXT.callVerification;
    if (isPersonalLoanMoneyOnWay) return PERSONAL_LOAN_BADGE_TEXT.moneyOnTheWay;
    if (isPersonalLoanMesalve) return PERSONAL_LOAN_BADGE_TEXT.meSalve;
    if (isPersonalLoanApproved) return PERSONAL_LOAN_BADGE_TEXT.approved;
    if (isPersonalLoanRejected) return PERSONAL_LOAN_BADGE_TEXT.rejected;
    return "";
  }, [
    isPersonalLoanActive,
    isPersonalLoanReviewing,
    isPersonalLoanNeedVerificationCall,
    isPersonalLoanMoneyOnWay,
    isPersonalLoanMesalve,
    isPersonalLoanApproved,
    isPersonalLoanRejected,
  ]);
}
