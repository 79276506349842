import * as Yup from 'yup'
import  { useState, useRef, useContext } from 'react'
import Emitter from 'utils/emitter'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { UserContext } from 'context/UserContext'
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import STATE_CONSTANTS from 'constants/states'
import SelectBank from 'components/Modals/SelectBank'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import validateRoutingNumber from 'utils/validateRoutingNumber'

export default function PaymentProfileAddTemplate({
    onAddProfile,
    setShowLoader
}) {
    const form = useRef('')
    const { state: userState, addBankAccount } = useContext(UserContext)
    const {
        user: { uw_details, first_name, first_surname }
    } = userState
    const [loading, setLoading] = useState(false)
    const [bankSelectVisible, setBankSelectVisible] = useState(false)
    const [bank, setBank] = useState({
        name: '',
        routingNumber: '',
        maxDigitsAccount: ''
    })

    const { banks: principalBanks } = STATE_CONSTANTS(
        userState.user.state
    ).approved

    const { getData: getDataFingerprint } = useVisitorData(
        { extendedResult: true },
        { immediate: true }
    )

    const fingerprintSetData = async () => {
        try {
            await getDataFingerprint({
                ignoreCache: true,
                tag: {
                    env: process.env.REACT_APP_FINGERPRINT_ENV,
                    user_id: userState.user.id,
                    place: 'payment_profile_success'
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const isValidRoutingNumber = async (routingNumber) => {
        return await validateRoutingNumber(routingNumber)
    }

    const onSubmit = async ({ accountType, accountNumber, routingNumber }) => {
        if (loading === true) {
            return
        }

        try {
            setLoading(true)
            setShowLoader(true)

            let validationRoutingNumber = await isValidRoutingNumber(
                routingNumber
            )

            if (!validationRoutingNumber) {
                Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Error al agregar tu perfil de pago',
                    message: `Parece que tu número de ruta no es correcto`
                })
                return
            }

            /**
             * TODO: Regresar el id de la tarjeta que se agregó
             * esto para después seleccionar en el listado
             * una tarjeta.
             */
            const {
                data: {
                    data: { last4 }
                }
            } = await addBankAccount({
                accountType,
                accountNumber,
                routingNumber
            })

            if (last4 && last4.numbers) {
                await onAddProfile({
                    accountType,
                    accountNumber,
                    routingNumber,
                    last4: last4.numbers,
                    bankName: last4.bankName
                })
                await fingerprintSetData()
            }
        } catch (error) {
            if (error.response.data.data === 'No coincide') {
                Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Error al agregar tu perfil de pago',
                    message: `Parece que tu número de cuenta no coincide con la proporcionada anteriormente`
                })
            }
        } finally {
            setLoading(false)
            setShowLoader(false)
        }
    }

    const onSelectBank = (bank) => {
        setBank(bank)
        form.current.setFieldValue('bankName', bank.bankName)
        form.current.setFieldValue('routingNumber', bank.routingNumber)
    }

    const validationSchema = Yup.object().shape({
        accountType: Yup.string().required('Este campo es requerido'),
        bankName: Yup.string().required('Este campo es requerido'),
        accountNumber: Yup.string()
            .typeError('El campo debe ser numérico')
            .required('Este campo es requerido')
            .test(
                'equal',
                `Escribe los ${
                    bank.maxDigitsAccount ?? ''
                } dígitos de tu número de cuenta`,
                (val) =>
                    val?.length <= bank.maxDigitsAccount ||
                    bank.maxDigitsAccount === ''
            )
            .test(
                'onlyNumbers',
                `El número de cuenta debe ser numérico`,
                (val) => {
                    if (val === undefined) {
                        return false
                    }

                    if (/^[0-9]+$/.test(val)) {
                        return true
                    }
                }
            )
            .transform((value, originalValue) =>
                /\s/.test(originalValue) ? NaN : value
            ),
        repeatAccountNumber: Yup.string()
            .typeError('El campo debe ser numérico')
            .required('Este campo es requerido')
            .oneOf(
                [Yup.ref('accountNumber')],
                'Tu número de cuenta no es igual'
            ),
        routingNumber: Yup.string()
            .typeError('El campo debe ser numérico')
            .required('Este campo es requerido')
            .test(
                'onlyNumbers',
                `El número de ruta debe ser numérico`,
                (val) => {
                    if (val === undefined) {
                        return false
                    }

                    if (/^[0-9]+$/.test(val)) {
                        return true
                    }
                }
            )
            .test(
                'routingLength',
                'El número de ruta está incorrecto',
                (val) => {
                    if (val === undefined) {
                        return false
                    }
                    if (val.toString().length === 9) {
                        return true
                    }
                }
            )
            .transform((value, originalValue) =>
                /\s/.test(originalValue) ? NaN : value
            )
    })

    const initialFormValues = {
        accountType: 'checking',
        bankName: '',
        accountNumber: '',
        repeatAccountNumber: '',
        routingNumber: ''
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={form}>
            {({ errors, touched }) => (
                <Form className="flex flex-col grow" autoComplete="off">
                    <div className="mb-10">
                        <h1 className="text-dark-kiwi font-semibold text-2xl">
                            Agrega tu perfil de pago
                        </h1>

                        {uw_details && uw_details.support_account_number && (
                            <div className="mt-4">
                                <p className="mb-10">
                                    Asegura que el nombre a continuación sea el
                                    mismo al de tu cuenta bancaria
                                </p>
                                <div className="flex gap-6">
                                    <div className="w-1/2">
                                        <label className="text-sm mb-2">
                                            Nombre en la cuenta:
                                        </label>
                                        <div className="text-xl font-semibold">
                                            {first_name + ' ' + first_surname}
                                        </div>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="text-sm mb-2">
                                            Cuenta termina en:
                                        </label>
                                        <div className="text-xl font-semibold">
                                            ****{' '}
                                            {uw_details.support_account_number.slice(
                                                -4
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="h-0.5 bg-gray-200-kiwi mt-6"></div>
                            </div>
                        )}
                    </div>

                    <div className="mb-10">
                        <div className="mb-6">
                            <label
                                htmlFor="accountType"
                                className="inline-block text-sm mb-2">
                                Tipo de cuenta
                            </label>
                            <div className="flex gap-8">
                                <div className="relative flex w-2/4">
                                    <Field
                                        className="peer hidden"
                                        type="radio"
                                        id="checking"
                                        name="accountType"
                                        value="checking"
                                    />
                                    <div
                                        className={`pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-blue-kiwi after:rounded-full absolute left-4 top-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid -translate-y-1/2 ${
                                            errors.accountType &&
                                            touched.accountType
                                                ? 'border-red-kiwi/50'
                                                : 'border-gray-400-kiwi '
                                        }`}></div>
                                    <label
                                        className={`pl-12 peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                            errors.accountType &&
                                            touched.accountType
                                                ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                : ''
                                        }`}
                                        htmlFor="checking">
                                        Checking
                                    </label>
                                </div>

                                <div className="relative flex w-2/4">
                                    <Field
                                        className="peer hidden"
                                        type="radio"
                                        id="savings"
                                        name="accountType"
                                        value="savings"
                                    />
                                    <div
                                        className={`pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-blue-kiwi after:rounded-full absolute left-4 top-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid -translate-y-1/2 ${
                                            errors.accountType &&
                                            touched.accountType
                                                ? 'border-red-kiwi/50'
                                                : 'border-gray-400-kiwi '
                                        }`}></div>
                                    <label
                                        className={`pl-12 peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                            errors.accountType &&
                                            touched.accountType
                                                ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                : ''
                                        }`}
                                        htmlFor="savings">
                                        Ahorros
                                    </label>
                                </div>
                            </div>
                            <ErrorMessage
                                name="accountType"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>

                        <div className="w-full mb-6">
                            <label
                                htmlFor="bankName"
                                className="inline-block text-sm mb-2">
                                Selecciona tu banco
                            </label>
                            <Field name="bankName" as="select">
                                {({ field }) => (
                                    <div>
                                        <div
                                            className={`h-[42px] focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${
                                                errors.bankName &&
                                                touched.bankName
                                                    ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                    : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                            }`}
                                            disabled
                                            onClick={() =>
                                                setBankSelectVisible(true)
                                            }>
                                            {field.value}
                                        </div>
                                        <SelectBank
                                            data={principalBanks}
                                            selectedBank={field.value}
                                            isVisible={bankSelectVisible}
                                            closeModal={() => {
                                                setBankSelectVisible(false)
                                            }}
                                            selectBank={(bank) => {
                                                onSelectBank(bank)
                                            }}
                                        />
                                    </div>
                                )}
                            </Field>
                            <ErrorMessage
                                name="bankName"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>

                        <div className="mb-6">
                            <label
                                htmlFor="accountNumber"
                                className="inline-block text-sm mb-2">
                                Número de cuenta
                            </label>
                            <Field
                                name="accountNumber"
                                className={`w-full rounded-lg py-2 px-4 border ${
                                    errors.accountNumber &&
                                    touched.accountNumber
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                }`}
                            />
                            <ErrorMessage
                                name="accountNumber"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>

                        <div className="mb-6">
                            <label
                                htmlFor="repeatAccountNumber"
                                className="inline-block text-sm mb-2">
                                Confirmar número de cuenta
                            </label>
                            <Field
                                name="repeatAccountNumber"
                                className={`w-full rounded-lg py-2 px-4 border ${
                                    errors.repeatAccountNumber &&
                                    touched.repeatAccountNumber
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                }`}
                            />
                            <ErrorMessage
                                name="repeatAccountNumber"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>

                        <div className="">
                            <label
                                htmlFor="routingNumber"
                                className="inline-block text-sm mb-2">
                                Número de ruta
                            </label>
                            <Field
                                disabled={bank.routingNumber}
                                id="routingNumber"
                                name="routingNumber"
                                className={`w-full rounded-lg py-2 px-4 border ${
                                    errors.routingNumber &&
                                    touched.routingNumber
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                }`}
                            />
                            <ErrorMessage
                                name="routingNumber"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>
                    </div>

                    <Button
                        id="payment_profile_created-gtm"
                        className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                        loading={loading}
                        type="submit">
                        Continuar
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

PaymentProfileAddTemplate.propTypes = {
    onAddProfile: PropTypes.func.isRequired,
    setShowLoader: PropTypes.func.isRequired,
}
