import PropTypes from "prop-types";
export const TransactionDetail = ({ label, value }) => (
    <div className="flex items-center justify-between gap-4">
        <p className="text-sm">{label}</p>
        <p className="text-slate-900-kiwi text-sm font-semibold">{value}</p>
    </div>
);
TransactionDetail.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};