import  { useState } from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import Referral from './Referral'
import Social from './Social'

export default function HomeRecommended({ className }) {
    const [swipeIndex, setSwipeIndex] = useState(0)

    const onSwipeSlider = ({ realIndex }) => {
        setSwipeIndex(realIndex)
    }

    return (
        <div className={`${className}`}>
            <div className="font-semibold text-sm mb-4">
                Recomendados para ti
            </div>

            <Swiper
                className="w-full cursor-grab"
                centeredSlides={false}
                spaceBetween={24}
                slidesPerView={1.1}
                onSlideChange={onSwipeSlider}>
                <SwiperSlide>
                    <Referral />
                </SwiperSlide>
                <SwiperSlide>
                    <Social />
                </SwiperSlide>
            </Swiper>

            <div className="flex justify-center gap-2 mt-4">
                <div
                    className={`h-1 rounded-full ${
                        swipeIndex === 0
                            ? 'bg-dark-kiwi w-4'
                            : 'bg-gray-300-kiwi w-2'
                    }`}></div>
                <div
                    className={`h-1 rounded-full ${
                        swipeIndex === 1
                            ? 'bg-dark-kiwi w-4'
                            : 'bg-gray-300-kiwi w-2'
                    }`}></div>
            </div>
        </div>
    )
}

HomeRecommended.propTypes = {
    className: PropTypes.string
}