import SuccessSVG from "assets/onboarding_v3/success.svg";
import PrimaryButton from "components/Button/Primary";
import { useLocation, useNavigate } from "react-router-dom";
const BankAccountsSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    textButton = "Ir a cuentas bancarias",
    redirectRouteButton = "/bank-accounts",
  } = location?.state || {};

  const onContinue = () => {
    navigate(redirectRouteButton);
  };

  return (
    <>
      <div className="flex flex-col justify-center text-center m-auto mb-10">
        <img className="mx-auto mb-8" src={SuccessSVG} />
        <h1 className="text-dark-kiwi font-semibold text-2xl">
          ¡Tu cuenta de banco ha sido conectada!
        </h1>
      </div>

      <PrimaryButton
        className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
        onClick={onContinue}
      >
        {textButton}
      </PrimaryButton>
    </>
  );
};

export default BankAccountsSuccess;
