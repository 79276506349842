import  { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import ProgressBar from 'components/ProgressBar'

export default function RequestLoanBankStatementsOnboarding() {
    const navigate = useNavigate()

    const onBackward = () => {
        navigate('/request-loan/validation')
    }

    const onUploadFiles = () => {
        navigate('/request-loan/bank-statements/documents')
    }

    return (
        <Fragment>
            <ProgressBar progress="12/13" onBackward={onBackward} />

            <div className="mb-10">
                <h1 className="font-semibold mb-4 text-2xl">
                    Enviar estados bancarios
                </h1>
                <p>
                    Envía tus 2 últimos estados bancarios incluyendo todas las
                    páginas al correo <strong>support@kiwicredito.com</strong> o
                    puedes subir tus estados desde aquí.
                </p>
            </div>

            <button
                className="mt-auto bg-blue-kiwi text-white rounded-xl p-3 flex items-center justify-center"
                onClick={onUploadFiles}>
                Subir archivo o imagen
            </button>
        </Fragment>
    )
}
