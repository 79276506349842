import PropTypes from "prop-types";

export const DescriptionPaymentRow = ({ label, value }) => {
  return (
    <div className="flex flex-row justify-between items-center">
      <label className="text-gray-700-kiwi text-sm font-normal">{label}</label>
      <p className="text-dark-kiwi text-sm font-semibold">{value}</p>
    </div>
  );
};

DescriptionPaymentRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
