import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import BackwardButton from "components/Button/Backward";
import PageTitle from "components/PageTitle";
import TabsComponent from "components/Tabs";
import ContentNotifications from "./ContentNotifications";
import {
  changeStatusNotifications,
  getNotificationsStatus,
} from "api/Notifications";
const Notifications = () => {
  const [smsCheck, setSmsCheck] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);
  const [selectedTab, setSelectedTab] = useState("email");

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    getStatus(tab);
  };

  const navigate = useNavigate();

  const onBackward = () => {
    navigate("/settings");
  };

  const handleSMS = () => {
    handleChangeStatus("sms", !smsCheck);
    setSmsCheck(!smsCheck);
  };

  const handleEmail = () => {
    handleChangeStatus("email", !emailCheck);
    setEmailCheck(!emailCheck);
  };

  const handleChangeStatus = async (type, on) => {
    try {
      await changeStatusNotifications({ type, on });
    } catch (error) {
      console.log(error);
    }
  };

  const getStatus = async (type) => {
    try {
      const {
        data: { promotional_email, promotional_sms },
      } = await getNotificationsStatus();
      setEmailCheck(promotional_email);
      setSmsCheck(promotional_sms);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStatus(selectedTab);
  }, []);

  const tabs = [
    {
      label: "Email",
      key: "email",
      content: (
        <ContentNotifications
          title="Ofertas y novedades"
          description="Recibe notificaciones sobre promociones exclusivas, novedades en
        productos y ofertas especiales."
          checked={emailCheck}
          handleCheck={handleEmail}
        />
      ),
    },
    {
      label: "SMS",
      key: "sms",
      content: (
        <ContentNotifications
          title="Ofertas y novedades"
          description="Recibe notificaciones sobre promociones exclusivas, novedades en
          productos y ofertas especiales."
          checked={smsCheck}
          handleCheck={handleSMS}
        />
      ),
    },
  ];

  return (
    <div className="w-full min-h-full flex flex-col px-6 py-10">
      <BackwardButton onBackward={onBackward} />
      <PageTitle className="mb-4">Notificaciones</PageTitle>
      <p className="text-base mb-6 text-gray-700-kiwi">
        Configura qué tipo de mensajes quieres recibir y por qué canales.
      </p>

      <TabsComponent
        tabs={tabs}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />
    </div>
  );
};

export default Notifications;
