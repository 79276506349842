import { useNavigate } from "react-router-dom";
import Question from "assets/icons_v3/fonts/question";
import BackwardButton from "components/Button/Backward";
import PropTypes from "prop-types";

export const Header = ({ backRoute, disableHelpIcon }) => {
  const navigate = useNavigate();
  return (
    <div className="flex row justify-between">
      <BackwardButton onBackward={() => navigate(backRoute)} className="mb-6" />

      {!disableHelpIcon && <Question />}
    </div>
  );
};

Header.propTypes = {
  backRoute: PropTypes.string,
  disableHelpIcon: PropTypes.bool,
};

Header.defaultProps = {
  backRoute: "/home",
  disableHelpIcon: true,
};
