import { UserContext } from "context/UserContext";
import { useContext } from "react";

const useNotificationsCenter = () => {
  const {
    state: { notifications },
  } = useContext(UserContext);

  return notifications || [];
};

export default useNotificationsCenter;
