export const getWholeAddress = async (address, currentCoordinates) => {
  if (!address?.address_components) {
    return {
      street: "",
      city: "",
      state: "",
      zip_code: "",
      plus_code: "",
      route: "",
    };
  }

  const findComponent = (type) => {
    return (
      address.address_components.find((component) =>
        component.types.includes(type),
      )?.long_name || ""
    );
  };

  let city = findComponent("locality");
  if (city === "") {
    city = findComponent("sublocality");
  }

  const state = findComponent("country");
  const zip_code = findComponent("postal_code");
  const plus_code = findComponent("plus_code");
  const route = findComponent("route");
  const street_number = findComponent("street_number");

  let street = "";
  if (street_number && route) {
    street = `${street_number} ${route}`;
  } else if (plus_code) {
    street = plus_code;
  } else if (route) {
    street = route;
  } else {
    street = city;
  }

  return {
    street,
    city,
    state,
    zip_code,
    plus_code,
    route,
    latitude: currentCoordinates.lat,
    longitude: currentCoordinates.lng,
  };
};
