import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";

import { CashbackAPI } from "api";
import { UserContext } from "context/UserContext";

import LoadingModal from "components/Modals/Loading";
import MenuCashbackComponent from "components/MenuCashback";
import MapLocationsComponent from "components/MapLocations";

export default function CashbackMapView() {
  const navigate = useNavigate();
  const { state: userState } = useContext(UserContext);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const getOffers = async (value) => {
    try {
      setLoading(true);
      const { data } = await CashbackAPI.getOffers(userState.user.id);
      if (data.length === 0) {
        setOffers([]);
        return;
      }

      if (value !== "all") {
        const filtered = data.filter(
          (offer) => offer.store.merchant.industry === value,
        );
        setOffers(filtered);
      } else {
        setOffers(data);
      }
    } catch (error) {
      console.error("Error fetching offers:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const onClickShowMap = () => {
    navigate("/cashback/list-offers");
  };

  const onCloseModal = () => {
    navigate("/cashback/list-offers");
  };

  const selectOption = async (value) => {
    getOffers(value);
    setSelectedCategory(value);
  };

  useEffect(() => {
    getOffers("all");
  }, []);

  return (
    <div className="flex flex-col w-full min-h-full">
      <LoadingModal isVisible={loading} />
      <div className="rounded-b-xl absolute w-full overflow-hidden pb-6 pt-10 gap-4 bg-white z-10">
        <button onClick={onCloseModal} className="px-6 mb-4">
          <XIcon className="w-6" />
        </button>
        <MenuCashbackComponent
          selectedCategory={selectedCategory}
          selectOption={selectOption}
        />
      </div>
      <div className="w-full h-full relative">
        <MapLocationsComponent
          locations={offers}
          loading={loading}
          onClickShowMap={onClickShowMap}
        />
      </div>
    </div>
  );
}
