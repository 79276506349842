import  {useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import AddCreditCardTemplate from 'templates/AddCreditCard';

export default function ActiveLoanPaymentMethodCard() {
    const creditCardRef = useRef();
    const navigate = useNavigate();

    const onBackward = () => {
        navigate(-1);
    };

    return (
        <AddCreditCardTemplate
            product="personal_loan"
            onBackward={onBackward}
            ref={creditCardRef}
        />
    );
}
