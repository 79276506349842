import PropTypes from 'prop-types';

const DownIcon = ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95 4.95-4.95a1 1 0 0 1 1.414 1.414l-5.657 5.657Z"
        />
    </svg>
);

DownIcon.displayName = 'DownIcon';

DownIcon.propTypes = {
    className: PropTypes.string,
};

export default DownIcon;
