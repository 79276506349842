import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CashbackContext } from "context/CashbackContext";

import WomenCashBack from "assets/cashback/women_cash.webp";
import Button from "components/Button";
import Menu from "templates/Menu";
import getAddressFromLatLong from "utils/Maps/getAddressFromLatLong";
import { getWholeAddress } from "utils/Maps/getFullWholeAddress";
const PERMISSION_STATUS = {
  PROMPT: "prompt",
  GRANTED: "granted",
  DENIED: "denied",
};

export default function CashbackGet() {
  const navigate = useNavigate();
  const { state: cashbackState, setState: setCashbackState } =
    useContext(CashbackContext);
  const [loader, setLoader] = useState(false);

  const onContinue = async () => {
    try {
      const hasPendingPermission = await pendingInitialPermission();

      if (!hasPendingPermission) {
        return navigate("/cashback/add-location");
      }

      return navigate("/cashback/list-offers");
    } catch (error) {
      console.error("Error in cashback flow:", error);
      return navigate(
        cashbackState.address
          ? "/cashback/list-offers"
          : "/cashback/add-location",
      );
    }
  };

  const getAddress = async (lat, lng) => {
    try {
      const address = await getAddressFromLatLong(lat, lng);
      const addressInfo = await getWholeAddress(address, { lat, lng });

      setCashbackState((state) => ({
        ...state,
        user_current_location: { lat, lng },
        address: addressInfo,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const pendingInitialPermission = async () => {
    setLoader(true);

    try {
      if (!navigator.geolocation) {
        console.warn("Geolocation is not supported in this browser.");
        return false;
      }

      if (!navigator.permissions) {
        console.warn("Permissions API is not supported in this browser.");
        return false;
      }

      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      });

      if (permissionStatus.state === PERMISSION_STATUS.DENIED) {
        console.warn("Location permission is denied");
        return false;
      }

      const getPosition = async () => {
        return new Promise((resolve, reject) => {
          const TIMEOUT_DURATION = 20000;
          let isFinished = false;

          const timeout = setTimeout(() => {
            if (!isFinished) {
              isFinished = true;
              reject(new Error("Geolocation request timed out"));
            }
          }, TIMEOUT_DURATION);

          const options = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          };

          navigator.geolocation.getCurrentPosition(
            (position) => {
              if (!isFinished) {
                isFinished = true;
                clearTimeout(timeout);
                resolve(position);
              }
            },
            (error) => {
              if (!isFinished) {
                isFinished = true;
                clearTimeout(timeout);
                reject(error);
              }
            },
            options,
          );
        });
      };

      let position;
      try {
        position = await getPosition();

        if (!position?.coords?.latitude || !position?.coords?.longitude) {
          console.error("Invalid coordinates received");
          return false;
        }

        const finalStatus = await navigator.permissions.query({
          name: "geolocation",
        });
        await getAddress(position.coords.latitude, position.coords.longitude);

        return finalStatus.state === PERMISSION_STATUS.GRANTED;
      } catch (error) {
        console.error("Error getting position:", error.message);

        if (error.code === 1) {
          console.log("User denied location permission");
          return false;
        }

        if (error.code === 3 || error.message.includes("timeout")) {
          console.log("Location request timed out");
          return false;
        }

        return false;
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      return false;
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="flex flex-col w-full min-h-full px-6 py-10">
        <div className="flex flex-col justify-center gap-8 pt-12 pb-10">
          <img src={WomenCashBack} className="max-w-[13rem] mx-auto" />
          <div className="flex flex-col gap-4 text-center">
            <h1 className="text-slate-900-kiwi font-semibold text-xl">
              Gana Cashback en tus tiendas favoritas con Kiwi
            </h1>
            <p className="text-slate-600-kiwi">
              Selecciona una oferta, canjea y ahorra en cada compra que
              realices.
              <br />
              <br />
              ¡Convierte cada compra en ahorro!
            </p>
          </div>
        </div>
        <Button
          className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto mb-16"
          loading={loader}
          onClick={onContinue}
        >
          Explorar ofertas
        </Button>
      </div>
      <Menu selected="cashback" />
    </>
  );
}
