import { forwardRef, useImperativeHandle, useRef } from 'react'
import BaseModal from './Modal'

const SMSTermsModal = forwardRef((props, ref) => {
    const BaseModalRef = useRef()

    useImperativeHandle(ref, () => ({
        ...BaseModalRef.current
    }))

    return (
        <BaseModal
            title="TCPA, Servicio de Marcaci&oacute;n Autom&aacute;tica, Llamadas de Marketing, Consentimiento y Privacidad de Mensajes de Texto SMS"
            ref={BaseModalRef}>
            <div className="flex flex-col gap-6">
                <p>
                    Sin perjuicio de cualquier elecci&oacute;n actual o anterior para
                    aceptar o no recibir llamadas o mensajes SMS (incluidos los
                    mensajes de texto) de la Compa&ntilde;&iacute;a, nuestros agentes,
                    representantes, afiliados, cesionarios, sucesores, abogados,
                    compa&ntilde;&iacute;as de cobro, proveedores de servicios o cualquier
                    persona que llame o le contacte en nuestro nombre
                    (colectivamente, las &quot;Partes de la Compa&ntilde;&iacute;a&quot; o
                    individualmente &quot;Parte de la Compa&ntilde;&iacute;a&quot;), como consideraci&oacute;n
                    buena y valiosa por la Membres&iacute;a y los Servicios que se le
                    brindan, Usted acepta expresamente recibir llamadas y
                    mensajes (incluidos mensajes de texto) a trav&eacute;s de un
                    sistema de marcaci&oacute;n telef&oacute;nica automatizada (&ldquo;ATDS&rdquo;) de las
                    Partes de la Compa&ntilde;&iacute;a en cualquier n&uacute;mero de tel&eacute;fono
                    asociado con Su cuenta que haya proporcionado o pueda
                    proporcionar en el futuro (incluido cualquier n&uacute;mero de
                    celular o m&oacute;vil) con avisos de servicio de cuenta; con
                    avisos de cobro, mensajes de marketing, promocionales o de
                    marketing mixto, utilizando mensajes de voz
                    pregrabados/artificiales a trav&eacute;s de un ATDS. Tambi&eacute;n otorga
                    permiso a las Partes de la Compa&ntilde;&iacute;a para comunicarle dicha
                    informaci&oacute;n mediante notificaci&oacute;n autom&aacute;tica o correo
                    electr&oacute;nico. Los mensajes automatizados pueden ser
                    reproducidos por una m&aacute;quina autom&aacute;ticamente cuando se
                    contesta un tel&eacute;fono, ya sea que usted o alguien m&aacute;s
                    responda. En caso de que un agente o representante llame, &eacute;l
                    o ella puede dejar un mensaje en Su contestador autom&aacute;tico,
                    correo de voz o enviar uno por mensaje de texto. No puede
                    revocar su consentimiento para recibir notificaciones de
                    cobro o servicio de cuenta si tiene una obligaci&oacute;n pendiente
                    con las Partes de la Compa&ntilde;&iacute;a o una de sus afiliadas o si
                    est&aacute; utilizando los servicios de una de las Partes de la
                    Compa&ntilde;&iacute;a o de sus afiliadas. Sin embargo, puede comunicarse
                    con la Compa&ntilde;&iacute;a en cualquier momento para revocar su
                    consentimiento para recibir mensajes promocionales o de
                    marketing enviando un correo electr&oacute;nico a
                    support@kiwicredito.com con el asunto &quot;Detener mensajes de
                    marketing&quot; e incluyendo su nombre, direcci&oacute;n postal, n&uacute;mero
                    de tel&eacute;fono y direcci&oacute;n de correo electr&oacute;nico e indicando
                    que no desea recibir tales llamadas, mensajes de texto o
                    correos electr&oacute;nicos de; llamando a un representante de
                    atenci&oacute;n al cliente al 787-490-2004 y proporcionando el
                    n&uacute;mero de tel&eacute;fono y/o correo electr&oacute;nico del que no desea
                    recibir dichas llamadas, mensajes de texto o correos
                    electr&oacute;nicos.
                </p>

                <p>
                    Usted certifica, garantiza y declara que los n&uacute;meros de
                    tel&eacute;fono y/o correos electr&oacute;nicos que ha proporcionado a las
                    Partes de la Compa&ntilde;&iacute;a son Sus tel&eacute;fonos y correos
                    electr&oacute;nicos personales y acepta que las Partes de la
                    Compa&ntilde;&iacute;a no ser&aacute;n responsables ante Usted por ning&uacute;n
                    reclamo, da&ntilde;o, tarifa, inconveniente o molestia, o p&eacute;rdida
                    de privacidad en relaci&oacute;n con dichas llamadas, mensajes de
                    texto o correos electr&oacute;nicos. Usted comprende que cualquier
                    persona con acceso a Su tel&eacute;fono o correo electr&oacute;nico puede
                    escuchar o leer los mensajes, sin perjuicio de que las
                    Partes de la Compa&ntilde;&iacute;a se comuniquen &uacute;nicamente con Usted. Si
                    cambia un n&uacute;mero de tel&eacute;fono o correo electr&oacute;nico de las
                    Partes Prestamistas, o si usted deja de ser el propietario,
                    suscriptor o usuario principal de dicho n&uacute;mero de tel&eacute;fono o
                    correo electr&oacute;nico, acepta notificar inmediatamente a las
                    Partes de la Compa&ntilde;&iacute;a tales hechos para que actualicen sus
                    registros. Su proveedor de telefon&iacute;a celular o m&oacute;vil,
                    proveedor de Internet o proveedor de correo electr&oacute;nico le
                    cobrar&aacute; seg&uacute;n el tipo de plan que tenga.
                </p>

                <p>
                    Usted comprende y acepta que, sujeto a la ley aplicable, las
                    Partes de la Compa&ntilde;&iacute;a pueden monitorear y/o grabar
                    cualquiera de Sus conversaciones telef&oacute;nicas con cualquiera
                    de sus representantes para capacitaci&oacute;n, control de calidad,
                    evidencia y cualquier otro prop&oacute;sito. Sin embargo, las
                    Partes de la Compa&ntilde;&iacute;a no tienen ninguna obligaci&oacute;n de
                    monitorear, retener o reproducir dichas grabaciones, a menos
                    que lo exija la ley aplicable.
                </p>
            </div>
        </BaseModal>
    )
})

SMSTermsModal.displayName = 'SMSTermsModal';

export default SMSTermsModal
