import PropTypes from 'prop-types';

const MapLine = ({ className, ...props }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    className={className}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.18375 2.06938C6.38902 2.00096 6.61094 2.00096 6.81621 2.06938L10.5 3.29731L14.0259 2.12199C14.5871 1.93493 15.1666 2.35264 15.1666 2.94419V11.7598C15.1666 12.1902 14.8912 12.5723 14.4828 12.7085L10.8162 13.9307C10.6109 13.9991 10.389 13.9991 10.1838 13.9307L6.49998 12.7027L2.97405 13.8781C2.41285 14.0651 1.83331 13.6475 1.83331 13.0559V4.24029C1.83331 3.80986 2.10875 3.42772 2.51709 3.29161L6.18375 2.06938ZM5.83331 11.5195V3.59165L3.16665 4.48054V12.4084L5.83331 11.5195ZM7.16665 3.59165V11.5195L9.83331 12.4084V4.48054L7.16665 3.59165ZM11.1666 4.48054V12.4084L13.8333 11.5195V3.59165L11.1666 4.48054Z"
      fill="white"
    />
  </svg>
);

MapLine.propTypes = {
    className: PropTypes.string,
};

export default MapLine;