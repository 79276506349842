import  { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import mixpanel from 'utils/mixpanel'
import PlaidLogoSVG from 'assets/logos/plaid.svg'
import Button from 'components/Button'
import Advisement from 'components/Advisement'
import LoadingModal from 'components/Modals/Loading'
import {
    BankIcon,
    Wallet3Icon,
    BalanceIcon,
    TimeIcon,
    Headphone2Icon
} from 'assets/icons_v3/fonts'
import { usePlaidLink } from 'react-plaid-link'
import { GAevent } from 'utils/GA'
import { PlaidAPI } from 'api'

export default function PlaidTemplate({
    title,
    description,
    onSuccess,
    onExit
}) {
    const [showLoader, setShowLoader] = useState(false)
    const [linkTokenPlaid, setLinkTokenPlaid] = useState('')

    useEffect(() => {
        createTokenPlaid()
    }, [])

    const createTokenPlaid = async () => {
        try {
            setShowLoader(true)

            const {
                data: {
                    data: { link_token }
                }
            } = await PlaidAPI.generateToken()

            setLinkTokenPlaid(link_token)
        } catch (error) {
            console.error(error)
        } finally {
            setShowLoader(false)
        }
    }

    const { open: openPlaid } = usePlaidLink({
        token: linkTokenPlaid,
        onSuccess: async (public_token) => {
            try {
                setShowLoader(true)

                const {
                    data: { success }
                } = await PlaidAPI.publicTokenExchange({
                    public_token
                })

                if (success) {
                    GAevent('user_action', 'plaid_success')
                    _cio.track('link_bank_account', {
                        status: 'successful'
                    })
                    mixpanel.track('Link Bank Account', {
                        Type: 'error',
                        Reason: 'plaid'
                    })

                    onSuccess()
                }
            } catch (error) {
                console.error(error)
            } finally {
                setShowLoader(false)
            }
        },
        onExit: async () => {
            mixpanel.track('Link Bank Account', {
                Type: 'exit',
                Reason: 'plaid'
            })
            setShowLoader(false)
            onExit()
        }
    })

    const onContinue = async () => {
        await openPlaid()
        setShowLoader(true)
    }

    return (
        <Fragment>
            <LoadingModal isVisible={showLoader} />
            <div className="mb-10">
                <h1 className="font-semibold mb-4 text-2xl">{title}</h1>
                <div className="mb-8">{description}</div>

                <div className="flex flex-col gap-6 ">
                    <div className="flex items-center">
                        <div className="flex h-10 w-10 bg-slate-100-kiwi rounded-full mr-4 flex-none">
                            <Wallet3Icon className="m-auto text-regal-blue-kiwi" />
                        </div>
                        <div className="text-sm">
                            Tu cuenta bancaria está a tu nombre
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex h-10 w-10 bg-slate-100-kiwi rounded-full mr-4 flex-none">
                            <BalanceIcon className="m-auto text-regal-blue-kiwi" />
                        </div>
                        <div className="text-sm">
                            Actualmente no está sobregirada
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex h-10 w-10 bg-slate-100-kiwi rounded-full mr-4 flex-none">
                            <TimeIcon className="m-auto text-regal-blue-kiwi" />
                        </div>
                        <div className="text-sm">
                            Tiene al menos tres meses de transacciones
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex h-10 w-10 bg-slate-100-kiwi rounded-full mr-4 flex-none">
                            <BankIcon className="m-auto text-regal-blue-kiwi" />
                        </div>
                        <div className="text-sm">
                            Aquí es donde obtienes tu principal fuente de
                            ingresos
                        </div>
                    </div>
                </div>

                <img className="mx-auto mt-14" src={PlaidLogoSVG} />
            </div>

            <div className="flex flex-col mt-auto">
                <Advisement icon="shield_check">
                    Utilizamos Plaid para vincular tu cuenta bancaria de manera
                    segura, empleando protocolos de cifrado avanzados para
                    garantizar la protección de tus datos.
                </Advisement>

                <Button
                    className="bg-blue-kiwi text-white rounded-xl p-3 flex items-center justify-center mt-10"
                    onClick={onContinue}
                    loading={showLoader}>
                    Conectar cuenta de banco
                </Button>

                <a
                    href="tel:+17874221861"
                    className="mt-6 text-blue-kiwi mx-auto flex items-center gap-2">
                    <Headphone2Icon />
                    ¿Necesitas ayuda?
                </a>
            </div>
        </Fragment>
    )
}


PlaidTemplate.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    onSuccess: PropTypes.func,
    onExit: PropTypes.func
}