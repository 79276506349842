export const jsonSchema = {
    title: 'DMP Input Schema',
    type: 'object',
    properties: {
        user_information: {
            type: 'object',
            properties: {
                visitor_id: {
                    type: 'string',
                    description: 'Visitor ID for the visit.',
                },
                user_id: {
                    type: ['string', 'null'],
                    description:
                        'User ID when user has already signed up for Kiwi.',
                },
                age: {
                    type: 'integer',
                    minimum: 10,
                    maximum: 100,
                    description: 'The age of the user.',
                },
                gender: {
                    type: 'string',
                    enum: ['male', 'female', 'other'],
                    description: 'The gender of the user.',
                },
                location: {
                    type: 'object',
                    properties: {
                        country: {
                            type: 'string',
                            description: 'The country of the user.',
                        },
                        state: {
                            type: ['string', 'null'],
                            description: 'The state of the user.',
                        },
                        city: {
                            type: ['string', 'null'],
                            description: 'The city of the user.',
                        },
                        zip: {
                            type: ['string', 'null'],
                            description: 'The zip code of the user.',
                        },
                        latitude: {
                            type: ['number', 'null'],
                            description: 'The latitude of the user.',
                        },
                        longitude: {
                            type: ['number', 'null'],
                            description: 'The longitude of the user.',
                        },
                    },
                    required: ['country', 'state', 'city'],
                },
            },
            required: ['visitor_id', 'user_id', 'location'],
        },
        device_information: {
            type: 'object',
            properties: {
                device_id: {
                    type: 'string',
                    description: 'Device ID for the visit.',
                },
                device_type: {
                    type: 'string',
                    description: 'The type of device used for the visit.',
                },
                os: {
                    type: 'string',
                    description: 'The operating system of the device.',
                },
                os_version: {
                    type: 'string',
                    description: 'The version of the operating system.',
                },
                app_version: {
                    type: 'string',
                    description: 'The version of the app.',
                },
                browser: {
                    type: 'string',
                    description: 'The browser used for the visit.',
                },
            },
            required: [
                'device_id',
                'device_type',
                'os',
                'os_version',
                'app_version',
                'browser',
            ],
        },
        event_information: {
            type: 'object',
            properties: {
                event_type: {
                    type: 'string',
                    description: 'The type of event.',
                },
                event_id: {
                    type: 'string',
                    description: 'The ID of the event. UUID format.',
                },
                session_id: {
                    type: 'string',
                    description:
                        'The ID of the session. UUID format. Should be the same for all events in the session. Session lives for 4 hours.',
                },
                event_timestamp: {
                    type: 'string',
                    format: 'date-time',
                    description:
                        'The timestamp for the event, in ISO 8601 format. Should be in UTC time.',
                },
                event_duration: {
                    type: ['integer', 'null'],
                    minimum: 0,
                    description: 'The duration of the event in seconds.',
                },
                reference_page: {
                    type: 'string',
                    description: 'The reference page for the visit.',
                },
                event_url: {
                    type: 'string',
                    description: 'The URL of the event.',
                },
                utm_parameters: {
                    type: 'object',
                    description: 'The values from utm parameters.',
                    properties: {
                        utm_source: {
                            type: ['string', 'null'],
                            description: 'The utm source.',
                        },
                        utm_medium: {
                            type: ['string', 'null'],
                            description: 'The utm medium.',
                        },
                        utm_campaign: {
                            type: ['string', 'null'],
                            description: 'The utm campaign.',
                        },
                        utm_term: {
                            type: ['string', 'null'],
                            description: 'The utm term.',
                        },
                        utm_content: {
                            type: ['string', 'null'],
                            description: 'The utm content.',
                        },
                    },
                    required: ['utm_source', 'utm_medium', 'utm_campaign'],
                },
            },
            required: [
                'event_type',
                'event_id',
                'session_id',
                'event_timestamp',
                'event_duration',
                'reference_page',
                'event_url',
                'utm_parameters',
            ],
        },
    },
    required: ['user_information', 'device_information', 'event_information'],
};