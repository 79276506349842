import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from 'context/UserContext';
import mixpanel from 'utils/mixpanel';
import ReviewingImage from 'assets/onboarding/reviewing.svg';
import CreditLimitCancelModal from 'components/Modals/CreditLimitCancel';
import {CREDIT_LIMIT_STATUS} from 'constants';
import {CreditLimitContext} from 'context/CreditLimitContext';
import Button from 'components/Button';
import {ProductsAPI} from 'api';

export default function CreditLimitReviewing() {
    const navigate = useNavigate();

    const {updateSurvey} = useContext(CreditLimitContext);
    const {state: userState, setState: setUserState} = useContext(UserContext);
    const {
        products: {personal_loan},
    } = userState;

    const [visibleModal, setVisibleModal] = useState(false);
    const [creditLimit, setCreditLimit] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const {application, credit_limit} = personal_loan;

        if (credit_limit?.status !== CREDIT_LIMIT_STATUS.pending) {
            return navigate('/home');
        }

        setCreditLimit(application.credit_limit);
    }, []);

    const onToggleModal = () => {
        setVisibleModal(!visibleModal);
    };

    const onWaitingSurvey = () => {
        navigate('/home');
    };

    const onCancelSurvey = async () => {
        try {
            setLoading(true);

            const {
                credit_limit: {id},
            } = personal_loan;

            const {
                data: {success},
            } = await updateSurvey(id, {
                status: CREDIT_LIMIT_STATUS.canceled,
                closed: true,
            });

            if (success) {
                const {
                    data: {data: personal_loan},
                } = await ProductsAPI.getPersonalLoan();

                setUserState({
                    ...userState,
                    products: {
                        ...userState.products,
                        personal_loan,
                    },
                });

                mixpanel.track('Cancel Limit Increase');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <CreditLimitCancelModal
                isVisible={visibleModal}
                cancelSurvey={onCancelSurvey}
                closeModal={onToggleModal}
                creditLimit={creditLimit}
            />

            <div className="flex flex-col justify-center text-center m-auto">
                <img className="mx-auto" width="218" src={ReviewingImage} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Estamos verificando tu información
                </h1>
                <p>
                    Estamos analizando tu solicitud de aumento de límite,
                    recibirás respuesta en un plazo de 12 a 24 horas laborales
                </p>
            </div>
            <div className="flex flex-col mt-10">
                <Button
                    className="rounded-xl p-3 bg-blue-kiwi text-white"
                    loading={loading}
                    onClick={onWaitingSurvey}>
                    Esperar verificación
                </Button>
                <button
                    className="text-blue-kiwi mx-auto mt-6"
                    onClick={onToggleModal}
                    disabled={loading}>
                    Continuar con límite actual
                </button>
            </div>
        </Fragment>
    );
}
