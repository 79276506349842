import { LoanAPI } from "api";
import { Presentation2Icon, ReceiveMoneyIcon } from "assets/icons_v3/fonts";
import EmptyStateStoreSVG from "assets/empty_states/store.svg";
import BackwardButton from "components/Button/Backward";
import PrimaryButton from "components/Button/Primary";
import LoadingModal from "components/Modals/Loading";
import PageTitle from "components/PageTitle";
import PaymentSkeleton from "components/Skeleton/Payment";
import { Fragment, useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import formatDate from "utils/formatDate";
import moneyFormat from "utils/moneyFormat";
import { UserContext } from "context/UserContext";
import LoanLink from "components/LoanLink";

export default function DocumentsProducts() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);
  const [loans, setLoans] = useState([]);
  const [selectedProduct] = useState(location.state ?? "personal_loan");
  const { state: userState } = useContext(UserContext);

  const productsData = {
    personal_loan: {
      title: "Préstamos personales",
      emptyState: {
        title: "Aún no tienes préstamos personales",
        description:
          "Puedes solicitar tu préstamo personal de hasta $3,000 ¡Ahora mismo!",
        button: "Solicitar mi préstamo",
        action: () => {
          const { status } = userState.products.personal_loan;

          if (status === "apply") {
            return navigate("/request-loan/address");
          } else if (status === "approved") {
            return navigate("/approved-loan/amount");
          }

          navigate("/home");
        },
      },
      link: {
        icon: <ReceiveMoneyIcon />,
        description: (loan) =>
          `${formatDate(loan.created_at, "MMMM DD, YYYY")} - $${moneyFormat(loan.amount)}`,
        title: "Préstamo personal",
        badgeTitle: "Crédito activo",
      },
    },
    credit_builder: {
      title: "Credit Builder",
      emptyState: {
        title: "Aún no tienes un curso comprado",
        description:
          "Puedes construir tu historial de crédito por tan solo $5/mes",
        button: "Construir mi historial",
        action: () => {
          navigate("/credit-builder/build-score", {
            state: {
              onBackward: "/documents/products",
            },
          });
        },
      },
      link: {
        icon: <Presentation2Icon />,
        description: (loan) => loan.cb_orders[0]?.product.name ?? "-",
        title: "Financiamiento",
        badgeTitle: "Curso activo",
      },
    },
  };

  const [layoutDetails] = useState(productsData[selectedProduct]);

  useEffect(() => {
    getProductLoans();
  }, [selectedProduct]);

  const productsAPI = {
    personal_loan: LoanAPI.getPersonalLoans,
    credit_builder: LoanAPI.getCreditBuilderLoans,
  };

  const getProductLoans = async () => {
    try {
      setLoans([]);
      setShowLoader(true);

      const { data } = await productsAPI[selectedProduct]();

      setLoans(data.loans);
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false);
    }
  };

  const onBackward = () => {
    navigate("/documents");
  };

  return (
    <Fragment>
      <BackwardButton onBackward={onBackward} />
      <LoadingModal isVisible={showLoader} />
      <PageTitle>{layoutDetails.title}</PageTitle>

      <div className="flex flex-col gap-2 mt-8">
        {showLoader ? (
          <Fragment>
            {[...Array(10)].map((item, index) => (
              <PaymentSkeleton key={index} className="mb-4" />
            ))}
          </Fragment>
        ) : (
          <Fragment>
            {loans.length > 0 ? (
              loans.map((loan) => (
                <LoanLink
                  isActive={!!loan.loanpro_loan}
                  badgeTitle={layoutDetails.link.badgeTitle}
                  key={loan.id}
                  icon={layoutDetails.link.icon}
                  title={layoutDetails.link.title}
                  description={layoutDetails.link.description(loan)}
                  onClick={() =>
                    navigate(`/documents/loan/${loan.id}`, {
                      state: selectedProduct,
                    })
                  }
                />
              ))
            ) : (
              <div className="mt-24 text-center grow">
                <img src={EmptyStateStoreSVG} className="mx-auto" />
                <div className="font-semibold mb-2 mt-6">
                  {layoutDetails.emptyState.title}
                </div>
                <div className="text-sm mb-8 text-slate-500-kiwi">
                  {layoutDetails.emptyState.description}
                </div>
                <PrimaryButton onClick={layoutDetails.emptyState.action}>
                  {layoutDetails.emptyState.button}
                </PrimaryButton>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
