import  { Fragment, useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import mixpanel from 'utils/mixpanel'
import { InformationCircleIcon } from '@heroicons/react/outline'

import ProgressBar from 'components/ProgressBar'
import Button from 'components/Button'
import NumberFormat from 'react-number-format'
import moneyFormat from 'utils/moneyFormat'
import AnnualIncomeModal from 'components/Modals/AnnualIncome'
import AdditionalIncome from 'components/Modals/AdditionalIncome'
import { UserContext } from 'context/UserContext'
import { CreditLimitContext } from 'context/CreditLimitContext'

export default function CreditLimitSurvey() {
    const navigate = useNavigate()
    const location = useLocation()

    const { state: userState } = useContext(UserContext)
    const { state: creditLimitState, setState: setCreditLimitState } =
        useContext(CreditLimitContext)

    const [loading, setLoading] = useState(false)
    const [creditLimit, setCreditLimit] = useState(0)
    const [annualModal, setAnnualModal] = useState(false)
    const [additionalModal, setAdditionalModal] = useState(false)

    useEffect(() => {
        const {
            products: {
                personal_loan: {
                    application: { credit_limit }
                }
            }
        } = userState

        setCreditLimit(credit_limit)
        setCreditLimitState({
            ...creditLimitState,
            prevPath: location.state?.prevPath
        })
    }, [])

    const onContinue = (survey) => {
        try {
            if (loading === true) {
                return
            }

            setLoading(true)

            setCreditLimitState({
                ...creditLimitState,
                survey: {
                    ...survey,
                    completed: true
                }
            })

            mixpanel.track('Limit Increase Survey', {
                'Annual Income': survey.annual
            })

            navigate('/credit-limit/bank-statements')
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onBackward = () => {
        navigate(creditLimitState.prevPath ?? '/home')
    }

    const validationSchema = Yup.object().shape({
        annual: Yup.string().required('Este campo es requerido'),
        additional: Yup.string(),
        mortgage: Yup.string().required('Este campo es requerido')
    })

    return (
        <Formik
            initialValues={creditLimitState.survey}
            validationSchema={validationSchema}
            onSubmit={onContinue}>
            {({ errors, touched }) => (
                <Fragment>
                    <ProgressBar progress="1/2" onBackward={onBackward} />
                    <AnnualIncomeModal
                        isVisible={annualModal}
                        closeModal={() => setAnnualModal(!annualModal)}
                    />
                    <AdditionalIncome
                        isVisible={additionalModal}
                        closeModal={() => setAdditionalModal(!additionalModal)}
                    />

                    <Form className="flex flex-col grow" autoComplete="off">
                        <div>
                            <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                                Aumento de límite
                            </h1>

                            <p>Responde a las siguientes preguntas.</p>
                        </div>

                        <div className="my-10 bg-blue-50-kiwi p-4 rounded-lg flex flex-col items-center border border-dashed border-blue-kiwi">
                            <div className="text-dark-kiwi font-semibold text-5xl">
                                ${moneyFormat(creditLimit)}
                            </div>
                            <div className="text-blue-kiwi text-sm">
                                Límite de crédito actual
                            </div>
                        </div>

                        <div className="mb-10 flex flex-col gap-6">
                            <div>
                                <label className="flex text-sm mb-2">
                                    <span>
                                        ¿Cuál es tu ingreso total anual?
                                    </span>
                                    <InformationCircleIcon
                                        className="shrink-0 ml-4 w-5 text-blue-kiwi cursor-pointer"
                                        onClick={() =>
                                            setAnnualModal(!annualModal)
                                        }
                                    />
                                </label>
                                <Field name="annual" id="annual">
                                    {({ field, ...props }) => (
                                        <NumberFormat
                                            {...field}
                                            {...props}
                                            id="annual"
                                            thousandSeparator={true}
                                            prefix={'$ '}
                                            allowNegative={false}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            placeholder="$ 0.00"
                                            autoComplete="off"
                                            className={`focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${errors.annual && touched.annual
                                                ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                                }`}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="annual"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                            <div>
                                <label
                                    className="flex text-sm mb-2">
                                    <span>
                                        Ingreso adicional anual no tributable
                                        (opcional)
                                    </span>
                                    <InformationCircleIcon
                                        className="shrink-0 ml-4 w-5 text-blue-kiwi cursor-pointer"
                                        onClick={() =>
                                            setAdditionalModal(!additionalModal)
                                        }
                                    />
                                </label>
                                <Field name="additional" id="additional">
                                    {({ field, ...props }) => (
                                        <NumberFormat
                                            {...field}
                                            {...props}
                                            id="additional"
                                            thousandSeparator={true}
                                            prefix={'$ '}
                                            allowNegative={false}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            placeholder="$ 0.00"
                                            autoComplete="off"
                                            className={`focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${errors.additional &&
                                                touched.additional
                                                ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                                }`}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="additional"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                            <div>
                                <label
                                    className="inline-block text-sm mb-2">
                                    Pago mensual de hipoteca/alquiler
                                </label>
                                <Field name="mortgage" id="mortgage">
                                    {({ field, ...props }) => (
                                        <NumberFormat
                                            {...field}
                                            {...props}
                                            id="mortgage"
                                            thousandSeparator={true}
                                            prefix={'$ '}
                                            allowNegative={false}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            placeholder="$ 0.00"
                                            autoComplete="off"
                                            className={`focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${errors.mortgage &&
                                                touched.mortgage
                                                ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                                }`}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="mortgage"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>

                        <Button
                            className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                            loading={loading}
                            type="submit">
                            Continuar
                        </Button>
                    </Form>
                </Fragment>
            )}
        </Formik>
    )
}
