import  {useContext, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {RequestLoanContext} from 'context/RequestLoanContext';
import {UserContext} from 'context/UserContext';

export default function RequestLoanBankStatements() {
    const navigate = useNavigate();
    const {state: requestState} = useContext(RequestLoanContext);
    const {state: userState} = useContext(UserContext);

    useEffect(() => {
        dataLayer.push({
            event: 'new_application',
            eventCategory: 'loan_process',
            eventAction: 'loan_process_bank_account',
            dl_user_id: userState.user.id,
        });

        if (requestState.residence.completed === false) {
            return navigate('/request-loan/residence');
        }

        if (requestState.validation.completed === false) {
            return navigate('/request-loan/validation');
        }
    });

    return <Outlet />;
}
