import ArrowLeft from 'assets/icons/back.svg'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const BackButton = ({ route }) => {
    const navigate = useNavigate()

    const onClick = () => {
        return navigate(route)
    }

    return (
        <img
            className="ml-2 cursor-pointer"
            width="8"
            src={ArrowLeft}
            onClick={onClick}
            alt="kiwi-image"
        />
    )
}

BackButton.propTypes = {
    route: PropTypes.string.isRequired
}

export default BackButton
