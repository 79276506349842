import PropTypes from "prop-types";

const NotificationSuccessIcon = ({ className, ...props }) => (
  <svg
    width="171"
    height="138"
    viewBox="0 0 171 138"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <circle cx="85.1038" cy="76.5" r="61.5" fill="#EFFAF4" />
    <circle cx="84.5002" cy="76.4546" r="37.5" fill="#D7F4E1" />
    <g clipPath="url(#clip0_23_477)">
      <path
        d="M98.9969 92.0008H77.2736C75.0519 92.0008 73.2502 90.1991 73.2502 87.9775V72.6908C73.2502 70.4691 75.0519 68.6675 77.2736 68.6675H98.9977C101.219 68.6675 103.021 70.4691 103.021 72.6908V87.9783C103.02 90.1991 101.219 92.0008 98.9969 92.0008Z"
        fill="#97DEB1"
      />
      <path
        d="M74.9438 89.0841H73.5522C71.2513 89.0841 69.3855 87.2183 69.3855 84.9175V69.0841C69.3855 66.7833 71.2513 64.9175 73.5522 64.9175H84.3588"
        stroke="#043960"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.219 78.0986V84.9161C100.219 87.217 98.3529 89.0828 96.0521 89.0828H81.8054"
        stroke="#043960"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.3853 70.75L83.6128 78.4408C84.3544 78.8417 85.2486 78.8417 85.9903 78.4408L89.2961 76.6542"
        stroke="#043960"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.3128 58.145C92.257 58.145 88.9695 61.4325 88.9695 65.4884C88.9695 66.785 89.3086 68.0009 89.8986 69.0584L88.9853 72.3284C88.902 72.6259 89.1761 72.9009 89.4745 72.8175L92.7462 71.9042C93.8028 72.4925 95.0178 72.8317 96.3136 72.8317C100.369 72.8317 103.657 69.5442 103.657 65.4884C103.657 63.575 102.925 61.8325 101.726 60.5259"
        stroke="#043960"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.4988 63.3306V67.6497"
        stroke="#043960"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.4985 63.3306L94.8735 64.2622"
        stroke="#043960"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <ellipse cx="117.8" cy="4.21308" rx="4.21308" ry="4.21308" fill="#D7F4E1" />
    <ellipse
      cx="161.804"
      cy="123.116"
      rx="6.08557"
      ry="6.08557"
      fill="#D7F4E1"
    />
    <ellipse
      cx="163.208"
      cy="50.557"
      rx="7.48993"
      ry="7.48993"
      fill="#D7F4E1"
    />
    <ellipse
      cx="8.72817"
      cy="108.381"
      rx="8.42617"
      ry="8.42617"
      fill="#D7F4E1"
    />
    <circle cx="19.6814" cy="24.6358" r="4.6812" fill="#D7F4E1" />
    <defs>
      <clipPath id="clip0_23_477">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(64.802 57)"
        />
      </clipPath>
    </defs>
  </svg>
);

NotificationSuccessIcon.displayName = "NotificationSuccessIcon";

NotificationSuccessIcon.propTypes = {
  className: PropTypes.string,
};

export default NotificationSuccessIcon;
