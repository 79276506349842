import http from 'utils/http';

const fetchPaymentProfilesComparison = async () => {
    const response = await http.get('/payment-profile/plaid-accounts-underwriting');
    return response;
};

export default {
    fetchPaymentProfilesComparison
};
