import axios from "axios";

const getFingerprintResponse = async (requestId) => {
  try {
    const fingerprintResponse = await axios.get(
      `${process.env.REACT_APP_FINGERPRINT_API_URL}/${requestId}`,

      {
        headers: {
          Accept: "application/json",

          "Auth-API-Key": `${process.env.REACT_APP_FINGERPRINT_API_SERVER_KEY}`,
        },
      },
    );

    return fingerprintResponse;
  } catch (error) {
    console.error("Error fetching fingerprint response:", error);
    throw new Error("Failed to fetch fingerprint response");
  }
};

const getIpInfo = async (requestId) => {
  try {
    const fingerprintResponse = await getFingerprintResponse(requestId);

    const ipLocation =
      fingerprintResponse?.data?.products?.identification?.data?.ipLocation;

    if (!ipLocation) {
      console.error(
        "Failed to retrieve IP information from fingerprint response",
      );
      return null;
    }

    return ipLocation;
  } catch (error) {
    console.error("Error in getIpInfo:", error.message);
    return null;
  }
};

export { getFingerprintResponse, getIpInfo };
