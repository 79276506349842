import { useContext, useEffect, useState } from "react";
import {
  getTransactions,
  getTransactionsGrouped,
} from "../services/walletTransactions";
import { RewardsContext } from "../store/rewards.context";

export const useWalletTransactions = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const { setTransactions, setTransactionsGroup, transactions, transactionsGroup } = useContext(RewardsContext)

  const refetch = async (reload) => {
    setLoading(true);
    try {
      const transactions = await getTransactions({ page, limit });
      setTransactions((prev) => {
        if (reload) return transactions.data;
        return [...prev, ...transactions.data];
      });
    } catch (error) {
      console.log("🚀 ~ createWal ~ error:", error);
    }
    setLoading(false);
  };

  const refetchGrouped = async () => {
    setLoading(true);
    try {
      const transactions = await getTransactionsGrouped();
      setTransactionsGroup(transactions);
    } catch (error) {
      console.log("🚀 ~ error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    refetch();
    refetchGrouped();
  }, []);

  const nextPage = () => {
    setPage((prev) => prev + 1);
    refetch();
  };

  const changeLimit = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
    refetch(true);
  };

  return {
    transactions,
    transactionsGroup,
    refetch,
    refetchGrouped,
    loading,
    nextPage,
    changeLimit,
  };
};
