import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { CashbackAPI } from "api";
import { CashbackContext } from "context/CashbackContext";
import { CASHBACK_TRANSACTION_STATUS } from "constants";

import BackwardButton from "components/Button/Backward";
import CashbackEmptySVG from "assets/onboarding_v3/cashback_empty.svg";
import PaymentSkeleton from "components/Skeleton/Payment";
import CashbackPendingComponent from "components/CashbackPendingComponent";
export default function CashbackPendingOfferList() {
  const navigate = useNavigate();
  const { state: cashbackState, setState: setCashbackState } =
    useContext(CashbackContext);

  const [processedOffersPending, setProcessedOffersPending] = useState([]);
  const [loading, setLoading] = useState(true);

  const onBackward = () => {
    navigate("/cashback/list-offers");
  };

  const getProcessedOffersPending = async () => {
    try {
      setLoading(true);
      const { data } = await CashbackAPI.getProcessedOffersByStatus({
        cashback_id: cashbackState.cashback_id,
        status: CASHBACK_TRANSACTION_STATUS.PENDING,
      });
      setProcessedOffersPending(data);
    } catch (error) {
      console.error("Error fetching processed offers:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSelectOffer = async (offer) => {
    setCashbackState((state) => ({
      ...state,
      selectedOffer: offer,
    }));
    navigate("/cashback/offer-details");
  };

  useEffect(() => {
    getProcessedOffersPending();
  }, []);

  return (
    <>
      <div className="w-full flex min-h-full h-full flex-col px-6 pb-10 pt-10">
        <BackwardButton onBackward={onBackward} />
        <h2 className="font-semibold text-slate-900-kiwi text-2xl">
          Ofertas activas
        </h2>

        {!loading ? (
          processedOffersPending?.[0] ? (
            <div className="flex flex-col gap-6 mt-10">
              {processedOffersPending.map((offer) => (
                <div key={offer.id}>
                  <CashbackPendingComponent
                    offer={offer.offer}
                    onSelectOffer={() => onSelectOffer(offer.offer)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col gap-6 h-full">
              <div className="flex flex-col items-center justify-center gap-8 h-full ">
                <img src={CashbackEmptySVG} />
                <div className="text-center">
                  <p className="text-slate-600 font-semibold">
                    Aún no tienes historial
                  </p>
                  <p className="text-slate-400-kiwi text-sm mt-2">
                    ¿Estás listo para comenzar a generarlo?
                  </p>
                  <p className="text-slate-400-kiwi text-sm">
                    Explora las ofertas y comienza a generar tu historial de
                    cashback
                  </p>
                </div>
              </div>
              <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                onClick={onBackward}
              >
                Explorar ofertas
              </button>
            </div>
          )
        ) : (
          <div className="flex flex-col gap-4 mt-10">
            {Array.from({ length: 10 }).map((_, index) => (
              <PaymentSkeleton key={index} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}
