import Button from "components/Button";
import BaseModal from "../BaseModal";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
export default function AlertClosedOffer({
  isVisible,
  icon,
  offer,
  closeModal,
  redirectOfferSelected,
}) {
  const [title, setTitle] = useState("");
  const [text_time, setTextTime] = useState("");
  useEffect(() => {
    setTitle(
      `${offer?.store.merchant.merchant_name ?? "---"} está cerrada en este momento`,
    );
    setTextTime(`Abre a las ${offer?.store.open_hours ?? "---"} `);
  }, [offer]);

  return (
    <BaseModal isVisible={isVisible} closeModal={closeModal}>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col items-center text-center gap-8">
          <img src={icon} className="w-44" />
          <div className="flex flex-col gap-6">
            <h2 className="text-slate-900 font-semibold text-xl">{title}</h2>
            <div>
              <p>
                Puedes reclamar esta oferta de todos modos o esperar hasta que
                este abierta.
              </p>
              <p className="font-semibold">{text_time}</p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-6">
          <Button
            onClick={redirectOfferSelected}
            className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto w-full"
          >
            Reclamar de todos modos
          </Button>
          <Button
            onClick={closeModal}
            className="cursor-pointer !bg-white !text-blue-kiwi w-full  rounded-xl"
          >
            Cancelar
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}

AlertClosedOffer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  offer: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  redirectOfferSelected: PropTypes.func.isRequired,
};
