import { UserContext } from "context/UserContext";
import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { GiftIcon } from "assets/icons_v3/fonts";
import { ReferralsAPI } from "api";

export default function HomeHeader({ className }) {
  const navigate = useNavigate();
  const { state: userState } = useContext(UserContext);
  const { user } = userState;

  const [hasReferrals, setHasReferrals] = useState(false);

  useEffect(() => {
    getUserReferrals();
  }, []);

  const getUserReferrals = async () => {
    const {
      data: { data: referrals },
    } = await ReferralsAPI.getUserReferrals(user.id);

    if (referrals) {
      setHasReferrals(referrals.length ? true : false);
    }
  };

  const onReferralCode = () => {
    navigate("/referrals");
  };

  return (
    <div className={`flex items-center gap-6 justify-between ${className}`}>
      <div>
        <div className="text-sm text-slate-500-kiwi">Hola,</div>
        <div className="font-semibold -my-1 text-xl text-slate-900-kiwi">
          {user.first_name}
        </div>
      </div>
      <div className="flex gap-2">
        <div
          className="w-9 h-9 bg-gray-200-kiwi rounded-full flex relative cursor-pointer"
          onClick={onReferralCode}
        >
          <GiftIcon className="w-5 m-auto" />

          {hasReferrals && (
            <div className="w-2 h-2 bg-red-kiwi rounded-full absolute shrink-0 right-0"></div>
          )}
        </div>
      </div>
    </div>
  );
}

HomeHeader.propTypes = {
  className: PropTypes.string,
};
