import { createContext, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";

export const RewardsContext = createContext()



export const RewardsProvider = () => {
    const [wallet, setWallet] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [transactionsGroup, setTransactionsGroup] = useState({});

    const reset = () => {
        setWallet(null)
        setTransactions([])
        setTransactionsGroup({})
    }

    const providerValues = useMemo(() => ({
        wallet,
        transactions,
        transactionsGroup,
        reset,
        setWallet,
        setTransactions,
        setTransactionsGroup,
    }), [wallet,
        transactions,
        transactionsGroup,
        reset,
        setWallet,
        setTransactions,
        setTransactionsGroup,])

    return (
        <RewardsContext.Provider value={providerValues}>
            <Outlet />
        </RewardsContext.Provider>
    )
}