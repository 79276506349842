import  { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from './../../../context/AuthContext'

import SplashComponent from './../../../components/Splash'

export default function Splash() {
    const navigate = useNavigate()

    const { state: authState } = useContext(AuthContext)

    useEffect(() => {
        setTimeout(() => {
            if (authState.firstTime) {
                return navigate('/tour')
            }

            navigate('/login')
        }, 2500)
    }, [])

    return <SplashComponent />
}
