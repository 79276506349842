import httpRewards from "utils/httpRewards";

const getTransactions = async ({ page = 1, limit = 20 }) => {
  const { data } = await httpRewards.get(
    `/wallets/transactions?page=${page}&limit=${limit}`,
  );
  return data;
};
const getTransactionsGrouped = async () => {
  const { data } = await httpRewards.get(`/wallets/transactions/grouped`);
  return data?.transactions ?? {};
};

const createWithdrawalTransaction = async (values) => {
  const { data } = await httpRewards.post("/wallets/transactions/withdraw", {
    amount: values.amount,
    label: values.label,
    type: "transfer",
  });
  return data;
};

export { getTransactions, getTransactionsGrouped, createWithdrawalTransaction };
