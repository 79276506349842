import BaseModal from '../BaseModal'
import PropTypes from 'prop-types';

const LateFee = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="Cargo por mora"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6 mt-6">
                <p>
                    El &quot;cargo por mora&quot; o &quot;late fee&quot; es una tarifa que se aplica
                    cuando el pago se retrasa más de 11 días respecto a la fecha
                    de pago acordada, equivalente al 2.50% del monto en mora.
                </p>
            </div>
        </BaseModal>
    )
}

LateFee.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default LateFee
