import dayjs from 'dayjs'
import anime from 'animejs'
import  { Fragment, useContext, useEffect, useState } from 'react'
import { UserContext } from 'context/UserContext'
import { EXPIRATION_CUPON_DAYS, REFERRAL_AMOUNT } from 'constants'
import { ArrowRightIcon } from '@heroicons/react/solid'
import ConfettiExplosion from 'react-confetti-explosion'
import WomanHappyMoneyImg from 'assets/women_happy_money.svg'
import CuponModal from 'components/Modals/Cupon'
import ShareModal from 'components/Modals/ShareSocial'
import mixpanel from 'utils/mixpanel'
import PropTypes from 'prop-types'

const CuponCard = ({ className }) => {
    const { state: userState } = useContext(UserContext)
    const {
        products: {
            personal_loan: { loan }
        }
    } = userState

    const [cuponVisible, setCuponVisible] = useState(false)
    const [showCuponCard, setShowCuponCard] = useState(false)
    const [shareVisible, setShareVisible] = useState(false)
    const [showConfetti, setShowConfetti] = useState(false)

    const loanDate = dayjs(loan.date ?? loan.created_at)
    const expirationDate = dayjs(loanDate.add(EXPIRATION_CUPON_DAYS, 'day'))
    const [currentTime, setCurrentTime] = useState(dayjs())
    const [timeLeft, setTimeLeft] = useState(expirationDate.diff(currentTime))

    useEffect(() => {
        countdown()
    }, [])

    useEffect(() => {
        const showAnimation = sessionStorage.getItem('cuponConfetti')
        if (showCuponCard && !showAnimation) {
            fadeInAnimation()
        }
    }, [showCuponCard])

    useEffect(() => {
        const interval = setInterval(countdown, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [currentTime])

    const countdown = () => {
        const newTimeLeft = expirationDate.diff(currentTime)
        setTimeLeft(newTimeLeft)

        if (newTimeLeft <= 0) {
            setShowCuponCard(false)
        } else {
            setShowCuponCard(true)
            setCurrentTime(dayjs())
        }
    }

    const days = Math.floor(timeLeft / (24 * 60 * 60 * 1000))
    const hours = Math.floor(
        (timeLeft % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
    )
    const minutes = Math.floor((timeLeft % (60 * 60 * 1000)) / (60 * 1000))
    const seconds = Math.floor((timeLeft % (60 * 1000)) / 1000)

    const toggleCuponModal = () => {
        setCuponVisible(!cuponVisible)
    }

    const toggleShareModal = () => {
        setShareVisible(!shareVisible)
    }

    const onAcceptCupon = () => {
        mixpanel.track('Share Story')
        toggleCuponModal()
        toggleShareModal()
    }

    const onShareSocial = () => {
        toggleShareModal()
    }

    const fadeInAnimation = () => {
        let container = document.getElementById('cupon-card')

        anime
            .timeline({
                easing: 'easeInCubic',
                targets: container,
                update: ({ progress }) => {
                    if (progress >= 40 && showConfetti === false) {
                        setShowConfetti(true)
                    }
                },
                complete: () => {
                    sessionStorage.setItem('cuponConfetti', true)
                }
            })
            .add({
                opacity: [0, 1],
                duration: 500
            })
            .add(
                {
                    easing: 'easeOutElastic(1, .6)',
                    translateX: [350, 0],
                    duration: 800
                },
                '-=250'
            )
    }

    return (
        <Fragment>
            {showCuponCard && (
                <div
                    id="cupon-card"
                    className={`p-4 bg-blue-50-kiwi/40 rounded-2xl flex justify-between ${className}`}>
                    {showConfetti && (
                        <div className="absolute left-1/2">
                            <ConfettiExplosion
                                colors={[
                                    '#ffcc7f ',
                                    '#ff9900 ',
                                    '#ffc166',
                                    '#ffd699 '
                                ]}
                                particleCount={20}
                                particleSize={10}
                            />
                        </div>
                    )}
                    <div>
                        <p className="text-regal-blue-kiwi mb-1 text-xs font-semibold">
                            Acaba en {days} {days === 1 ? 'día' : 'días'}{' '}
                            {String(hours).padStart(2, '0')}:
                            {String(minutes).padStart(2, '0')}:
                            {String(seconds).padStart(2, '0')}
                        </p>
                        <p className="font-semibold text-dark-kiwi mb-1 text-sm">
                            ¡Comparte tu éxito!
                        </p>
                        <p className="text-xs mb-4">
                            Te invitamos a ganarte un bono de ${REFERRAL_AMOUNT}{' '}
                            que se aplicaría para reducir tu balance de crédito.
                        </p>
                        <button
                            className="flex items-center gap-2 text-blue-kiwi underline text-xs font-medium"
                            onClick={toggleCuponModal}>
                            Compartir ahora
                            <ArrowRightIcon className="w-4 h-4 text-blue-kiwi" />
                        </button>
                    </div>

                    <img src={WomanHappyMoneyImg} alt="Bono" className="ml-6" />
                </div>
            )}

            <CuponModal
                isVisible={cuponVisible}
                closeModal={toggleCuponModal}
                acceptModal={onAcceptCupon}
            />
            <ShareModal
                isVisible={shareVisible}
                closeModal={toggleShareModal}
                shareSocial={onShareSocial}
            />
        </Fragment>
    )
}

CuponCard.propTypes = {
    className: PropTypes.string
};

export default CuponCard
