import BaseModal from '../BaseModal'
import FacebookIcon from 'assets/social/facebook.svg'
import InstagramIcon from 'assets/social/instagram.svg'
import PropTypes from 'prop-types'

const ShareModal = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title={'Comparte tu story vía:'}
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex gap-6 mt-8">
                <a
                    href="fb://facewebmodal/f?href=https://it-it.facebook.com/"
                    className="flex items-center flex-col cursor-pointer">
                    <img width={40} src={FacebookIcon} alt="Facebook icon" />
                    <p className="mt-4 text-xs">Facebook</p>
                </a>
                <a
                    href="instagram://app"
                    className="flex items-center flex-col cursor-pointer">
                    <img width={40} src={InstagramIcon} alt="Instagram icon" />
                    <p className="mt-4 text-xs">Instagram</p>
                </a>
            </div>
        </BaseModal>
    )
}

ShareModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ShareModal
