import Advisement from "components/Advisement";
import PrimaryButton from "components/Button/Primary";
import PageTitle from "components/PageTitle";
import { useNavigate } from "react-router-dom";
import CardAccount from "./CardAccount";

const SectionAccount = ({ account }) => {
  const navigate = useNavigate();

  const hasDisconnected = account.status === "disconnected";

  const onContinue = () => {
    navigate("/bank-accounts/plaid", {
      state: {
        redirectPath: "/bank-accounts/success",
        redirectToBack: "/bank-accounts",
      },
    });
  };
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-4 w-full">
          <PageTitle>Cuentas bancarias</PageTitle>
          <p>Puedes verificar el estado de tu cuenta de banco vinculada.</p>
        </div>
        <CardAccount account={account} />
      </div>
      {hasDisconnected && (
        <div className="flex flex-col mt-auto">
          <Advisement icon="bank">
            Puedes reconectar tu banco actual o elegir otro diferente. Asegúrate
            de que tu cuenta esté activa.
          </Advisement>

          <PrimaryButton onClick={onContinue} className="mt-4">
            Conectar cuenta de banco
          </PrimaryButton>
        </div>
      )}
    </>
  );
};

export default SectionAccount;
