import React, {useContext} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {UserContext} from 'context/UserContext';
import {useIsSecondMoneyOnTheWay} from 'hooks/personalLoan';

export const ActiveLoanMiddleware = () => {
    const {state: userState} = useContext(UserContext);
    const {
        products: {personal_loan},
    } = userState;

    return personal_loan.status === 'current' ? (
        <Outlet />
    ) : (
        <Navigate to="/home" />
    );
};

export const MoneysOnTheWayMiddlware = () => {
    const isSecondMoneyOnTheWay = useIsSecondMoneyOnTheWay();

    return isSecondMoneyOnTheWay ? (
        <Navigate to="/moneys-on-the-way" />
    ) : (
        <Outlet />
    );
};
