import {useNavigate} from 'react-router-dom';
import {usePersonalLoanStatus} from './usePersonalLoanStatus';

import {
    PersonalLoanApplyRedirect,
    PersonalLoanApprovedRedirect,
} from 'utils/redirectPersonalLoan';

export function usePersonalLoanRedirect() {
    const navigate = useNavigate();

    const {
        isPersonalLoanNeedVerificationCall,
        isPersonalLoanMoneyOnWay,
        isPersonalLoanMesalve,
        isPersonalLoanApply,
        isPersonalLoanReviewing,
        isPersonalLoanApproved,
        isPersonalLoanRejected,
        isPersonalLoanActive,
        personalLoanContext,
    } = usePersonalLoanStatus();

    const personalLoanRedirect = async () => {
        if (isPersonalLoanNeedVerificationCall) {
            return navigate('/approved-loan/need-verification-call');
        }

        if (isPersonalLoanMoneyOnWay) {
            return navigate('/moneys-on-the-way');
        }

        if (isPersonalLoanMesalve) {
            return navigate('/mesalve-code');
        }

        if (isPersonalLoanApply || isPersonalLoanReviewing) {
            const route = await PersonalLoanApplyRedirect(personalLoanContext);
            return navigate(route);
        }

        if (isPersonalLoanApproved) {
            const route =
                await PersonalLoanApprovedRedirect(personalLoanContext);
            return navigate(route);
        }

        if (isPersonalLoanRejected) {
            return navigate('/rejected-loan');
        }

        if (isPersonalLoanActive) {
            return navigate('/active-loan/disbursement');
        }
    };

    return personalLoanRedirect;
}
