import {
    BoxAllIcon,
    GasStationIcon,
    ForkKnifeIcon,
    ShoppingBag,
} from 'assets/icons_v3/fonts';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';

const MenuCashbackComponent = ({selectOption, selectedCategory}) => {
    const menu_list = [
        {
            label: 'all',
            title: 'Todas',
            icon: BoxAllIcon,
        },
        {
            label: 'gas',
            title: 'Gasolineras',
            icon: GasStationIcon,
        },
        {
            label: 'food',
            title: 'Comida',
            icon: ForkKnifeIcon,
        },
        {
            label: 'store',
            title: 'Tiendas',
            icon: ShoppingBag,
        },
    ];
    return (
        <Swiper
            className="cursor-grab !px-6"
            slidesPerView={'auto'}
            spaceBetween={16}>
            {menu_list.map((item, index) => (
                <SwiperSlide
                    className={`border ${selectedCategory === item.label ? 'bg-regal-blue-950-kiwi/20' : ''}  border-regal-blue-950-kiwi rounded-lg h-8 py-1.5 px-3 gap-2 cursor-pointer !w-max`}
                    key={index}
                    onClick={() => selectOption(item.label)}>
                    <div className="flex gap-2 text-regal-blue-kiwi items-center">
                        <item.icon className="w-5 h-5" />
                        <p className="text-sm font-medium">{item.title}</p>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default MenuCashbackComponent;
